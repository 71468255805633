import classNames from 'classnames'
import React from 'react'

import Table from 'common/components/table/Table'

export interface AvailableQuantityProps {
  inventoryItem: { stock: null | number; committed: number }
}

const AvailableQuantity = ({ inventoryItem }: AvailableQuantityProps) => {
  if (inventoryItem?.stock == null) return <Table.EmptyCellIndicator />

  const availableQuantity = inventoryItem.stock! - inventoryItem.committed

  return <div className={classNames({ 'text-error-default': availableQuantity <= 0 })}>{availableQuantity}</div>
}

export default AvailableQuantity
