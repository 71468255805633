import { CollisionDescriptor, Collision, CollisionDetection, ClientRect } from '@dnd-kit/core'

export function sortCollisionsDesc(
  { data: { value: a } }: CollisionDescriptor,
  { data: { value: b } }: CollisionDescriptor
) {
  return b - a
}

export const getIntersectionRatio = (
  entry: ClientRect,
  target: ClientRect,
  threshold = 0
): { intersectionRatio: number } => {
  const top = Math.max(target.top, entry.top)
  const left = Math.max(target.left, entry.left)
  const right = Math.min(target.left + target.width, entry.left + entry.width)
  const bottom = Math.min(target.top + target.height, entry.top + entry.height)
  const height = bottom - top

  if (left < right && top < bottom) {
    const intersectionArea = height
    const intersectionRatio = intersectionArea / (target.height + entry.height - intersectionArea)

    if (intersectionRatio < threshold) return { intersectionRatio: 0 }

    return { intersectionRatio: Number(intersectionRatio.toFixed(4)) }
  }

  return { intersectionRatio: 0 }
}

type MultiCollisionDetection = (...args: Parameters<CollisionDetection>) => Collision[][]

export const rectIntersection: MultiCollisionDetection = ({ collisionRect, droppableRects, droppableContainers }) => {
  const collisions: CollisionDescriptor[] = []
  const realCollisions: CollisionDescriptor[] = []

  let i = 0

  for (const droppableContainer of droppableContainers) {
    const { id } = droppableContainer
    const rect = droppableRects.get(id)
    const transform = droppableContainer.node.current?.style.transform

    const transformY = transform?.includes('translate3d') ? +transform?.split(',')[1]?.replace('px', '') : 0

    if (rect) {
      const { intersectionRatio } = getIntersectionRatio(rect, collisionRect, 0.6)
      const { intersectionRatio: realIntersectionRatio } = getIntersectionRatio(
        { ...rect, top: rect.top + transformY },
        collisionRect
      )

      if (intersectionRatio > 0) {
        collisions.push({
          id,
          data: { collisionRect, droppableContainer, value: intersectionRatio, index: i },
        })
      }
      if (realIntersectionRatio > 0) {
        realCollisions.push({
          id,
          data: { collisionRect, droppableContainer, value: realIntersectionRatio, index: i },
        })
      }
    }
    i += 1
  }

  return [collisions.sort(sortCollisionsDesc), realCollisions.sort(sortCollisionsDesc)]
}
