import React from 'react'

import classMerge from 'utils/classMerge'

const variants = {
  default: 'max-w-7xl',
  lg: 'max-w-[1920px] px-4',
}

const Page = ({
  variant = 'default',
  className,
  children,
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  variant?: keyof typeof variants
}) => {
  return (
    <div className={classMerge('flex justify-center w-full pt-12 mt-[52px] px-4', className)}>
      <div className={classMerge('w-full', variants[variant])}>{children}</div>
    </div>
  )
}

export default Page
