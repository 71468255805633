import { INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, $isListNode, ListNode } from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ListPlugin as LexicalListPlugin } from '@lexical/react/LexicalListPlugin'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import { SELECTION_CHANGE_COMMAND, $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW } from 'lexical'
import React, { useCallback, useEffect, useState } from 'react'

import BulletListIcon from 'icons/bold/01-Interface Essential/36-Lists/list-bullets.svg'

import ToolbarButton from '../ToolbarButton'

const ListPlugin = () => {
  const [editor] = useLexicalComposerContext()
  const [blockType, setBlockType] = useState('paragraph')

  const handleBulletListClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    blockType === 'ul'
      ? editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
      : editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
  }

  const updatePlugin = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
      const elementDOM = editor.getElementByKey(element.getKey())

      if (elementDOM) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()
          setBlockType(type)
        } else {
          setBlockType(element.getType())
        }
      }
    }
  }, [])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => updatePlugin())
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updatePlugin()
          return false
        },
        COMMAND_PRIORITY_LOW
      )
    )
  }, [])

  return (
    <>
      <LexicalListPlugin />
      <ToolbarButton onMouseDown={handleBulletListClick} isActive={blockType === 'ul'} aria-label="Format Bullet List">
        <BulletListIcon width={16} height={16} />
      </ToolbarButton>
    </>
  )
}

export default ListPlugin
