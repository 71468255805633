import { DesignCustomExtraPrice, DesignType } from '@packages/types'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import QuoteContext from 'cms/quotes/QuoteContext'
import { trpc } from 'common/hooks/trpc'
import withFlag from 'common/users/components/withFlag'

import { useFetchQuote } from '../../hooks/useFetchQuote'
import { defaultPersistenceState, toFormValues } from '../../utils'
import QuoteCard from './QuoteCard'
import QuoteDetailsCard from './QuoteDetailsCard'
import QuoteExpirationCard from './QuoteExpirationCard'
import QuoteHeader from './QuoteHeader'
import QuoteStoreCard from './QuoteStoreCard'
import Button from 'common/components/Button'
import Modal from 'common/components/modal/Modal'
import useNavigationBlockingModal from 'common/components/modal/useNavigationBlockingModal'
import useToast from 'common/components/toast/useToast'
import * as yup from 'yup'
import { mapValues } from 'lodash'

export type CustomizationFormItem = {
  type: DesignType.CustomizationDesign
  id: string
  price: number
  basePrice?: number
  pricing?: Record<string, number>
  customExtraPrices?: DesignCustomExtraPrice[]
}

export type BulkOrderFormItem = {
  type: DesignType.BulkOrderDesign
  id: string
  designs: {
    design: CustomizationFormItem
    quantity: number
  }[]
  ignoreBulkPrices: boolean
  bulkPrices: {
    quantity: string
    price: string
  }[]
  customExtraPrices?: DesignCustomExtraPrice[]
}

export type QuoteFormValues = {
  id: string
  items: {
    design: CustomizationFormItem | BulkOrderFormItem
    quantity: number
  }[]
  taxes?: number
  applyTaxes?: boolean
  expireOn?: string
}

const numberValidator = yup.number().required().min(0)
const QuoteSchema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      design: yup.lazy(design => {
        if (design.type === DesignType.CustomizationDesign) {
          return yup.object().shape({
            type: yup.string().required(),
            id: yup.string().required(),
            price: numberValidator,
            basePrice: numberValidator,
            pricing: yup.lazy(pricing => {
              return yup.object(mapValues(pricing, () => numberValidator))
            }),
            customExtraPrices: yup.array().of(
              yup.object().shape({
                description: yup.string().required(),
                quantity: numberValidator,
                price: numberValidator,
              })
            ),
          })
        }

        return yup.object().shape({
          type: yup.string().required(),
          id: yup.string().required(),
          designs: yup.array().of(
            yup.object().shape({
              design: yup.object().shape({
                id: yup.string().required(),
                type: yup.string().required(),
                basePrice: numberValidator,
                pricing: yup.lazy(pricing => {
                  return yup.object(mapValues(pricing, () => numberValidator))
                }),
                price: numberValidator,
              }),
              quantity: numberValidator.integer(),
            })
          ),
          ignoreBulkPrices: yup.boolean(),
          bulkPrices: yup.array().of(
            yup.object().shape({
              quantity: yup.string().required(),
              price: yup.string().required(),
            })
          ),
          customExtraPrices: yup.array().of(
            yup.object().shape({
              description: yup.string().required(),
              quantity: numberValidator,
              price: numberValidator,
            })
          ),
        })
      }),
      quantity: numberValidator.integer(),
    })
  ),
  taxes: yup.number().min(0),
  applyTaxes: yup.boolean().required(),
  expireOn: yup.date().required(),
})

interface QuoteProps {
  isRevision?: boolean
}

const Quote = ({ isRevision }: QuoteProps) => {
  const history = useHistory()
  const { id: quoteId } = useParams<{ id: string }>()
  const { brandName } = useParams<{ brandName?: string }>()
  const baseUrl = brandName ? `/brands/${brandName}` : ''
  const [isEditing, setIsEditing] = useState(false)
  const { openGenericErrorToast } = useToast()

  const location = useLocation<{ persistence?: typeof defaultPersistenceState }>()
  const persistence = location.state?.persistence

  const { quote, isLoadingQuotes, isFetchingQuotes, invalidateQueries, handleGoBack, quoteSiblings } = useFetchQuote({
    persistence,
    quoteId,
    baseUrl,
    isRevision,
    setIsEditing,
  })

  const { mutateAsync: saveQuote, isLoading: isSaving } = trpc.quote.save.useMutation({
    onSuccess: () => {
      setIsEditing(false)
      invalidateQueries()
    },
    onError: () => {
      openGenericErrorToast('Could not save this quote.')
    },
  })

  const { mutateAsync: saveQuoteRevision, isLoading: isSavingRevision } = trpc.quote.saveRevision.useMutation({
    onSuccess: () => {
      setIsEditing(false)
      invalidateQueries()
    },
    onError: () => {
      openGenericErrorToast('Could not create a revision for this quote.')
    },
  })

  const confirmLeaveModal = useNavigationBlockingModal(history, [!!isRevision && !isSavingRevision && isEditing])

  const handleSubmit = async (values: QuoteFormValues, _formikHelpers: FormikHelpers<QuoteFormValues>) => {
    if (!quote?.id || !values || !isEditing) return
    if (isRevision) {
      const revision = await saveQuoteRevision({
        ...values,
        quoteId: quote.quoteId,
      })

      history.push(`${baseUrl}/quotes/${revision.id}`)
      return
    }

    return await saveQuote({ ...values, id: quote.id })
  }

  return (
    <main>
      <Header />
      <SideMenu />
      <Page data-testid="quote page">
        <QuoteContext.Provider
          value={{
            isEditing,
            setIsEditing,
            isSaving: isSaving || isSavingRevision,
            quote,
            invalidateQueries,
            handleGoBack,
            isFetchingQuotes,
            quoteSiblings,
            isLoadingQuotes,
            isRevision: !!isRevision,
            currenciesInputProps:
              !quote?.currency || quote.currency === 'USD'
                ? { leftAddon: quote?.currency }
                : { rightAddon: quote?.currency },
          }}
        >
          <Formik
            initialValues={{ ...toFormValues(quote) }}
            onSubmit={handleSubmit}
            validationSchema={QuoteSchema}
            enableReinitialize
          >
            <Form id="quote-form">
              <QuoteHeader baseUrl={baseUrl} />

              <div className="flex items-start">
                {isLoadingQuotes || !quote ? (
                  <QuoteCard itemIndex={0} baseUrl={baseUrl} />
                ) : (
                  quote.items?.map(({ design }, index) => {
                    return <QuoteCard key={`${design?.id}-${index}`} itemIndex={index} baseUrl={baseUrl} />
                  })
                )}

                <div className="flex flex-col space-y-8">
                  <QuoteDetailsCard />
                  <QuoteExpirationCard />
                  <QuoteStoreCard />
                </div>
              </div>

              {confirmLeaveModal.isVisible && (
                <Modal {...confirmLeaveModal.modalProps}>
                  <Modal.Title>Unsaved revision</Modal.Title>
                  <Modal.Details>
                    Are you sure you want to leave without finalizing? All changes will be deleted, this action can't be
                    undone
                  </Modal.Details>
                  <Modal.Actions>
                    <Button onClick={confirmLeaveModal.close}>Stay</Button>
                    <Button variant="error" onClick={confirmLeaveModal.forceNavigate}>
                      Leave
                    </Button>
                  </Modal.Actions>
                </Modal>
              )}
            </Form>
          </Formik>
        </QuoteContext.Provider>
      </Page>
    </main>
  )
}

export default withFlag({
  Component: Quote,
  feature: 'quote_phase_1',
})
