import classNames from 'classnames'
import React from 'react'

import PageAside from './PageAside'
import PageContent from './PageContent'
import PageHeader from './PageHeader'
import PageSection from './PageSection'
import PageSeparator from './PageSeparator'

const Page = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div
    {...props}
    className={classNames('print:m-0 print:p-5 relative flex-1 ml-64 mt-[52px] px-5 md:px-12 pb-12 pt-7', className)}
  >
    {children}
  </div>
)

Page.Aside = PageAside
Page.Header = PageHeader
Page.Section = PageSection
Page.Separator = PageSeparator
Page.Content = PageContent

export default Page
