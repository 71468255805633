import { PrintAreaMeasurementUnit } from '@packages/types'

import { CONVERSIONS_RATES_TO_INCHES } from './constants'

export const calculateDPI = (originalDimensions, dimensions, printArea) => {
  const designDimensions = {
    width: dimensions.width / printArea.productPreview.views[printArea.productPreview.designView].scale,
    height: dimensions.height / printArea.productPreview.views[printArea.productPreview.designView].scale,
  }

  return (
    ((originalDimensions.width * originalDimensions.height) / (designDimensions.width * designDimensions.height)) *
    printArea.dpi *
    CONVERSIONS_RATES_TO_INCHES[printArea.measurementUnit]
  )
}

export const convertToPixels = (printArea, scale = 1) => {
  let fullWidth
  let fullHeight
  let fullBleed
  let fullMargins

  switch (printArea.measurementUnit) {
    case PrintAreaMeasurementUnit.Centimeters:
    case PrintAreaMeasurementUnit.Millimeters:
    case PrintAreaMeasurementUnit.Inches:
      fullWidth = (printArea.dpi * printArea.width) / CONVERSIONS_RATES_TO_INCHES[printArea.measurementUnit]
      fullHeight = (printArea.dpi * printArea.height) / CONVERSIONS_RATES_TO_INCHES[printArea.measurementUnit]
      fullBleed = (printArea.dpi * printArea.bleed) / CONVERSIONS_RATES_TO_INCHES[printArea.measurementUnit]
      fullMargins = {
        horizontal:
          (printArea.dpi * printArea.margins.horizontal) / CONVERSIONS_RATES_TO_INCHES[printArea.measurementUnit],
        vertical: (printArea.dpi * printArea.margins.vertical) / CONVERSIONS_RATES_TO_INCHES[printArea.measurementUnit],
      }
      break
    case PrintAreaMeasurementUnit.Pixels:
    default:
      fullWidth = printArea.width
      fullHeight = printArea.height
      fullBleed = printArea.bleed
      fullMargins = {
        horizontal: printArea.margins.horizontal,
        vertical: printArea.margins.vertical,
      }
      break
  }

  return {
    ...printArea,
    width: fullWidth * scale,
    height: fullHeight * scale,
    margins: {
      horizontal: fullMargins.horizontal * scale,
      vertical: fullMargins.vertical * scale,
    },
    bleed: fullBleed * scale,
  }
}
