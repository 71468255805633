import React from 'react'

import Button from 'common/components/Button'
import Card from 'common/components/card/Card'

const SubscriptionCardSkeleton = () => {
  return (
    <Card className="flex flex-1 flex-col">
      <Card.Section className="space-x-6">
        <div className="flex flex-grow flex-wrap space-y-4 justify-between">
          <span className="w-full text-neutral-400 text-xs leading-5">Plan</span>
          <span className="animate-pulse bg-neutral-50 h-5 w-20 rounded-lg" />
          <span className="animate-pulse bg-neutral-50 h-5 w-20 rounded-lg" />
        </div>
        <div className="border-r border-solid border-neutral-100" />
        <div className="flex flex-col space-y-4 items-end">
          <span className="text-neutral-400 text-xs leading-5">Subscription date</span>
          <span className="animate-pulse bg-neutral-50 h-5 w-16 rounded-lg" />
        </div>
      </Card.Section>
      <Card.Separator />
      <Card.Section className="space-x-6">
        <div className="flex flex-col w-[211px] space-y-4">
          <span className="text-neutral-400 text-xs leading-5">Status</span>
          <span className="animate-pulse bg-neutral-50 h-5 w-10 rounded-lg" />
        </div>
        <div className="border-r border-solid border-neutral-100" />
        <div className="flex flex-col flex-grow space-y-4 items-end">
          <span className="text-neutral-400 text-xs leading-5">Billed</span>
          <span className="animate-pulse bg-neutral-50 h-5 w-14 rounded-lg" />
        </div>
      </Card.Section>
      <Card.Separator />
      <Card.Section className="flex justify-between py-3">
        <Button disabled className="w-20 animate-pulse" />
        <Button disabled className="w-20 animate-pulse" />
      </Card.Section>
    </Card>
  )
}

export default SubscriptionCardSkeleton
