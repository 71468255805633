import { FontAssets, FontType, OverflowBehavior, TextAlign, TextVerticalAlign } from '@packages/types'
import React from 'react'

import useMultilineSVGText from './useMultilineSVGText'

interface MultilineTextProps {
  text: string
  fontFamily: string
  fontType?: FontType
  fontSize: number
  fontAssets: FontAssets
  textAlign: TextAlign
  verticalAlign: TextVerticalAlign
  color: string
  outlineColor: string
  outlineWidth: string
  boundingBox?: { maxWidth?: number; maxHeight?: number }
  overflowBehavior?: OverflowBehavior
}

const MultilineText = ({
  text,
  fontFamily,
  fontType,
  fontSize,
  fontAssets,
  textAlign,
  verticalAlign,
  color,
  outlineColor,
  outlineWidth,
  boundingBox,
  overflowBehavior,
}: MultilineTextProps) => {
  const html = useMultilineSVGText({
    text,
    fontFamily,
    fontType,
    fontSize,
    fontAssets,
    textAlign,
    verticalAlign,
    color,
    outlineColor,
    outlineWidth,
    boundingBox,
    overflowBehavior,
  })

  return <div dangerouslySetInnerHTML={{ __html: html }} style={{ width: '100%', height: '100%' }} />
}

export default MultilineText
