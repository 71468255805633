import React from 'react'

import BlankState from 'common/components/blankState/BlankState'
import SkateboardIcon from 'icons/custom/bold-skateboard.svg'

export type ActiveBlankStateProps = {
  onClick: () => void
}

const ActiveBlankState = ({ onClick }: ActiveBlankStateProps) => {
  return (
    <>
      <BlankState.Icon Icon={SkateboardIcon} className="text-neutral-300" />
      <BlankState.Title>There are no products, yet</BlankState.Title>
      <BlankState.Details>
        Create your first product from scratch or explore all the features and possibilities with our demos.
      </BlankState.Details>
      <BlankState.Action onClick={onClick}>Create product</BlankState.Action>
    </>
  )
}

export default ActiveBlankState
