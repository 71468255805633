import classNames from 'classnames'
import React from 'react'

import LinkedIcon from 'icons/bold/05-Internet-Networks-Servers/07-Data-Transfer/data-transfer-diagonal.svg'

import { FormikVariants, VariantFormValue } from '../types'
import { getInventoryItem } from '../utils'
import useSharedSkus from './useSharedSkus'

export interface SharedCellProps {
  variant: VariantFormValue
  formik: FormikVariants
  focusedSku?: string
  locationId: string
}

const SharedCell = ({ variant, formik, focusedSku, locationId }: SharedCellProps) => {
  const inventoryItem = getInventoryItem(formik, variant)

  const skuSharedData = useSharedSkus(locationId, inventoryItem.sku, formik)

  if (inventoryItem.sku === '' || skuSharedData == null || skuSharedData?.variantIds.length < 2) return null

  return (
    <LinkedIcon
      className={classNames('w-3 h-3 fill-neutral-300', { 'fill-primary-500': focusedSku === inventoryItem?.sku })}
    />
  )
}

export default SharedCell
