import React from 'react'
import { toast } from 'react-hot-toast'

import Toast from './Toast'
import { ToastType } from './types/toastType'

interface BasicToastProps {
  id: string
  visible?: boolean
  dismissable?: boolean
  type: ToastType
  message: string
}

const BasicToast = ({ id, visible, type, message, dismissable = true }: BasicToastProps) => {
  return (
    <Toast visible={visible}>
      <Toast.Icon type={type} />
      <Toast.Message>{message}</Toast.Message>
      {dismissable && <Toast.CloseButton onClick={() => toast.dismiss(id)} />}
    </Toast>
  )
}

export default BasicToast
