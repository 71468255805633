import countryLanguages from '@packages/data/countryLanguages.json'
import * as normalize from '@packages/normalizer'
import { Product, DefaultTheme } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { FormikHelpers } from 'formik'
import { set } from 'lodash/fp'
import React from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import usePluginService from 'cms/plugins/hooks/usePluginService'
import * as themeUtils from 'cms/theme/utils'
import type { Translation as TranslationType, TranslationData } from 'cms/translations/types/translation'
import { NetworkError } from 'common/api/types/error'
import BlankState from 'common/components/blankState/BlankState'
import Button from 'common/components/Button'
import IconButton from 'common/components/IconButton'
import Modal from 'common/components/modal/Modal'
import Popover from 'common/components/popover/Popover'
import Select from 'common/components/inputs/select/Select'
import Tabs from 'common/components/tabs/Tabs'
import useModal from 'common/components/modal/useModal'
import usePopover from 'common/components/popover/usePopover'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import useCustomizerProductService from 'common/customizerProducts/hooks/useCustomizerProductService'
import useGoBack from 'common/hooks/useGoBack'
import { trpc } from 'common/hooks/trpc'
import useProductService from 'common/products/hooks/useProductService'
import ProductAsyncSelect from 'common/products/components/ProductAsyncSelect'
import { FeatureFlag } from 'common/users/components/withFlag'
import quoteConfig from 'customizer/quote/config.json'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'
import EyeIcon from 'icons/core-solid/interface-essential/interface-edit-view.svg'
import TranslateIcon from 'icons/regular/01-Interface Essential/35-Text-Options/translate.svg'
import type { Plugin } from 'plugins/types/plugin'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import useTranslationService from './../hooks/useTranslationService'
import { getCustomizerProductTexts, getPluginTexts, getTexts } from '../utils'
import TranslationForm from './TranslationForm'

const { Normalizer: CustomizerProductNormalizer } = normalize.customizerProduct

const queryParamsConfig = {
  tab: withDefault(StringParam, 'theme'),
  productId: StringParam,
  pluginId: StringParam,
}

const Translation = () => {
  const { openToast, openGenericErrorToast } = useToast()
  const queryClient = useQueryClient()
  const [query, setQuery] = useQueryParams(queryParamsConfig, { updateType: 'replace' })
  const { languageCode, brandName } = useParams<{ languageCode: string; brandName?: string }>()
  const baseUrl = brandName ? `/brands/${brandName}` : ''
  const goBack = useGoBack()
  const translationService = useTranslationService()
  const productService = useProductService()
  const customizerProductService = useCustomizerProductService()
  const pluginService = usePluginService()
  const moreOptionsPopover = usePopover({ placement: 'bottom-end', offsetConfig: 8 })
  const deleteTranslationModal = useModal()

  const activeThemeQuery = trpc.theme.getCurrent.useQuery()
  const pluginsQuery = useQuery(pluginService.fetchAll.queryKeys, pluginService.fetchAll)

  const translationQuery = useQuery([translationService.fetch.queryKeys, languageCode], () =>
    translationService.fetch(languageCode)
  )

  const selectedProductQuery = useQuery(
    [...productService.fetch.queryKeys, query.productId],
    () => productService.fetch(query.productId!) as Promise<Product>,
    {
      enabled: !!query.productId,
    }
  )

  const customizerProductQuery = useQuery(
    [...customizerProductService.fetch.queryKeys, query.productId],
    () => customizerProductService.fetch(selectedProductQuery.data!.live),
    {
      enabled: !!selectedProductQuery.data,
      select: data => CustomizerProductNormalizer.run(data, 'customizerProducts'),
    }
  )

  const { mutate: updateTranslation } = useMutation<TranslationType, NetworkError, TranslationType>(
    updatedTranslation => translationService.update(updatedTranslation.id, updatedTranslation),
    {
      onSuccess: () => {
        openToast('Translation was successfully saved!', ToastType.success)
        queryClient.invalidateQueries(translationService.fetchAll.queryKeys)
        queryClient.invalidateQueries([translationService.fetch.queryKeys, languageCode])
      },
      onError: () => {
        openGenericErrorToast('Translation has not been saved.')
      },
    }
  )

  const { mutate: updatePluginTranslation } = useMutation<Plugin, NetworkError, Record<string, TranslationData>>(
    updatedTranslation =>
      pluginService.update(query.pluginId!, { translations: { [languageCode]: updatedTranslation } }),
    {
      onSuccess: () => {
        openToast('Translation was successfully saved!', ToastType.success)
        queryClient.invalidateQueries(pluginService.fetchAll.queryKeys)
      },
      onError: () => {
        openGenericErrorToast('Translation has not been saved.')
      },
    }
  )

  const { mutate: deleteTranslation, isLoading: isDeletingTranslation } = useMutation<void, NetworkError, void>(
    () => translationService.delete(translationQuery.data!.id),
    {
      onSuccess: () => {
        openToast('Translation was successfully deleted!', ToastType.success)
        goBack()
      },
      onError: () => {
        openGenericErrorToast('Translation has not been deleted.')
      },
    }
  )

  const handleTranslationFormSubmit = (
    path: string,
    values: Record<string, TranslationData>,
    formikHelpers: FormikHelpers<object>
  ) => {
    const updatedTranslation = set(path, values, translationQuery.data!)
    updateTranslation(updatedTranslation, { onSettled: () => formikHelpers.setSubmitting(false) })
  }

  const handlePluginTranslationFormSubmit = (
    values: Record<string, TranslationData>,
    formikHelpers: FormikHelpers<object>
  ) => {
    updatePluginTranslation(values, { onSettled: () => formikHelpers.setSubmitting(false) })
  }

  const isLoading =
    translationQuery.isLoading ||
    activeThemeQuery.isLoading ||
    pluginsQuery.isLoading ||
    (query.productId && selectedProductQuery.isLoading)
  const language = countryLanguages.find(({ iso639_1 }) => iso639_1 === translationQuery.data?.code)
  const activeTheme = activeThemeQuery.data?.name || DefaultTheme
  const themeTranslations = translationQuery.data?.static[activeTheme] || {}
  const quoteTranslations = translationQuery.data?.static.quote || {}

  const themeTexts = getTexts(themeUtils.getThemeConfig(activeTheme).texts)
  const quoteTexts = getTexts(quoteConfig.texts)

  const selectedPlugin = (pluginsQuery.data || []).find(({ id }) => id === query.pluginId)
  const pluginsOptions = (pluginsQuery.data || []).map(plugin => ({ label: plugin.name, value: plugin.id }))

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      {!isLoading && (
        <>
          <Page>
            <Page.Header>
              <div className="flex items-center">
                <IconButton Icon={LeftArrow} onClick={goBack} className="mr-2" aria-label="Go back" />
                <h1>{language!.name} translation</h1>
              </div>
              <div className="flex space-x-2">
                <Link
                  to={`${baseUrl}/preview?lang=${languageCode}${
                    query.productId ? `&productId=${query.productId}` : ''
                  }`}
                  target="_blank"
                >
                  <IconButton Icon={EyeIcon} aria-label="Preview" />
                </Link>
                <IconButton {...moreOptionsPopover.referenceProps} Icon={MoreIcon} aria-label="More options" />
                <Popover {...moreOptionsPopover.floatingProps} isOpen={moreOptionsPopover.isOpen}>
                  <Popover.Action
                    className="text-tertiary-red-700"
                    onClick={() => {
                      moreOptionsPopover.close()
                      deleteTranslationModal.open()
                    }}
                  >
                    Delete
                  </Popover.Action>
                </Popover>
              </div>
            </Page.Header>

            <Tabs className="mb-10">
              <Tabs.Tab isSelected={query.tab === 'theme'} onClick={() => setQuery({ ...query, tab: 'theme' })}>
                Theme
              </Tabs.Tab>
              <Tabs.Tab isSelected={query.tab === 'product'} onClick={() => setQuery({ ...query, tab: 'product' })}>
                Product
              </Tabs.Tab>
              <FeatureFlag feature="quote_phase_1">
                <Tabs.Tab isSelected={query.tab === 'quote'} onClick={() => setQuery({ ...query, tab: 'quote' })}>
                  Quote
                </Tabs.Tab>
              </FeatureFlag>
              {!!pluginsQuery.data?.length && (
                <Tabs.Tab isSelected={query.tab === 'plugin'} onClick={() => setQuery({ ...query, tab: 'plugin' })}>
                  Plugin
                </Tabs.Tab>
              )}
            </Tabs>

            {query.tab === 'theme' && (
              <Page.Section>
                <TranslationForm
                  texts={themeTexts}
                  translations={themeTranslations}
                  onSubmit={(values, formikHelpers) => {
                    handleTranslationFormSubmit(`static.${activeTheme}`, values, formikHelpers)
                  }}
                />
              </Page.Section>
            )}

            {query.tab === 'product' && (
              <>
                <Page.Section>
                  <Page.Aside title="Translate product" description="Translate your products one by one" />
                  <Page.Content className="flex flex-1">
                    <ProductAsyncSelect
                      value={
                        selectedProductQuery.data && {
                          label: selectedProductQuery.data.name,
                          value: selectedProductQuery.data.id,
                        }
                      }
                      onChange={option =>
                        option && setQuery({ ...query, productId: (option as { label: string; value: string }).value })
                      }
                    />
                  </Page.Content>
                </Page.Section>

                <Page.Separator />

                {query.productId && !customizerProductQuery.isLoading && (
                  <Page.Section>
                    <TranslationForm
                      texts={getCustomizerProductTexts(customizerProductQuery.data!)}
                      translations={translationQuery.data?.products[query.productId] || {}}
                      onSubmit={(values, formikHelpers) => {
                        handleTranslationFormSubmit(`products.${query.productId}`, values, formikHelpers)
                      }}
                    />
                  </Page.Section>
                )}
              </>
            )}

            {query.tab === 'quote' && (
              <FeatureFlag feature="quote_phase_1">
                <Page.Section>
                  <TranslationForm
                    texts={quoteTexts}
                    translations={quoteTranslations}
                    onSubmit={(values, formikHelpers) => {
                      handleTranslationFormSubmit('static.quote', values, formikHelpers)
                    }}
                  />
                </Page.Section>
              </FeatureFlag>
            )}

            {query.tab === 'plugin' && (
              <>
                <Page.Section>
                  <Page.Aside title="Translate plugin" description="Translate your plugins one by one" />
                  <Page.Content className="flex flex-1">
                    <Select
                      className="w-4/5"
                      options={pluginsOptions}
                      value={pluginsOptions.find(({ value }) => value === query.pluginId)}
                      onChange={option => setQuery({ ...query, pluginId: option!.value })}
                      blurInputOnSelect
                      isSearchable
                    />
                  </Page.Content>
                </Page.Section>

                <Page.Separator />

                {selectedPlugin && !isNullOrEmpty(selectedPlugin.texts) && (
                  <Page.Section>
                    <TranslationForm
                      texts={getPluginTexts(selectedPlugin)}
                      translations={selectedPlugin.translations[languageCode] || {}}
                      onSubmit={handlePluginTranslationFormSubmit}
                    />
                  </Page.Section>
                )}

                {selectedPlugin && isNullOrEmpty(selectedPlugin.texts) && (
                  <div className="flex justify-center">
                    <BlankState className="mt-16">
                      <BlankState.Icon Icon={TranslateIcon} />
                      <BlankState.Title>There is no translations for this plugin</BlankState.Title>
                      <BlankState.Details>
                        Choose another plugin to view texts that need translations
                      </BlankState.Details>
                    </BlankState>
                  </div>
                )}
              </>
            )}
          </Page>
          {deleteTranslationModal.isVisible && (
            <Modal {...deleteTranslationModal.modalProps}>
              <Modal.CloseButton onClick={deleteTranslationModal.close} />
              <Modal.Title>Delete Translation</Modal.Title>

              <Modal.Details>
                Are you sure you want to delete the {language!.name} translation? This action cannot be undone.
              </Modal.Details>

              <Modal.Actions>
                <Button
                  type="button"
                  variant="default"
                  className="px-4"
                  onClick={deleteTranslationModal.close}
                  disabled={isDeletingTranslation}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="error"
                  className="px-4"
                  onClick={() => deleteTranslation()}
                  isLoading={isDeletingTranslation}
                  disabled={isDeletingTranslation}
                >
                  Delete
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </>
      )}
    </main>
  )
}

export default Translation
