import { Quote } from '@packages/types'
import ReactPDF, { View, Text } from '@react-pdf/renderer'
import { FormikContextType } from 'formik'
import React from 'react'

import { selectQuoteTotalPrices } from 'cms/quotes/utils'

import { QuoteFormValues } from '../Quote/Quote'
import Price from './Price'

interface PrintableQuoteTableFooterProps {
  quote: Quote
  styles: ReactPDF.Styles
}

const PrintableQuoteTableFooter = ({ quote, styles }: PrintableQuoteTableFooterProps) => {
  const { applyTaxes, taxes } = quote
  const priceBeforeTaxes = selectQuoteTotalPrices(quote, {
    values: { ...quote, applyTaxes: false },
  } as unknown as FormikContextType<QuoteFormValues>)
  const totalPrice = selectQuoteTotalPrices(quote, { values: quote } as unknown as FormikContextType<QuoteFormValues>)

  return (
    <View wrap={false}>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.tableColDescription]}></View>
        <View style={styles.tableCol}></View>
        <View style={styles.tableCol}>
          <Text>Subtotal</Text>
        </View>
        <View style={[styles.tableCol, styles.tableColRightAlign]}>
          <Price amount={priceBeforeTaxes} currency={quote.currency ?? 'USD'} />
        </View>
      </View>
      {applyTaxes && (
        <View style={styles.tableRow}>
          <View style={[styles.tableColInnerItems, styles.tableColDescription]}></View>
          <View style={styles.tableColInnerItems}></View>
          <View style={styles.tableColInnerItems}>
            <Text>{`Tax (${taxes}%)`}</Text>
          </View>
          <View style={[styles.tableColInnerItems, styles.tableColRightAlign]}>
            <Price amount={(priceBeforeTaxes * Number(taxes)) / 100} currency={quote.currency ?? 'USD'} />
          </View>
        </View>
      )}
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.tableColDescription]}></View>
        <View style={styles.tableCol}></View>
        <View style={[styles.tableCol, styles.totalPrice]}>
          <Text>Total</Text>
        </View>
        <View style={[styles.tableCol, styles.totalPrice, styles.tableColRightAlign]}>
          <Price amount={totalPrice} currency={quote.currency ?? 'USD'} />
        </View>
      </View>
    </View>
  )
}

export default PrintableQuoteTableFooter
