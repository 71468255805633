import { ThemeBuilderConfig, ThemeBuilderSectionSetting } from '@packages/types'
import tinycolor from 'tinycolor2'

export const reduceSettingToValues = (settings: ThemeBuilderConfig['settings']) => {
  return Object.keys(settings).reduce(
    (themeSettings, key) => {
      const reducedSetting = Object.keys(settings[key].settings).reduce(
        (setting, k) => {
          setting[k] = settings[key].settings[k].default
          return setting
        },
        {} as Record<string, ThemeBuilderSectionSetting['default']>
      )

      themeSettings[key] = reducedSetting

      return themeSettings
    },
    {} as Record<string, Record<string, ThemeBuilderSectionSetting['default']>>
  )
}

export const getDisabledColor = (color: string) => tinycolor(color).setAlpha(0.38).toHex8String()
