import { SET_LEFT_PANEL, SET_BUILDER_MODE } from './actionTypes'
import { BuilderMode } from './types/builderMode'
import { LeftPanel } from './types/leftPanel'

export const setLeftPanel = (tab: LeftPanel, options: { initialFocusInputName?: string } = {}) => ({
  type: SET_LEFT_PANEL,
  payload: { tab, options },
})

export const setBuilderMode = (payload: BuilderMode) => ({ type: SET_BUILDER_MODE, payload })
