import React, { useState } from 'react'

import StockAdjustModal from 'cms/inventory/components/modals/StockAdjustModal'
import Button from 'common/components/Button'
import Popover from 'common/components/popover/Popover'
import usePopover from 'common/components/popover/usePopover'
import useModal from 'common/components/modal/useModal'
import BulkUnsavedChangesModal from 'common/components/BulkUnsavedChangesModal'
import { BulkSelection } from 'common/hooks/useBulkSelection'
import { trpc } from 'common/hooks/trpc'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'

import ContinueSellingModal from './../modals/ContinueSellingModal'
import StopSellingModal from './../modals/StopSellingModal'
import UpdateSKUsModal from './../modals/UpdateSKUsModal'
import { FormikVariants } from '../types'

export interface VariantsBulkPopoverProps {
  bulkSelection: BulkSelection
  locationId: string
  formik: FormikVariants
}

const VariantsBulkPopover = ({ locationId, formik, bulkSelection }: VariantsBulkPopoverProps) => {
  const [bulkAction, setBulkAction] = useState<undefined | (() => void)>()
  const popover = usePopover({ placement: 'bottom-start' })
  const updateSKUsModal = useModal()
  const stockAdjustModal = useModal()
  const continueSellingModal = useModal()
  const stopSellingModal = useModal()
  const bulkUnsavedChangesModal = useModal()
  const withClose = (callback: () => void) => () => {
    callback()
    popover.close()
  }

  const trpcContext = trpc.useContext()

  const handleBulkActionClick = (bulkAction: () => void) => {
    if (formik.dirty) {
      setBulkAction(() => bulkAction)
      bulkUnsavedChangesModal.open()
    } else {
      bulkAction()
    }
  }

  const handleSaveSuccess = () => {
    formik.setStatus('submitted')
    trpcContext.variant.get.invalidate()
    trpcContext.variant.getByIds.invalidate()
  }

  return (
    <>
      <Button
        tabIndex={-1}
        variant="subtle"
        small
        icon={<MoreIcon className="w-2.5 h-2.5" />}
        {...popover.referenceProps}
      >
        Bulk action
      </Button>
      <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
        <Popover.Action onClick={withClose(() => handleBulkActionClick(stockAdjustModal.open))}>
          Adjust stocks
        </Popover.Action>
        <Popover.Action onClick={withClose(() => handleBulkActionClick(updateSKUsModal.open))}>
          Update SKUs
        </Popover.Action>
        <Popover.Action onClick={withClose(() => handleBulkActionClick(continueSellingModal.open))}>
          Continue selling when out of stock
        </Popover.Action>
        <Popover.Action onClick={withClose(() => handleBulkActionClick(stopSellingModal.open))}>
          Stop selling when out of stock
        </Popover.Action>
      </Popover>
      {updateSKUsModal.isVisible && (
        <UpdateSKUsModal
          locationId={locationId}
          formik={formik}
          modalProps={updateSKUsModal.modalProps}
          handleClose={updateSKUsModal.close}
          ids={bulkSelection.selectedIds}
          onSuccess={handleSaveSuccess}
        />
      )}
      {stockAdjustModal.isVisible && (
        <StockAdjustModal
          locationId={locationId}
          modalProps={stockAdjustModal.modalProps}
          handleClose={stockAdjustModal.close}
          ids={bulkSelection.selectedIds}
          onSuccess={handleSaveSuccess}
        />
      )}
      {continueSellingModal.isVisible && (
        <ContinueSellingModal
          modalProps={continueSellingModal.modalProps}
          handleClose={continueSellingModal.close}
          formik={formik}
          ids={bulkSelection.selectedIds}
          onSuccess={handleSaveSuccess}
        />
      )}
      {stopSellingModal.isVisible && (
        <StopSellingModal
          modalProps={stopSellingModal.modalProps}
          handleClose={stopSellingModal.close}
          formik={formik}
          ids={bulkSelection.selectedIds}
          onSuccess={handleSaveSuccess}
        />
      )}
      {bulkUnsavedChangesModal.isVisible && (
        <BulkUnsavedChangesModal
          onCancelClick={() => {
            setBulkAction(undefined)
            bulkUnsavedChangesModal.close()
          }}
          onContinueClick={() => {
            setBulkAction(undefined)
            bulkAction?.()
            bulkUnsavedChangesModal.close()
          }}
          modalProps={bulkUnsavedChangesModal.modalProps}
        />
      )}
    </>
  )
}

export default VariantsBulkPopover
