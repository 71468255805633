import React from 'react'

import ArrowLeft from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'
import ArrowRight from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'

import IconButton from '../IconButton'

export interface TablePaginationNavigationProps {
  onPreviousPage: () => void
  onNextPage: () => void
  lastIndex: number
  resultSize: number
  count: number
  collectionSize: number
}

const TablePaginationNavigation = ({
  onPreviousPage,
  onNextPage,
  lastIndex,
  resultSize,
  count,
  collectionSize,
}: TablePaginationNavigationProps) => {
  const canGoBack = lastIndex > 0
  const canGoNext = lastIndex + count < collectionSize

  const rangeStart = collectionSize > 0 ? lastIndex + 1 : 0
  const rangeEnd = lastIndex + resultSize

  return (
    <>
      <IconButton
        Icon={ArrowLeft}
        smallIcon
        className="ml-5"
        onClick={onPreviousPage}
        disabled={!canGoBack}
        aria-label="previous"
      />
      <span className="mx-2">
        {rangeStart} - {rangeEnd} of {collectionSize}
      </span>
      <IconButton Icon={ArrowRight} smallIcon onClick={onNextPage} disabled={!canGoNext} aria-label="next" />
    </>
  )
}

export default TablePaginationNavigation
