import { UseMutateFunction } from '@tanstack/react-query'

import { trpc, RouterOutputs, RouterInputs, ReactQueryOptions } from './trpc'

type GetUserLatestInput = RouterInputs['activityLog']['getUserLatest']
type GetUserLatestOutput = RouterOutputs['activityLog']['getUserLatest']
type CreateIntput = RouterInputs['activityLog']['create']
type CreateOutput = RouterOutputs['activityLog']['create']

const useUserActivityLog = (
  logName: GetUserLatestInput,
  queryOpts?: ReactQueryOptions['activityLog']['getUserLatest']
): [UseMutateFunction<CreateOutput, unknown, CreateIntput>, GetUserLatestOutput | undefined] => {
  const trpcUtils = trpc.useContext()

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation({
    onSuccess: () => trpcUtils.activityLog.getUserLatest.invalidate(logName),
  })

  const { data: userLastActivityLog } = trpc.activityLog.getUserLatest.useQuery(logName, queryOpts)

  return [createActivityLog, userLastActivityLog]
}

export default useUserActivityLog
