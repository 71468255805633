import { ThemeBuilderSection } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import InventoryIcon from 'icons/bold/10-Shipping-Delivery/01-Warehouse-Fullfilment/warehouse-packages.svg'
import AnswerIcon from 'icons/regular/01-Interface Essential/02-Dashboard/layout-corners-dashboard-1.svg'
import QuestionIcon from 'icons/regular/01-Interface Essential/03-Menu/navigation-menu-4.svg'
import SettingIcon from 'icons/regular/01-Interface Essential/13-Controls/settings-toggle-horizontal.svg'
import ZoomInIcon from 'icons/regular/01-Interface Essential/26-Zoom/zoom-in.svg'
import PrinterIcon from 'icons/regular/01-Interface Essential/30-Print/print-text.svg'
import TypingIcon from 'icons/regular/01-Interface Essential/32-Form-edition/typing.svg'
import ConfirmIcon from 'icons/regular/01-Interface Essential/33-Form-Validation/check-button.svg'
import TextIcon from 'icons/regular/01-Interface Essential/34-Text-Formating/small-caps.svg'
import ModalIcon from 'icons/regular/01-Interface Essential/38-Layouts/layout-top.svg'
import ViewsIcon from 'icons/regular/01-Interface Essential/48-Select/circle-pad-finger.svg'
import ShareIcon from 'icons/regular/05-Internet-Networks-Servers/08-Upload-Download/upload-bottom.svg'
import UploadIcon from 'icons/regular/05-Internet-Networks-Servers/08-Upload-Download/upload-circle.svg'
import PriceIcon from 'icons/regular/09-Shopping-Ecommerce/05-Discount-Coupons/discount-dollar-dash.svg'
import AddToCartIcon from 'icons/regular/09-Shopping-Ecommerce/09-Carts/shopping-cart-add.svg'
import ThumbnailIcon from 'icons/regular/12-Design/01-Design-Process/design-shape-monitor.svg'
import ColorPickerIcon from 'icons/regular/12-Design/05-Colors-Painting/color-picker-2.svg'
import FileInfoIcon from 'icons/regular/16-Files-Folders/01-Common-Files/common-file-text-info.svg'
import BulkIcon from 'icons/regular/17-Users/17-Geomertic-Close Up-Multiple Users actions/multiple-actions-check-1.svg'
import ProductIcon from 'icons/regular/29-Clothes-Footwear-Accessories/11-Footwear/footwear-sneakers-1.svg'
import ArrowRightIcon from 'icons/regular/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'

const Icons: Record<string, JSX.Element> = {
  text: <TextIcon width={14} />,
  product: <ProductIcon width={14} />,
  choice: <SettingIcon width={14} />,
  arrow: <ArrowRightIcon width={14} />,
  zoom: <ZoomInIcon width={14} />,
  addToCart: <AddToCartIcon width={14} />,
  share: <ShareIcon width={14} />,
  typing: <TypingIcon width={14} />,
  views: <ViewsIcon width={14} />,
  info: <FileInfoIcon width={14} />,
  question: <QuestionIcon width={14} />,
  answer: <AnswerIcon width={14} />,
  thumbnail: <ThumbnailIcon width={14} />,
  confirm: <ConfirmIcon width={14} />,
  price: <PriceIcon width={14} />,
  printer: <PrinterIcon width={14} />,
  upload: <UploadIcon width={14} />,
  bulkIcon: <BulkIcon width={14} />,
  modal: <ModalIcon width={14} />,
  inventory: <InventoryIcon width={14} />,
  colorPicker: <ColorPickerIcon width={14} />,
}

const SectionIcon = (props: { icon: string }) => (Icons[props.icon] ? Icons[props.icon] : null)

interface ThemeSettingSectionProps {
  section: ThemeBuilderSection
  selected?: boolean
  onClick: () => void
}

const ThemeSettingSection = ({ section, selected, onClick }: ThemeSettingSectionProps) => {
  return (
    <div
      className={classNames(
        'mx-1 pl-2.5 h-[38px] flex items-center transition-all rounded-md hover:cursor-pointer hover:bg-neutral-50',
        {
          'text-primary-600 bg-neutral-50': selected,
        }
      )}
      onClick={onClick}
    >
      <span className="w-4 h-4 mr-3">
        <SectionIcon icon={section.icon} />
      </span>
      <span className="text-xs">{`${section.name}${section.mobileOnly ? ' (mobile)' : ''}`}</span>
    </div>
  )
}

export default ThemeSettingSection
