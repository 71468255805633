import { StripeCardBrand } from '@packages/types'
import React, { ReactElement, forwardRef } from 'react'

import Popover, { PopoverProps } from 'common/components/popover/Popover'
import useCopyToClipboard from 'common/hooks/useCopyToClipboard'
import AmexCardIcon from 'icons/bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-amex.svg'
import ValidCardIcon from 'icons/bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-check.svg'
import InvalidCardIcon from 'icons/bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-disable.svg'
import MastercardCardIcon from 'icons/bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-mastercard.svg'
import VisaCardIcon from 'icons/bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-visa.svg'

type TestCard = {
  number: number
  label: string
  brand: StripeCardBrand
  success: boolean
  locale: 'US' | 'FR' | 'CA' | 'DE'
}

const testCards: TestCard[] = [
  {
    number: 4242424242424242,
    label: '4242 4242 4242 4242',
    success: true,
    brand: StripeCardBrand.Visa,
    locale: 'US',
  },
  {
    number: 5555555555554444,
    label: '5555 5555 5555 4444',
    success: true,
    brand: StripeCardBrand.Mastercard,
    locale: 'US',
  },
  {
    number: 378282246310005,
    label: '3782 822463 10005',
    success: true,
    brand: StripeCardBrand.Amex,
    locale: 'US',
  },
  {
    number: 4000001240000000,
    label: '4000 0012 4000 0000',
    success: true,
    brand: StripeCardBrand.Visa,
    locale: 'CA',
  },
  {
    number: 4000002500000003,
    label: '4000 0025 0000 0003',
    success: true,
    brand: StripeCardBrand.Visa,
    locale: 'FR',
  },
  {
    number: 4000002760000016,
    label: '4000 0027 6000 0016',
    success: true,
    brand: StripeCardBrand.Visa,
    locale: 'DE',
  },
  {
    number: 4000000000000002,
    label: '4000 0000 0000 0002',
    success: false,
    brand: StripeCardBrand.Visa,
    locale: 'US',
  },
]

const localFlags: { [locale: string]: string } = {
  FR: '\uD83C\uDDEB\uD83C\uDDF7',
  US: '\uD83C\uDDFA\uD83C\uDDF8',
  CA: '\uD83C\uDDE8\uD83C\uDDE6',
  DE: '\uD83C\uDDE9\uD83C\uDDEA',
}

const CardIcons: Record<StripeCardBrand, ReactElement> = {
  [StripeCardBrand.Visa]: <VisaCardIcon className="w-6 h-6" />,
  [StripeCardBrand.Amex]: <AmexCardIcon className="w-6 h-6" />,
  [StripeCardBrand.Mastercard]: <MastercardCardIcon className="w-6 h-6" />,
}

const TestCardPopover = forwardRef<HTMLDivElement, PopoverProps>((popoverProps, ref) => {
  const copyToClipboard = useCopyToClipboard()

  return (
    <Popover {...popoverProps} ref={ref} className="w-[260px]">
      <span className="font-h2 p-2">Test Cards</span>
      {testCards.map(card => (
        <Popover.Action
          key={card.label}
          className="flex items-center space-x-2"
          onClick={() => copyToClipboard(card.number.toString())}
        >
          <span>{localFlags[card.locale]}</span>
          {card.success ? <ValidCardIcon className="w-6 h-6" /> : <InvalidCardIcon className="w-6 h-6" />}
          {CardIcons[card.brand]}
          <span>{card.label}</span>
        </Popover.Action>
      ))}
    </Popover>
  )
})

export default TestCardPopover
