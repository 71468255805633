import { View } from '@packages/types'
import { createSelector } from 'reselect'

import { RootState } from 'customizer/store'

export const currentViewSelector = (customizerState: RootState) => customizerState.displayer.currentView

const answersSelector = (customizerState: RootState) => customizerState.customization.answers

const questionsSelector = (customizerState: RootState) => customizerState.customization.questions

export const zoomSelector = (customizerState: RootState) => customizerState.displayer.zoom

export const currentStepSelector = (customizerState: RootState) => customizerState.questionPanel.currentStep

export const getAnswerFromId = createSelector(
  answersSelector,
  (_state: RootState, answerId: string) => answerId,
  (answers, answerId) => answers[answerId]
)

export const selectedAnswersByQuestionIdsSelector = createSelector(
  answersSelector,
  questionsSelector,
  (_state: RootState, questionIds: string[]) => questionIds,
  (answers, questions, questionIds) => {
    return questionIds.reduce<Record<string, { id: string; views: View[] }>>(
      (selectedAnswersByQuestionId, questionId) => {
        const selectedAnswerId = questions[questionId]?.selectedAnswer
        const answer = answers[selectedAnswerId!]

        if (!answer) return selectedAnswersByQuestionId
        return { ...selectedAnswersByQuestionId, [questionId]: { id: answer.id, views: answer.views! } }
      },
      {}
    )
  }
)
