import React from 'react'

import IconButton from 'common/components/IconButton'
import Popover from 'common/components/popover/Popover'
import usePopover from 'common/components/popover/usePopover'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'

import { VariantFormValue } from '../types'

export interface VariantPopoverProps {
  variant: VariantFormValue
  setContinueSelling: (continueSelling: boolean) => void
}

const VariantPopover = ({ variant, setContinueSelling }: VariantPopoverProps) => {
  const popover = usePopover({ placement: 'bottom-start' })

  const withClose = (callback: () => void) => () => {
    callback()
    popover.close()
  }

  return (
    <>
      <IconButton
        tabIndex={-1}
        variant="subtle"
        small
        Icon={MoreIcon}
        {...popover.referenceProps}
        aria-label={`${variant.name} actions`}
      />
      <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
        {variant.continueSelling && (
          <Popover.Action onClick={withClose(() => setContinueSelling(false))}>
            Stop selling when out of stock
          </Popover.Action>
        )}
        {!variant.continueSelling && (
          <Popover.Action onClick={withClose(() => setContinueSelling(true))}>
            Continue selling when out of stock
          </Popover.Action>
        )}
      </Popover>
    </>
  )
}

export default VariantPopover
