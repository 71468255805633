import React, { ForwardedRef, useEffect, useState, useRef } from 'react'

import classMerge from 'utils/classMerge'

type BaseInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export interface InputProps extends Omit<BaseInputProps, 'ref'> {
  testId?: string
  hasError?: boolean
  leftAddon?: React.ReactNode
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  rightAddon?: React.ReactNode
  multiline?: boolean
  focusClassName?: string
  inputClassName?: string
  id?: string
  small?: boolean
}

const Input = React.forwardRef(
  (
    {
      testId,
      className,
      disabled,
      hasError,
      leftAddon,
      rightAddon,
      onFocus,
      onBlur,
      focusClassName = '',
      inputClassName,
      small = false,
      ...rest
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [focused, setIsFocused] = useState(false)
    const localRef = useRef<HTMLInputElement | null>(null)

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = e => {
      setIsFocused(true)
      onFocus?.(e)
    }

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = e => {
      setIsFocused(false)
      onBlur?.(e)
    }

    const handleWheelInputNumber = (e: WheelEvent) => {
      if ((e.target as HTMLElement) === document.activeElement) {
        e.preventDefault()
      }
    }

    useEffect(() => {
      const node = localRef.current
      if (node && rest.type === 'number') {
        node.addEventListener('wheel', handleWheelInputNumber, { passive: false })
        return () => {
          node.removeEventListener('wheel', handleWheelInputNumber)
        }
      }

      return () => {}
    }, [ref])

    return (
      <div
        className={classMerge(
          'relative flex items-center w-full text-neutral-800 bg-white border border-solid overflow-hidden',
          {
            'px-2 h-6 rounded': small,
            'px-3 h-8 rounded-md': !small,
            'ring ring-primary-100 border-primary-200 z-[1]': focused,
            '!bg-neutral-50 !text-neutral-200 border-transparent': disabled,
            'border-error-default': !focused && !!hasError,
            'border-neutral-100': !focused && !hasError,
          },
          className
        )}
      >
        {leftAddon}
        <input
          data-testid={testId}
          ref={node => {
            localRef.current = node

            if (typeof ref === 'function') {
              ref(node)
            } else if (ref) {
              ref.current = node
            }
          }}
          disabled={disabled}
          className={classMerge(
            'placeholder-neutral-300 outline-none font-input w-full',
            {
              'pl-3': !!leftAddon,
              [focusClassName]: focused,
              'bg-neutral-50 text-neutral-200': disabled,
            },
            inputClassName
          )}
          onFocus={handleFocus}
          onBlur={handleBlur}
          role="input"
          {...rest}
        />
        {rightAddon}
      </div>
    )
  }
)

export default Input
