import { StringSanitizer } from '@packages/sanitizer'
import { Answer, Group, PartType, PrintArea, Question } from '@packages/types'

import * as answersSelectors from 'builder/build/answers/selectors'
import * as groupsSelectors from 'builder/build/groups/selectors'
import * as partsSelectors from 'builder/build/parts/selectors'
import * as printAreasSelectors from 'builder/build/printAreas/selectors'
import * as questionsSelectors from 'builder/build/questions/selectors'
import { useSelector } from 'cms/hooks'

import QuickActionManageViews from './QuickActionManageViews'
import QuickActionReorderLayers from './QuickActionReorderLayers'
import QuickActionResizeCanvas from './QuickActionResizeCanvas'
import QuickActionUpdateCustomizerTitle from './QuickActionUpdateCustomizerTitle'

const allQuickActions = [
  { key: 'reorderLayers', searchableText: 'reorder layers', Component: QuickActionReorderLayers },
  { key: 'manageViews', searchableText: 'manage views', Component: QuickActionManageViews },
  {
    key: 'resizeCanvas',
    searchableText: 'resize canvas update dimensions update canvas dimensions',
    Component: QuickActionResizeCanvas,
  },
  {
    key: 'updateCustomizerTitle',
    searchableText:
      'add title change title update title edit title add customizer title change customizer title update customizer title edit customizer title',
    Component: QuickActionUpdateCustomizerTitle,
  },
]

type GroupedResult =
  | { type: 'quickAction'; results: typeof allQuickActions }
  | { type: 'question'; results: Question[] }
  | { type: 'answer'; results: { question: Question; answer: Answer }[] }
  | { type: 'group'; results: Group[] }
  | { type: 'printArea'; results: { question: Question; printArea: PrintArea }[] }

const isTextMatching = (text = '', query = '') =>
  query && StringSanitizer.sanitize(text).includes(StringSanitizer.sanitize(query))

const useBuilderUniversalSearch = (query: string): GroupedResult[] => {
  const allParts = useSelector(partsSelectors.partsAsArraySelector)
  const allGroups = useSelector(groupsSelectors.groupsAsArraySelector)
  const allQuestions = useSelector(questionsSelectors.questionsAsArraySelector)
  const allAnswers = useSelector(answersSelectors.answersAsArraySelector)
  const allPrintAreas = useSelector(printAreasSelectors.printAreasAsArraySelector)

  if (!query) return [{ type: 'quickAction', results: allQuickActions }]

  const filteredAnswers = allAnswers.filter(answer => isTextMatching(answer.name, query))
  const filteredPrintAreas = allPrintAreas.filter(printArea => isTextMatching(printArea.name, query))
  const filteredQuickActions = allQuickActions.filter(quickAction => isTextMatching(quickAction.searchableText, query))
  const filteredGroups = allGroups.filter(group => group.id !== 'root-step' && isTextMatching(group.name, query))
  const filteredQuestions = allQuestions.filter(question => isTextMatching(question.name, query))

  const answerResults = allQuestions.reduce<{ question: Question; answer: Answer }[]>((result, question) => {
    const matchingAnswers = filteredAnswers.filter(answer => question.answers.includes(answer.id))
    return [...result, ...matchingAnswers.map(answer => ({ question, answer }))]
  }, [])

  const printAreaResults = allParts.reduce<{ question: Question; printArea: PrintArea }[]>((result, part) => {
    if (part.type === PartType.Image) return result

    const matchingPrintArea = filteredPrintAreas.find(printArea => printArea.id === part.printArea)

    if (!matchingPrintArea) return result

    const question = allQuestions.find(({ id }) => (part.type === PartType.Logo ? id === part.logo : id === part.text))
    return question ? [...result, { question, printArea: matchingPrintArea }] : result
  }, [])

  return [
    { type: 'quickAction', results: filteredQuickActions },
    { type: 'question', results: filteredQuestions },
    { type: 'answer', results: answerResults },
    { type: 'group', results: filteredGroups },
    { type: 'printArea', results: printAreaResults },
  ].filter(({ results }) => results.length > 0) as GroupedResult[]
}

export default useBuilderUniversalSearch
