import { NormalizedCustomizerProduct, Rule } from '@packages/types'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import { bulkOrderGroupChildrenSelector } from 'builder/build/groups/selectors'
import { useSelector } from 'cms/hooks'
import Button from 'common/components/Button'
import BulkOrderIcon from 'icons/core-solid/interface-essential/interface-user-add.svg'

import isValidRule from '../isValidRule'
import RuleBuilder from './RuleBuilder/RuleBuilder'

export interface AddRuleProps {
  rule: Rule
  customizerProduct: NormalizedCustomizerProduct
  addRule: (rule: Rule, index?: number) => void
  cancel: () => void
  index?: number
}

const AddRule = ({ rule, customizerProduct, index, cancel, addRule }: AddRuleProps) => {
  const [newRule, setNewRule] = useState(rule)
  const [isEditedRuleValid, setIsEditedRuleValid] = useState(false)

  const bulkOrderGroupChildren = useSelector(bulkOrderGroupChildrenSelector)

  useEffect(() => {
    setIsEditedRuleValid(
      isValidRule({ rule: newRule, groups: customizerProduct.groups, questions: customizerProduct.questions })
    )
  }, [newRule, customizerProduct.questions])

  const isRuleInBulkOrder = !!newRule.when?.some(({ path }) => bulkOrderGroupChildren.includes(path[1]))

  return (
    <RuleBuilder
      className={classNames('mt-4', { 'mx-[42px]': index == null, 'mb-4': index != null })}
      rule={newRule}
      updateRule={setNewRule}
      customizerProduct={customizerProduct}
    >
      <div
        className={classNames('px-6 py-3 border-t border-solid border-neutral-75 flex space-x-1', {
          'justify-between': isRuleInBulkOrder,
          'justify-end': !isRuleInBulkOrder,
        })}
      >
        {isRuleInBulkOrder && (
          <div className="flex items-center space-x-2 text-neutral-500 fill-neutral-500">
            <BulkOrderIcon className="w-4 h-4" />
            <span>You’re creating a rule within a bulk order</span>
          </div>
        )}
        <div className="flex space-x-1">
          <Button type="button" onClick={cancel}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!isEditedRuleValid} onClick={() => addRule(newRule, index)}>
            Add
          </Button>
        </div>
      </div>
    </RuleBuilder>
  )
}

export default AddRule
