import { Answer, Question } from '@packages/types'
import { createSelector } from 'reselect'

import createCustomizerProductEntitySelectors from 'builder/build/core/createCustomizerProductEntitySelectors'
import { RootState } from 'cms/store'
import isNullOrEmpty from 'utils/isNullOrEmpty'

const { questionByIdSelector, questionsSelector, questionsAsArraySelector } =
  createCustomizerProductEntitySelectors('questions')

export { questionsSelector, questionsAsArraySelector, questionByIdSelector }

const answersSelector = ({ productBuilder }: RootState) => productBuilder.answers

export const questionAnswersSelector = createSelector(
  answersSelector,
  (_state: RootState, question: Question | undefined) => question,
  (answers, question) => {
    if (isNullOrEmpty(question)) return []

    return question!.answers.reduce<Answer[]>((result, id) => {
      const answer = answers[id]

      if (!isNullOrEmpty(answer)) result.push(answer)

      return result
    }, [])
  }
)

export const importableAnswersSelector = createSelector(
  questionsSelector,
  answersSelector,
  (_state: RootState, questionId: string) => questionId,
  (questions, answers, questionId) => {
    const question = questions[questionId]
    return Object.values(answers).filter(
      answer => (answer.type as string) === question.type && !question.answers.includes(answer.id)
    )
  }
)

export const isAnswerLinkedSelector = createSelector(
  questionsAsArraySelector,
  (_state: RootState, props: { answerId: string; questionsToIgnore?: string[] }) => props,
  (questions, { answerId, questionsToIgnore = [] }) => {
    return !!questions.find(question => !questionsToIgnore.includes(question.id) && question.answers.includes(answerId))
  }
)

export const firstNonArchivedAnswerSelector = createSelector(
  questionsSelector,
  answersSelector,
  (_state: RootState, questionId: string) => questionId,
  (questions, answers, questionId) => {
    const question = questions[questionId]
    const id = question.answers.find(id => !answers[id].archived)!
    return answers[id]
  }
)

export const defaultStartingPointSelector = (state: RootState) =>
  state.productBuilder.customizerProduct.defaultConfiguration ?? {}

export const defaultAnswerIdSelector = createSelector(
  defaultStartingPointSelector,
  (_state: RootState, questionId: string) => questionId,
  (configuration, questionId) => configuration[questionId]
)

export const linkedQuestionsSelector = createSelector(
  questionsSelector,
  (_state: RootState, questionId: string) => questionId,
  (questions, questionId) => Object.values(questions).filter(question => question?.linkedQuestionId === questionId)
)
