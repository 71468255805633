import { CustomizerProduct } from '@packages/types'
import { useContext } from 'react'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import TenantContext from 'common/tenant/TenantContext'

const useCustomizerProductService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetch: createQuery([tenant, 'customizerproduct'], async (id: string) => {
      const { data } = await apiClient.get<CustomizerProduct>(`/brands/${tenant}/customizerproducts/${id}`)
      return data
    }),
  }
}

export default useCustomizerProductService
