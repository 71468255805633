import classNames from 'classnames'
import React from 'react'

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export interface SwitchProps extends InputProps {
  className?: string
  disabled?: boolean
  checked?: boolean
}

const Switch = ({ className, name, id, disabled, checked, onChange, ...rest }: SwitchProps) => {
  return (
    <label className={classNames('relative cursor-pointer block', className)}>
      <input
        {...rest}
        disabled={disabled}
        type="checkbox"
        id={id}
        className="toggle-switch sr-only"
        checked={checked}
        onChange={onChange}
        role="checkbox"
      />
      <div
        className={classNames('track block w-8 h-4 rounded-full', {
          'shadow-xs': !disabled,
          'bg-neutral-100': !checked && !disabled,
          'bg-neutral-50': !checked && disabled,
          'bg-secondary-orange-100': checked && !disabled,
          'bg-secondary-orange-75': checked && disabled,
        })}
      />
      <div
        className={classNames('absolute left-[2px] top-0.5 bg-white w-3 h-3 rounded-full transition', {
          'shadow-xs translate-x-[16px]': checked,
        })}
      ></div>
    </label>
  )
}

export default Switch
