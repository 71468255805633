import React from 'react'

import Button from 'common/components/Button'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import ListIcon from 'icons/core-solid/interface-essential/interface-setting-menu-1.svg'

export interface TreeContainerProps extends React.PropsWithChildren {
  isEmpty: boolean
  onCreateQuestionClick: () => void
}

const TreeContainer = React.forwardRef<HTMLDivElement, TreeContainerProps>(
  ({ children, isEmpty, onCreateQuestionClick }, ref) => (
    <div
      ref={ref}
      className="py-4 overflow-y-auto basis-[55%] h-[55%] grow-0 shrink-0"
      role="list"
      aria-label="Questions"
    >
      {isEmpty && (
        <div className="px-6 mt-24 z-[1]">
          <div className="w-8 h-8 bg-neutral-75 rounded-md mb-4 p-2.5">
            <ListIcon />
          </div>
          <div className="font-medium mb-2">There are no questions, yet</div>
          <div className="text-neutral-600 mb-4">Create your first question to start building your customizer.</div>
          <div className="rules__empty-state__action">
            <Button icon={<AddIcon className="w-2.5 h-2.5 fill-current" />} onClick={onCreateQuestionClick}>
              Create question
            </Button>
          </div>
        </div>
      )}
      {children}
    </div>
  )
)

export default TreeContainer
