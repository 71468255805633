import { transform } from 'lodash'

import { ExtraPricesType, PricingFormValues, ProductPricingWithId } from './types'

const toFormValues = (pricing: Partial<ProductPricingWithId>): PricingFormValues => ({
  id: pricing.id!,
  bulkPrices: pricing.bulkPrices || [],
  basePrice: pricing.basePrice || '0',
  equations: pricing.equations || [],
  extraPrices: pricing.extraPrices
    ? transform(
        pricing.extraPrices,
        (result: ExtraPricesType, answersPrices, questionId: string) => {
          Object.keys(answersPrices).forEach(answerId => {
            result.push({ questionId, answerId, price: answersPrices[answerId] })
          })
        },
        []
      )
    : [],
  applyTaxes: Boolean(pricing.applyTaxes),
  taxes: pricing.taxes || '0',
})

const fromFormValues = (pricingsFormValues: PricingFormValues[]): ProductPricingWithId[] =>
  pricingsFormValues.map(pricingFormValues => ({
    ...pricingFormValues,
    equations: pricingFormValues.equations || [],
    extraPrices: pricingFormValues.extraPrices.reduce(
      (extraPrices, extraPrice) => {
        return {
          ...extraPrices,
          [extraPrice.questionId]: {
            ...extraPrices[extraPrice.questionId],
            [extraPrice.answerId]: extraPrice.price,
          },
        }
      },
      {} as Record<string, Record<string, string>>
    ),
  }))

export { toFormValues, fromFormValues }
