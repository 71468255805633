// https://github.com/intercom/example-single-page-app-install/blob/logged-in-react-router/src/intercom.js

export const APP_ID = 'j9bkvee3'

export const load = () => {
  ;(function () {
    var w = window
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      var d = document
      var i = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      var l = function () {
        var s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/' + APP_ID
        var x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()
}

export const boot = (options = {}) => {
  window && window.Intercom && window.Intercom('boot', { app_id: APP_ID, ...options })
}

export const update = intercomSettings => {
  if (intercomSettings) window.intercomSettings = intercomSettings
  window && window.Intercom && window.Intercom('update')
}

export const shutdown = () => {
  window && window.Intercom && window.Intercom('shutdown')
}
