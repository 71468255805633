import React from 'react'

import BlankState from 'common/components/blankState/BlankState'

export interface NewBlankStateProps {
  handleViewAllVariantsClick: (e: React.MouseEvent) => void
}

const NewBlankState = ({ handleViewAllVariantsClick: handleViewAllVariants }: NewBlankStateProps) => {
  return (
    <BlankState className="max-w-sm">
      <BlankState.Title>No new variants</BlankState.Title>
      <BlankState.Details>
        New variants are created when you add answers to a question in the question combination
      </BlankState.Details>
      <BlankState.Action onClick={handleViewAllVariants}>View all variants</BlankState.Action>
    </BlankState>
  )
}

export default NewBlankState
