import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import qs from 'qs'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { defaultPersistenceState } from 'cms/orders/utils'
import type { Paginated } from 'common/api/types/pagination'

import type { DenormalizedOrder, Order } from './../types/order'
import useOrderService from './useOrderService'

type IndexedOrders = {
  previous?: Order
  next?: Order
}

type UseFetchOrderOptions = {
  persistence?: typeof defaultPersistenceState
  orderId: string
  baseUrl: string
}

export const useFetchOrder = ({ persistence, orderId, baseUrl }: UseFetchOrderOptions) => {
  const orderService = useOrderService()
  const history = useHistory()

  const {
    data,
    hasPreviousPage,
    hasNextPage,
    fetchPreviousPage,
    fetchNextPage,
    isLoading: isLoadingInifiniteOrders,
    isFetching: isFetchingInifiniteOrders,
  } = useInfiniteQuery(
    [...orderService.fetchAll.queryKeys, persistence, 'infinite'],
    ({ pageParam = persistence?.lastIndex || 0 }) =>
      orderService.fetchAll({ ...persistence, lastIndex: pageParam }) as Promise<Paginated<Order>>,
    {
      enabled: !!persistence,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getPreviousPageParam: currentPage => {
        const { lastIndex, resultSize } = currentPage.pagination
        const firstIndex = lastIndex - resultSize - persistence!.count
        return firstIndex >= 0 ? firstIndex : undefined
      },
      getNextPageParam: currentPage => {
        const { lastIndex, collectionSize } = currentPage.pagination
        return lastIndex < collectionSize ? lastIndex : undefined
      },
    }
  )

  const { data: directOrder, isLoading: isLoadingDirectOrder } = useQuery(
    [...orderService.fetch.queryKeys, orderId],
    () => orderService.fetch(orderId),
    { enabled: !persistence }
  )

  const { data: relatedDesigns, isLoading: isLoadingRelatedDesigns } = useQuery(
    [...orderService.fetchRelatedDesigns.queryKeys, orderId],
    () => orderService.fetchRelatedDesigns(orderId),
    { enabled: !!persistence }
  )

  const handleGoBack = () => {
    if (persistence) {
      const currentPageIndex = (data?.pages || []).findIndex(page => page.results.find(({ id }) => id === orderId))
      const lastIndex = data?.pageParams?.[currentPageIndex] || persistence.lastIndex
      const query = qs.stringify({ ...persistence, lastIndex }, { encode: false, skipNulls: true })
      history.push(`${baseUrl}/orders?${query}`)
    } else {
      history.push(`${baseUrl}/orders`)
    }
  }

  const isLoadingOrders = persistence ? isLoadingInifiniteOrders : isLoadingDirectOrder
  const isLoadingDesigns = persistence ? isLoadingRelatedDesigns : isLoadingDirectOrder

  let orderSiblings: IndexedOrders = {}
  let order: Order | DenormalizedOrder | undefined

  if (!!persistence) {
    const allOrders = (data?.pages || []).reduce<Order[]>((result, page) => [...result, ...page.results], [])
    const currentPosition = allOrders.findIndex(({ id }) => id === orderId)

    orderSiblings = {
      previous: allOrders[currentPosition - 1],
      next: allOrders[currentPosition + 1],
    }
    order = allOrders[currentPosition]
  } else {
    order = directOrder
  }

  const designs = persistence ? relatedDesigns : directOrder?.items.map(({ design }) => design)

  useEffect(() => {
    if (!orderSiblings.previous && hasPreviousPage) fetchPreviousPage()
    if (!orderSiblings.next && hasNextPage) fetchNextPage()
  })

  return {
    order,
    designs,
    orderSiblings,
    isLoadingOrders,
    isFetchingOrders: isFetchingInifiniteOrders,
    isLoadingDesigns,
    handleGoBack,
  }
}
