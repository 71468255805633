import { Subscription, SubscriptionStatus } from '@packages/types'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { isPast } from 'date-fns'
import React, { useContext, useEffect } from 'react'

import useSubscriptionService from 'cms/subscription/hooks/useSubscriptionService'
import ResubscribeFullPageModal from 'cms/subscription/components/modals/ResubscribeFullPageModal'
import useModal from 'common/components/modal/useModal'
import TenantContext from 'common/tenant/TenantContext'
import useCurrentUser from 'common/users/hooks/useCurrentUser'

import useAuthService from './../hooks/useAuthService'

interface SubscribedRoutesProps {
  children: React.ReactNode
}

const isCanceled = (subscription: Subscription) => {
  return subscription.status === SubscriptionStatus.Canceled
}

const isFreeTrialExpired = (subscription: Subscription) => {
  return subscription.status === SubscriptionStatus.FreeTrial && isPast(new Date(subscription.freeTrialEndsOn))
}

const SubscribedRoutes = ({ children }: SubscribedRoutesProps) => {
  const subscriptionService = useSubscriptionService()
  const authService = useAuthService()
  const { isMCZRUser, isMasterUser, isLoadingCurrentUser } = useCurrentUser()
  const tenant = useContext(TenantContext)
  const resubscribeModal = useModal()
  const queryClient = useQueryClient()

  const { mutate: updateToken } = useMutation(authService.updateToken, {
    onSuccess: data => {
      if (data.token) {
        localStorage.setItem('bearer', data.token)
        queryClient.invalidateQueries([tenant])
      }
    },
  })

  const { data: subscription, isLoading: isLoadingSubscription } = useQuery(
    subscriptionService.fetchOne.queryKeys,
    subscriptionService.fetchOne
  )

  const subscriptionActive = subscription && !isCanceled(subscription) && !isFreeTrialExpired(subscription)

  useEffect(() => {
    if (!isLoadingCurrentUser && !isMCZRUser) {
      updateToken()
    }
  }, [!subscriptionActive, isMCZRUser])

  useEffect(() => {
    if (!isMasterUser && !isLoadingSubscription && !subscriptionActive) {
      resubscribeModal.open()
    } else if (subscriptionActive && resubscribeModal.modalProps.isOpen) {
      resubscribeModal.close()
    }
  }, [!subscriptionActive, isMasterUser, isLoadingSubscription])

  if (!subscription) return null

  return (
    <>
      {resubscribeModal.isVisible && (
        <ResubscribeFullPageModal
          {...resubscribeModal.modalProps}
          subscription={subscription}
          onClose={isMCZRUser ? resubscribeModal.close : undefined}
        />
      )}

      {((subscriptionActive && !isLoadingSubscription) || isMCZRUser) && children}
    </>
  )
}

export default SubscribedRoutes
