import createInHousePlugin from '../createInHousePlugin'
import { Components } from './components'
import Plugin from './initialize'

const version = '1.0.2'

export default createInHousePlugin(
  'Pure Hockey Share Button',
  'pureHockeyPlugin',
  version,
  Plugin,
  Components,
  undefined,
  undefined,
  undefined
)
