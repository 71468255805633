import { Quote, ECommerce, QuoteStatus } from '@packages/types'
import { pdf } from '@react-pdf/renderer'
import { useQuery } from '@tanstack/react-query'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router'

import useBrandService from 'cms/brands/hooks/useBrandService'
import QuoteContext from 'cms/quotes/QuoteContext'
import { selectQuoteBulkPrice } from 'cms/quotes/utils'
import Button from 'common/components/Button'
import IconButton from 'common/components/IconButton'
import Modal from 'common/components/modal/Modal'
import Popover from 'common/components/popover/Popover'
import useModal from 'common/components/modal/useModal'
import usePopover from 'common/components/popover/usePopover'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import { trpc } from 'common/hooks/trpc'
import withFlag from 'common/users/components/withFlag'
import NavVerticalIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import EditIcon from 'icons/bold/01-Interface Essential/22-Edit/pencil-write-2.svg'
import downloadContent from 'utils/downloadContent'

import PrintableQuote from '../PrintableQuote/PrintableQuote'
import BusinessInfoMissingModal from './BusinessInfoMissingModal'
import ConfirmBulkPriceModal from './ConfirmBulkPriceModal'
import { QuoteFormValues } from './Quote'
import QuoteConvertToOrderSection from './QuoteConvertToOrderSection'

const QuoteHeaderActions = () => {
  const history = useHistory()
  const params = useParams<{ brandName?: string }>()
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''
  const dropdownMenu = usePopover({ placement: 'bottom-end', offsetConfig: 10 })
  const brandService = useBrandService()

  const { quote, isEditing, setIsEditing, invalidateQueries, isRevision } = useContext(QuoteContext) as {
    quote: Quote
    isEditing: boolean
    setIsEditing: (isEditing: boolean) => void
    invalidateQueries: () => void
    isRevision: boolean
  }
  const formik = useFormikContext<QuoteFormValues>()

  const businessInfoMissingModal = useModal()
  const confirmBulkPriceModal = useModal()
  const confirmCancelModal = useModal()

  const { openToast, openGenericErrorToast } = useToast()

  const { data: brand, isLoading: isBrandLoading } = useQuery(
    brandService.fetchAccount.queryKeys,
    brandService.fetchAccount
  )

  const { mutate: convertToOrder, isLoading } = trpc.quote.convertToOrder.useMutation({
    onSuccess: () => {
      if (quote.store.eCommerce === ECommerce.Shopify) {
        openToast(
          'Draft order was successfully created. To send an official invoice to your customer, please refer to the generated draft order in your Shopify dashboard',
          ToastType.success,
          { duration: 8000 }
        )
      } else if (quote.store.eCommerce === ECommerce.Wix) {
        openToast(`A Wix checkout link was successfully generated`, ToastType.success)
      } else {
        openToast(`Quote QT${quote.quoteId} was successfully converted to order`, ToastType.success)
      }

      invalidateQueries()
    },
    onError: () => openGenericErrorToast(`Failed to convert quote QT${quote.quoteId} to order.`),
  })

  const { mutateAsync: updateQuote } = trpc.quote.update.useMutation({
    onSuccess: () => {
      invalidateQueries()
    },
  })

  const downloadQuotePDF = async () => {
    const fileName = `QT${quote.quoteId}.pdf`
    const blob = await pdf(<PrintableQuote quote={quote} infos={brand?.business} logo={brand?.logo} />).toBlob()
    downloadContent(blob, 'application/pdf', fileName)
    dropdownMenu.close()
  }

  const cancelQuote = async () => {
    await updateQuote({ id: quote.id, status: QuoteStatus.Canceled })
    dropdownMenu.close()
    confirmCancelModal.close()
    openToast(`Quote QT${quote.quoteId} was successfully cancelled`, ToastType.success)
  }

  const handleMenuClickConvertToOrder: React.MouseEventHandler<HTMLButtonElement> = async () => {
    quote && convertToOrder({ id: quote.id })
  }

  const handleDownloadPdfClick = () => {
    if (!brand?.business?.name || !brand?.business?.email) {
      businessInfoMissingModal.open()
      dropdownMenu.close()
    } else {
      downloadQuotePDF()
    }
  }

  const handleMenuClickCancel = () => {
    confirmCancelModal.open()
    dropdownMenu.close()
  }

  const handleMenuClickReviseQuote = async () => {
    history.push(`${baseUrl}/quotes/${quote.id}/revise`)
  }

  const handleMenuClickMarkAsSent = async () => {
    await updateQuote({ id: quote.id, sent: !quote.sent })
    openToast(`Quote QT${quote.quoteId} was ${!quote.sent ? 'marked' : 'unmarked'} as sent`, ToastType.success)
    dropdownMenu.close()
  }

  const handleEditClick = () => {
    setIsEditing(true)
    formik.setSubmitting(false)
  }

  const handleDiscard = () => {
    if (isRevision) {
      return history.push(`${baseUrl}/quotes/${quote.id}`)
    }

    formik.resetForm()
    setIsEditing(false)
  }

  const handleFinalize = () => {
    const designWithBadPrice = formik.values.items.find((item: any) => {
      if ('bulkPrices' in item.design) {
        if (item.design.ignoreBulkPrices) return false
        const bulkPrice = selectQuoteBulkPrice(item.design)

        if (!bulkPrice) return false
        return item.design.designs.find((subDesign: any) => subDesign.design.basePrice !== Number(bulkPrice.price))
      }
      return false
    })

    if (designWithBadPrice && formik.dirty) {
      return confirmBulkPriceModal.open()
    }

    formik.submitForm()
  }

  if (!quote) return null

  return (
    <React.Fragment>
      {!isEditing ? (
        <div className="flex space-x-3 print:hidden">
          {quote.status === QuoteStatus.Open && !quote.integration?.shopify?.draftOrderId && (
            <QuoteConvertToOrderSection
              quote={quote}
              onConvertToOrder={handleMenuClickConvertToOrder}
              isLoading={isLoading}
            />
          )}

          {quote.status === QuoteStatus.Draft && (
            <Button onClick={handleEditClick} variant="primary" type="button">
              <EditIcon className="fill-white w-3 h-3 mr-2" />
              Prepare quote
            </Button>
          )}

          {quote.status !== QuoteStatus.Draft && (
            <Button
              variant="primary"
              onClick={handleDownloadPdfClick}
              disabled={isBrandLoading}
              isLoading={isBrandLoading}
              type="button"
            >
              Download PDF
            </Button>
          )}

          {![QuoteStatus.Canceled, QuoteStatus.Accepted].includes(quote.status) && (
            <IconButton
              aria-label="open actions menu"
              Icon={NavVerticalIcon}
              {...dropdownMenu.referenceProps}
              type="button"
            />
          )}

          <Popover {...dropdownMenu.floatingProps} isOpen={dropdownMenu.isOpen}>
            {quote.status === QuoteStatus.Open && (
              <>
                <Popover.Action className="text-neutral-800" onClick={handleMenuClickMarkAsSent} type="button">
                  {!quote.sent ? 'Mark as sent' : 'Unmark as sent'}
                </Popover.Action>
                {!quote.integration?.shopify?.draftOrderId && !quote.integration?.wix?.checkoutUrl && (
                  <Popover.Action className="text-neutral-800" onClick={handleMenuClickReviseQuote} type="button">
                    Revise quote
                  </Popover.Action>
                )}
              </>
            )}
            {quote.status === QuoteStatus.Draft && (
              <Popover.Action className="text-neutral-800" onClick={handleDownloadPdfClick}>
                Download PDF
              </Popover.Action>
            )}
            {![QuoteStatus.Canceled, QuoteStatus.Accepted].includes(quote.status) && (
              <Popover.Action className="text-error-default" onClick={handleMenuClickCancel} type="button">
                Cancel quote
              </Popover.Action>
            )}
          </Popover>
        </div>
      ) : (
        <div className="flex space-x-3 print:hidden">
          <Button onClick={handleDiscard} disabled={formik.isSubmitting} type="button">
            Discard
          </Button>
          <Button
            variant="primary"
            onClick={handleFinalize}
            isLoading={formik.isSubmitting}
            disabled={!isEmpty(formik.errors)}
            type="button"
          >
            Finalize
          </Button>
        </div>
      )}

      {confirmCancelModal.isVisible && (
        <Modal {...confirmCancelModal.modalProps}>
          <Modal.Title>Cancel quote</Modal.Title>
          <Modal.Details>Are you sure you want to cancel this quote?</Modal.Details>
          <Modal.Actions>
            <Button onClick={confirmCancelModal.close} type="button">
              No
            </Button>
            <Button variant="error" onClick={cancelQuote} type="button">
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {businessInfoMissingModal.isVisible && (
        <BusinessInfoMissingModal
          onDownloadClick={downloadQuotePDF}
          onAddInfoClick={() => {
            history.push(`${baseUrl}/business`)
          }}
          onClose={businessInfoMissingModal.close}
          onBackdropClick={businessInfoMissingModal.close}
          {...businessInfoMissingModal.modalProps}
        />
      )}

      <ConfirmBulkPriceModal {...confirmBulkPriceModal} />
    </React.Fragment>
  )
}

export default withFlag({
  feature: 'quote_phase_2',
  Component: QuoteHeaderActions,
})
