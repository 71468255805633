import { Addon } from '@packages/types'
import React from 'react'

import Card from 'common/components/card/Card'
import Switch from 'common/components/Switch'
import classMerge from 'utils/classMerge'

interface AddonCardProps {
  subscriptionAddons: Addon[]
  availableAddons: Addon[]
  onAdd: (addon: Addon) => void
  onRemove: (addon: Addon) => void
}

const AddonsList = ({ subscriptionAddons: subscribedAddons, availableAddons, onAdd, onRemove }: AddonCardProps) => {
  return (
    <>
      {availableAddons.map(addon => {
        const isAdded = !!subscribedAddons.find(subAddon => subAddon.addonId === addon.addonId)
        return (
          <Card.Section key={addon.addonId} className="md:flex-col">
            <div className="flex justify-between items-center">
              <div className="flex space-x-1 items-center">
                <Switch
                  className="mr-2"
                  aria-label="addon-switch"
                  onChange={e => {
                    if (e.target.checked) {
                      onAdd(addon)
                    } else {
                      onRemove(addon)
                    }
                  }}
                  checked={isAdded}
                />
                <span className={classMerge('font-medium', { 'text-neutral-400': !isAdded })}>{addon.name}</span>
              </div>
              <span className={classMerge('h-fit w-fit ml-2 text-primary-600', { 'text-neutral-400': !isAdded })}>
                +${addon.price} monthly
              </span>
            </div>
            <span className="pt-4 text-neutral-400">{addon.description}</span>
          </Card.Section>
        )
      })}
    </>
  )
}

export default AddonsList
