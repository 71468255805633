import classNames from 'classnames'
import React, { forwardRef } from 'react'

import Button, { ButtonProps } from '../Button'

export interface BlankStateActionProps extends ButtonProps {
  className?: string
  children?: React.ReactNode
}

const BlankStateAction = forwardRef<HTMLButtonElement, ButtonProps>(({ className, ...rest }, ref) => {
  return <Button ref={ref} className={classNames('mt-6 w-fit', className)} {...rest} />
})

export default BlankStateAction
