import {
  Asset,
  FontType,
  ThemeBuilderColorSetting,
  ThemeBuilderColorWithAlphaSetting,
  ThemeBuilderFontFamilySetting,
  ThemeBuilderFontSizeSetting,
  ThemeBuilderRadioSetting,
  ThemeBuilderSectionSetting,
  ThemeBuilderSelectSetting,
  ThemeBuilderSettingType,
  ThemeBuilderSwitchSetting,
} from '@packages/types'
import { Field, FieldProps, useFormikContext } from 'formik'
import { get, merge } from 'lodash'
import React, { CSSProperties } from 'react'
import { ColorChangeHandler, SketchPicker } from 'react-color'
import tinycolor from 'tinycolor2'

import * as builderConstants from 'builder/build/common/constants'
import { getFontLabel, getFontValue } from 'common/assets/utils/formatFont'
import AsyncFontSelect from 'common/assets/components/AsyncFontSelect'
import Input from 'common/components/inputs/Input'
import Select from 'common/components/inputs/select/Select'
import Switch from 'common/components/Switch'
import Radio from 'common/components/inputs/Radio'
import Tooltip from 'common/components/Tooltip'
import Popover from 'common/components/popover/Popover'
import usePopover from 'common/components/popover/usePopover'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import useDocumentMoveHandlers from 'common/hooks/useDocumentMoveHandlers'
import { trpc } from 'common/hooks/trpc'
import InfoIcon from 'icons/bold/01-Interface Essential/14-Alerts/information-circle.svg'

type GetAllColors = (values: Record<string, { color: string; title: string }>) => { color: string; title: string }[]

interface ColorPickerProps {
  withAlpha?: boolean
  value: string
  presetColors: { color: string; title: string }[]
  onChange: ColorChangeHandler
}

const ColorPicker = ({ withAlpha, value, presetColors, onChange }: ColorPickerProps) => {
  const { isMoving, ...documentMoveHandlers } = useDocumentMoveHandlers()

  return (
    <div {...documentMoveHandlers}>
      <SketchPicker
        disableAlpha={!withAlpha}
        presetColors={presetColors}
        color={tinycolor(value).toRgb()}
        onChange={onChange}
        styles={
          merge(builderConstants.colorPickerStyle, {
            picker: {
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
            } as CSSProperties,
            saturation: {
              marginLeft: '-1rem',
              marginRight: '-1rem',
              width: 'calc(100% + 2rem)',
              paddingBottom: '75%',
              position: 'relative',
              overflow: 'hidden',
            } as CSSProperties,
          }) as React.ComponentProps<typeof SketchPicker>['styles']
        }
      />
    </div>
  )
}

interface ColorSettingProps {
  fieldName: string
  setting: ThemeBuilderColorSetting | ThemeBuilderColorWithAlphaSetting
  withAlpha?: boolean
  getAllColors: GetAllColors
}

export const ColorSetting = ({ fieldName, setting, withAlpha, getAllColors }: ColorSettingProps) => {
  const popover = usePopover({ placement: 'left' })

  return (
    <div className="flex px-4 h-8 items-center" data-testid="color-setting">
      <Field name={fieldName}>
        {({ field, form }: FieldProps<(typeof setting)['default']>) => {
          const { value } = field
          const { setFieldValue, values } = form

          return (
            <>
              <label className="basis-40 flex items-center space-x-2">
                <span>{setting.name}</span>
              </label>
              <div
                {...popover.referenceProps}
                className="h-6 w-6 border border-solid border-neutral-75 rounded-full shadow-xs hover:cursor-pointer hover:shadow-sm transition-shadow ml-auto"
                style={{ backgroundColor: value }}
              />
              <Popover {...popover.floatingProps} isOpen={popover.isOpen} className="w-48">
                <ColorPicker
                  value={value}
                  withAlpha={withAlpha}
                  presetColors={getAllColors(values)}
                  onChange={color =>
                    setFieldValue(fieldName, withAlpha ? tinycolor(color.rgb).toRgbString() : color.hex)
                  }
                />
              </Popover>
            </>
          )
        }}
      </Field>
    </div>
  )
}

interface FontSettingProps {
  fieldName: string
  setting: ThemeBuilderFontFamilySetting
}

export const FontSetting = ({ setting, fieldName }: FontSettingProps) => {
  const trpcUtils = trpc.useContext()
  const formik = useFormikContext()
  const { openToast } = useToast()

  const { fontFamily: value, type, asset } = get(formik.values, fieldName) || {}

  const updateFontAssets = async (asset: Asset | null) => {
    if (asset) {
      formik.setFieldValue(fieldName, { fontFamily: getFontValue(asset), type: FontType.Custom, asset })
      openToast(`${asset.originalFilename} was successfully uploaded`, ToastType.success)
    } else {
      formik.setFieldValue(fieldName, null)
    }

    trpcUtils.asset.listCustomFonts.invalidate()
  }

  return (
    <div className="flex flex-col px-4" data-testid="font-setting">
      <div className="mb-1 flex items-center space-x-2">
        <span>{setting.name}</span>
      </div>
      <div className="flex w-full">
        <Field name={fieldName}>
          {({ form }: FieldProps<(typeof setting)['default']>) => (
            <AsyncFontSelect
              onUpload={updateFontAssets}
              onChange={selectedOption => {
                form.setFieldValue(
                  fieldName,
                  { fontFamily: selectedOption!.value, type: selectedOption!.type, asset: selectedOption!.asset },
                  true
                )
              }}
              value={{
                value,
                type,
                asset,
                label: type === FontType.Custom ? getFontLabel(asset) : value,
              }}
              fontType={type}
              className="w-full"
              id="font-upload-popover"
            />
          )}
        </Field>
      </div>
    </div>
  )
}

interface SelectSettingProps {
  fieldName: string
  setting: ThemeBuilderSelectSetting
}

export const SelectSetting = ({ fieldName, setting }: SelectSettingProps) => {
  return (
    <div className="flex flex-col px-4" data-testid="select-setting">
      <div className="mb-1 flex items-center space-x-2">
        <span>{setting.name}</span>
      </div>
      <Field name={fieldName}>
        {({ field, form }: FieldProps<(typeof setting)['default']>) => {
          return (
            <Select
              value={setting.options.find(option => option.value === field.value)}
              options={setting.options}
              onChange={option => {
                form.setFieldValue(fieldName, option!.value, true)
              }}
              menuPortalTarget={document.querySelector('body')}
            />
          )
        }}
      </Field>
    </div>
  )
}

interface SizeSettingProps {
  fieldName: string
  setting: ThemeBuilderFontSizeSetting
}

export const SizeSetting = ({ fieldName, setting }: SizeSettingProps) => {
  return (
    <div className="flex px-4 h-8 items-center" data-testid="size-setting">
      <div className="basis-36 shrink-0 flex items-center space-x-2">
        <span>{setting.name}</span>
      </div>
      <Field name={fieldName}>
        {({ field, form }: FieldProps<(typeof setting)['default']>) => {
          return (
            <Input
              type="number"
              min="0"
              value={field.value?.split('px')[0]}
              onChange={e => {
                form.setFieldValue(
                  fieldName,
                  Number(e.currentTarget.value) < 0 ? '0px' : e.currentTarget.value + 'px',
                  true
                )
              }}
              rightAddon="px"
            />
          )
        }}
      </Field>
    </div>
  )
}

interface SwitchSettingProps {
  fieldName: string
  setting: ThemeBuilderSwitchSetting
}

export const SwitchSetting = ({ fieldName, setting }: SwitchSettingProps) => {
  return (
    <div className="flex px-4 h-8 items-center" data-testid="switch-setting">
      <Field name={fieldName}>
        {({ field, form }: FieldProps<(typeof setting)['default']>) => {
          const { value } = field
          const { setFieldValue } = form

          return (
            <>
              <div className="basis-40 shrink-0 flex items-center space-x-2">{setting.name}</div>

              <div className="ml-auto flex items-center gap-3">
                <Tooltip content={setting.description} disabled={!setting.description}>
                  {setting.description && <InfoIcon className="w-4 h-4 fill-neutral-500" />}
                </Tooltip>
                <Switch checked={value} onChange={e => setFieldValue(fieldName, e.target.checked, true)} />
              </div>
            </>
          )
        }}
      </Field>
    </div>
  )
}

interface RadioSettingProps {
  fieldName: string
  setting: ThemeBuilderRadioSetting
}

export const RadioSetting = ({ fieldName, setting }: RadioSettingProps) => {
  return (
    <Field name={fieldName}>
      {({ field, form }: FieldProps<string>) => {
        const { value } = field
        const { setFieldValue } = form

        return (
          <div className="flex flex-col space-y-2">
            {setting.options.map(option => (
              <div className="flex px-4 h-8 items-center" key={option.label}>
                <div className="basis-36 shrink-0">{option.label}</div>
                <Radio
                  className="ml-auto"
                  id={option.label}
                  name={option.label}
                  checked={value === option.value}
                  onChange={() => setFieldValue(fieldName, option.value, true)}
                />
              </div>
            ))}
          </div>
        )
      }}
    </Field>
  )
}

interface SettingFactoryProps {
  fieldName: string
  setting: ThemeBuilderSectionSetting
  getAllColors: GetAllColors
}

const SettingFactory = (props: SettingFactoryProps) => {
  switch (props.setting.type) {
    case ThemeBuilderSettingType.FontSize:
      return <SizeSetting {...props} setting={props.setting} />
    case ThemeBuilderSettingType.FontFamily:
      return <FontSetting {...props} setting={props.setting} />
    case ThemeBuilderSettingType.Select:
      return <SelectSetting {...props} setting={props.setting} />
    case ThemeBuilderSettingType.Color:
      return <ColorSetting {...props} setting={props.setting} />
    case ThemeBuilderSettingType.ColorWithAlpha:
      return <ColorSetting {...props} setting={props.setting} withAlpha />
    case ThemeBuilderSettingType.Switch:
      return <SwitchSetting {...props} setting={props.setting} />
    case ThemeBuilderSettingType.Radio:
      return <RadioSetting {...props} setting={props.setting} />
    default:
      return null
  }
}

interface ThemeSettingProps {
  sectionKey: string
  settingKey: string
  setting: ThemeBuilderSectionSetting
  getAllColors: GetAllColors
}

const ThemeSetting = (props: ThemeSettingProps) => {
  const fieldName = `${props.sectionKey}.${props.settingKey}`

  return <SettingFactory {...props} fieldName={fieldName} />
}

export default ThemeSetting
