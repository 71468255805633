import React from 'react'

const CardTabPrimaryText = ({
  children,
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) => (
  <div
    className={'whitespace-nowrap flex text-ellipsis w-full overflow-hidden group-[[aria-selected="true"]]:font-medium'}
  >
    {children}
  </div>
)

export default CardTabPrimaryText
