import React from 'react'

import EmptyBlankState, { EmptyBlankStateProps } from './EmptyBlankState'
import FiltersBlankState, { FiltersBlankStateProps } from './FiltersBlankState'
import NewBlankState, { NewBlankStateProps } from './NewBlankState'

interface BlankStateProps extends EmptyBlankStateProps, NewBlankStateProps, FiltersBlankStateProps {
  areFiltersEmpty: boolean
  isNewTabSelected: boolean
}

const BlankState = ({
  areFiltersEmpty,
  isNewTabSelected,
  handleChangeQuestionsClick,
  handleClearFiltersClick,
  handleViewAllVariantsClick,
}: BlankStateProps) => {
  if (areFiltersEmpty === false) return <FiltersBlankState handleClearFiltersClick={handleClearFiltersClick} />

  if (isNewTabSelected) return <NewBlankState handleViewAllVariantsClick={handleViewAllVariantsClick} />

  return <EmptyBlankState handleChangeQuestionsClick={handleChangeQuestionsClick} />
}

export default BlankState
