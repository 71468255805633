import classNames from 'classnames'
import React from 'react'

const TableFooterRow = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) => {
  return (
    <tr {...rest} className={classNames('border-t border-t-neutral-100', className)}>
      {children}
    </tr>
  )
}

export default TableFooterRow
