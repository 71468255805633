import { Group, Question } from '@packages/types'
import classNames from 'classnames'
import React, { useState } from 'react'

import Card from 'builder/build/common/components/cards/Card'
import TypeIcons from 'builder/build/common/components/TypeIcons'
import Separator from 'builder/build/common/components/Separator'
import { useSelector } from 'cms/hooks'
import Button from 'common/components/Button'
import Checkbox from 'common/components/inputs/Checkbox'
import IconButton from 'common/components/IconButton'
import Input from 'common/components/inputs/Input'
import { isBehindTheScene } from 'customizer/customization/utils'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import CloseIcon from 'icons/regular/01-Interface Essential/33-Form-Validation/close.svg'

import { groupsAsArraySelector } from '../../selectors'

export interface AddToBulkOrderMenuProps {
  bulkOrderGroup?: Group
  questions: Question[]
  onAddQuestions: (questions: string[]) => void
  onCreateQuestion: () => void
  onClose: () => void
}

const AddToBulkOrderMenu = ({
  bulkOrderGroup,
  questions,
  onAddQuestions,
  onCreateQuestion,
  onClose,
}: AddToBulkOrderMenuProps) => {
  const groups = useSelector(groupsAsArraySelector)
  const [filter, setFilter] = useState('')
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([])

  const toggleSelectedQuestion = (questionId: string) => {
    if (selectedQuestions.includes(questionId)) {
      setSelectedQuestions(state => state.filter(item => item !== questionId))
    } else {
      setSelectedQuestions(state => [...state, questionId])
    }
  }

  const filteredQuestions = questions.filter(
    question =>
      question.name.toLowerCase().includes(filter.toLowerCase()) &&
      !bulkOrderGroup?.children.includes(question.id) &&
      !isBehindTheScene(groups, question)
  )

  return (
    <div className="w-[304px]">
      <div className="p-4">
        <Button className="w-full" onClick={onCreateQuestion}>
          <div className="flex justify-center w-full">
            <AddIcon className="w-2.5 mr-2" /> <span>Create question</span>
          </div>
        </Button>
      </div>

      <Separator>or</Separator>

      <div className="font-medium px-4 my-4 mb-2">Select existing question</div>
      <div className={classNames('mx-4', { 'mb-0': !!filteredQuestions.length, 'mb-4': !filteredQuestions.length })}>
        <Input
          placeholder="Search questions..."
          value={filter}
          onChange={e => setFilter(e.target.value)}
          rightAddon={
            filter !== '' && (
              <IconButton
                small
                variant="subtle"
                className="-mr-2"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setFilter('')
                }}
                Icon={CloseIcon}
              />
            )
          }
        />
      </div>

      <div className="popper-menu-scroll-container">
        {!questions.length && <p className="px-4">There are no questions, yet</p>}

        {!filteredQuestions.length && !!questions.length && (
          <p className="px-4">There are no questions with the specified filter</p>
        )}

        {filteredQuestions.map((question, index) => (
          <Card
            key={question.id}
            onClick={() => toggleSelectedQuestion(question.id)}
            className={classNames('mx-4 mb-2 px-1', {
              'mt-2': index === 0,
            })}
          >
            <Checkbox className="mr-2" id="show-on-view" checked={selectedQuestions.includes(question.id)} />

            <TypeIcons entity={question} />

            <span className="ml-2 mr-auto">{question.name}</span>
          </Card>
        ))}
      </div>

      <div className="flex justify-end space-x-2 p-4">
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="primary"
          onClick={() => onAddQuestions(selectedQuestions)}
          disabled={!selectedQuestions.length}
        >
          Add
        </Button>
      </div>
    </div>
  )
}

export default AddToBulkOrderMenu
