import { ShopifyInstalledTheme, ShopifyOnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import useShopifyProxyService from 'cms/onlineStores/hooks/useShopifyProxyService'
import Alert from 'common/components/alert/Alert'
import getSemanticVersionInfos from 'utils/getSemanticVersionInfos'

interface ShopifyThemeVersioningAlert {
  selectedTheme?: ShopifyInstalledTheme
  onlineStore: ShopifyOnlineStore
}

const ShopifyThemeVersioningAlert = ({ selectedTheme, onlineStore }: ShopifyThemeVersioningAlert) => {
  const shopifyProxyService = useShopifyProxyService()

  const { data: themeAssetVersions } = useQuery(
    [...shopifyProxyService.fetchThemeAssetVersions.queryKeys, onlineStore.id, selectedTheme?.id],
    () => shopifyProxyService.fetchThemeAssetVersions(onlineStore.id, selectedTheme!.id),
    { enabled: !!selectedTheme?.id }
  )

  if (!themeAssetVersions || themeAssetVersions.lastVersion === themeAssetVersions.version) return null

  const currentVersionInfos = getSemanticVersionInfos(themeAssetVersions.version)
  const lastVersionInfos = getSemanticVersionInfos(themeAssetVersions.lastVersion)

  if (currentVersionInfos?.major !== lastVersionInfos?.major) {
    return (
      <Alert variant="warning">
        <Alert.Body>
          <Alert.Title>Update to product version {themeAssetVersions.lastVersion} is available</Alert.Title>
          <Alert.Details>
            This version include critical changes. We strongly suggest you to make this update.
          </Alert.Details>
          <Alert.Details>Your actual version is {themeAssetVersions.version}</Alert.Details>
          <Alert.Details>
            Updating your version will erase any code changes you (or your developer) might have done. Be sure to take a
            look to our docs to see how to recover the code and re-apply your changes. You might need a developer for
            this.
          </Alert.Details>
          <Alert.Link
            onClick={() =>
              window.open(
                'https://help.gokickflip.com/en/articles/7960037-how-to-merge-previous-custom-changes-to-kickflip-template-after-updating-it',
                '_blank'
              )
            }
          >
            Learn more
          </Alert.Link>
        </Alert.Body>
      </Alert>
    )
  }

  return (
    <Alert variant="info">
      <Alert.Body>
        <Alert.Title>Update to product version {themeAssetVersions.lastVersion} is available</Alert.Title>
        <Alert.Details>This version mostly include bug fixes.</Alert.Details>
        <Alert.Details>Your actual version is {themeAssetVersions.version}</Alert.Details>
        <Alert.Details>
          Updating your version will erase any code changes you (or your developer) might have done. Be sure to take a
          look to our docs to see how to recover the code and re-apply your changes. You might need a developer for
          this.
        </Alert.Details>
        <Alert.Link
          onClick={() =>
            window.open(
              'https://help.gokickflip.com/en/articles/7960037-how-to-merge-previous-custom-changes-to-kickflip-template-after-updating-it',
              '_blank'
            )
          }
        >
          Learn more
        </Alert.Link>
      </Alert.Body>
    </Alert>
  )
}

export default ShopifyThemeVersioningAlert
