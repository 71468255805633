import { CustomizationDesign, Design } from '@packages/types'
import { useContext } from 'react'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import type { Paginated } from 'common/api/types/pagination'
import TenantContext from 'common/tenant/TenantContext'
import dataURItoBlob from 'utils/dataURItoBlob'

import { DesignsSortKeys } from '../types/datatable'

export type FetchDesignsParams = {
  products?: string[]
  sortOrder?: 'descending' | 'ascending'
  sortKey?: DesignsSortKeys
  count?: number
  page?: number
  filter?: string
  endDate?: Date | null
  startDate?: Date | null
  lastIndex?: number
}

export type FetchAllDesignsResponse = Paginated<Design>

export const useDesignService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetchAll: createQuery([tenant, 'designs'], async (params: FetchDesignsParams) => {
      const { data } = await apiClient.get<FetchAllDesignsResponse>(`/brands/${tenant}/designs`, { params })
      return data
    }),
    fetch: createQuery([tenant, 'design'], async (id: string) => {
      const { data } = await apiClient.get<Design>(`/brands/${tenant}/designs/${id}`)
      return data
    }),
    generatePrintFiles: async (id: string) => {
      const { data } = await apiClient.post<CustomizationDesign>(`/brands/${tenant}/designs/${id}/generate-print-files`)
      return data
    },
    saveDesignImages: async (id: string, images: string[]) => {
      const formData = new FormData()

      images.forEach((image, i) => {
        formData.append('designImages[]', dataURItoBlob(image), `${id}-${i + 1}.png`)
      })

      await apiClient.post(`/brands/${tenant}/designs/${id}/images`, formData)
    },
  }
}

export default useDesignService
