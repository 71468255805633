import React from 'react'

import { useDispatch } from 'cms/hooks'
import BoxTransformer from 'common/components/editor/transformers/BoxTransformer'
import type { ClippingGroup } from 'customizer/2dDisplayer/types/node'

import { applyPrintAreaEdition } from '../../actions'
import TargetClipContainer from '../containers/TargetClipContainer'
import PrintArea from '../nodes/PrintArea'
import NodeInfo from './NodeInfo'
import { EditorProps } from './types'
import useBoxEditor from './useBoxEditor'

const PrintAreaEditor = ({ node, entity, currentView }: EditorProps<ClippingGroup>) => {
  const { targetRef, Target, getTransformedBoundingBox, onDrag, onResize, onRotate } = useBoxEditor({
    width: node.width + node.bleed * 2,
    height: node.height + node.bleed * 2,
    x: node.x,
    y: node.y,
    scale: node.scale,
    rotation: node.rotation,
    origin: 'top left',
  })

  const dispatch = useDispatch()

  const handleTransformEnd = () => {
    const boundingBox = getTransformedBoundingBox()

    dispatch(
      applyPrintAreaEdition(
        node.id,
        { ...boundingBox, width: boundingBox.width! - node.bleed * 2, height: boundingBox.height! - node.bleed * 2 },
        currentView
      )
    )
  }

  return (
    <>
      <TargetClipContainer>
        <Target>
          <PrintArea
            color={node.outlineColor}
            width={node.width}
            height={node.height}
            bleed={node.bleed}
            margins={node.margins}
          />
        </Target>
      </TargetClipContainer>
      <BoxTransformer
        target={targetRef}
        onDrag={onDrag}
        onResize={onResize}
        onRotate={onRotate}
        onTransformEnd={handleTransformEnd}
        info={<NodeInfo entity={entity} node={node} />}
        keepRatio
        origin
        transformOrigin="center"
        onRotateStart={e => {
          e.setFixedDirection([0, 0])
        }}
      />
    </>
  )
}

export default PrintAreaEditor
