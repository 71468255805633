import { Group, Question } from '@packages/types'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

import AnswersDimensionsWarning from 'builder/build/answers/components/AnswersDimensionsWarning'
import Card from 'builder/build/common/components/cards/Card'
import TypeIcons from 'builder/build/common/components/TypeIcons'

export interface FolderElementCardProps {
  text: string
  entity: Group | Question
  onClick: () => void
  isDragged: boolean
  isDragOverlay: boolean
}

const FolderElementCard = forwardRef<HTMLDivElement, FolderElementCardProps>(
  ({ text, entity, onClick, isDragOverlay, isDragged, ...props }, ref) => {
    return (
      <Card
        onClick={onClick}
        ref={ref}
        {...props}
        className={classNames('mx-2', {
          'opacity-80': isDragOverlay,
          'opacity-50': isDragged,
        })}
      >
        <TypeIcons entity={entity} />
        <span className="ml-2 mr-auto">{text}</span>
        <AnswersDimensionsWarning entityId={entity.id} entityType={entity.entityType} placement="top-start" />
      </Card>
    )
  }
)

export default FolderElementCard
