import React from 'react'

import CommonTopBar from 'common/components/topBar/TopBar'

import './TopBar.scss'

interface TopBarProps {
  children: React.ReactNode
}

const TopBar = ({ children }: TopBarProps) => <CommonTopBar>{children}</CommonTopBar>

export default TopBar
