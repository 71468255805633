import * as answersSelectors from 'builder/build/answers/selectors'
import useCustomizerSelector from 'builder/build/customizer/hooks/useCustomizerSelector'
import { useSelector } from 'cms/hooks'
import * as customizationSelectors from 'customizer/customization/selectors'

const useSelectPartAnswer = (entityId?: string) => {
  const answer = useSelector(state => answersSelectors.answerByIdSelector(state, { id: entityId }))
  const customizerSelectedAnswerId = useCustomizerSelector(state =>
    customizationSelectors.selectedAnswerIdSelector(state, entityId)
  )
  const customizerSelectedAnswer = useSelector(state =>
    answersSelectors.answerByIdSelector(state, { id: customizerSelectedAnswerId })
  )

  return [answer, customizerSelectedAnswer]
}

export default useSelectPartAnswer
