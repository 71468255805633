import React from 'react'

import Tree from 'builder/build/groups/components/Tree'
import SubmitActionSection from 'builder/build/submit/components/SubmitActionSection'

export interface QuestionTreeProps {
  onCreateQuestionClick: () => void
}

const QuestionTreePanel = ({ onCreateQuestionClick }: QuestionTreeProps) => {
  return (
    <div className="flex flex-col h-full overflow-hidden">
      <Tree onCreateQuestionClick={onCreateQuestionClick} />
      <SubmitActionSection />
    </div>
  )
}

export default QuestionTreePanel
