import React from 'react'

import useSharedSkus from '../../hooks/useSharedSkus'
import StockCell, { StockCellProps } from './StockCell'

export interface StockCellWithSharedDataProps extends Omit<StockCellProps, 'sharedCount'> {
  sku: string
}

const StockCellWithSharedData = ({ sku, ...props }: StockCellWithSharedDataProps) => {
  const skuSharedData = useSharedSkus(sku)

  return <StockCell {...props} sharedCount={skuSharedData == null ? 0 : skuSharedData.variantIds.length} />
}

export default StockCellWithSharedData
