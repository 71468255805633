import { ProductPricing, Equation } from '@packages/types'

export enum UpdateTypes {
  adjust = 'adjust',
  set = 'set',
}

export type ExtraPrice = {
  questionId: string
  answerId: string
  price: string
}

export type PriceBreak = {
  quantity: string
  price: string
}

export type ExtraPricesType = ExtraPrice[]

type EquationsType = Equation[]

export type PricingFormValues = {
  id: string
  basePrice: string
  bulkPrices: PriceBreak[]
  extraPrices: ExtraPricesType
  equations: EquationsType
  applyTaxes: boolean
  taxes: string
}

export interface ProductPricingWithId extends ProductPricing {
  id: string
}
