import { isEqual } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { OnDrag, OnResize, OnRotate } from 'react-moveable'
import { useCallbackRef } from 'use-callback-ref'

import ContainerContext from 'common/components/editor/containers/ContainerContext'
import { getCenterFromPoints, getRotationFromPoints, rotatePoint, toRads } from 'utils/math'

const usePathEditor = (path: { bezier: number[]; scale: number }) => {
  const [, forceUpdate] = useState<number>(0)
  const targetRef = useRef<HTMLDivElement>(null)
  const pathRef = useCallbackRef<SVGPathElement>(null, () => forceUpdate(c => c + 1))
  const controlLine1Ref = useRef<SVGLineElement>(null)
  const controlLine2Ref = useRef<SVGLineElement>(null)
  const { scale: parentScale } = useContext(ContainerContext)

  const scale = path.scale * parentScale
  const scaledBezier = path.bezier.map(coord => coord * scale)

  const frame = useRef({
    bezier: scaledBezier,
    newBezier: scaledBezier,
  })

  const leftX = scaledBezier[0] * scale
  const leftY = scaledBezier[1] * scale
  const rightX = scaledBezier[6] * scale
  const rightY = scaledBezier[7] * scale

  const rotation = getRotationFromPoints([
    { x: leftX, y: leftY },
    { x: rightX, y: rightY },
  ])

  const renderNewBezier = (newBezier: number[]) => {
    if (!pathRef.current) return
    let d = 'M '
    for (let i = 0; i <= newBezier.length - 2; i += 2) {
      d += `${newBezier[i]},${newBezier[i + 1]} `
      if (i == 0) {
        d += 'C '
      }
    }
    pathRef.current.setAttributeNS(null, 'd', d)

    controlLine1Ref.current?.setAttributeNS(null, 'x2', `${newBezier[0]}`)
    controlLine1Ref.current?.setAttributeNS(null, 'y2', `${newBezier[1]}`)
    controlLine1Ref.current?.setAttributeNS(null, 'x1', `${newBezier[2]}`)
    controlLine1Ref.current?.setAttributeNS(null, 'y1', `${newBezier[3]}`)

    controlLine2Ref.current?.setAttributeNS(null, 'x1', `${newBezier[4]}`)
    controlLine2Ref.current?.setAttributeNS(null, 'y1', `${newBezier[5]}`)
    controlLine2Ref.current?.setAttributeNS(null, 'x2', `${newBezier[6]}`)
    controlLine2Ref.current?.setAttributeNS(null, 'y2', `${newBezier[7]}`)
  }

  const getTransformedBezier = () => frame.current.newBezier.map(coord => coord / scale)

  useEffect(() => {
    if (!isEqual(scaledBezier, frame.current.bezier)) {
      frame.current = {
        bezier: scaledBezier,
        newBezier: scaledBezier,
      }
    }
  }, [scaledBezier])

  return {
    targetRef,
    getTransformedBezier,
    bezier: scaledBezier,
    scale,
    pathRef,
    controlLine1Ref,
    controlLine2Ref,

    onDrag: (e: OnDrag) => {
      const newBezier = frame.current.bezier.map((coord, i) => (i % 2 === 0 ? coord + e.dist[0] : coord + e.dist[1]))
      renderNewBezier(newBezier)

      frame.current.newBezier = newBezier
    },
    onResize: (e: OnResize) => {
      const offsetX = (Math.sin(rotation) * e.height) / 2
      const offsetY = (Math.cos(rotation) * e.height) / 2 + e.height / 2

      const unrotatedLeft = {
        x: e.drag.beforeTranslate[0] - offsetX,
        y: e.drag.beforeTranslate[1] + offsetY,
      }
      const unrotatedRight = {
        x: e.drag.beforeTranslate[0] - offsetX + e.width,
        y: e.drag.beforeTranslate[1] + offsetY,
      }
      const center = getCenterFromPoints([unrotatedLeft, unrotatedRight])

      const rotatedLeft = rotatePoint(unrotatedLeft, rotation, center)
      const rotatedRight = rotatePoint(unrotatedRight, rotation, center)

      const newBezier = [
        rotatedLeft.x,
        rotatedLeft.y,
        rotatedLeft.x,
        rotatedLeft.y,
        rotatedRight.x,
        rotatedRight.y,
        rotatedRight.x,
        rotatedRight.y,
      ]

      renderNewBezier(newBezier)

      frame.current.newBezier = newBezier
    },
    onTransform: (bezier: number[]) => {
      frame.current.newBezier = bezier
    },
    onRotate: (e: OnRotate) => {
      const initialLeft = { x: frame.current.bezier[0], y: frame.current.bezier[1] }
      const initialRight = { x: frame.current.bezier[6], y: frame.current.bezier[7] }

      const center = {
        x: e.drag.translate[0] + e.moveable.state.transformOrigin[0],
        y: e.drag.translate[1] + e.moveable.state.transformOrigin[1],
      }

      const rotatedLeft = rotatePoint(initialLeft, toRads(e.rotation) - rotation, center)
      const rotatedRight = rotatePoint(initialRight, toRads(e.rotation) - rotation, center)

      const newBezier = [
        rotatedLeft.x,
        rotatedLeft.y,
        rotatedLeft.x,
        rotatedLeft.y,
        rotatedRight.x,
        rotatedRight.y,
        rotatedRight.x,
        rotatedRight.y,
      ]

      renderNewBezier(newBezier)

      frame.current.newBezier = newBezier
    },
  }
}

export default usePathEditor
