import classNames from 'classnames'
import React from 'react'

import './MovablePanelHeader.scss'

interface PopperPanelHeaderProps {
  children: React.ReactNode
}

const PopperPanelHeader = ({ children }: PopperPanelHeaderProps) => {
  return <div className={classNames('popper-menu-header-section')}>{children}</div>
}

export default PopperPanelHeader
