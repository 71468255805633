import classNames from 'classnames'
import React, { forwardRef } from 'react'

export type FilterContainerVariant = 'default' | 'card'

export const classes = {
  default: 'border-t',
  card: 'px-6',
}

export interface FilterContainerProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant?: FilterContainerVariant
}

const FilterContainer = forwardRef<HTMLDivElement, FilterContainerProps>(
  ({ className, variant = 'default', children }, ref) => (
    <div className={classNames('flex items-center border-b border-neutral-100', classes[variant], className)} ref={ref}>
      {children}
    </div>
  )
)

export default FilterContainer
