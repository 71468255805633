import classNames from 'classnames'
import React from 'react'

interface PageAsideProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  title: string
  description?: string
}

const PageAside = ({ title, description, children, className, ...props }: PageAsideProps) => (
  <aside {...props} className={classNames('relative w-full mb-8 md:mb-0 md:w-2/5 2xl:w-1/2', className)}>
    <h2 className="mb-3">{title}</h2>
    {description && <span className="text-xs text-neutral-600">{description}</span>}
    {children}
  </aside>
)

export default PageAside
