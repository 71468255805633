import { SET_THEME } from './actionTypes'

export const requestCss = () => {
  return (dispatch, getState) => {
    const url = `${DEPRECATED_CUSTOM_CSS_CDN_URL}${getState().tenant}/${getState().tenant}.css`
    const link = document.createElement('link')
    link.id = 'custom-css'
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.media = 'all'
    link.href = url

    let linkEl = document.getElementById('custom-css')
    if (linkEl) {
      linkEl = link
    } else {
      document.head.appendChild(link)
    }
  }
}

export const setTheme = theme => ({ type: SET_THEME, payload: theme })
