import { memoize, gte, lte, isFinite } from 'lodash'

// memoize to avoid creating a new function on every render
export const min = memoize(minValue => (value: string | number) => gte(value, minValue))

// memoize to avoid creating a new function on every render
export const nullableMin = memoize(
  minValue => (value: string | number | null | undefined) =>
    value == null || isNaN(+value) || value == '' || gte(value, minValue)
)

// memoize to avoid creating a new function on every render
export const max = memoize(
  (maxValue: string | number) => (value: string | number | null | undefined) => lte(value, maxValue)
)

// memoize to avoid creating a new function on every render
export const color = (value: string | number) =>
  isFinite(Number(value)) && Number(value) >= 0x0 && Number(value) <= 0xffffff

// memoize to avoid creating a new function on every render
export const combine =
  (...args: any[]) =>
  (value: any) =>
    args.reduce((result, validator) => result && validator(value), true)
