import 'react-datepicker/dist/react-datepicker.min.css'
import './react-datepicker.css'

import React from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'

import Input from '../Input'
import DatePickerCalendarContainer from './DatePickerCalendarContainer'
import DatePickerHeader from './DatePickerHeader'
import DatePickerIcon from './DatePickerIcon'

export type ReactDatePickerRef = ReactDatePicker & {
  handleBlur: () => void
  handleFocus: () => void
  input: HTMLInputElement
}

export interface DatePickerInputProps extends Omit<ReactDatePickerProps, 'onChange'> {
  onValueChange: (date?: Date | null) => void
  disabled?: boolean
  hasError?: boolean
  id?: string
  inputRef?: React.RefObject<ReactDatePickerRef>
}

const DatePickerInput = ({
  onValueChange,
  className,
  id,
  inputRef,
  selected,
  disabled,
  hasError,
  ...rest
}: DatePickerInputProps) => {
  const ref = inputRef || React.createRef<ReactDatePickerRef>()

  const blur = () => {
    ref.current?.setOpen(false)
    ref.current?.handleBlur()
  }

  const handleCalendarIconClick = () => ref.current?.input.focus()

  const handleClearDates = () => {
    onValueChange?.(null)
    blur()
  }

  return (
    <ReactDatePicker
      calendarContainer={DatePickerCalendarContainer}
      customInput={
        <Input
          className={className}
          hasError={hasError}
          rightAddon={
            <DatePickerIcon
              onCalendarClick={handleCalendarIconClick}
              onClearDate={handleClearDates}
              showRemoveIcon={!!selected}
              disabled={disabled}
            />
          }
        />
      }
      disabled={disabled}
      id={id}
      onCalendarClose={blur}
      onChange={onValueChange}
      ref={ref}
      renderCustomHeader={DatePickerHeader}
      selected={selected}
      showPopperArrow={false}
      {...rest}
    />
  )
}

export default DatePickerInput
