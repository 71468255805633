import { PrestaShopOnlineStore, StartingPoint } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React, { useState } from 'react'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import usePrestashopProxyService from 'cms/onlineStores/hooks/usePrestashopProxyService'
import useModal from 'common/components/modal/useModal'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import useCopyToClipboard from 'common/hooks/useCopyToClipboard'
import { trpc } from 'common/hooks/trpc'
import PrestashopLogo from 'icons/custom/prestashop.svg'

import DisconnectModal from '../modals/DisconnectModal'
import ConnectButton from './ConnectButton'
import LinkSection from './LinkSection'

interface PrestaShopListItemProps {
  onlineStore: PrestaShopOnlineStore
  startingPoint: StartingPoint
  productId: string
}

const TOOL_TIP_MESSAGE = {
  available: 'This product is available on a Prestashop\nsales channel',
  unavailable: 'You have not made this product available\non a Prestashop sales channel yet',
}

const PrestaShopListItem = ({ onlineStore, startingPoint, productId }: PrestaShopListItemProps) => {
  const { openGenericErrorToast, openToast } = useToast()
  const [isOffline, setIsOffline] = useState<boolean>(false)
  const copyToClipboard = useCopyToClipboard()
  const prestashopProxyService = usePrestashopProxyService()
  const onlineStoreService = useOnlineStoreService()
  const disconnectModal = useModal()
  const trpcUtils = trpc.useContext()
  const queryClient = useQueryClient()

  const startingPointName = startingPoint.name || 'starting point'

  const handleSyncCodeClick = () => {
    const buffer = Buffer.from(
      JSON.stringify({
        startingPointImage: startingPoint.previewImage,
        startingPointName: startingPoint.name,
        startingPointId: startingPoint.id,
        price: onlineStore.productsData[productId].pricing?.basePrice ?? 0,
      })
    ).toString('base64')

    copyToClipboard(buffer)
  }

  const { data: connectedStartingPoint, isLoading: isLoadingConnectedStartingPoint } = useQuery(
    [...prestashopProxyService.fetchStartingPoint.queryKeys, onlineStore.id, startingPoint.id, productId],
    () => prestashopProxyService.fetchStartingPoint(onlineStore.id, startingPoint.id, productId),
    {
      retry: (failureCount, error: AxiosError) => {
        if (error.status === 404) return false // product not found (not connected)
        return failureCount < 3
      },
      enabled: !!onlineStore.url && !!productId,
      onSuccess: () => {
        setIsOffline(false)
      },
      onError: (error: AxiosError) => {
        if (error.status !== 404) setIsOffline(true)
      },
    }
  )

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation({
    onSuccess: () => trpcUtils.activityLog.listLatest.invalidate(),
  })

  const { mutate: connect, isLoading: isConnecting } = useMutation(
    () => onlineStoreService.addStartingPoint(onlineStore.id, productId, { startingPointId: startingPoint.id }),
    {
      onSuccess: () => {
        openToast(`Successfully connected ${startingPointName}`, ToastType.success)
        queryClient.invalidateQueries([
          ...prestashopProxyService.fetchStartingPoint.queryKeys,
          onlineStore.id,
          startingPoint.id,
          productId,
        ])
        createActivityLog({ type: 'addStartingPointToStore' })
      },
      onError: () => {
        openGenericErrorToast(`Failed to connect ${startingPointName}`)
      },
    }
  )

  const { mutate: disconnect, isLoading: isDisconnecting } = useMutation(
    () => prestashopProxyService.removeStartingPoint(onlineStore.id, startingPoint.id),
    {
      onSuccess: () => {
        openToast(`Successfully disconnected ${startingPointName}`, ToastType.success)

        queryClient.resetQueries([
          ...prestashopProxyService.fetchStartingPoint.queryKeys,
          onlineStore.id,
          startingPoint.id,
          productId,
        ])
      },
      onError: () => {
        openGenericErrorToast(`Failed to disconnect ${startingPointName}`)
      },
    }
  )

  return (
    <>
      <div className="bg-neutral-75 flex rounded-md items-center py-2 pl-4 pr-2 relative">
        <div className="flex items-center w-2/5">
          <PrestashopLogo className="w-5 h-5" />
          <div className="flex flex-col ml-4">
            <span className="font-medium">{onlineStore.name}</span>
            <span className="font-medium text-xs">{onlineStore.url}</span>
          </div>
        </div>

        <div className="w-2/5">
          <LinkSection
            toolTipMessage={connectedStartingPoint?.active ? TOOL_TIP_MESSAGE.available : TOOL_TIP_MESSAGE.unavailable}
            available={connectedStartingPoint?.active}
            eCommerce={onlineStore.eCommerce}
            handleSyncCodeClick={handleSyncCodeClick}
            isLoading={isLoadingConnectedStartingPoint || isDisconnecting || isConnecting}
            isOffline={isOffline}
            isConnected={!!connectedStartingPoint}
          />
        </div>

        <div className="flex w-1/5 justify-end">
          <ConnectButton
            eCommerce={onlineStore.eCommerce}
            handleConnect={connect}
            handleDisconnect={() => disconnectModal.open()}
            isConnected={!!connectedStartingPoint}
            isLoading={isLoadingConnectedStartingPoint}
            isConnecting={isConnecting}
            isDisconnecting={isDisconnecting}
            isOffline={isOffline}
          />
        </div>
      </div>

      {disconnectModal.isVisible && (
        <DisconnectModal
          onDisconnect={disconnect}
          onClose={disconnectModal.close}
          startingPointId={startingPoint.id}
          productId={productId}
          onlineStore={onlineStore}
          isDisconnecting={isDisconnecting}
          {...disconnectModal.modalProps}
        />
      )}
    </>
  )
}

export default PrestaShopListItem
