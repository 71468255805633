import { CustomizationDesign, OnlineStore } from '@packages/types'
import React from 'react'

import Card from 'common/components/card/Card'
import Price from 'common/components/Price'

import DesignCardAssetsSection from './DesignCardAssetsSection'
import DesignCardSummarySection from './DesignCardSummarySection'

interface DesignDetailsProps {
  isLoadingOnlineStores?: boolean
  isLoadingDesigns?: boolean
  onlineStore?: OnlineStore
  designId: string
  design?: CustomizationDesign
  isFromBulkOrder?: boolean
  index?: number
}

const DesignDetails = ({
  isLoadingOnlineStores,
  isLoadingDesigns,
  onlineStore,
  designId,
  design,
  isFromBulkOrder,
  index,
}: DesignDetailsProps) => {
  return (
    <div className="border-b border-neutral-100 print:clear-both print:border print:border-neutral-100 print:rounded-xl">
      <Card.Section>
        <div className="w-full sm:flex">
          {isFromBulkOrder && (
            <div className="flex flex-col flex-[2] border-b md:border-b-0 border-neutral-100 pb-4 md:pb-0 print:border-b-0 print:border-r print: mr-4">
              <span className="text-xs font-medium text-neutral-600 mb-4">Bulk Item</span>
              <span>#{index}</span>
            </div>
          )}

          <div className="flex flex-col flex-[2] border-b md:border-r md:border-b-0 border-neutral-100 pb-4 md:pb-0 print:border-b-0 print:border-r print: mr-4">
            <span className="text-xs font-medium text-neutral-600 mb-4">Product</span>
            {isLoadingDesigns && <div className="animate-pulse h-6 bg-neutral-75 rounded max-w-xs w-full" />}
            {!isLoadingDesigns && design && <span>{design.productName}</span>}
          </div>

          <div className="max-w-fit md:pl-20 flex flex-col flex-1 md:text-right mt-4 md:mt-0 print:mt-0">
            <span className="text-xs font-medium text-neutral-600 mb-4">Total</span>
            {isLoadingDesigns && <div className="animate-pulse h-6 bg-neutral-75 rounded max-w-xs w-full" />}
            {!isLoadingDesigns && !isLoadingOnlineStores && design && (
              <Price amount={design.price} currency={onlineStore?.currency || 'USD'} />
            )}
          </div>
        </div>
      </Card.Section>
      <Card.Separator />
      <div className="flex flex-col md:flex-row print:flex-col">
        <DesignCardAssetsSection designId={designId} />
        <DesignCardSummarySection design={design} isLoading={isLoadingDesigns} />
      </div>
    </div>
  )
}

export default DesignDetails
