import { AnswerType, FontType } from '@packages/types'
import { generateId } from '@packages/unique-string'
import { cloneDeep } from 'lodash'

import * as coreTypes from 'builder/build/core/actionTypes'
import * as coreSelectors from 'builder/build/core/selectors'
import * as printAreaUtils from 'builder/build/printAreas/utils'
import * as constants from 'common/customizerProducts/constants'
import getExtension from 'utils/getExtension'

import * as types from './actionTypes'
import { answerByIdSelector } from './selectors'

const {
  convertToPrintAreaLogoPosition,
  convertToPrintAreaTextPosition,
  centerPrintAreaLogoPosition,
  centerPrintAreaTextPosition,
} = printAreaUtils

export const createAnswer =
  (type, view = {}) =>
  (dispatch, getState) => {
    const answerId = generateId('ANSWER')
    const views = coreSelectors.viewsSelector(getState())

    return dispatch({ type: types.CREATE_ANSWER, payload: { type, views, view, id: answerId } })
  }

export const createTextPositionAnswer =
  (view = {}) =>
  (dispatch, getState) => {
    const answerId = generateId('ANSWER')
    const views = coreSelectors.viewsSelector(getState())
    const productDimensions = coreSelectors.dimensionsSelector(getState())

    return dispatch({
      type: types.CREATE_ANSWER,
      payload: { type: AnswerType.Position, views, view, id: answerId, productDimensions },
    })
  }

export const createLogoPositionAnswer =
  (view = {}) =>
  (dispatch, getState) => {
    const answerId = generateId('ANSWER')
    const views = coreSelectors.viewsSelector(getState())
    const productDimensions = coreSelectors.dimensionsSelector(getState())
    return dispatch({
      type: types.CREATE_ANSWER,
      payload: { type: AnswerType.LogoPosition, view, views, id: answerId, productDimensions },
    })
  }

export const createPrintAreaAnswer = (printArea, originalAnswer) => {
  return dispatch => {
    const answerId = generateId('ANSWER')
    const printAreaLogoPosition = convertToPrintAreaLogoPosition(printArea, originalAnswer)

    return dispatch({
      type: types.CREATE_ANSWER,
      payload: {
        type: AnswerType.PrintAreaLogoPosition,
        id: answerId,
        position: printAreaLogoPosition,
      },
    })
  }
}

export const createPrintAreaTextAnswer = (printArea, originalAnswer) => {
  return dispatch => {
    const answerId = generateId('ANSWER')
    const printAreaLogoPosition = convertToPrintAreaTextPosition(printArea, originalAnswer)

    return dispatch({
      type: types.CREATE_ANSWER,
      payload: {
        type: AnswerType.PrintAreaTextPosition,
        id: answerId,
        position: printAreaLogoPosition,
      },
    })
  }
}

export const centerLogoPrintAreaAnswer = (answerId, printArea) => {
  return (dispatch, getState) => {
    const answer = answerByIdSelector(getState(), { id: answerId })
    const printAreaLogoPosition = centerPrintAreaLogoPosition(answer.position, printArea)

    return dispatch({
      type: coreTypes.PATCH,
      payload: { answers: [{ id: answer.id, position: printAreaLogoPosition }] },
    })
  }
}

export const centerTextPrintAreaAnswer = (answerId, printArea) => {
  return (dispatch, getState) => {
    const answer = answerByIdSelector(getState(), { id: answerId })
    const printAreaLogoPosition = centerPrintAreaTextPosition(answer.position, printArea)

    return dispatch({
      type: coreTypes.PATCH,
      payload: { answers: [{ id: answer.id, position: printAreaLogoPosition }] },
    })
  }
}

export const patchAnswer = (answer, patch) => ({
  type: coreTypes.PATCH,
  payload: { answers: [{ id: answer.id, ...patch }] },
})

export const changeAnswersType = (answerIds, type) => {
  return (dispatch, getState) => {
    const views = coreSelectors.viewsSelector(getState())
    dispatch({ type: types.CHANGE_TYPE, payload: { ids: answerIds, type, views } })
  }
}

export const patchAnswerViews = (answer, patch) => dispatch =>
  dispatch(patchAnswer(answer, { views: answer.views.map(view => ({ ...view, ...patch })) }))

export const patchAnswerView = (answer, patch, view) => dispatch => {
  const patchedViews = cloneDeep(answer.views)
  patchedViews[view] = { ...patchedViews[view], ...patch }

  return dispatch(patchAnswer(answer, { views: patchedViews }))
}

export const deleteAnswer = answerId => ({ type: types.DELETE_ANSWER, payload: { answerId } })

export const archiveAnswer = answerId => ({ type: types.ARCHIVE_ANSWER, payload: { answerId } })

export const restoreAnswer = answerId => ({ type: types.RESTORE_ANSWER, payload: { answerId } })

export const updateAssets = (answer, assets) => {
  return dispatch => {
    const assetsByFileExtension = assets.reduce((assetsByFileExtension, asset) => {
      assetsByFileExtension[getExtension(asset.filename)] = asset
      return assetsByFileExtension
    }, {})

    dispatch(
      patchAnswerViews(answer, {
        assets: { ...answer.views[0].assets, ...assetsByFileExtension },
        fontType: FontType.Custom,
      })
    )
  }
}

export const removeAsset = (answer, extension) => {
  return dispatch => dispatch(patchAnswerViews(answer, { assets: { ...answer.views[0].assets, [extension]: null } }))
}

export const updateFont = (answer, font, fontType, asset) => dispatch => {
  const emptyFontAssets = { ttf: null, otf: null, woff: null, woff2: null, google: null }

  switch (fontType) {
    case FontType.Google:
      return dispatch(
        patchAnswerViews(answer, {
          assets: { ...emptyFontAssets, [FontType.Google]: font },
          font,
          fontType,
        })
      )
    default:
      return dispatch(
        patchAnswerViews(answer, {
          assets: { ...emptyFontAssets, [getExtension(asset.filename)]: asset },
          font,
          fontType,
        })
      )
  }
}

export const duplicateAnswer = answerId => dispatch => {
  const newAnswerId = generateId('ANSWER')
  dispatch({ type: types.DUPLICATE_ANSWER, payload: { answerId, newAnswerId } })

  return newAnswerId
}
