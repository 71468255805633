import React, { useEffect } from 'react'
import { SingleValueProps, components } from 'react-select'

import FontService from 'utils/loaders/font/FontService'

import type { FontOption } from './../types/font'

export type FontMenuSingleValueProps = SingleValueProps<FontOption>

const FontMenuSingleValue = (props: FontMenuSingleValueProps) => {
  const { value, type, asset } = props.data || {}

  useEffect(() => {
    FontService.loadFont({ fontFamily: value, type, asset })
  }, [value, type, asset])

  return <components.SingleValue {...props} />
}

export default FontMenuSingleValue
