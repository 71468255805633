export const customizationExperiencesAdvantages = {
  title: 'Customization experience',
  checklist: [
    'Live preview',
    'Multi-view product',
    'Dynamic pricing',
    'Unlimited variants',
    'Multi-component product',
    'Product bundle',
    'Image upload',
    'Text & font',
    'Multi-language',
    'Multi-currency',
  ],
}

export const adminTools = {
  title: 'Admin tool',
  checklist: [
    'Theme editor',
    'Live coloring tool',
    'Print-ready files',
    'User content moderation',
    'SKU management',
    'API access',
    'Dependencies / logics',
  ],
}

export const questionAnswerTuples = [
  {
    question: 'Can I connect more than one store to my Kickflip account?',
    answer: 'Yes, your Kickflip account can be connected to an unlimited number of stores.',
  },
  {
    question: 'Can I cancel my account anytime?',
    answer: 'Yes. You can cancel your account anytime.',
  },
  {
    question: 'Do I need to pay for hosting?',
    answer: 'No. Hosting is included with Kickflip and bandwidth is unlimited!',
  },
  {
    question: 'How do you calculate the transaction fee?',
    answer: 'The transaction fee applies to all orders processed and confirmed through Kickflip.',
  },
  {
    question: 'Can I change my plan anytime?',
    answer: 'Yes. You can upgrade or downgrade your plan anytime.',
  },
]
