import { StripeSubscription } from '@packages/types'
import { useStripe } from '@stripe/react-stripe-js'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'

import useSubscriptionService from 'cms/subscription/hooks/useSubscriptionService'
import Button from 'common/components/Button'

export interface StripeAuthenticateButtonProps {
  subscription: StripeSubscription
}

const StripeAuthenticateButton = ({ subscription }: StripeAuthenticateButtonProps) => {
  const stripe = useStripe()
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const queryClient = useQueryClient()
  const subscriptionService = useSubscriptionService()
  const { mutate: updatePaymentIntent } = useMutation(subscriptionService.updatePaymentIntent, {
    onSuccess: () => {
      setIsAuthenticating(false)
      queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
    },
  })

  if (!stripe) return null

  const handleAuthenticateClick = async () => {
    setIsAuthenticating(true)

    const { paymentIntent, error } = await stripe.confirmCardPayment(
      subscription.stripePaymentIntentRequiringAction!.client_secret!,
      {
        payment_method:
          subscription.stripePaymentIntentRequiringAction!.payment_method ??
          subscription.stripePaymentIntentRequiringAction!.last_payment_error!.payment_method?.id,
      }
    )

    setIsAuthenticating(false)

    if (error) return

    updatePaymentIntent(paymentIntent)
  }

  return (
    <Button variant="onColor" isLoading={isAuthenticating} onClick={handleAuthenticateClick}>
      Authenticate
    </Button>
  )
}

export default StripeAuthenticateButton
