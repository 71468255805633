import React from 'react'

import BlankState from 'common/components/blankState/BlankState'
import Table from 'common/components/table/Table'
import OrderIcon from 'icons/regular/07-Work-Office-Companies/07-Office-Files/office-file-sheet.svg'

interface OrdersFiltersBlankStateProps {
  numberOfColumns: number
  onClearFilters: () => void
}

const OrdersFiltersBlankState = ({ numberOfColumns, onClearFilters }: OrdersFiltersBlankStateProps) => (
  <Table.Body>
    <Table.Row className="hover:bg-white border-b-0">
      <Table.Cell colSpan={numberOfColumns}>
        <div className="flex items-center justify-center py-32">
          <BlankState>
            <BlankState.Icon Icon={OrderIcon} />
            <BlankState.Title>No orders to display with the specified filters</BlankState.Title>
            <BlankState.Details>Remove or clear the applied filters</BlankState.Details>
            <BlankState.Action onClick={onClearFilters}>Clear all filters</BlankState.Action>
          </BlankState>
        </div>
      </Table.Cell>
    </Table.Row>
  </Table.Body>
)

export default OrdersFiltersBlankState
