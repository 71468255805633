import { Answer, Question, Rule, RuleAssertion, RuleThenType, RuleThen } from '@packages/types'

const actionsToWord = {
  [RuleThenType.RestrictAllBut]: 'should be',
  [RuleThenType.AddRestriction]: 'should not be',
  [RuleThenType.DisableQuestion]: 'should be unavailable',
  [RuleThenType.SetAuthorizedAnswers]: 'should be one of',
  [RuleThenType.SetRestrictedAnswers]: 'should not be one of',
}

const questionName = (questions: Record<string, Question>, questionId: string) => {
  return questions[questionId]?.name ?? ''
}

const answerName = (answers: Record<string, Answer>, answerId?: string) => {
  return answerId ? answers[answerId]?.name : ''
}

const listAnswers = (answers: Record<string, Answer>, then: RuleThen) => {
  switch (then.type) {
    case RuleThenType.RestrictAllBut:
    case RuleThenType.AddRestriction:
      return answerName(answers, then.answerId)
    case RuleThenType.SetAuthorizedAnswers:
    case RuleThenType.SetRestrictedAnswers:
      return then.payload?.map((answerId: string) => answerName(answers, answerId)).join(' or ')
    case RuleThenType.DisableQuestion:
    default:
      return ''
  }
}

const assertionToString = (assertion: RuleAssertion) => {
  switch (assertion) {
    case RuleAssertion.Is:
      return ' is '
    case RuleAssertion.IsNot:
      return ' is not '
    case RuleAssertion.MatchesInState:
      return ' matches '
    case RuleAssertion.DoesNotMatchInState:
      return " doesn't match "
    default:
      return ''
  }
}

interface Props {
  rules: Record<string, Rule>
  answers: Record<string, Answer>
  questions: Record<string, Question>
}

const ruleAsNaturalLanguage = ({ rules, answers, questions }: Props, { id }: Rule) => {
  const rule = rules[id]
  let sentence = 'when '

  try {
    rule.when.forEach(({ value, assertion, path }, index) => {
      const isNotFirstRule = index > 0
      if (isNotFirstRule) sentence += ' and '

      const isValueAnArray = assertion !== RuleAssertion.Is && assertion !== RuleAssertion.IsNot
      const targetElement = isValueAnArray ? questionName(questions, value[1]) : answerName(answers, <string>value)

      const assertionString = assertionToString(assertion)
      sentence += questionName(questions, path[1]) + assertionString + targetElement
    })

    const thenQuestionName = questionName(questions, rule.then[0].questionId)
    const thenActionWord = actionsToWord[rule.then[0].type]
    const thenAnswer = listAnswers(answers, rule.then[0])
    sentence += ` then ${thenQuestionName} ${thenActionWord} ${thenAnswer}`
  } catch (error) {
    return ''
  }

  return sentence
}

export default ruleAsNaturalLanguage
