import { Rule } from '@packages/types'

import * as types from './actionTypes'

export const deleteRule = (ruleId: string) => ({ type: types.DELETE_RULE, payload: { ruleId } })

export const addRule = (rule: Rule, index?: number) => ({ type: types.ADD_RULE, payload: { rule, index } })

export const updateRule = (rule: Rule) => ({ type: types.UPDATE_RULE, payload: rule })

export const removeAnswerFromRules = (questionId: string, answerId: string) => ({
  type: types.REMOVE_ANSWER_FROM_RULES,
  payload: { questionId, answerId },
})
