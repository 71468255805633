import classNames from 'classnames'
import React from 'react'

import Button from '../Button'

interface ToastActionProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  children: React.ReactNode
}

const ToastAction = ({ onClick, className, children }: ToastActionProps) => {
  return (
    <Button onClick={onClick} className={classNames('ml-4', className)}>
      {children}
    </Button>
  )
}

export default ToastAction
