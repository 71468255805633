import { DenormalizedBulkOrder } from '@packages/types'
import { AnyAction } from 'redux'

import * as questionPanelTypes from 'customizer/questionPanel/actionTypes'
import { AppDispatch, RootState } from 'customizer/store'

import * as types from './actionTypes'
import { BulkOrderRow } from './reducer'
import { isBulkOrderTotalQuantityValidSelector } from './selectors'

const getNewRowIndexAfterRemove = (currentIndex: number, indexToRemove: number) => {
  if (indexToRemove > currentIndex) return currentIndex
  if (indexToRemove < currentIndex) return currentIndex - 1
  if (currentIndex !== 0) return currentIndex - 1

  return currentIndex
}

export const openModal = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()

  dispatch({ type: types.SET_FORM_VISIBILITY, payload: true })

  return dispatch(updateShownRow(null, state.bulkOrder.rows[state.bulkOrder.previewedRowIndex]))
}

export const closeModal = () => ({ type: types.SET_FORM_VISIBILITY, payload: false })

export const addItem =
  (bulkOrderGroup: DenormalizedBulkOrder) => async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch({ type: types.ADD_ITEM, payload: bulkOrderGroup })

    const state = getState()
    const newRowIndex = state.bulkOrder.rows.length - 1

    if (!state.bulkOrder.isFormVisible) {
      dispatch(updatePreviewedRow(newRowIndex, state.bulkOrder.rows[newRowIndex]))
      dispatch(updateShownRow(newRowIndex, state.bulkOrder.rows[newRowIndex]))
    }
  }

export const setDeletingIndex = (index: number) => ({ type: types.SET_DELETING_INDEX, payload: { index } })

export const removeItem = (indexToRemove: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const stateBeforeDelete = getState()
  const newRowIndex = getNewRowIndexAfterRemove(stateBeforeDelete.bulkOrder.previewedRowIndex, indexToRemove)
  await dispatch({ type: types.REMOVE_ITEM, payload: { index: indexToRemove } })

  const stateAfterDelete = getState()
  dispatch(updatePreviewedRow(newRowIndex, stateAfterDelete.bulkOrder.rows[newRowIndex]))
  dispatch(updateShownRow(null, stateAfterDelete.bulkOrder.rows[newRowIndex]))
}

export const updateShownRow = (index: number | null, row: BulkOrderRow) => ({
  type: types.UPDATE_SHOWN_ROW_INDEX,
  payload: { index, row },
})

export const updatePreviewedRow = (index: number | null, row: BulkOrderRow) => ({
  type: types.UPDATE_PREVIEWED_ROW_INDEX,
  payload: { index, row },
})

export const resetRows = (bulkOrderGroup: DenormalizedBulkOrder) => ({
  type: types.RESET_ROWS,
  payload: bulkOrderGroup,
})

export const updateRowCustomization =
  (rowIndex: number, action: AnyAction) => (dispatch: AppDispatch, getState: () => RootState) => {
    const { customization } = getState()

    dispatch({
      type: types.UPDATE_ROW_CUSTOMIZATION,
      payload: { rowIndex, action, customization },
    })
  }

export const updateQuantityInBulkOrder = (rowIndex: number, quantity: number | undefined) => ({
  type: types.UPDATE_ROW_QUANTITY,
  payload: { rowIndex, quantity },
})

export const validateBulkOrderTotalQuantity = () => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const isTotalQuantityValid = isBulkOrderTotalQuantityValidSelector(getState())

    dispatch({ type: questionPanelTypes.SET_IS_SUBMIT_VALID, payload: !!isTotalQuantityValid })

    return !!isTotalQuantityValid
  }
}

export const removeAllRows =
  (bulkOrderGroup: DenormalizedBulkOrder) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const stateAfterDelete = getState()

    await dispatch(resetRows(bulkOrderGroup))
    dispatch(updatePreviewedRow(0, stateAfterDelete.bulkOrder.rows[0]))
    dispatch(updateShownRow(null, stateAfterDelete.bulkOrder.rows[0]))
  }
