import { FontAssets, FontType } from '@packages/types'
import React, { MutableRefObject, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { assignRef } from 'use-callback-ref'

import TextOnPathSVG from 'common/drawing/TextOnPathSVG'

interface SVGPathTextProps {
  text: string
  fontFamily: string
  fontType?: FontType
  fontSize: number
  fontAssets: FontAssets
  textAlign: 'left' | 'right' | 'center'
  color: string
  outlineColor?: string
  outlineWidth?: number
  bezier: number[]
  pathRef: MutableRefObject<SVGPathElement | null>
  bboxRef?: MutableRefObject<SVGGElement | null>
}

const SVGPathText = ({
  text,
  fontFamily,
  fontType,
  fontSize,
  fontAssets,
  textAlign,
  color,
  outlineColor,
  outlineWidth,
  bezier,
  pathRef,
  bboxRef,
}: SVGPathTextProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const svg = useMemo(() => new TextOnPathSVG(), [])
  const [html, setHTML] = useState('')
  useEffect(() => {
    svg
      .build({
        color: { hex: color },
        position: { textAlign, bezier },
        text: { value: text },
        font: { size: `${fontSize}px`, family: fontFamily, assets: fontAssets, fontType },
        outline: { hex: outlineColor, width: `${outlineWidth}` },
        noOffset: true,
      })
      .then(() => {
        const textContainerElement = svg.toSVG()
        textContainerElement.style.width = '100%'
        textContainerElement.style.height = '100%'
        textContainerElement.style.position = 'absolute'
        textContainerElement.style.overflow = 'visible'
        textContainerElement.style.minWidth = '1px'
        textContainerElement.style.minHeight = '1px'

        setHTML(textContainerElement.outerHTML)
      })
  }, [text, fontFamily, fontSize, fontAssets, textAlign, color, outlineColor, outlineWidth])

  useLayoutEffect(() => {
    if (!ref.current) return
    assignRef(pathRef, ref.current.querySelector('path'))
    if (bboxRef) assignRef(bboxRef, ref.current.querySelector('g'))
  })

  return (
    <div
      ref={ref}
      dangerouslySetInnerHTML={{ __html: html }}
      className="w-full h-full absolute top-0 left-0 overflow-visible"
    />
  )
}

export default SVGPathText
