import classNames from 'classnames'
import React from 'react'

const TablePagination = ({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div {...rest} className={classNames('flex items-center justify-end mt-4', className)}>
    {children}
  </div>
)

export default TablePagination
