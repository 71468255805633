import { RootState } from 'customizer/store'

export const quoteSelector = (state: RootState) => state.quote

export const quoteFormVisibilitySelector = (state: RootState) => state.quote.isFormVisible

export const quoteFormSelector = (state: RootState) => state.quote.form

export const quoteFormValuesSelector = (state: RootState) => state.quote.formValues

export const quoteFormErrorsSelector = (state: RootState) => state.quote.formErrors

export const quoteRequestStatusSelector = (state: RootState) => state.quote.status

export const quoteHasErrorsSelector = (state: RootState) => state.quote.hasErrors

export const quoteIdSelector = (state: RootState) => state.quote.quoteId
