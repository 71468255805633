import { BulkOrderDesign, CustomizationDesign, Quote, DesignType } from '@packages/types'
import ReactPDF, { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'

import Price from './Price'

interface PrintableQuoteTableBodyProps<DesignType> {
  quote: Quote
  design: DesignType
  styles: ReactPDF.Styles
}

const TableBodyBulk = ({ design, quote, styles }: PrintableQuoteTableBodyProps<BulkOrderDesign>) => {
  return (
    <View>
      <View style={styles.tableRow}>
        <View style={styles.tableEmptyCol}></View>
        <View style={styles.tableEmptyCol}></View>
        <View style={styles.tableEmptyCol}></View>
        <View style={styles.tableEmptyCol}></View>
      </View>
      {design.designs.map(({ quantity, design: subDesign, price: itemTotal }, index: number) => {
        return (
          <View key={index}>
            <View style={[styles.tableRow, styles.tableRowWithItemImage]}>
              <View style={[styles.tableColInnerItems, styles.tableColDescription, styles.tableColInnerItemBigger]}>
                <Image src={subDesign.designImage?.url} style={styles.bulkDesignImage} />
                <Text style={styles.itemTitle}>{`Item ${index + 1}`}</Text>
              </View>
              <View style={styles.tableColInnerItems}>
                <Text>{quantity}</Text>
              </View>
              <View style={styles.tableColInnerItems}>
                <Price amount={subDesign.basePrice} currency={quote.currency ?? 'USD'} />
              </View>
              <View style={[styles.tableColInnerItems, styles.tableColRightAlign]}>
                <Price amount={subDesign.basePrice * quantity} currency={quote.currency ?? 'USD'} />
              </View>
            </View>
            {subDesign.productionData?.map(({ key, value, values, ref }, childIndex: number) => {
              const price = subDesign.pricing[ref.questionId]
              return (
                <View style={[styles.tableRow]} key={`${key}-${childIndex}`}>
                  <View style={[styles.tableColInnerItems, styles.tableColDescription]}>
                    <Text
                      style={[styles.extraPriceTitle, styles.extraPriceTitleBulk]}
                    >{`${key} / ${value ? value : values?.join(', ')}`}</Text>
                  </View>
                  <View style={styles.tableColInnerItems}>
                    <Text>{quantity}</Text>
                  </View>
                  <View
                    style={!price ? [styles.tableColInnerItems, styles.tableColNoPrice] : styles.tableColInnerItems}
                  >
                    <Price amount={price} currency={quote.currency ?? 'USD'} />
                  </View>
                  <View style={[styles.tableColInnerItems, styles.tableColRightAlign]}>
                    <Price amount={price * quantity} currency={quote.currency ?? 'USD'} />
                  </View>
                </View>
              )
            })}
            <View style={styles.tableRow}>
              <View style={[styles.tableColInnerItems, styles.tableColDescription]}></View>
              <View style={styles.tableColInnerItems}></View>
              <View style={[styles.tableColInnerItems, styles.itemTotalSection]}>
                <Text>{`Item ${index + 1} total`}</Text>
              </View>
              <View style={[styles.tableColInnerItems, styles.tableColRightAlign, styles.itemTotalSection]}>
                <Price amount={itemTotal} currency={quote.currency ?? 'USD'} />
              </View>
            </View>
            {index !== design.designs.length - 1 && (
              <View style={styles.tableRow}>
                <View style={styles.tableEmptyCol}></View>
                <View style={styles.tableEmptyCol}></View>
                <View style={styles.tableEmptyCol}></View>
                <View style={styles.tableEmptyCol}></View>
              </View>
            )}
          </View>
        )
      })}
    </View>
  )
}

const TableBodyCustomization = ({ design, quote, styles }: PrintableQuoteTableBodyProps<CustomizationDesign>) => {
  return (
    <View>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.tableColDescription, styles.tableColProductTitle]}>
          <Text style={styles.productTitle}>{design.productName}</Text>
        </View>
        <View style={[styles.tableCol, styles.tableColProductTitle]}>
          <Text>{quote.items[0].quantity}</Text>
        </View>
        <View style={[styles.tableCol, styles.tableColProductTitle]}>
          <Price amount={design.basePrice} currency={quote.currency ?? 'USD'} />
        </View>
        <View style={[styles.tableCol, styles.tableColRightAlign, styles.tableColProductTitle]}>
          <Price amount={design.basePrice * quote.items[0].quantity} currency={quote.currency ?? 'USD'} />
        </View>
      </View>
      {design.productionData?.map(({ key, value, ref }, index: number) => {
        const price = design.pricing[ref.questionId]
        return (
          <View key={`${key}-${index}`}>
            <View style={styles.tableRow}>
              <View style={[styles.tableColInnerItems, styles.tableColDescription]}>
                <Text style={styles.extraPriceTitle}>{`${key} / ${value}`}</Text>
              </View>
              <View style={styles.tableColInnerItems}>
                <Text>{quote.items[0].quantity}</Text>
              </View>
              <View style={!price ? [styles.tableColInnerItems, styles.tableColNoPrice] : styles.tableColInnerItems}>
                <Price amount={price} currency={quote.currency ?? 'USD'} />
              </View>
              <View style={[styles.tableColInnerItems, styles.tableColRightAlign]}>
                <Price amount={price} currency={quote.currency ?? 'USD'} />
              </View>
            </View>
          </View>
        )
      })}
    </View>
  )
}

const TableBodyCustomExtraPrices = ({
  design,
  quote,
  styles,
}: PrintableQuoteTableBodyProps<BulkOrderDesign | CustomizationDesign>) => {
  return (design.customExtraPrices || []).map((detail, index) => {
    if (detail.price == null || detail.quantity == null) return null

    return (
      <View key={index}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, styles.tableColDescription]}>
            <Text style={styles.extraPriceTitle}>{detail.description}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text>{detail.quantity}</Text>
          </View>
          <View style={styles.tableCol}>
            <Price amount={detail.price} currency={quote.currency ?? 'USD'} />
          </View>
          <View style={[styles.tableCol, styles.tableColRightAlign]}>
            <Price amount={detail.price * detail.quantity} currency={quote.currency ?? 'USD'} />
          </View>
        </View>
      </View>
    )
  })
}

const PrintableQuoteTableBody = ({
  quote,
  design,
  styles,
}: PrintableQuoteTableBodyProps<BulkOrderDesign | CustomizationDesign>) => {
  const isFromBulk = design.type === DesignType.BulkOrderDesign

  if (isFromBulk) {
    return (
      <>
        <TableBodyBulk design={design} quote={quote} styles={styles} />
        <TableBodyCustomExtraPrices design={design} quote={quote} styles={styles} />
      </>
    )
  }

  return (
    <>
      <TableBodyCustomization design={design} quote={quote} styles={styles} />
      <TableBodyCustomExtraPrices design={design} quote={quote} styles={styles} />
    </>
  )
}

export default PrintableQuoteTableBody
