import classNames from 'classnames'
import React from 'react'

export interface BlankStateDetailsProps {
  className?: string
  children?: React.ReactNode
}

const BlankStateDetails = ({ children, className }: BlankStateDetailsProps) => (
  <p className={classNames('mt-2 text-neutral-600', className)}>{children}</p>
)

export default BlankStateDetails
