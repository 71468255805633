import { AnswerType, ColorAnswer, MaterialAnswer, MaterialView } from '@packages/types'
import { merge } from 'lodash'
import React from 'react'

import * as builderConstants from 'builder/build/common/constants'
import PatchSketchPicker from 'builder/build/common/components/PatchSketchPicker'
import { useDispatch } from 'cms/hooks'

import { patchAnswer, patchAnswerViews } from '../actions'
import LightingSection from './sections/LightingSection'
import GeneralSettingsSection from './sections/GeneralSettingsSection'
import TitleSection from './sections/TitleSection'

interface MaterialAnswerPanelProps {
  answer: MaterialAnswer | ColorAnswer
}

export const MaterialAnswerPanel = ({ answer }: MaterialAnswerPanelProps) => {
  const dispatch = useDispatch()

  const updateAnswer: GeneralSettingsSection['onUpdate'] = (field, value) =>
    dispatch(patchAnswer(answer, { [field]: value }))

  const updateAnswerViews = (values: Partial<MaterialView>) => dispatch(patchAnswerViews(answer, { ...values }))

  return (
    <div
      className="panel__content"
      aria-label={`${answer.type === AnswerType.Material ? 'Material' : 'Color'} answer panel`}
    >
      <TitleSection answer={answer} />
      <hr className="panel__divider panel__divider--no-bottom-margin" />
      <div className="panel__section panel__section--no-vertical-padding">
        <PatchSketchPicker
          styles={
            merge(builderConstants.colorPickerStyle, {
              saturation: {
                marginLeft: '-1rem',
                marginRight: '-1rem',
                width: 'calc(100% + 2rem)',
                paddingBottom: '75%',
                position: 'relative',
                overflow: 'hidden',
              },
            }) as any
          }
          disableAlpha
          color={answer.views[0].color}
          onChange={color => updateAnswerViews({ color: color.hex })}
        />
      </div>
      <hr className="panel__divider" />
      {answer.type === AnswerType.Material && (
        <>
          <LightingSection view={answer.views[0]} onUpdate={updateAnswerViews} />
          <hr className="panel__divider" />
        </>
      )}
      <GeneralSettingsSection answer={answer} onUpdate={updateAnswer} />
    </div>
  )
}

export default MaterialAnswerPanel
