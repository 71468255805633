import { CustomizerProductSubmitAction } from '@packages/types'
import React from 'react'
import { useHistory } from 'react-router'

import { useSelector } from 'cms/hooks'
import IconButton from 'common/components/IconButton'
import withFlag from 'common/users/components/withFlag'
import EditIcon from 'icons/bold/01-Interface Essential/22-Edit/pencil-1.svg'

import { submitActionSelector } from '../selectors'

const SubmitActionSection = () => {
  const history = useHistory()
  const submitAction = useSelector(submitActionSelector)

  const onEditClick = () => {
    history.push('/submit-action')
  }

  return (
    <div className="h-10 min-h-[2.5rem] basis-10 flex items-center justify-between px-4 pr-[1.1rem] border-t border-neutral-100">
      <span className="text-sm">
        {submitAction === CustomizerProductSubmitAction.GetAQuote ? 'Get a quote' : 'Add to cart'}
      </span>
      <IconButton small variant="subtle" Icon={EditIcon} onClick={onEditClick} aria-label="Edit submit action" />
    </div>
  )
}

export default withFlag({
  Component: SubmitActionSection,
  feature: 'quote_phase_1',
})
