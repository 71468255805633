import React from 'react'

import CardList, { ReorderDetails } from 'builder/build/common/components/cards/CardList'
import * as layersSelectors from 'builder/build/layers/selectors'
import { useDispatch, useSelector } from 'cms/hooks'

import { moveLayer } from '../actions'
import LayersOrderingEmptyState from './LayersOrderingEmptyState'
import LayersOrderingItem from './LayersOrderingItem'

export interface LayersOrderingPanelProps {
  onGoToQuestionsClick: () => void
}

const LayersOrderingPanel = ({ onGoToQuestionsClick }: LayersOrderingPanelProps) => {
  const dispatch = useDispatch()

  const layerTree = useSelector(layersSelectors.layerTreeSelector)

  const handleReorder = (reorderDetails: ReorderDetails) => dispatch(moveLayer(reorderDetails))

  if (layerTree.length === 0) return <LayersOrderingEmptyState onGoToQuestionsClick={onGoToQuestionsClick} />

  return (
    <div className="flex flex-col h-[calc(100%-50px)]">
      <div className="flex px-4 my-4">
        <span className="font-medium text-neutral-700">Reorder layers</span>
        <p className="ml-auto text-xs text-right text-neutral-400">Top</p>
      </div>
      <div className="overflow-y-auto flex-grow">
        <CardList
          className="space-y-2 pb-8"
          onReorder={handleReorder}
          CardComponent={LayersOrderingItem}
          items={layerTree.map(layer => ({ id: layer.id, props: { layer } }))}
        />
      </div>
    </div>
  )
}

export default LayersOrderingPanel
