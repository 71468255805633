import React, { useEffect, useState } from 'react'

import UpdateCount from 'common/components/UpdateCount'
import { trpc } from 'common/hooks/trpc'

import type { InventoryFormikContext } from './../types/form'

export interface InventoryUpdateCountProps {
  formik: InventoryFormikContext
}

const InventoryUpdateCount = ({ formik }: InventoryUpdateCountProps) => {
  const trpcContext = trpc.useContext()

  const [dirtyCount, setDirtyCount] = useState(0)

  useEffect(() => {
    Object.keys(formik.touched)
      .reduce(async (dirtyCountPromise, inventoryItemId) => {
        const dirtyCount = await dirtyCountPromise

        const inventoryItem = formik.values[inventoryItemId]
        const initialInventoryItem = formik.initialValues[inventoryItemId]

        if (initialInventoryItem.stock === inventoryItem.stock) return dirtyCount

        if (inventoryItem.sku === '') return dirtyCount + 1

        const sharedData = await trpcContext.client.variant.getSkuSharedData.query(inventoryItem.sku)

        return dirtyCount + sharedData.variantIds.length
      }, Promise.resolve(0))
      .then(setDirtyCount)
  }, [formik.values])

  return <UpdateCount text="stock" count={dirtyCount} />
}

export default InventoryUpdateCount
