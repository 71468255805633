import { TextAnswer, TextView } from '@packages/types'
import React from 'react'

import { useDispatch } from 'cms/hooks'
import UncontrolledTextArea from 'common/components/inputs/UncontrolledTextArea'

import { patchAnswer, patchAnswerViews } from '../actions'
import GeneralSettingsSection from './sections/GeneralSettingsSection'
import TitleSection from './sections/TitleSection'

interface TextAnswerPanelProps {
  answer: TextAnswer
}

export const TextAnswerPanel = ({ answer }: TextAnswerPanelProps) => {
  const dispatch = useDispatch()
  const currentValue = (answer.views as TextView[])?.[0].text || ''

  const updateAnswer: GeneralSettingsSection['onUpdate'] = (field, value) => {
    dispatch(patchAnswer(answer, { [field]: value }))
  }

  const updateText = (value: string) => {
    dispatch(patchAnswerViews(answer, { text: value }))
    dispatch(patchAnswer(answer, { value }))
  }

  return (
    <div className="panel__content" aria-label="Text answer panel">
      <TitleSection answer={answer} />
      <hr className="panel__divider" />
      <div className="panel__section">
        <div className="panel__group">
          <div className="font-medium mb-4">Text</div>
          <UncontrolledTextArea
            value={currentValue}
            onChange={value => updateText(value)}
            className="py-[5px]"
            aria-label="Text answer value"
          />
        </div>
      </div>
      <hr className="panel__divider" />
      <GeneralSettingsSection answer={answer} onUpdate={updateAnswer} />
    </div>
  )
}

export default TextAnswerPanel
