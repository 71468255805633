import { Rule } from '@packages/types'
import { AnyAction } from 'redux'

import * as answerTypes from 'builder/build/answers/actionTypes'

import * as types from './actionTypes'
import isAnswerInRule from './isAnswerInRule'

export interface State {
  [key: string]: Rule
}

export const rules = (state: State = {}, action: AnyAction): State => {
  switch (action.type) {
    case types.ADD_RULE:
      return { ...state, [action.payload.rule.id]: action.payload.rule }
    case types.DELETE_RULE:
      const nextState = Object.values({ ...state }).reduce((acc, rule) => {
        if (rule.id !== action.payload.ruleId) {
          acc[rule.id] = rule
        }

        return acc
      }, {} as State)

      return { ...nextState }
    case types.UPDATE_RULE:
      return { ...state, [action.payload.id]: action.payload }
    case types.REMOVE_ANSWER_FROM_RULES:
      const { answerId, questionId } = action.payload

      const rulesToUpdate = Object.values(state).filter(rule => isAnswerInRule({ rule, answerId, questionId }))

      const updatedState = { ...state }

      rulesToUpdate.forEach(rule => {
        updatedState[rule.id] = {
          ...rule,
          when: rule.when.map(ruleWhen => {
            if (Array.isArray(ruleWhen.value)) {
              return { ...ruleWhen, value: ruleWhen.value.filter(x => x !== answerId) }
            } else if (ruleWhen.value === answerId) {
              return { ...ruleWhen, value: '' }
            }

            return { ...ruleWhen }
          }),
          then: rule.then.map(ruleThen => ({
            ...ruleThen,
            answerId: ruleThen.answerId === answerId ? '' : ruleThen.answerId,
            payload: ruleThen.payload?.filter(p => p !== answerId),
          })),
        }
      })

      return updatedState
    case answerTypes.UNLINK_ANSWER:
      const { answerId: payloadAnswerId, questionId: payloadQuestionId, newAnswerId } = action.payload

      const rulesWithUnlinkedAnswers = Object.values(state).filter(rule =>
        isAnswerInRule({ rule, answerId: payloadAnswerId, questionId: payloadQuestionId })
      )

      const newState = { ...state }
      rulesWithUnlinkedAnswers.forEach(rule => {
        const newWhen = rule.when.map(ruleWhen => {
          if (Array.isArray(ruleWhen.value)) {
            return { ...ruleWhen, value: ruleWhen.value.map(val => (val === payloadAnswerId ? newAnswerId : val)) }
          }

          return { ...ruleWhen, value: ruleWhen.value === payloadAnswerId ? newAnswerId : ruleWhen.value }
        })

        const newThen = rule.then.map(ruleThen => {
          if (!!ruleThen.payload) {
            return { ...ruleThen, payload: ruleThen.payload.map(val => (val === payloadAnswerId ? newAnswerId : val)) }
          }

          return { ...ruleThen, answerId: ruleThen.answerId === payloadAnswerId ? newAnswerId : ruleThen.answerId }
        })

        newState[rule.id] = { ...rule, when: newWhen, then: newThen }
      })

      return { ...newState }
    default:
      return state
  }
}

export default rules
