import { Group } from '@packages/types'
import React from 'react'
import { useHistory } from 'react-router-dom'

import IconTab from 'builder/build/common/components/IconTab'
import PanelHeaderSection from 'builder/build/common/components/PanelHeaderSection'
import Tab from 'builder/build/common/components/Tab'
import * as customizerProductsSelectors from 'builder/build/customizerProducts/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import Button from 'common/components/Button'
import IconButton from 'common/components/IconButton'
import Modal from 'common/components/modal/Modal'
import Popover from 'common/components/popover/Popover'
import useModal from 'common/components/modal/useModal'
import usePopover from 'common/components/popover/usePopover'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import SettingsIcon from 'icons/core-solid/interface-essential/interface-setting-cog.svg'

import { deleteGroup } from '../actions'

const { groupParentExceptRootSelector } = customizerProductsSelectors

export type Tab = 'group' | 'settings'

export interface HeaderSectionProps {
  group: Group
  selectedTab: Tab
  setTab: (tab: Tab) => void
  tabLabel: string
}

const HeaderSection = ({ group, selectedTab, setTab, tabLabel }: HeaderSectionProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const deleteGroupModal = useModal()
  const dropdownButton = usePopover({ placement: 'bottom-end' })

  const closeMenu = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    dropdownButton.close()
    deleteGroupModal.open()
  }

  const parent = useSelector(state => groupParentExceptRootSelector(state, group.id))

  const handleDeleteGroup = () => {
    history.push(!!parent ? `/groups/${parent.id}` : '/')
    dispatch(deleteGroup(group.id))
  }

  return (
    <PanelHeaderSection>
      <div className="flex space-x-3 mr-auto" role="tablist">
        <Tab isSelected={selectedTab === 'group'} onClick={() => setTab('group')}>
          {tabLabel}
        </Tab>
        <IconTab
          isSelected={selectedTab === 'settings'}
          onClick={() => setTab('settings')}
          aria-label={`${tabLabel} settings`}
        >
          <SettingsIcon />
        </IconTab>
      </div>
      <IconButton Icon={MoreIcon} {...dropdownButton.referenceProps} />
      <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
        <Popover.Action key="delete" className="text-tertiary-red-700" onClick={closeMenu}>
          Delete
        </Popover.Action>
      </Popover>
      {deleteGroupModal.isVisible && (
        <Modal onBackdropClick={deleteGroupModal.close} {...deleteGroupModal.modalProps}>
          <Modal.CloseButton onClick={deleteGroupModal.close} />
          <Modal.Title>Delete {group.name}</Modal.Title>

          <Modal.Details>
            Are you sure you want to delete the group {group.name}? This action cannot be undone.
          </Modal.Details>

          <Modal.Actions>
            <Button type="button" variant="default" className="px-4" onClick={deleteGroupModal.close}>
              Cancel
            </Button>
            <Button type="button" variant="error" className="px-4" onClick={handleDeleteGroup}>
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </PanelHeaderSection>
  )
}

export default HeaderSection
