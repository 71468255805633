import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as yup from 'yup'

import TrackerContext from 'cms/tracking/components/TrackerContext'
import Alert from 'common/components/alert/Alert'
import Button from 'common/components/Button'
import HelperText from 'common/components/inputs/HelperText'
import Input from 'common/components/inputs/Input'
import InputField from 'common/components/inputs/InputField'
import Label from 'common/components/inputs/Label'
import PasswordInput from 'common/components/inputs/PasswordInput'
import PublicFormFooter from 'common/components/PublicFormFooter'
import TenantContext from 'common/tenant/TenantContext'
import useUserService from 'common/users/hooks/useUserService'
import * as usersUtils from 'common/users/utils'
import { getAdminAppUrl, getBrandAppUrl, getRegisterAppUrl } from 'utils/getAppUrl'
import getCallbackUrl from 'utils/getCallbackUrl'

import useAuthService from './../hooks/useAuthService'
import type { LoginFormValues } from './../types/form'
import AuthFormContainer from './AuthFormContainer'
import AuthFormTitle from './AuthFormTitle'
import AuthLink from './AuthLink'

const formValidation = yup.object().shape({
  email: yup.string().required('Please fill the email field').email('Please enter a valid email'),
  password: yup.string().required('Please fill the password field'),
})

export interface LoginFormProps extends RouteComponentProps {
  initialValues?: Partial<LoginFormValues>
}

const LoginForm = ({ initialValues }: LoginFormProps) => {
  const queryClient = useQueryClient()
  const authService = useAuthService()
  const userService = useUserService()
  const tenant = useContext(TenantContext)
  const tracker = useContext(TrackerContext)

  const {
    mutate: login,
    reset: resetLogin,
    isSuccess: isLoginSuccess,
    isError: isLoginError,
  } = useMutation(authService.login, {
    onSuccess: async data => {
      localStorage.setItem('bearer', data.token)
      queryClient.invalidateQueries(userService.fetchCurrent.queryKeys)
    },
    retry: false,
  })

  const {
    isError: isFetchUserError,
    isSuccess: isFetchUserSuccess,
    isLoading: isFetchingUser,
    fetchStatus: fetchUserStatus,
    remove,
  } = useQuery(userService.fetchCurrent.queryKeys, userService.fetchCurrent, {
    enabled: isLoginSuccess,
    onSuccess: currentUser => {
      if (tenant) {
        document.cookie = `brand=${tenant}; domain=${COOKIE_DOMAIN}; path=/`
      }
      tracker.send('user_login_success')

      const token = localStorage.getItem('bearer') as string
      const registerAppUrl = getRegisterAppUrl()
      const adminAppUrl = getAdminAppUrl()
      const brandAppUrl = getBrandAppUrl(tenant)

      const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin')

      if (redirectAfterLogin) {
        sessionStorage.removeItem('redirectAfterLogin')
        const redirectUrl = usersUtils.isMCZRUser(currentUser)
          ? `${adminAppUrl}${redirectAfterLogin}`
          : `${brandAppUrl}/admin${redirectAfterLogin}`

        const callbackUrl = getCallbackUrl(registerAppUrl, token, redirectUrl)
        location.replace(callbackUrl)
      } else {
        const redirectUrl = usersUtils.isMCZRUser(currentUser)
          ? `${adminAppUrl}/brands`
          : `${brandAppUrl}/admin/products`

        const callbackUrl = getCallbackUrl(registerAppUrl, token, redirectUrl)
        location.replace(callbackUrl)
      }
    },
    retry: false,
  })

  const formik = useFormik<LoginFormValues>({
    initialValues: { email: '', password: '', ...initialValues },
    onSubmit: (values, { setSubmitting }) => login(values, { onSettled: () => setSubmitting(false) }),
    validationSchema: formValidation,
    validateOnMount: true,
  })

  return (
    <AuthFormContainer>
      <AuthFormTitle>Log in</AuthFormTitle>
      {(isLoginError || (isLoginSuccess && isFetchUserError)) && (
        <Alert
          variant="error"
          className="mb-8"
          onDismiss={() => {
            resetLogin()
            remove()
          }}
        >
          <Alert.Title>Incorrect username or password</Alert.Title>
        </Alert>
      )}
      <form onSubmit={formik.handleSubmit} noValidate>
        <InputField className="mb-6">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            name="email"
            type="email"
            autoComplete="true"
            placeholder="email@example.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            hasError={formik.touched.email && formik.errors.email != null}
          />
          {formik.touched.email && formik.errors.email != null && (
            <HelperText hasError>{formik.errors.email}</HelperText>
          )}
        </InputField>

        <InputField className="mb-6">
          <Label htmlFor="password">Password</Label>
          <PasswordInput
            id="password"
            name="password"
            placeholder="Enter password"
            value={formik.values.password}
            onChange={formik.handleChange}
            hasError={formik.touched.password && formik.errors.password != null}
          />
          {formik.touched.password && formik.errors.password != null && (
            <HelperText hasError>{formik.errors.password}</HelperText>
          )}
        </InputField>

        <PublicFormFooter>
          <Button
            id="submit-button"
            isLoading={
              formik.isSubmitting ||
              (isFetchingUser && fetchUserStatus !== 'idle') ||
              (isLoginSuccess && isFetchUserSuccess)
            }
            variant="primary"
            type="submit"
            className="w-full mb-4 lg:w-fit lg:mb-0"
          >
            Log in
          </Button>

          <AuthLink to="/login/forgot">Forgot password?</AuthLink>
        </PublicFormFooter>
      </form>
    </AuthFormContainer>
  )
}

export default LoginForm
