import React from 'react'

import Button from './Button'
import Modal, { ModalProps } from './modal/Modal'

interface UnsavedChangesModalProps extends Omit<ModalProps, 'children'> {
  onLeaveClick: () => void
  onStayClick: () => void
}

const UnsavedChangesModal = ({ onLeaveClick, onStayClick, ...modalProps }: UnsavedChangesModalProps) => {
  return (
    <Modal {...modalProps} aria-labelledby="unsaved-changes-title">
      <Modal.Title id="unsaved-changes-title">Unsaved changes</Modal.Title>
      <Modal.Details>
        Are you sure you want to leave without saving? All changes will be deleted, this action can't be undone.
      </Modal.Details>
      <Modal.Actions>
        <Button type="button" variant="default" className="px-4" onClick={onStayClick}>
          Stay
        </Button>
        <Button id="create-brand" type="submit" variant="error" className="px-4" onClick={onLeaveClick}>
          Leave
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default UnsavedChangesModal
