import React from 'react'

interface VerticalFieldProps {
  children: [React.ReactNode, React.ReactNode]
}

const VerticalField = ({ children }: VerticalFieldProps) => {
  return (
    <div className="flex flex-col">
      <div className="mb-4">{children[0]}</div>
      {children[1]}
    </div>
  )
}

export default VerticalField
