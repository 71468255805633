import { $isAtNodeEnd } from '@lexical/selection'
import {
  $createRangeSelection,
  $setSelection,
  RangeSelection,
  createCommand,
  LexicalCommand,
  LexicalNode,
} from 'lexical'

export const UPDATE_LINK_COMMAND: LexicalCommand<void> = createCommand('UPDATE_LINK_COMMAND')

export const getSelectedNode = (selection: RangeSelection) => {
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()

  if (anchorNode === focusNode) return anchorNode

  return $isAtNodeEnd(selection.anchor) ? focusNode : anchorNode
}

export const selectNode = (node: LexicalNode) => {
  const newSelection = $createRangeSelection()
  newSelection.focus.set(node.getKey(), 0, 'text')
  newSelection.anchor.set(node.getKey(), node.getTextContentSize(), 'text')
  $setSelection(newSelection)
}
