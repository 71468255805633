import { useState } from 'react'

const useMouseOver = () => {
  const [isMouseOver, setIsMouseOver] = useState(false)

  const onMouseEnter = () => setIsMouseOver(true)
  const onMouseLeave = () => setIsMouseOver(false)

  return { isMouseOver, onMouseEnter, onMouseLeave }
}

export default useMouseOver
