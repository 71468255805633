import jwtDecode from 'jwt-decode'

export const getBearerToken = () => {
  const bearer = localStorage.getItem('bearer')
  if (!bearer) return null

  try {
    return jwtDecode<{ exp: number }>(bearer)
  } catch (error) {
    return null
  }
}

export const isBearerTokenExpired = () => {
  const token = getBearerToken()
  return !!token && token.exp ? Date.now() >= token.exp * 1000 : true
}
