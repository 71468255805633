import { Asset, LogoView, LogoAnswer, supportedImageExtensions } from '@packages/types'
import React from 'react'

import { useDispatch } from 'cms/hooks'
import FileUploader from 'common/assets/components/FileUploader'

import { patchAnswer, patchAnswerViews } from '../actions'
import GeneralSettingsSection from './sections/GeneralSettingsSection'
import TitleSection from './sections/TitleSection'

interface LogoAnswerPanelProps {
  answer: LogoAnswer
}

export const LogoAnswerPanel = ({ answer }: LogoAnswerPanelProps) => {
  const dispatch = useDispatch()

  const updateAnswer: GeneralSettingsSection['onUpdate'] = (field, value) =>
    dispatch(patchAnswer(answer, { [field]: value }))

  const updateLogo = (logo: Asset | Asset[] | null) => dispatch(patchAnswerViews(answer, { logo }))

  return (
    <div className="panel__content" aria-label="Logo answer panel">
      <TitleSection answer={answer} />
      <hr className="panel__divider" />
      <div className="panel__section">
        <div className="panel__group">
          <label className="mb-4 font-medium block">Image</label>
          <FileUploader
            file={(answer.views?.[0] as LogoView | undefined)?.logo}
            dropText="Drop image, or"
            onChange={updateLogo}
            extensions={supportedImageExtensions}
          />
        </div>
      </div>
      <hr className="panel__divider" />
      <GeneralSettingsSection answer={answer} onUpdate={updateAnswer} />
    </div>
  )
}

export default LogoAnswerPanel
