import classNames from 'classnames'
import React from 'react'

const PageSection = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <section {...props} className={classNames('flex flex-col md:flex-row w-full mb-10 md:space-x-10', className)}>
    {children}
  </section>
)

export default PageSection
