import { Quote, ECommerce, ShopifyOnlineStore, WooCommerceOnlineStore } from '@packages/types'
import React from 'react'

import QuoteShopifyDraftOrderCard from './QuoteShopifyDraftOrderCard'
import QuoteWooCommerceOrderCard from './QuoteWooCommerceOrderCard'

export type QuoteHeaderIntegrationSectionProps = {
  quote: Quote
}

type QuoteWithShopifyStore = Omit<Quote, 'store'> & { store: ShopifyOnlineStore }
type QuoteWithWooCommerceStore = Omit<Quote, 'store'> & { store: WooCommerceOnlineStore }

const QuoteHeaderIntegrationSection = ({ quote }: QuoteHeaderIntegrationSectionProps) => {
  switch (quote.store.eCommerce) {
    case ECommerce.Shopify:
      return <QuoteShopifyDraftOrderCard quote={quote as QuoteWithShopifyStore} />
    case ECommerce.Woocommerce:
      return <QuoteWooCommerceOrderCard quote={quote as QuoteWithWooCommerceStore} />
    default:
      return null
  }
}

export default QuoteHeaderIntegrationSection
