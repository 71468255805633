import React, { MutableRefObject } from 'react'

type ContainerContext = {
  scale: number
  containerRef?: MutableRefObject<null | HTMLElement>
  isRoot: boolean
}

export default React.createContext<ContainerContext>({
  containerRef: undefined,
  scale: 1,
  isRoot: true,
})
