import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'

import TenantContext from 'common/tenant/TenantContext'

import useSubscriptionService from './../hooks/useSubscriptionService'
import { subscriptionHasError } from '../utils'

const useSubscriptionHasError = () => {
  const tenant = useContext(TenantContext)
  const subscriptionService = useSubscriptionService()
  const { data: subscription } = useQuery(subscriptionService.fetchOne.queryKeys, subscriptionService.fetchOne, {
    enabled: !!tenant,
  })

  if (!subscription) return false

  return subscriptionHasError(subscription)
}

export default useSubscriptionHasError
