import { Middleware, Store } from 'redux'

import { RootState } from 'cms/store'

import { updatePreview } from './actions'

const builderToPreviewMiddleware =
  (previewStore: Store): Middleware<Record<string, never>, RootState> =>
  store =>
  next =>
  action => {
    const result = next(action)
    previewStore.dispatch(updatePreview(store.getState().productBuilder))
    return result
  }

export default builderToPreviewMiddleware
