import classNames from 'classnames'
import React from 'react'

export interface BlankStateIconProps {
  className?: string
  Icon: React.ElementType
}

const BlankStateIcon = ({ Icon, className }: BlankStateIconProps) => (
  <Icon className={classNames('rounded-lg p-2 bg-neutral-75', className)} width={45} height={45} />
)

export default BlankStateIcon
