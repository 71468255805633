import classNames from 'classnames'
import React from 'react'

const TableFooterCell = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) => (
  <td className={classNames('px-1', className)} {...rest}>
    {children}
  </td>
)

export default TableFooterCell
