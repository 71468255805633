import React from 'react'

export interface SubscriptionQuestionAndAnswerProps {
  question: string
  answer: string
}

const SubscriptionQuestionAndAnswer = ({ question, answer }: SubscriptionQuestionAndAnswerProps) => {
  return (
    <div>
      <span className="font-p1 text-neutral-900">{question}</span>
      <hr className="max-w-3xl border-neutral-75 my-1" />
      <span className="font-p3 text-neutral-600">{answer}</span>
    </div>
  )
}

export default SubscriptionQuestionAndAnswer
