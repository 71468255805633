import classNames from 'classnames'
import React from 'react'

interface ToastMessageProps {
  className?: string
  children: React.ReactNode | string
}

const ToastMessage = ({ className, children }: ToastMessageProps) => {
  return <p className={classNames('w-fit text-sm my-3', className)}>{children}</p>
}

export default ToastMessage
