import { PlanName, CustomPlan, TransactionFeeType } from '@packages/types'
import { FormikConfig, useFormik } from 'formik'
import * as yup from 'yup'

export type CreateCustomPlanFormValues = {
  name: PlanName.Custom
  custom: true
  price: CustomPlan['price']
  features: {
    productLimit: number
    whiteLabel: boolean
  }
  attributesText: string[]
}

const formValidation = yup.object().shape({
  name: yup.string(),
  price: yup.object().when('name', {
    is: PlanName.Custom,
    then: schema =>
      schema.shape({
        recurringAmount: yup.number().required('Please enter a price').noWhitespace(),
        transactionFeePercentage: yup.number().required('Please enter a transaction fee').noWhitespace(),
      }),
  }),
  features: yup.object().when('name', {
    is: PlanName.Custom,
    then: schema => schema.shape({ whiteLabel: yup.bool().required('Please choose an option') }),
  }),
  productLimit: yup.number().notRequired().noWhitespace(),
})

const useCustomPlanForm = ({
  onSubmit,
  initialValues,
}: {
  onSubmit: FormikConfig<CreateCustomPlanFormValues>['onSubmit']
  initialValues?: CreateCustomPlanFormValues
}) => {
  const formik = useFormik<CreateCustomPlanFormValues>({
    initialValues: {
      name: PlanName.Custom,
      custom: true,
      price: initialValues?.price
        ? initialValues.price
        : {
            recurringAmount: 0,
            transactionFeePercentage: 0,
            type: TransactionFeeType.Flat,
            cappedAmount: 100000,
          },
      features: {
        productLimit: initialValues?.features.productLimit || 0,
        whiteLabel: initialValues?.features.whiteLabel || true,
      },
      attributesText: initialValues?.attributesText ?? [
        'Unlimited products',
        'Unlimited pageviews',
        'Unlimited storage',
        'Dozens of feature updates every year',
        'No setup fee or other hidden fees',
        'White label',
      ],
    },
    onSubmit: onSubmit,
    validationSchema: formValidation,
  })

  return formik
}

export default useCustomPlanForm
