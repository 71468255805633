import { Answer, Question } from '@packages/types'
import React from 'react'

import AnswerCardThumbnail from 'builder/build/questions/components/sections/AnswerCardThumbnail'
import UniversalSearchList from 'common/components/universalSearch/UniversalSearchList'

interface AnswerSearchResultProps {
  query: string
  answer: Answer
  question: Question
  onClick: () => void
}

const AnswerSearchResult = ({ query, answer, question, onClick }: AnswerSearchResultProps) => {
  return (
    <UniversalSearchList.Result onClick={onClick}>
      <AnswerCardThumbnail answer={answer} />
      <UniversalSearchList.HighlightableText text={answer.name} highlight={query} />
      <UniversalSearchList.SupportingText>{question.name}</UniversalSearchList.SupportingText>
    </UniversalSearchList.Result>
  )
}

export default AnswerSearchResult
