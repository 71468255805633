import ReactDOM from 'react-dom'
import { MoveableManagerInterface, MoveableProps, Renderer } from 'react-moveable'

const Scrimable = {
  name: 'scrimable',
  props: [],
  events: [],
  render(moveable: MoveableManagerInterface<MoveableProps & { info: React.ReactNode }, any>, React: Renderer) {
    if (!moveable.isDragging()) return null

    return ReactDOM.createPortal(<div className="fixed w-full h-full top-0 left-0 z-[2]" />, document.body)
  },
}

export default Scrimable
