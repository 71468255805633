import classNames from 'classnames'
import React from 'react'
import ReactSelect, { Props as ReactSelectProps, Theme as SelectTheme, components, OptionProps } from 'react-select'

import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'

import { theme } from '../../../../../tailwind.config'

import './Select.css'

const getSelectTheme = (providedTheme: SelectTheme): SelectTheme => ({
  ...providedTheme,
  colors: {
    ...providedTheme.colors,
    primary: theme.colors.primary[500],
    primary75: theme.colors.primary[800],
    primary50: theme.colors.primary[100],
    primary25: theme.colors.neutral[75],
    danger: theme.colors.error.default,
    dangerLight: theme.colors.error.light,
    neutral0: theme.colors.white,
    neutral5: theme.colors.neutral[50],
    neutral10: theme.colors.neutral[100],
    neutral20: theme.colors.neutral[200],
    neutral30: theme.colors.neutral[300],
    neutral60: theme.colors.neutral[600],
    neutral70: theme.colors.neutral[700],
    neutral80: theme.colors.neutral[800],
    neutral90: theme.colors.neutral[900],
  },
  spacing: { ...providedTheme.spacing, controlHeight: 28, baseUnit: 4 },
  borderRadius: 8,
})

export type Option<TValue> = {
  label: React.ReactNode
  value: TValue
}

export interface SelectProps<TValue = unknown, IsMulti extends boolean = false>
  extends Omit<ReactSelectProps<Option<TValue>, IsMulti>, 'isDisabled'> {
  className?: string
  disabled?: boolean
  hasError?: boolean
}

const OptionWithRole = <TValue = unknown, IsMulti extends boolean = false>({
  children,
  ...props
}: OptionProps<Option<TValue>, IsMulti>) => (
  <components.Option {...props} innerProps={{ ...props.innerProps, role: 'option' }}>
    {children}
  </components.Option>
)

function Select<TValue = unknown, IsMulti extends boolean = false>({
  className,
  disabled,
  hasError,
  menuPlacement = 'auto',
  isSearchable = false,
  ...rest
}: SelectProps<TValue, IsMulti>) {
  return (
    <ReactSelect
      isDisabled={disabled}
      menuPlacement={menuPlacement}
      theme={getSelectTheme}
      classNamePrefix="react-select"
      className={classNames(className, 'min-h-[32px]', { 'react-select-error': hasError })}
      isSearchable={isSearchable}
      components={{
        MultiValueRemove: ({ innerProps }) => (
          <div {...innerProps} className="px-1 cursor-pointer fill-neutral-600 flex justify-center items-center">
            <Remove width={8} />
          </div>
        ),
        Option: OptionWithRole,
      }}
      {...rest}
    />
  )
}

export default Select
