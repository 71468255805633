import { Design } from '@packages/types'

export enum Status {
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  IN_PRODUCTION = 'inproduction',
  FULFILLED = 'fulfilled',
}

export enum PaymentStatus {
  UNPAID = 'unpaid',
  PAID = 'paid',
  REFUNDED = 'refunded',
  Authorized = 'authorized',
  Partially_refunded = 'partially_refunded',
  Partially_paid = 'partially_paid',
}

export interface OrderItem {
  design: string
  discounts: { amount: number }[]
  quantity: number
  cancelled: number
}

export interface Order {
  id: string
  orderId: number
  eCommerceOrderId: string
  eCommerceOrderName: string
  store: string
  currency: string
  items: OrderItem[]
  date: string
  status: Status
  paymentStatus: PaymentStatus
  totalPrice: number
  invoiceItem: string
  exchangeRateAtCreation: number
  transactionFeePercentage: number
  trial: boolean
  isTest: boolean
}

export interface DenormalizedOrderItem {
  design: Design
  discounts: { amount: number }[]
  quantity: number
  cancelled?: number
}

export interface DenormalizedOrder extends Omit<Order, 'items'> {
  items: DenormalizedOrderItem[]
}
