import { MoveableState } from 'react-moveable'

import { rotatePoint } from 'utils/math'

export const pathToBezier = (path: string) => {
  const splittedPath = path.split(',')
  const bezier = []
  for (let i = 0; i < splittedPath.length; i++) {
    const splittedSubPath = splittedPath[i].split(' ')
    for (let j = 0; j < splittedSubPath.length; j++) {
      const parsedValue = parseFloat(splittedSubPath[j])
      if (!isNaN(parsedValue)) {
        bezier.push(parsedValue)
      }
    }
  }

  return bezier
}

export const bezierMaxY = (bezier: number[]) => {
  let maxY = 0

  for (let i = 1; i < bezier.length; i = i + 2) {
    if (bezier[i] > maxY) maxY = bezier[i]
  }

  return maxY
}

export const bezierToPath = (bezier: number[]) => {
  let d = 'M '
  for (let i = 0; i <= bezier.length - 2; i += 2) {
    d += `${bezier[i]},${bezier[i + 1]} `
    if (i == 0) {
      d += 'C '
    }
  }
  return d
}

export const getOffsetBezier = (bezier: number[], moveableState: MoveableState) => {
  const { transformOrigin, rotation, renderLines } = moveableState

  const offsetX = -transformOrigin[0]
  const offsetY = -transformOrigin[1]
  const offsetBezier = bezier.map((coord, i) =>
    i % 2 === 0 ? coord - offsetX + renderLines[0][0][0] : coord - offsetY + renderLines[0][0][1]
  )

  for (let i = 0; i < offsetBezier.length; i += 2) {
    const point = { x: offsetBezier[i], y: offsetBezier[i + 1] }
    const rotatedPoint = rotatePoint(point, rotation, { x: renderLines[0][0][0], y: renderLines[0][0][1] })
    offsetBezier[i] = rotatedPoint.x
    offsetBezier[i + 1] = rotatedPoint.y
  }

  return offsetBezier
}
