import React, { useContext } from 'react'

import QuoteContext from 'cms/quotes/QuoteContext'
import { selectQuoteBulkPrice } from 'cms/quotes/utils'
import Button from 'common/components/Button'
import Modal from 'common/components/modal/Modal'
import { ModalType } from 'common/components/modal/useModal'

import { BulkOrderFormItem, QuoteFormValues } from './Quote'
import { useFormikContext } from 'formik'

const ConfirmBulkPriceModal = (props: ModalType) => {
  const { quote } = useContext(QuoteContext)
  const formik = useFormikContext<QuoteFormValues>()

  const handleConfirmBulkPriceModal = (ignoreBulkPrices: boolean) => {
    if (!ignoreBulkPrices) return props.close()

    formik.setFieldValue('items.0.design.ignoreBulkPrices', ignoreBulkPrices)
    formik.submitForm()
    props.close()
  }

  if (!quote || !props.isVisible) return null

  const design = formik.values.items[0].design as BulkOrderFormItem
  const bulkPriceBreak = selectQuoteBulkPrice(design)

  return (
    <Modal {...props.modalProps}>
      <Modal.Title>Product has a price break</Modal.Title>
      <Modal.Details>
        The total quantity is over <span className="font-semibold">{bulkPriceBreak?.quantity}</span> for{' '}
        <span className="font-semibold">{quote.items[0].design.productName}</span> on this quote. The base price of the
        product set at this quantity is <span className="font-semibold">{bulkPriceBreak?.price}</span>. Do you want to
        ignore this rule?
      </Modal.Details>
      <Modal.Actions>
        <Button onClick={() => handleConfirmBulkPriceModal(false)} type="button">
          No, I want to fix it manually
        </Button>
        <Button variant="primary" onClick={() => handleConfirmBulkPriceModal(true)} type="button">
          Yes, I want to ignore the price break rule
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmBulkPriceModal
