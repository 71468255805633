import { Group } from '@packages/types'
import React, { useState } from 'react'

import Panel from 'builder/build/common/components/panels/Panel'

import HeaderSection, { Tab } from '../HeaderSection'
import FolderSettingsTab from './FolderSettingsTab'
import FolderTab from './FolderTab'

export interface FolderPanelProps {
  group: Group
}

const FolderPanel = ({ group }: FolderPanelProps) => {
  const [tab, setTab] = useState<Tab>('group')

  return (
    <Panel side="right">
      <HeaderSection group={group} selectedTab={tab} setTab={setTab} tabLabel="Group" />
      <div className="panel__content">
        {tab === 'group' && <FolderTab group={group} />}
        {tab === 'settings' && <FolderSettingsTab group={group} />}
      </div>
    </Panel>
  )
}

export default FolderPanel
