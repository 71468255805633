import { Addon, Plan, TransactionFeeType } from '@packages/types'
import React, { useState } from 'react'

import Card from 'common/components/card/Card'
import Slider from 'common/components/inputs/Slider'
import Arrow from 'img/Arrow-2--Streamline-Beveled-Scribbles.png'
import './EstimateSlider.css'
import classMerge from 'utils/classMerge'

export interface EstimatePriceCardProps {
  plan: Plan
  addons: Addon[]
}

const defaultSales = 30000
const minSales = 0
const maxSales = 1000000000

const EstimatePriceCard = ({ plan, addons }: EstimatePriceCardProps) => {
  const [estimatedSales, setEstimatedSales] = useState<number | null>(defaultSales)

  const [inputWidth, setInputWidth] = useState(0)

  const totalAddonPrice = addons.reduce<number>((acc, cur) => acc + cur.price, 0)

  if (plan.price.type === TransactionFeeType.Tiered) {
    const sortedTiers = plan.price.transactionFeeTiers.sort(
      (a, b) => (a.upTo ?? Number.MAX_SAFE_INTEGER) - (b.upTo ?? Number.MAX_SAFE_INTEGER)
    )

    const sales = estimatedSales == null ? minSales : estimatedSales

    let orderRemainingAmountInUSD = sales

    let orderAmount = 0
    let periodCSV = 0

    while (orderRemainingAmountInUSD > 0) {
      const currentTier = sortedTiers.find(tier => {
        return periodCSV < (tier.upTo ?? Number.MAX_SAFE_INTEGER)
      })!
      const remaingAmountInTier = (currentTier.upTo ?? Number.MAX_SAFE_INTEGER) - periodCSV

      const billableAmountForCurrentTier = Math.min(orderRemainingAmountInUSD, remaingAmountInTier)

      orderAmount = orderAmount + billableAmountForCurrentTier * (currentTier.percentage / 100)

      orderRemainingAmountInUSD = orderRemainingAmountInUSD - billableAmountForCurrentTier

      periodCSV = periodCSV + billableAmountForCurrentTier
    }

    const priceRatio = (plan.price.recurringAmount + orderAmount + totalAddonPrice) / sales
    const pricePercentOfSales = priceRatio * 100

    const formattedSales =
      estimatedSales == null
        ? '$'
        : estimatedSales.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          })

    return (
      <Card className="flex-col bg-transparent shadow-transparent w-full h-max border border-rounded border-neutral-100">
        <Card.Section className="w-full">
          <div className="flex flex-col w-full">
            <h3 className="w-full flex items-center justify-center mb-2 text-xl">Cost estimator</h3>
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="flex w-full items-center justify-center space-x-8 relative">
                  <div className="flex flex-col items-center">
                    <div className="">Monthly customized product sales</div>
                    <span
                      ref={el => {
                        if (el && el.offsetWidth !== inputWidth) setInputWidth(el.offsetWidth)
                      }}
                      className="text-3xl font-bold border border-transparent hover:border-neutral-100 rounded bg-transparent -z-10 opacity-0 absolute px-1 max-w-full"
                    >
                      {formattedSales}
                    </span>

                    <div className="text-xs">powered by Kickflip</div>
                  </div>
                </div>
                <div
                  className="flex mt-2 mb-1 relative rounded-lg"
                  style={{
                    boxShadow: 'inset rgba(0, 0, 0, 0.075) 0 0 0 1px',
                    background: 'linear-gradient(225deg, rgba(37,147,107,1) 27%, rgba(7,193,125,1) 99%)',
                  }}
                >
                  <div
                    className="w-full h-full absolute left-0 top-0 rounded-lg"
                    style={{
                      background: `linear-gradient(90deg, #0000FF 0% ${pricePercentOfSales}%, #00000000 ${pricePercentOfSales}% 100%)`,
                    }}
                  />
                  <Slider
                    className="w-full estimate-slider h-8"
                    min={10000}
                    max={1000000}
                    step={10000}
                    value={estimatedSales == null ? minSales : estimatedSales}
                    onChange={value => {
                      setEstimatedSales(value)
                    }}
                    gradient={['#00000000', '#00000000']}
                  />
                  <input
                    className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 px-1 text-3xl font-bold border border-transparent hover:border-neutral-100 rounded bg-transparent text-white"
                    style={{ width: inputWidth, textShadow: '0px 1px 3px rgba(0,0,0,0.2)' }}
                    value={formattedSales}
                    onChange={e => {
                      const parsedValue = e.target.value.replace('$', '').replaceAll(',', '')

                      const value = Number(parsedValue)

                      if (isNaN(value) || parsedValue === '') {
                        setEstimatedSales(null)
                        return
                      }

                      const parsedNumber = Math.min(Math.max(value, minSales), maxSales)

                      setEstimatedSales(parsedNumber)
                    }}
                    onBlur={() => {
                      if (estimatedSales == null) {
                        setEstimatedSales(defaultSales)
                      }
                    }}
                  />
                </div>
                <div
                  className={classMerge('w-full relative transition-all origin-left', {
                    'opacity-0 scale-50': pricePercentOfSales > 0.45,
                  })}
                >
                  <div className="z-[1] shadow-xs -top-10 -left-6 absolute bg-white w-full max-w-16 h-16 border-2 border-dashed border-neutral-100 flex items-center overflow-hidden rounded-full">
                    <div
                      className="h-8 ml-8 rounded-md bg-tertiary-green-400 w-full relative overflow-hidden shadow-xs scale-[3] origin-left"
                      style={{
                        minWidth: `${414}px`,
                      }}
                    >
                      <div
                        className="absolute left-0 bg-primary-500 h-full scale-[3] origin-left"
                        style={{
                          width: `${pricePercentOfSales}%`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <img src={Arrow} className="w-8 h-8 mt-1" />
                  <div>
                    <div className="flex mt-3">
                      <div className="text-xl font-bold text-primary-500">
                        {(plan.price.recurringAmount + orderAmount + totalAddonPrice).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 0,
                        })}
                      </div>
                      {sales > 1000 && (
                        <div className="self-end ml-1 mb-1 text-xs">
                          (only{' '}
                          {priceRatio.toLocaleString('default', {
                            style: 'percent',
                            roundingPriority: 'lessPrecision',
                            minimumFractionDigits: 2,
                            minimumSignificantDigits: 2,
                          })}
                          )
                        </div>
                      )}
                    </div>
                    <span className="text-xs">monthly cost</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Section>
      </Card>
    )
  }

  return null
}

export default EstimatePriceCard
