class Touch {
  identifier: number
  target: EventTarget
  clientX: number
  clientY: number
  screenX: number
  screenY: number
  pageX: number
  pageY: number

  constructor(settings: typeof Touch.prototype) {
    this.identifier = settings.identifier
    this.target = settings.target
    this.clientX = settings.clientX
    this.clientY = settings.clientY
    this.screenX = settings.screenX
    this.screenY = settings.screenY
    this.pageX = settings.pageX
    this.pageY = settings.pageY
  }
}

type TouchEventInit = UIEventInit & {
  altKey: boolean
  ctrlKey: boolean
  metaKey: boolean
  shiftKey: boolean
  touches: Touch[]
  targetTouches: Touch[]
  changedTouches: Touch[]
}

class TouchEvent extends UIEvent {
  altKey: boolean
  ctrlKey: boolean
  metaKey: boolean
  shiftKey: boolean
  touches: Touch[]
  targetTouches: Touch[]
  changedTouches: Touch[]

  constructor(eventName: string, eventInitDict: TouchEventInit) {
    super(eventName, eventInitDict)

    const touches = eventInitDict.touches as any
    touches.item = (index: number) => touches[index]

    const changedTouches = eventInitDict.changedTouches as any
    changedTouches.item = (index: number) => changedTouches[index]

    const targetTouches = eventInitDict.targetTouches as any
    targetTouches.item = (index: number) => targetTouches[index]

    this.altKey = eventInitDict.altKey
    this.ctrlKey = eventInitDict.ctrlKey
    this.metaKey = eventInitDict.metaKey
    this.shiftKey = eventInitDict.shiftKey
    this.touches = touches
    this.targetTouches = targetTouches
    this.changedTouches = changedTouches
  }
}

export default class TouchEmulator {
  touchElementSize = 30
  ignoredTags = ['TEXTAREA', 'INPUT', 'SELECT']
  propsToFake = ['ontouchstart', 'ontouchmove', 'ontouchcancel', 'ontouchend'] as const
  eventsToPrevent = ['mouseenter', 'mouseleave', 'mouseout', 'mouseover'] as const
  eventsTriggeringUpdate = ['touchstart', 'touchmove', 'touchend', 'touchcancel'] as const
  multiTouchOffset = 75

  targetWindow: Window

  isStarted = false
  touchElements: Record<number, HTMLDivElement> = {}
  eventTarget: EventTarget | null = null
  isMultiTouch = false
  multiTouchStartEvent: MouseEvent | null = null
  fakedTouchProps: typeof this.propsToFake[number][] = []

  touchStart: ((event: MouseEvent) => void) | null = null
  touchMove: ((event: MouseEvent) => void) | null = null
  touchEnd: ((event: MouseEvent) => void) | null = null

  constructor(targetWindow: Window) {
    this.targetWindow = targetWindow
  }

  private isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0
  }

  private fakeTouchSupport = () => {
    for (const prop of this.propsToFake) {
      if (!this.targetWindow[prop]) {
        this.fakedTouchProps.push(prop)
        this.targetWindow[prop] = null
      }
    }
  }

  private unfakeTouchSupport = () => {
    for (const prop of this.fakedTouchProps) {
      delete this.targetWindow[prop]
    }
  }

  private preventMouseEvents = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  private setTouchElementStyle = (element: HTMLDivElement, touch: Touch) => {
    const size = this.touchElementSize / 2
    const transform = `translate(${touch.clientX - size}px, ${touch.clientY - size}px)`

    element.style.position = 'fixed'
    element.style.left = '0'
    element.style.top = '0'
    element.style.background = '#ffffff'
    element.style.border = 'solid 1px #999999'
    element.style.opacity = '0.6'
    element.style.borderRadius = '100%'
    element.style.height = `${this.touchElementSize}px`
    element.style.width = `${this.touchElementSize}px`
    element.style.padding = '0'
    element.style.margin = '0'
    element.style.display = 'block'
    element.style.overflow = 'hidden'
    element.style.pointerEvents = 'none'
    element.style.userSelect = 'none'
    element.style.transform = transform
    element.style.zIndex = '100'
  }

  private createTouch = (identifier: number, pos: MouseEvent, deltaX = 0, deltaY = 0) => {
    return new Touch({
      identifier,
      target: this.eventTarget!,
      clientX: pos.clientX + deltaX,
      clientY: pos.clientY + deltaY,
      screenX: pos.screenX + deltaX,
      screenY: pos.screenY + deltaY,
      pageX: pos.pageX + deltaX,
      pageY: pos.pageY + deltaY,
    })
  }

  private createTouchList = (event: MouseEvent) => {
    if (this.isMultiTouch) {
      const offset = this.multiTouchOffset
      const deltaX = this.multiTouchStartEvent!.pageX - event.pageX
      const deltaY = this.multiTouchStartEvent!.pageY - event.pageY

      return [
        this.createTouch(1, this.multiTouchStartEvent!, deltaX * -1 - offset, deltaY * -1 + offset),
        this.createTouch(2, this.multiTouchStartEvent!, deltaX + offset, deltaY - offset),
      ]
    }

    return [this.createTouch(1, event, 0, 0)]
  }

  private getActiveTouches = (event: MouseEvent, eventName: string) => {
    if (event.type == 'mouseup') return []

    const touchList = this.createTouchList(event)

    if (this.isMultiTouch && event.type !== 'mouseup' && eventName === 'touchend') {
      return touchList.slice(1, 1)
    }

    return touchList
  }

  private getChangedTouches = (event: MouseEvent, eventName: string) => {
    const touchList = this.createTouchList(event)

    if (this.isMultiTouch && event.type !== 'mouseup' && ['touchstart', 'touchend'].includes(eventName)) {
      return touchList.slice(0, 1)
    }

    return touchList
  }

  private triggerTouch = (event: MouseEvent, eventName: string) => {
    const touchEvent = new TouchEvent(eventName, {
      bubbles: true,
      cancelable: true,
      altKey: event.altKey,
      ctrlKey: event.ctrlKey,
      metaKey: event.metaKey,
      shiftKey: event.shiftKey,
      touches: this.getActiveTouches(event, eventName),
      targetTouches: this.getActiveTouches(event, eventName),
      changedTouches: this.getChangedTouches(event, eventName),
    })

    this.eventTarget?.dispatchEvent(touchEvent)
  }

  private onMouse = (touchType: string) => {
    return (event: MouseEvent) => {
      if (!event.shiftKey && this.ignoredTags.includes((event.target as HTMLElement).tagName)) return

      this.preventMouseEvents(event)

      const isLeftClickButton = event.type === 'mousemove' ? event.buttons === 1 : event.button === 0
      if (!isLeftClickButton) return

      if (event.type == 'mousedown' || !this.eventTarget?.dispatchEvent) {
        this.eventTarget = event.target
      }

      if (this.isMultiTouch && !event.shiftKey) {
        this.triggerTouch(event, 'touchend')
        this.isMultiTouch = false
      }

      this.triggerTouch(event, touchType)

      if (!this.isMultiTouch && event.shiftKey) {
        this.isMultiTouch = true
        this.multiTouchStartEvent = event
        this.triggerTouch(event, 'touchstart')
      }

      if (event.type == 'mouseup') {
        this.multiTouchStartEvent = null
        this.isMultiTouch = false
        this.eventTarget = null
      }
    }
  }

  private addNewTouchElements = (event: TouchEvent) => {
    for (const touch of event.touches) {
      let touchElement = this.touchElements[touch.identifier]

      if (!touchElement) {
        touchElement = this.targetWindow.document.createElement('div')
        this.touchElements[touch.identifier] = touchElement
        this.targetWindow.document.body.appendChild(touchElement)
      }

      this.setTouchElementStyle(touchElement, touch)
    }
  }

  private removeEndedTouchElements = (event: TouchEvent) => {
    for (const touch of event.changedTouches) {
      const touchElement = this.touchElements[touch.identifier]

      if (touchElement) {
        touchElement.parentNode?.removeChild(touchElement)
        delete this.touchElements[touch.identifier]
      }
    }
  }

  private updateTouchElements = (event: Event) => {
    this.addNewTouchElements(event as TouchEvent)
    if (event.type == 'touchend' || event.type == 'touchcancel') this.removeEndedTouchElements(event as TouchEvent)
  }

  start = () => {
    if (this.isStarted || this.isTouchDevice()) return

    this.fakeTouchSupport()

    this.touchStart = this.onMouse('touchstart')
    this.touchMove = this.onMouse('touchmove')
    this.touchEnd = this.onMouse('touchend')

    this.targetWindow.addEventListener('mousedown', this.touchStart, true)
    this.targetWindow.addEventListener('mousemove', this.touchMove, true)
    this.targetWindow.addEventListener('mouseup', this.touchEnd, true)

    for (const eventName of this.eventsToPrevent) {
      this.targetWindow.addEventListener(eventName, this.preventMouseEvents, true)
    }

    for (const eventName of this.eventsTriggeringUpdate) {
      this.targetWindow.addEventListener(eventName, this.updateTouchElements, true)
    }

    this.isStarted = true
  }

  stop = () => {
    if (!this.isStarted || this.isTouchDevice()) return

    this.unfakeTouchSupport()

    this.targetWindow.removeEventListener('mousedown', this.touchStart!, true)
    this.targetWindow.removeEventListener('mousemove', this.touchMove!, true)
    this.targetWindow.removeEventListener('mouseup', this.touchEnd!, true)

    for (const eventName of this.eventsToPrevent) {
      this.targetWindow.removeEventListener(eventName, this.preventMouseEvents, true)
    }

    for (const eventName of this.eventsTriggeringUpdate) {
      this.targetWindow.removeEventListener(eventName, this.updateTouchElements, true)
    }

    this.touchStart = null
    this.touchMove = null
    this.touchEnd = null
    this.isStarted = false
  }
}
