import { ECommerce, OnlineStore } from '@packages/types'
import React from 'react'

import CustomOnlineStoreContent from './customStore/CustomOnlineStoreContent'
import PrestashopOnlineStoreContent from './prestashop/PrestashopOnlineStoreContent'
import ShopifyOnlineStoreContent from './shopify/ShopifyOnlineStoreContent'
import WixOnlineStoreContent from './wix/WixOnlineStoreContent'
import WooCommerceOnlineStoreContent from './wooCommerce/WooCommerceOnlineStoreContent'

interface OnlineStoreFactoryProps {
  onlineStore: OnlineStore
}

const OnlineStoreContentFactory = ({ onlineStore }: OnlineStoreFactoryProps) => {
  switch (onlineStore.eCommerce) {
    case ECommerce.Prestashop:
      return <PrestashopOnlineStoreContent onlineStore={onlineStore} />
    case ECommerce.Woocommerce:
      return <WooCommerceOnlineStoreContent onlineStore={onlineStore} />
    case ECommerce.Shopify:
      return <ShopifyOnlineStoreContent onlineStore={onlineStore} />
    case ECommerce.Wix:
      return <WixOnlineStoreContent onlineStore={onlineStore} />
    default:
      return <CustomOnlineStoreContent onlineStore={onlineStore} />
  }
}

export default OnlineStoreContentFactory
