import { FieldInputType, FormTypes, DefaultQuoteForm } from '@packages/types'

export const defaultQuoteForm: DefaultQuoteForm = {
  type: FormTypes.Quote,
  fields: [
    {
      name: 'Name',
      key: 'name',
      inputType: FieldInputType.Text,
      required: true,
    },
    {
      name: 'Email',
      key: 'email',
      inputType: FieldInputType.Email,
      required: true,
    },
    {
      name: 'Message',
      key: 'message',
      maxLength: 1000,
      inputType: FieldInputType.TextArea,
    },
  ],
}

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
