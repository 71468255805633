import { Product } from '@packages/types'
import { formatDistanceToNow } from 'date-fns'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Card from 'common/components/card/Card'
import EmptyProduct from 'icons/custom/empty-product.svg'
import classMerge from 'utils/classMerge'
import imageLoader, { dimensionStrings } from 'utils/loaders/ImageLoader'

import ProductCardActions from './ProductCardAction'

export interface ProductCardProps {
  product: Product
}

const ProductCard = ({ product }: ProductCardProps) => {
  const { name, updatedAt, thumbnail } = product
  const match = useRouteMatch()
  const history = useHistory()
  const lastUpdated = updatedAt ? formatDistanceToNow(new Date(updatedAt!), { addSuffix: true }) : 'N/A'

  const disabled = product.archived || product.deleted
  const src = thumbnail && imageLoader.buildSrc(thumbnail, { dimensionString: dimensionStrings.regular })

  const openBuilder = () => history.push(`${match.url}/${product.id}/builder`)

  return (
    <Card
      className={classMerge(
        'group flex-wrap w-full md:w-[240px] h-[234px] hover:shadow-[0_0_15px_0px_rgba(0,0,0,0.12)] transition-shadow m-[10px] mr-[20px] ml-0',
        {
          'shadow-[0_0_32px_0_rgba(0,0,0,0.08)] cursor-pointer': !disabled,
        }
      )}
      aria-label={name}
      shadow={false}
      role="gridcell"
      onClick={disabled ? undefined : openBuilder}
      disabled={disabled}
    >
      {src ? (
        <img
          src={src}
          className={classMerge('aspect-[4/3] object-contain p-1 w-full h-[163px]', { 'opacity-50': disabled })}
          role="img"
          alt={`${name} image`}
        />
      ) : (
        <EmptyProduct className={classMerge('mx-auto my-[46.5px] w-20 h-[70px]', { 'opacity-50': disabled })} />
      )}

      <Card.Separator className="w-full" />

      <div className="flex w-full h-[69px] justify-between p-4 relative">
        <div className="flex flex-col items-start whitespace-nowrap overflow-hidden space-y-1 group-hover:pr-3">
          <span
            className={classMerge('text-sm leading-4 text-ellipsis overflow-hidden', { 'text-neutral-800': !disabled })}
            aria-label="Product name"
          >
            {name}
          </span>
          <span className={classMerge('text-xs text-ellipsis overflow-hidden', { 'text-neutral-300': !disabled })}>
            Updated {lastUpdated}
          </span>
        </div>
        <ProductCardActions product={product} />
      </div>
    </Card>
  )
}

export default ProductCard
