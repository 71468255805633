import classNames from 'classnames'
import React from 'react'

import './TopBarSection.scss'

interface TopBarSectionProps {
  className?: string
  right?: boolean
  children?: React.ReactNode
}

const TopBarSection = ({ className, children, right = false }: TopBarSectionProps) => {
  return (
    <div className={classNames('top-bar__section', { 'top-bar__section--right': right }, className)}>{children}</div>
  )
}

export default TopBarSection
