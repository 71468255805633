import { Addon, PaymentStrategy, ShopifyOnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import useAddonService from 'common/addon/hooks/useAddonService'
import Alert from 'common/components/alert/Alert'
import Card from 'common/components/card/Card'
import FullPageModal from 'common/components/modal/FullPageModal'
import { ModalProps } from 'common/components/modal/Modal'
import usePlanService from 'common/plan/hooks/usePlanService'
import useSubscriptionService from './../../hooks/useSubscriptionService'
import ShopifyActivationCard from './../cards/ShopifyActivationCard'
import WixActivationCard from './../cards/WixActivationCard'
import AddonsList from './../cards/AddonsList'
import PlanCard from './../cards/PlanCard'
import StripeActivationFeedbackCard from './../cards/StripeActivationFeedbackCard'
import EntreprisePlanCard from './../cards/EntreprisePlanCard'
import EstimatePriceCard from './../cards/EstimatePriceCard'
import StripeActivationCard from './../cards/StripeActivationCard'
import SubscriptionCheckList from '../SubscriptionCheckList'
import SubscriptionQuestionAndAnswer from '../SubscriptionQuestionAndAnswer'
import { customizationExperiencesAdvantages, adminTools, questionAnswerTuples } from './subscriptionPageData'

interface FullPageBillingPlansProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
}

export enum TypeOfModalPage {
  stripe = 'stripe',
  stripeConfirmation = 'stripe_confirmation',
  default = 'default',
}

const planUnavailableString = "We are no longer offering your plan. It can't be recovered if you change plans."

const SubscriptionFullPageModal = ({ onClose, ...modalProps }: FullPageBillingPlansProps) => {
  const [typeOfModal, setTypeOfModal] = useState(TypeOfModalPage.default)
  const [addonsSubscribed, setAddonsSubscribed] = useState<Addon[]>([])
  const [initialized, setInitialized] = useState<boolean>(false)

  const addonService = useAddonService()
  const subscriptionService = useSubscriptionService()
  const onlineStoreService = useOnlineStoreService()
  const planService = usePlanService()

  const { data: availableAddons } = useQuery(addonService.fetchAll.queryKeys, addonService.fetchAll)
  const { data: subscription, refetch: refetchSubscription } = useQuery(
    subscriptionService.fetchOne.queryKeys,
    subscriptionService.fetchOne,
    {
      onSuccess: data => {
        if (!initialized) {
          setAddonsSubscribed(data?.addons)
          setInitialized(true)
        }
      },
    }
  )
  const { data: onlineStores } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll, {
    initialData: [],
  })
  const { data: availablePlans } = useQuery(planService.fetchAll.queryKeys, planService.fetchAll)

  const handleClose = () => {
    refetchSubscription()
    onClose()
  }

  if (!subscription || !availablePlans || !availableAddons || !onlineStores) return null

  const plan = availablePlans[0]
  const isPlanAvailable = !subscription.plan.custom && subscription.plan.planId === plan.planId

  let activationCard = null

  if (subscription.paymentStrategy === PaymentStrategy.Shopify)
    activationCard = (
      <ShopifyActivationCard
        onlineStores={onlineStores as ShopifyOnlineStore[]}
        plan={plan}
        subscription={subscription}
        addons={addonsSubscribed}
      />
    )
  if (subscription.paymentStrategy === PaymentStrategy.Wix)
    activationCard = (
      <WixActivationCard
        onlineStores={onlineStores}
        plan={plan}
        subscription={subscription}
        addons={addonsSubscribed}
        onSuccess={handleClose}
      />
    )
  if (subscription.paymentStrategy === PaymentStrategy.Stripe)
    activationCard = (
      <StripeActivationCard
        plan={plan}
        addons={addonsSubscribed}
        onSuccess={() => setTypeOfModal(TypeOfModalPage.stripeConfirmation)}
      />
    )

  return (
    <FullPageModal className="flex" onClose={handleClose} {...modalProps}>
      <div className="flex max-w-4xl items-start w-[410px] md:w-auto">
        {typeOfModal === TypeOfModalPage.default && (
          <div className="flex-col">
            {!isPlanAvailable && (
              <Alert aria-label="legacy plan warning" variant="warning" className="my-4">
                {planUnavailableString}
              </Alert>
            )}
            <div className="flex items-start space-x-6 mt-4">
              <div className="w-full flex flex-col">
                <div className="w-full items-center md:items-start md:flex-row flex md:space-x-6 md:space-y-0 flex-col space-y-6">
                  <PlanCard
                    plan={plan}
                    addonsSubscribed={addonsSubscribed}
                    addons={
                      <AddonsList
                        availableAddons={availableAddons}
                        subscriptionAddons={addonsSubscribed}
                        onAdd={addon => {
                          setAddonsSubscribed([...addonsSubscribed, addon])
                        }}
                        onRemove={addon => {
                          setAddonsSubscribed(addonsSubscribed.filter(a => a.addonId !== addon.addonId))
                        }}
                      />
                    }
                  />
                  <div className="w-full ">
                    <EstimatePriceCard plan={plan} addons={addonsSubscribed} />

                    <div className="pt-8">{activationCard}</div>
                    <div className="flex flex-col space-y-3 pt-8 px-4 font-p2">
                      <span>* All prices are in US dollars</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-8 space-y-16">
              <EntreprisePlanCard />

              <Card shadow={false} className="flex-col border border-neutral-100 w-full">
                <Card.Section>
                  <h1>What's included</h1>
                </Card.Section>
                <Card.Section className="pt-2">
                  <div className="grid grid-cols-2 gap-12 w-full">
                    <SubscriptionCheckList
                      list={customizationExperiencesAdvantages.checklist}
                      title={customizationExperiencesAdvantages.title}
                    />
                    <SubscriptionCheckList list={adminTools.checklist} title={adminTools.title} />
                  </div>
                </Card.Section>
              </Card>
              <div>
                <h1 className="pb-5">Frequently asked questions</h1>
                <div className="grid grid-cols-2 gap-12 pb-20">
                  {questionAnswerTuples.map((item, index) => {
                    return <SubscriptionQuestionAndAnswer key={index} question={item.question} answer={item.answer} />
                  })}
                </div>
              </div>
            </div>
          </div>
        )}

        {typeOfModal === TypeOfModalPage.stripeConfirmation && <StripeActivationFeedbackCard />}
      </div>
    </FullPageModal>
  )
}

export default SubscriptionFullPageModal
