import classNames from 'classnames'
import React from 'react'

import './Panel.scss'

export interface PanelProps {
  children?: React.ReactNode
  side?: 'left' | 'right'
  style?: React.CSSProperties
  elevated?: boolean
  className?: string
}

const Panel = ({ children, side, style, className, elevated = false }: PanelProps) => {
  return (
    <div
      className={classNames('panel', className, { 'panel--elevated': elevated, [`panel--${side}`]: side != null })}
      style={style}
    >
      {children}
    </div>
  )
}

export default Panel
