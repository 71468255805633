import { MoveableManagerInterface, MoveableProps, Renderer } from 'react-moveable'

import { toRads } from 'utils/math'

const RotatableViewer = {
  name: 'rotatable-viewer',
  props: [],
  events: [],
  render(moveable: MoveableManagerInterface<MoveableProps>, React: Renderer) {
    if (!moveable.props.rotatable) return []

    const rect = moveable.getRect()
    const { pos1, pos2 } = moveable.state

    return (
      <div
        key="rotatable-viewer-rotation"
        style={{
          position: 'absolute',
          transform:
            `translate(-50%, -50%)` +
            ` translate(${(pos1[0] + pos2[0]) / 2}px, ${(pos1[1] + pos2[1]) / 2}px)` +
            ` translate(${Math.sin(toRads(rect.rotation)) * 56}px, ${Math.cos(toRads(rect.rotation)) * -56}px)` +
            ` translate(${Math.sin(toRads(rect.rotation)) * 40}%, ${Math.cos(toRads(rect.rotation)) * -40}%)`,
          pointerEvents: 'none',
          transformOrigin: '50% 100%',
          opacity: moveable.isDragging('rotatable') ? 1 : 0,
        }}
      >
        <div className="bg-neutral-500 text-white shadow-xs py-1 px-2 rounded-full text-xs font-medium flex items-center justify-center">
          {Math.round(rect.rotation)}°
        </div>
      </div>
    )
  },
}

export default RotatableViewer
