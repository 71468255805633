import displayer, { initialState as displayerInitialState } from 'customizer/2dDisplayer/reducer'
import * as twoDDisplayerActionTypes from 'customizer/2dDisplayer/actionTypes'
import * as customizationActionTypes from 'customizer/customization/actionTypes'

import { ALLOW_EDIT_PRINT_AREA, CHANGE_PREVIEW_MODE } from './actionTypes'

export const initialState = { ...displayerInitialState }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case twoDDisplayerActionTypes.SWITCH_VIEW:
      if (action.payload === state.currentView) return state
      return {
        ...state,
        editedPart: null,
        hiddenParts: [],
        editedPrintArea: null,
        renderedPrintArea: null,
        zoom: 0,
        currentView: action.payload,
      }
    case customizationActionTypes.EDIT_PART:
      return {
        ...state,
        editedPart: action.payload.part.id,
        hiddenParts: [action.payload.part.id],
        preventTransform: true,
      }
    case customizationActionTypes.STOP_EDIT_PART:
      return {
        ...state,
        editedPart: null,
        hiddenParts: [],
        renderedPrintArea: null,
        displayGrid: false,
        preventTransform: false,
      }
    case twoDDisplayerActionTypes.EDIT_PRINT_AREA:
      return {
        ...state,
        editedPrintArea: action.payload,
        editedPart: null,
        hiddenParts: [],
        renderedPrintArea: state.editedPrintArea,
      }
    case ALLOW_EDIT_PRINT_AREA:
      return { ...state, editedPart: null, editedPrintArea: null, renderedPrintArea: action.payload, hiddenParts: [] }
    case CHANGE_PREVIEW_MODE:
      return { ...state, viewMode: action.payload }
    default:
      return displayer(state, action)
  }
}

export default reducer
