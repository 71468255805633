import { AdminUserRole, User, UserRole } from '@packages/types'

export const getFullName = ({ firstName = '', lastName = '' }: { firstName?: string; lastName?: string } = {}) => {
  return `${firstName} ${lastName}`.trim()
}

export const isMCZRUser = (user?: User) =>
  user && ([AdminUserRole.Master, AdminUserRole.MczrAdmin] as UserRole[]).includes(user.role)

export const isMasterUser = (user?: User) => user?.role === AdminUserRole.Master
