import { StoreTheme } from '@packages/types'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'

export type ThemeFetchOptions = { params?: { fields?: string[] } }

const useThemeService = () => {
  return {
    fetchAll: createQuery(['store-themes'], async () => {
      const { data } = await apiClient.get<StoreTheme[]>('/store/themes')
      return data
    }),
  }
}

export default useThemeService
