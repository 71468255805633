import classNames from 'classnames'
import React from 'react'

import TableBody from './TableBody'
import TableBodyLoader from './TableBodyLoader'
import TableBulkSelectionHeader from './TableBulkSelectionHeader'
import TableCell from './TableCell'
import TableEmptyCellIndicator from './TableEmptyCellIndicator'
import TableFooter from './TableFooter'
import TableFooterCell from './TableFooterCell'
import TableFooterRow from './TableFooterRow'
import TableGroupHeaderCell from './TableGroupHeaderCell'
import TableHeader from './TableHeader'
import TableHeaderCell from './TableHeaderCell'
import TableHeaderCellTooltip from './TableHeaderCellTooltip'
import TableHeaderFilter from './TableHeaderFilter'
import TableHeaderRow from './TableHeaderRow'
import TableLoader from './TableLoader'
import TablePagination from './TablePagination'
import TablePaginationNavigation from './TablePaginationNavigation'
import TablePaginationRowsPerPage from './TablePaginationRowsPerPage'
import TableRow from './TableRow'

export type TableProps = React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>

const Table = ({ children, className, ...props }: TableProps) => (
  <table className={classNames('table-auto min-w-full', className)} aria-label="Inventory table" {...props}>
    {children}
  </table>
)

Table.Body = TableBody
Table.Cell = TableCell
Table.Footer = TableFooter
Table.FooterCell = TableFooterCell
Table.FooterRow = TableFooterRow
Table.Header = TableHeader
Table.HeaderFilter = TableHeaderFilter
Table.HeaderCell = TableHeaderCell
Table.HeaderCellTooltip = TableHeaderCellTooltip
Table.HeaderGroupCell = TableGroupHeaderCell
Table.HeaderRow = TableHeaderRow
Table.Pagination = TablePagination
Table.PaginationNavigation = TablePaginationNavigation
Table.PaginationRowsPerPage = TablePaginationRowsPerPage
Table.Row = TableRow
Table.BodyLoader = TableBodyLoader
Table.Loader = TableLoader
Table.EmptyCellIndicator = TableEmptyCellIndicator
Table.BulkSelectionHeader = TableBulkSelectionHeader

export default Table
