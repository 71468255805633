import classNames from 'classnames'
import React, { forwardRef } from 'react'

import './MultiIconsButton.scss'
import Button, { ButtonVariant } from 'common/components/Button'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'

type Props = {
  Icon: React.ReactElement
  children: React.ReactNode
  className?: string
  variant?: ButtonVariant
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const MultiIconsButton = forwardRef<HTMLButtonElement, Omit<Props, 'ref'>>(
  ({ Icon, className, children, variant, ...rest }: Props, ref) => {
    return (
      <Button ref={ref} {...rest} className={classNames(className, 'w-full')} variant={variant}>
        <div className="flex space-x-0.5 mr-2 items-center">{Icon}</div>
        <span className="add-button__label mr-auto">{children}</span>
        <AddIcon className="w-3 fill-current ml-2" />
      </Button>
    )
  }
)

export default MultiIconsButton
