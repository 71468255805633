import React from 'react'
import toast, { Toast } from 'react-hot-toast'

import BasicToast from './BasicToast'
import type { ToastOptions } from './types/toast'
import { ToastType } from './types/toastType'

const defaultOptions: ToastOptions = {
  duration: 5000,
  dismissable: true,
}

const useToast = () => {
  const openCustomToast = (Toast: (props: Toast) => JSX.Element, options: ToastOptions = defaultOptions) => {
    toast.custom(Toast, options)
  }

  const openToast = (
    message: string,
    type: ToastType = ToastType.default,
    { dismissable, ...options }: ToastOptions = defaultOptions
  ) => {
    toast.custom(
      ({ id, visible }) => (
        <BasicToast id={id} visible={visible} type={type} message={message} dismissable={dismissable} />
      ),
      options
    )
  }

  const openGenericErrorToast = (message: string, options: ToastOptions = defaultOptions) => {
    openToast(
      `Oops... something went wrong! ${message} Please try again later or contact us if the error persists.`,
      ToastType.warning,
      options
    )
  }

  return { openToast, openCustomToast, openGenericErrorToast, closeToast: toast.dismiss }
}

export default useToast
