import { useFlags } from 'flagsmith/react'
import React from 'react'

declare type ElementProps<T> = T extends React.ComponentType<infer Props> ? (Props extends object ? Props : {}) : {}

function withFlag<T extends React.ComponentType<any>>({
  Component,
  feature,
  AlternateComponent,
}: {
  Component: T
  feature: string
  AlternateComponent?: () => JSX.Element
}) {
  type TProps = ElementProps<T>

  return (props: TProps) => {
    const flags = useFlags([feature])

    if (flags[feature].enabled) {
      return <Component {...props} />
    }

    return AlternateComponent ? <AlternateComponent /> : null
  }
}

export const FeatureFlag = ({
  feature,
  fallback = null,
  children,
}: {
  feature: string
  fallback?: JSX.Element | null
  children: JSX.Element
}) => {
  const flags = useFlags([feature])

  if (!flags[feature]?.enabled) return fallback

  return children
}

export default withFlag
