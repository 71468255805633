import classNames from 'classnames'
import React from 'react'

import Tooltip from 'common/components/Tooltip'
import Kickflip from 'icons/logos/skater.svg'

interface TopBarBackButtonProps {
  onBackClick: () => void
  buttonClassNames?: string
}

const TopBarBackButton = ({ onBackClick, buttonClassNames }: TopBarBackButtonProps) => {
  return (
    <Tooltip content="Leave" containerClassName="flex justify-center">
      <button
        className={classNames('z-[2] mr-2 cursor-pointer', buttonClassNames)}
        onClick={onBackClick}
        aria-label="Leave"
        type="button"
      >
        <Kickflip width={16} height={22} />
      </button>
    </Tooltip>
  )
}

export default TopBarBackButton
