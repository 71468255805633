export const defaultPersistenceState = {
  status: [],
  paymentStatus: [],
  various: [],
  startDate: undefined,
  endDate: undefined,
  collectionSize: 0,
  resultSize: 0,
  lastIndex: 0,
  count: 25,
  filter: '',
  sortKey: undefined,
  sortOrder: undefined,
}
