import { DataTablePersistence } from './persistence/types/persistence'

export type SortOrder = 'ascending' | 'descending'

type TableSortPersistence<TSortKeys> = { sortOrder?: SortOrder; sortKey?: TSortKeys }

type TableSortConfig = { onChange?: () => void }

const useTableSort =
  <TSortKeys, TModel extends TableSortPersistence<TSortKeys> = any>({ onChange }: TableSortConfig) =>
  ({ state, setState }: DataTablePersistence<TModel>) => {
    const handleSort = (key: TSortKeys) => {
      if (key !== state.sortKey) {
        setState(state => ({ ...state, sortKey: key, sortOrder: 'ascending' }))
      } else if (state.sortOrder === 'ascending') {
        setState(state => ({ ...state, sortOrder: 'descending' }))
      } else {
        setState(state => ({ ...state, sortKey: undefined, sortOrder: undefined }))
      }

      onChange?.()
    }

    return { sortKey: state.sortKey, sortOrder: state.sortOrder, handleSort }
  }

export default useTableSort
