import { ECommerce, OnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import Button, { ButtonProps } from 'common/components/Button'
import useModal from 'common/components/modal/useModal'
import useOnlineStoreService from './../hooks/useOnlineStoreService'
import { onlineStoresHasOneOf } from '../utils'
import AddOnlineStoreModal from './AddOnlineStoreModal'

const AddOnlineStoreButton = (props: ButtonProps) => {
  const history = useHistory()
  const match = useRouteMatch()
  const onlineStoreService = useOnlineStoreService()
  const addOnlineStoreModal = useModal()

  const { data: onlineStores, isLoading } = useQuery(
    onlineStoreService.fetchAll.queryKeys,
    onlineStoreService.fetchAll,
    { initialData: [] }
  )
  const isOnlineStoresEmpty = onlineStores.length === 0

  const addOnlineStore = (onlineStores: OnlineStore[]) => {
    if (onlineStoresHasOneOf(onlineStores, ECommerce.Shopify)) return window.open(ADD_SHOPIFY_STORE_URL)
    if (onlineStoresHasOneOf(onlineStores, ECommerce.Wix)) return window.open(ADD_WIX_STORE_URL)
    if (onlineStoresHasOneOf(onlineStores, ECommerce.Woocommerce)) return window.open(ADD_WOOCOMMERCE_STORE_URL)
    if (onlineStoresHasOneOf(onlineStores, ECommerce.CustomStore)) return history.push(`${match.url}/create`)
  }

  return (
    <>
      <Button
        {...props}
        onClick={e => {
          if (isOnlineStoresEmpty) {
            addOnlineStoreModal.open()
          } else {
            addOnlineStore(onlineStores)
          }
          props.onClick?.(e)
        }}
        disabled={isLoading}
      />
      {addOnlineStoreModal.isVisible && (
        <AddOnlineStoreModal onClose={addOnlineStoreModal.close} {...addOnlineStoreModal.modalProps} />
      )}
    </>
  )
}

export default AddOnlineStoreButton
