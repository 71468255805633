import { EntityType } from '@packages/types'
import React from 'react'

import type { TreeItemComponentProps } from 'common/components/sortableTree/types'

import { TreeItemData } from '../types'
import GroupTreeItem from './GroupTreeItem'
import QuestionTreeItem from './QuestionTreeItem'
import TreeItem from './TreeItem'

const EntityTreeItem = React.forwardRef<HTMLLIElement, TreeItemComponentProps<TreeItemData>>((props, ref) => {
  return (
    <>
      <TreeItem {...props} ref={ref}>
        {props.item.value?.entityType === EntityType.Group && (
          <GroupTreeItem
            group={props.item.value}
            onToggleCollapse={props.onToggleCollapse!}
            isCollapsed={props.item.collapsed!}
          />
        )}
        {props.item.value?.entityType === EntityType.Question && <QuestionTreeItem question={props.item.value} />}
      </TreeItem>
    </>
  )
})

export default EntityTreeItem
