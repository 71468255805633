const getXMCZRHeader = (): Record<string, string> => {
  const headers: Record<string, string> = {}
  try {
    const bearerToken = localStorage.getItem('bearer')
    if (bearerToken) headers['Authorization'] = `Bearer ${bearerToken}`
  } catch (error) {}

  headers['X-Request-Url'] = window.location.href

  return headers
}

export default getXMCZRHeader
