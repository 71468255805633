import { BulkOrder } from '@packages/types'
import React from 'react'

import Input from 'common/components/inputs/Input'
import { useDispatch } from 'customizer/hooks'

import { patchGroup } from '../../actions'

interface BulkOrderLabelSettingsSectionProps {
  bulkOrderGroup: BulkOrder
}

const BulkOrderLabelSettingsSection = ({ bulkOrderGroup }: BulkOrderLabelSettingsSectionProps) => {
  const dispatch = useDispatch()

  const handleAddItemLabelChange = (addItemLabel: string) => dispatch(patchGroup(bulkOrderGroup.id, { addItemLabel }))

  const handleItemLabelChange = (itemLabel: string) => dispatch(patchGroup(bulkOrderGroup.id, { itemLabel }))

  return (
    <div className="panel__section">
      <div className="mb-4">
        <div className="panel__row panel__row--no-margin-bottom">
          <label className="panel__field-label w-2/3">Add button label</label>
        </div>
        <div className="panel__row panel__row panel__row--no-margin-bottom pt-2">
          <Input
            placeholder="Add another item"
            onChange={e => handleAddItemLabelChange(e.target.value)}
            value={bulkOrderGroup.addItemLabel}
          />
        </div>
      </div>

      <div className="panel__row panel__row--no-margin-bottom">
        <label className="panel__field-label w-2/3">Item label</label>
      </div>
      <div className="panel__row panel__row panel__row--no-margin-bottom pt-2">
        <Input
          placeholder="Item"
          onChange={e => handleItemLabelChange(e.target.value)}
          value={bulkOrderGroup.itemLabel}
        />
      </div>
    </div>
  )
}

export default BulkOrderLabelSettingsSection
