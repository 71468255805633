import React from 'react'

import CardTab from './CardTab'
import CardTabLeftIconContainer from './CardTabLeftIconContainer'
import CardTabPrimaryText from './CardTabPrimaryText'
import CardTabTextContainer from './CardTabTextContainer'

const CardTabs = ({
  children,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>) => {
  return (
    <nav {...rest}>
      <ul className="flex flex-col space-y-4">{children}</ul>
    </nav>
  )
}

CardTabs.Tab = CardTab
CardTabs.PrimaryText = CardTabPrimaryText
CardTabs.TextContainer = CardTabTextContainer
CardTabs.LeftIconContainer = CardTabLeftIconContainer

export default CardTabs
