import { DemoComplexity, DemoProduct } from '@packages/types'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import FullPageModal from 'common/components/modal/FullPageModal'
import { ModalProps } from 'common/components/modal/Modal'
import Button from 'common/components/Button'
import SearchFilter from 'common/components/filters/SearchFilter'
import TagsFilter from 'common/components/filters/TagsFilter'
import useIntersect from 'common/hooks/useIntersect'
import { trpc } from 'common/hooks/trpc'
import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'

import DemoSearchBlankState from './blankStates/DemoSearchBlankState'
import ProductsList from './ProductsList'

interface ProductsDemoModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
}

export const COMPLEXITY_FILTERS: { name: DemoComplexity; text: string; className: string }[] = [
  { name: DemoComplexity.Basic, text: 'Basic', className: 'bg-success-light text-success-dark' },
  { name: DemoComplexity.Medium, text: 'Medium', className: 'bg-primary-75 text-primary-800' },
  { name: DemoComplexity.Complex, text: 'Complex', className: 'bg-warning-light text-warning-dark' },
]

const ProductsDemoModal = ({ onClose, ...modalProps }: ProductsDemoModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const [ref, inView] = useIntersect({ threshold: 0.5 })
  const [search, setSearch] = useState<string>('')
  const [complexity, setComplexity] = useState<DemoComplexity[]>([])

  const toggleComplexityFilter = (name: DemoComplexity) => {
    if (complexity.includes(name)) {
      setComplexity(complexity.filter(value => value != name))
    } else {
      setComplexity([...complexity, name])
    }
  }

  const LIMIT = 20

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = trpc.demoProduct.list.useInfiniteQuery(
    {
      limit: LIMIT,
      search,
      complexity,
    },
    {
      keepPreviousData: true,
      getPreviousPageParam: firstPage => {
        const { lastIndex } = firstPage.pagination

        if (lastIndex - LIMIT === 0) return undefined

        return Math.max(lastIndex - LIMIT, 0)
      },
      getNextPageParam: lastPage => {
        const { lastIndex, collectionSize } = lastPage.pagination

        if (lastIndex >= collectionSize) return undefined

        return lastPage.pagination.lastIndex
      },
    }
  )

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  const handleClearFilters = () => {
    setSearch('')
    setComplexity([])
  }

  const products = data?.pages.reduce<DemoProduct[]>((prev, current) => {
    return [...prev, ...current.results]
  }, [])

  const isBlankState = !isLoading && !products?.length

  return (
    <FullPageModal onClose={onClose} {...modalProps}>
      <div ref={modalRef} className="w-fit flex flex-col">
        <div className="py-4 text-neutral-600 font-semibold text-sm mx-[10px]">
          <h1 className="font-black text-xl text-neutral-900 pb-2">Explore with demos</h1>
          <span>
            Learn how Kickflip works with our wide range of demo products, from the most basic to the most complex.
          </span>
        </div>
        <div className="flex items-center py-2 border-y border-neutral-100 mx-[10px]">
          <div className="flex items-center flex-wrap">
            <div className="mr-2">
              <SearchFilter name="Search" aria-label="search by product name" setSearch={setSearch} search={search} />
            </div>
          </div>
          <div className="mr-2">
            <TagsFilter
              name="Complexity"
              filters={COMPLEXITY_FILTERS}
              activeFilters={complexity}
              toggleFilter={name => toggleComplexityFilter(name as DemoComplexity)}
              clearFilter={() => setComplexity([])}
            />
          </div>
          <div className="ml-auto">
            <Button
              variant="text"
              onClick={handleClearFilters}
              icon={<Remove className="w-2.5 h-2.5 fill-current" />}
              disabled={isEmpty(search) && isEmpty(complexity)}
            >
              Clear filters
            </Button>
          </div>
        </div>

        {!isBlankState && (
          <ProductsList
            products={products || []}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            className="block w-[780px] min-[1120px]:w-[1040px] min-[1380px]:w-[1300px] min-[1640px]:w-[1560px] mt-1 flex-nowrap"
          />
        )}

        {isBlankState && (search || complexity) && <DemoSearchBlankState clearFilters={handleClearFilters} />}

        <div ref={ref} className="flex justify-center pt-10 self-center">
          {hasNextPage && !isFetchingNextPage && (
            <Button variant="default" onClick={() => fetchNextPage()}>
              Load more
            </Button>
          )}
        </div>
      </div>
    </FullPageModal>
  )
}

export default ProductsDemoModal
