import { CustomizerProduct, Group, StartingPoint } from '@packages/types'
import { useMemo } from 'react'

import { RootState } from 'cms/store'
import customizationReducer from 'customizer/customization/reducer/reducer'
import * as customizationActions from 'customizer/customization/actions'
import isNullOrEmpty from 'utils/isNullOrEmpty'

export const getTitleAndSubtitle = (primaryName: string, secondaryName: string) => {
  if (primaryName === secondaryName || isNullOrEmpty(secondaryName)) return { title: primaryName }

  if (isNullOrEmpty(primaryName)) return { title: secondaryName }

  return { title: primaryName, subtitle: secondaryName }
}

export const useTitleAndSubtitle = (primaryName: string, secondaryName: string) =>
  useMemo(() => getTitleAndSubtitle(primaryName, secondaryName), [primaryName, secondaryName])

export const getGroupParent = (productBuilder: RootState['productBuilder'], id: string) => {
  return Object.values(productBuilder.groups).find(group => group.children.includes(id))
}

export const getFirstLevelAncestorId = (groups: Group[], entityId: string, rootId: string): string => {
  const groupParent = groups.find(group => group.children.includes(entityId))

  if (groupParent && groupParent.id !== rootId) return getFirstLevelAncestorId(groups, groupParent.id, rootId)

  return entityId
}

export const buildCustomizationState = (
  customizerProduct: CustomizerProduct,
  configuration: StartingPoint['configuration']
) => {
  return {
    displayer: { hiddenParts: [] },
    customization: customizationReducer(
      undefined,
      customizationActions.startCustomization({
        customizerProduct,
        startingPoint: { configuration },
      })
    ),
  }
}
