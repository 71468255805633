import { AnyAction } from 'redux'
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk'

import configureStore from 'redux-inject-reducer'

import reducer from './reducer'

// If it's a function, return the function return type, othwerwise return the type
type GetReturnTypeOrType<T> = T extends (...args: any[]) => unknown ? ReturnType<T> : GetStateType<T>

// For each property, if it's an object, loop through each property, otherwise return the type
type GetStateType<T> = T extends object
  ? T extends (...args: any[]) => unknown
    ? GetReturnTypeOrType<T>
    : { [P in keyof T]: GetStateType<T[P]> }
  : GetReturnTypeOrType<T>

const tenant = location.hostname.split('.')[0]
const store = configureStore(reducer, [thunk], tenant !== 'admin' ? { tenant } : {})

export default store
export type RootState = GetStateType<typeof reducer>
export type AppDispatch = ThunkDispatch<RootState, void, AnyAction>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>
