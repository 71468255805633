import React from 'react'

import ArrowUp from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-up.svg'

export interface ScrollToTopButtonProps {
  isVisible: boolean
  onClick?: () => void
}

const ScrollToTopButton = ({ isVisible, onClick }: ScrollToTopButtonProps) => {
  const handleClick = onClick
    ? onClick
    : () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }

  if (!isVisible) return null

  return (
    <button
      className="absolute bottom-28 z-10 h-6 p-1.5 left-1/2 -translate-x-1/2 bg-neutral-600 hover:bg-neutral-700 transition-colors rounded-md flex text-white space-x-2 items-center shadow-lg animate-modal-backdrop-show"
      onClick={handleClick}
    >
      <ArrowUp className="fill-white w-3 h-3" /> <div>Scroll to top</div>
    </button>
  )
}

export default ScrollToTopButton
