import { without } from 'lodash'

import { DataTablePersistence } from './persistence/types/persistence'

type TableFiltersConfig = { onChange?: () => void }

type RestrictedKeys = 'sortKey' | 'sortOrder' | 'search'

type Model = { [key: string]: any }

type NoRestrictedProperties<T> = { [key in Exclude<keyof T, RestrictedKeys>]: any }

const useTableFilters =
  <TFilters extends Model, TModel extends TFilters = any>({ onChange }: TableFiltersConfig) =>
  ({ state, setState }: DataTablePersistence<TModel>) => {
    const toggleFilter = (type: keyof NoRestrictedProperties<TFilters>, name: string) => {
      const filter = state[type]

      if (Array.isArray(filter)) {
        const newValue = filter.includes(name) ? without(filter, name) : [...filter, name]
        setState(state => ({ ...state, [type]: newValue }))
      } else {
        setState(state => ({ ...state, [type]: name }))
      }

      onChange?.()
    }

    const setFilter = (type: keyof NoRestrictedProperties<TFilters>, value: any) => {
      setState(state => ({ ...state, [type]: value }))

      onChange?.()
    }

    const clearFilters = (type: keyof NoRestrictedProperties<TFilters>) => {
      const filter = state[type]

      if (Array.isArray(filter)) {
        setState(state => ({ ...state, [type]: [] }))
      } else if (typeof filter === 'object' || typeof filter === 'boolean') {
        setState(state => ({ ...state, [type]: undefined }))
      } else if (typeof filter === 'string') {
        setState(state => ({ ...state, [type]: '' }))
      }

      onChange?.()
    }

    return { ...(state as TFilters), toggleFilter, setFilter, clearFilters }
  }

export default useTableFilters
