import React from 'react'

import classMerge from 'utils/classMerge'

export type SeparatorProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Separator = ({ children, className, ...rest }: SeparatorProps) => (
  <div className={classMerge('w-full flex items-center', className)} {...rest}>
    <hr className="flex-grow h-[1px] border-neutral-75 mr-2" />
    {children}
    <hr className="flex-grow h-[1px] border-neutral-75 ml-2" />
  </div>
)

export default Separator
