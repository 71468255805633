import { QuestionType } from '@packages/types'
import { keyBy } from 'lodash'

import { AppDispatch, RootState } from 'customizer/store'

import { SET_STOCKS } from './actionTypes'
import { Stock } from './types'

export const fetchStocks = (productId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()

  const response = await fetch(`${state.baseURL}/brands/${state.tenant}/variants/${productId}/stocks`)
  const stocks = await response.json()

  dispatch(setStocks(keyBy(stocks, 'id')))
}

export const setStocks = (stocks: Record<string, Stock>) => ({ type: SET_STOCKS, payload: stocks })

export const createFakeStocks = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()

  const stocks = Object.values(state.customization.questions).reduce<Stock[]>((acc, question, questionIndex) => {
    const stocks = question.answers.reduce<Stock[]>((answerAcc, answer, answerIndex) => {
      if (question.type !== QuestionType.Text) {
        return [
          ...answerAcc,
          {
            id: `${questionIndex}-${answerIndex}`,
            stock: 0,
            answersRefs: [{ answerId: answer, questionId: question.id }],
            continueSelling: false,
          },
        ]
      }

      return answerAcc
    }, [])

    return [...acc, ...stocks]
  }, [] as Stock[])

  dispatch(setStocks(keyBy(stocks, 'id')))
}
