import classNames from 'classnames'
import React from 'react'

interface CardTabProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  isSelected?: boolean
}

const CardTab = ({ isSelected, children, className, ...rest }: CardTabProps) => {
  return (
    <li
      role="tab"
      aria-selected={isSelected}
      className={classNames(
        'flex shrink-0 border border-neutral-100 bordershadow-xs py-3 px-2 rounded-md hover:cursor-pointer hover:border-primary-500 items-center relative group w-full overflow-hidden transition-colors',
        'aria-selected:ring-primary-300 aria-selected:ring-2 aria-selected:border-transparent aria-selected:text-primary-600',
        className
      )}
      {...rest}
    >
      {children}
    </li>
  )
}

export default CardTab
