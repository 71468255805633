import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import React from 'react'

import { Container, FlattenedItem } from './types'

const animateLayoutChanges: AnimateLayoutChanges = args => defaultAnimateLayoutChanges({ ...args, wasDragging: true })

export interface DroppableContainerProps<T> extends React.PropsWithChildren {
  disabled?: boolean
  id: string
  items: FlattenedItem<T>[]
  style?: React.CSSProperties
  Container: Container
}

const DroppableContainer = <T extends Record<string, any>>({
  children,
  disabled,
  id,
  items,
  Container,
  ...props
}: DroppableContainerProps<T>) => {
  const { setNodeRef } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  })

  return (
    <Container ref={setNodeRef} isEmpty={items.length === 0} {...props}>
      {children}
    </Container>
  )
}

export default DroppableContainer
