import { OnlineStore } from '@packages/types'
import React from 'react'

import PrestashopOnlineStoreConnectionStatus from './prestashop/PrestashopOnlineStoreConnectionStatus'
import ShopifyOnlineStoreConnectionStatus from './shopify/ShopifyOnlineStoreConnectionStatus'
import WoocommerceOnlineStoreConnectionStatus from './wooCommerce/WooCommerceOnlineStoreConnectionStatus'

type OnlineStoreConnectionStatusProps = {
  onlineStore: OnlineStore
}

const OnlineStoreConnectionStatus = ({ onlineStore }: OnlineStoreConnectionStatusProps) => {
  switch (onlineStore.eCommerce) {
    case 'woocommerce':
      return <WoocommerceOnlineStoreConnectionStatus onlineStore={onlineStore} />
    case 'prestashop':
      return <PrestashopOnlineStoreConnectionStatus onlineStore={onlineStore} />
    case 'shopify':
      return <ShopifyOnlineStoreConnectionStatus onlineStore={onlineStore} />
    default:
      return null
  }
}

export default OnlineStoreConnectionStatus
