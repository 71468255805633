import classNames from 'classnames'
import React from 'react'

import Button, { ButtonProps } from '../Button'

export type ClearFiltersButtonProps = Omit<ButtonProps, 'variant' | 'icon'>

const ClearFiltersButton = (props: ClearFiltersButtonProps) => (
  <Button variant="text" {...props} className={classNames(props.className, { 'opacity-0': props.disabled })}>
    Clear filters
  </Button>
)

export default ClearFiltersButton
