import React from 'react'

import ProductSettingsCanvasSizeSection from 'builder/build/settings/components/ProductSettingsCanvasSizeSection'
import ProductSettingsTitleSection from 'builder/build/settings/components/ProductSettingsTitleSection'
import ProductSettingsViewsSection from 'builder/build/settings/components/ProductSettingsViewsSection'

const ProductSettingsPanel = () => {
  return (
    <div className="h-full">
      <div className="flex flex-col flex-grow">
        <ProductSettingsTitleSection />
        <hr className="border-neutral-100" />
        <ProductSettingsViewsSection />
        <hr className="border-neutral-100" />
        <ProductSettingsCanvasSizeSection />
      </div>
    </div>
  )
}

export default ProductSettingsPanel
