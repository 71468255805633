import React from 'react'

import shopifyThemeSave from 'img/shopify-theme-save.png'
import shopifyAppEmbed from 'videos/shopify-app-embed-block.webm'

const ShopifyAppEmbedSteps = () => {
  return (
    <div className="flex flex-col space-y-8">
      <div className="flex justify-between w-full">
        <div className="flex flex-col space-y-4 pr-10">
          <p className="flex items-center">
            1. Activate <span className="ml-1 font-bold">Kickflip App Embed</span>
          </p>
          <p className="text-neutral-500">
            You can find it in Shopify Theme Editor's App Embed section or by clicking the Set up button below.
          </p>
        </div>
        <div className="flex items-center">
          <div className="w-max rounded-md shadow overflow-hidden">
            <video disableRemotePlayback autoPlay loop className="w-[200px] -ml-1">
              <source src={shopifyAppEmbed} type="video/webm" />
            </video>
          </div>
        </div>
      </div>
      <div className="h-[1px] w-full bg-neutral-100 self-center" />
      <div className="flex justify-between w-full">
        <div className="flex flex-col space-y-4 pr-10">
          <p>2. Set up the customizer to your liking</p>
          <p className="text-neutral-500">
            You can edit the integration directly in the Shopify theme editor. If it isn't working out of the box just
            follow the help links.
          </p>
        </div>
      </div>
      <div className="h-[1px] w-full bg-neutral-100 self-center" />
      <div className="flex justify-between w-full">
        <div className="flex flex-col space-y-4 pr-10">
          <p>
            3.<span className="ml-1 font-bold">Save</span> your theme
          </p>
          <p className="text-neutral-500">Click on the save button in the top left corner.</p>
        </div>
        <div className="flex items-center">
          <div className="relative w-max rounded-md shadow overflow-hidden origin-top-left">
            <img src={shopifyThemeSave} className="object-cover w-28" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopifyAppEmbedSteps
