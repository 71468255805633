import classNames from 'classnames'
import React from 'react'

import Tab, { TabProps } from './Tab'

export interface IconTabProps extends TabProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

export const tabIconDefaultClassName = 'fill-current text-current w-3.5 h-3.5'

const IconTab = ({ children, isSelected, className, onClick, ...rest }: IconTabProps) => (
  <Tab className={className} onClick={onClick} isSelected={isSelected} {...rest}>
    {React.cloneElement(children, {
      ...children.props,
      className: classNames(tabIconDefaultClassName, children.props.className),
    })}
  </Tab>
)

export default IconTab
