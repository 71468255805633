import React from 'react'

import NumberInput from 'common/components/inputs/number/NumberInput'
import Popover from 'common/components/popover/Popover'
import usePopover from 'common/components/popover/usePopover'

interface StockAdjustPopoverProps {
  popover: ReturnType<typeof usePopover>
  initialValue?: number
  value: number | null
  onChange: (stock: number | null) => void
  children: React.ReactNode
}

const StockAdjustPopover = ({ popover, initialValue, value, onChange, children }: StockAdjustPopoverProps) => {
  const defaultAdjustValue = value != null ? value - (initialValue == null ? 0 : initialValue) : ''

  return (
    <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
      <div className="flex flex-col w-56 px-2 pt-4 pb-2">
        <div className="flex space-x-2 mb-2">
          <div className="flex flex-col">
            <label htmlFor="stock-adjust-by" className="font-medium mb-2">
              Adjust by
            </label>
            <NumberInput
              id="stock-adjust-by"
              onFocus={e => e.target.select()}
              value={defaultAdjustValue}
              onValueChange={(value = 0) => onChange((initialValue != null ? initialValue : 0) + value)}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="stock-new" className="font-medium mb-2">
              New
            </label>
            <NumberInput
              id="stock-new"
              value={value == null ? '' : value}
              onValueChange={value => onChange(value ?? null)}
            />
          </div>
        </div>
        {children}
      </div>
    </Popover>
  )
}

export default StockAdjustPopover
