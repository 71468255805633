import { Product } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFlags } from 'flagsmith/react'
import React, { useContext, useEffect } from 'react'

import * as coreUtils from 'builder/build/core/utils'
import GlobalRouterContext from 'builder/common/GlobalRouterContext'
import { NetworkError } from 'common/api/types/error'
import IconButton from 'common/components/IconButton'
import Popover from 'common/components/popover/Popover'
import ProductLimitReachedToast from 'common/components/toast/ProductLimitReachedToast'
import useModal from 'common/components/modal/useModal'
import usePopover from 'common/components/popover/usePopover'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import useProductService from 'common/products/hooks/useProductService'
import useProductExportImport from 'common/products/hooks/useProductExportImport'
import ArchiveProductModal from 'common/products/components/ArchiveProductModal'
import DeleteProductModal from 'common/products/components/DeleteProductModal'
import ExportProductModal from 'common/products/components/ExportProductModal'
import useCurrentUser from 'common/users/hooks/useCurrentUser'
import ArrowDownIcon from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg'

const TopBarProductActions = () => {
  const { openToast, openCustomToast, openGenericErrorToast } = useToast()
  const popover = usePopover({ placement: 'bottom', offsetConfig: 8 })
  const { match, history } = useContext(GlobalRouterContext)
  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''
  const queryClient = useQueryClient()
  const productService = useProductService()
  const archiveModal = useModal()
  const exportModal = useModal()
  const deleteModal = useModal()
  const user = useCurrentUser()
  const { exportProduct } = useProductExportImport()

  const flags = useFlags(['product_delete'])

  const handleGoToVersionHistory = () => {
    popover.close()
    history.push(`${baseUrl}/products/${match.params.productId}/history`)
  }

  const { data: product, isLoading: isLoadingProduct } = useQuery(
    [...productService.fetch.queryKeys, match.params.productId],
    () => productService.fetch(match.params.productId)
  )

  const { mutate: duplicate } = useMutation<Product, NetworkError>(
    () => productService.duplicate(match.params.productId),
    {
      onSuccess: newProduct => {
        openToast(`Product "${product!.name}" was successfully duplicated!`, ToastType.success)
        history.push(`${baseUrl}/products/${newProduct.id}/builder`)
      },
      onError: error => {
        if (error.data?.name === 'MaximumActiveProductsException') {
          openCustomToast(({ id, visible }) => (
            <ProductLimitReachedToast
              id={id}
              visible={visible}
              onUpgradePlan={() => history.push(`${baseUrl}/subscription`)}
            />
          ))
        } else {
          openGenericErrorToast(`Product "${product!.name}" has not been duplicated.`)
        }
      },
    }
  )

  const { mutate: archive } = useMutation(() => productService.update(match.params.productId, { archived: true }), {
    onSuccess: () => {
      openToast(`Product "${product!.name}" was successfully archived!`, ToastType.success)
      queryClient.invalidateQueries(productService.fetchAll.queryKeys)
      history.push(`${baseUrl}/products`)
    },
    onError: () => {
      openGenericErrorToast(`Product "${product!.name}" has not been archived.`)
    },
  })

  const onExport = async (field: 'live' | 'draft') => {
    try {
      await exportProduct(match.params.productId, field)
    } catch (error) {
      openGenericErrorToast(`Product "${product!.name}" has not been exported.`)
    }
  }

  const withClosePopopver = (callback: () => void) => () => {
    popover.close()
    callback()
  }

  const handleDuplicate = withClosePopopver(() => duplicate())
  const handleArchive = withClosePopopver(() => archiveModal.open())
  const handleExport = withClosePopopver(() => exportModal.open())
  const handleDelete = withClosePopopver(() => deleteModal.open())

  useEffect(() => {
    if (popover.isOpen) queryClient.invalidateQueries(productService.fetch.queryKeys)
  }, [popover.isOpen])

  if (isLoadingProduct || coreUtils.isPathDemoProduct(match.path)) return null

  return (
    <>
      <IconButton
        Icon={ArrowDownIcon}
        variant="subtle"
        small
        smallIcon
        aria-label="Product actions"
        type="button"
        {...popover.referenceProps}
      />
      <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
        <Popover.Action onClick={handleGoToVersionHistory} aria-label="go to version history">
          Version history
        </Popover.Action>
        <Popover.Action onClick={handleDuplicate} aria-label="duplicate product">
          Duplicate
        </Popover.Action>
        {user.isMCZRUser && (
          <Popover.Action onClick={handleExport} aria-label="export product">
            Export
          </Popover.Action>
        )}
        <Popover.Action onClick={handleArchive} aria-label="archive product" className="text-tertiary-red-700">
          Archive
        </Popover.Action>

        {flags.product_delete.enabled && (
          <Popover.Action onClick={handleDelete} aria-label="delete product" className="text-tertiary-red-700">
            Delete
          </Popover.Action>
        )}
      </Popover>

      {archiveModal.isVisible && (
        <ArchiveProductModal
          productName={product!.name}
          onArchive={archive}
          onClose={archiveModal.close}
          {...archiveModal.modalProps}
        />
      )}

      {exportModal.isVisible && (
        <ExportProductModal
          productName={product!.name}
          hasDraft={!!product!.draft}
          onExport={onExport}
          onClose={exportModal.close}
          {...exportModal.modalProps}
        />
      )}

      {product && deleteModal.isVisible && (
        <DeleteProductModal
          product={product}
          onClose={deleteModal.close}
          onDeleted={() => history.push(`${baseUrl}/products`)}
          {...deleteModal.modalProps}
        />
      )}
    </>
  )
}

export default TopBarProductActions
