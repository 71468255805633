import classNames from 'classnames'
import React from 'react'

import TableHeaderCell from './TableHeaderCell'

const TableGroupHeaderCell = ({
  className,
  ...rest
}: React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) => (
  <TableHeaderCell {...rest} className={classNames(className, '!bg-white')} />
)

export default TableGroupHeaderCell
