import { Asset } from '@packages/types'
import React from 'react'

import UploadIcon from 'icons/bold/05-Internet-Networks-Servers/08-Upload-Download/upload-thick-bottom.svg'
import classMerge from 'utils/classMerge'

import useFilesUploader from '../hooks/useFilesUploader'
import FileInfo from './FileInfo'

interface FileUploaderProps {
  file?: Asset | null
  dropText: string
  extensions: string[]
  onChange: (asset: Asset | null) => void
}

function FileUploader({ file, dropText, extensions, onChange }: FileUploaderProps) {
  const fileUploader = useFilesUploader({ extensions, onFilesUploaded: assets => onChange(assets?.[0] || null) })

  return (
    <div
      className={classMerge('w-full min-h-[60px] rounded-md border border-dashed', {
        'bg-neutral-200 bg-opacity-20 border-transparent': file?.url,
        'border-[gainsboro]': !file?.url,
        'bg-white': !file?.url && !fileUploader.isDraggingOver,
        'bg-neutral-75': fileUploader.isDraggingOver,
        'cursor-pointer': !fileUploader.isLoading,
      })}
      onClick={e => {
        if (fileUploader.isLoading) return
        e.stopPropagation()
        fileUploader.trigger()
      }}
      {...fileUploader.droppableAreaProps}
    >
      {file?.url ? (
        <FileInfo
          id={file.id}
          filename={file.originalFilename}
          onDeleteClick={() => onChange(null)}
          url={file.url}
          date={file.date}
          mimetype={file.mimetype}
        />
      ) : (
        <div className="w-full h-full min-h-[60px] flex flex-row items-center justify-center text-center text-neutral-300 text-[0.825rem]">
          {fileUploader.isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <div className="mr-2 [&>svg]:w-4 [&>svg]:h-4 [&>svg]:fill-[rgba(0,0,0,0.16)]">
                <UploadIcon />
              </div>
              <div>
                <span>{dropText}&nbsp;</span>
                <span className="font-medium text-primary-600">browse</span>
              </div>
            </>
          )}
        </div>
      )}
      <input name="assets-uploader" data-testid="file-upload" {...fileUploader.fileInputProps} />
    </div>
  )
}

export default FileUploader
