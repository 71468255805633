import { Addon } from '@packages/types'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'

const useAddonService = () => {
  return {
    fetchAll: createQuery(['addons'], async () => {
      const { data } = await apiClient.get<Addon[]>('/addons')
      return data
    }),
  }
}

export default useAddonService
