import React from 'react'

import classMerge from 'utils/classMerge'

import UniversalSearchListHighlightableText from './UniversalSearchListHighlightableText'
import UniversalSearchListResult from './UniversalSearchListResult'
import UniversalSearchListSection from './UniversalSearchListSection'
import UniversalSearchListSupportingText from './UniversalSearchListSupportingText'
import UniversalSearchListTitle from './UniversalSearchListTitle'

const UniversalSearchList = ({ className, children, ...props }: React.ComponentProps<'div'>) => {
  return (
    <div {...props} role="list" className={classMerge('py-2.5 flex flex-col overflow-y-auto', className)}>
      {children}
    </div>
  )
}

UniversalSearchList.Section = UniversalSearchListSection
UniversalSearchList.Title = UniversalSearchListTitle
UniversalSearchList.Result = UniversalSearchListResult
UniversalSearchList.HighlightableText = UniversalSearchListHighlightableText
UniversalSearchList.SupportingText = UniversalSearchListSupportingText

export default UniversalSearchList
