import { Quote, WooCommerceOnlineStore } from '@packages/types'
import React from 'react'

type QuoteWithWooCommerceStore = Omit<Quote, 'store'> & { store: WooCommerceOnlineStore }

export type QuoteWooCommerceOrderCardProps = {
  quote: QuoteWithWooCommerceStore
}

const QuoteWooCommerceOrderCard = ({ quote }: QuoteWooCommerceOrderCardProps) => {
  if (!quote.integration?.wooCommerce?.url) return null
  return (
    <span className="pr-3 mr-3 divide-x border-r border-neutral-100">
      WooCommerce order:
      <a
        className="text-primary-600 pl-1"
        href={quote.integration.wooCommerce.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        #{quote.integration.wooCommerce.orderId}
      </a>
    </span>
  )
}

export default QuoteWooCommerceOrderCard
