import { useContext } from 'react'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import TenantContext from 'common/tenant/TenantContext'
import type { WordFilter } from './../types/wordFilter'

const useWordFilterService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetch: createQuery(['word-filter', tenant], async () => {
      const { data } = await apiClient.get<WordFilter>(`/brands/${tenant}/wordfilter`)
      return data
    }),
    update: async (wordFilter: Partial<WordFilter>) => {
      const { data } = await apiClient.post<WordFilter>(`/brands/${tenant}/wordfilter`, wordFilter)
      return data
    },
  }
}

export default useWordFilterService
