import { Asset } from '@packages/types'
import React from 'react'

import { FileType, getFileType } from 'common/assets/utils/getFileType'
import withFlag from 'common/users/components/withFlag'
import AudioFileIcon from 'icons/bold/14-Music-Audio/18-Audio-Files/audio-file-volume-alternate.svg'
import VideoFileIcon from 'icons/bold/15-Video-Movies-TV/08-Video-Files/video-file-play-alternate.svg'
import ImageLoader, { dimensionStrings } from 'utils/loaders/ImageLoader'

interface FileThumbnailProps {
  file: Asset
}

const FileThumbnail = ({ file }: FileThumbnailProps) => {
  const splittedUrl = file.url.split('.')
  const extension = splittedUrl[splittedUrl.length - 1]

  const type = getFileType(extension)

  return (
    <span className="w-6 h-6 mr-2 shrink-0 rounded overflow-hidden border border-solid border-neutral-75">
      {type === FileType.Audio && <AudioFileIcon className="object-center p-1 h-full w-full border-neutral-100" />}

      {type === FileType.Video && <VideoFileIcon className="object-center p-1 h-full w-full border-neutral-100" />}

      {type === FileType.Image && (
        <img
          src={ImageLoader.buildSrc(file.url, { preventResize: false, dimensionString: dimensionStrings.regular })}
          className="object-cover h-full w-full"
        />
      )}
    </span>
  )
}

export default withFlag({
  Component: FileThumbnail,
  feature: 'audio_video_uploader',
})
