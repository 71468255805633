import { Part, Question, QuestionType } from '@packages/types'
import React from 'react'
import { useSelector } from 'react-redux'

import * as questionsSelectors from 'builder/build/questions/selectors'
import * as constants from 'common/customizerProducts/constants'

import SelectOrCreateQuestionMenu from '../SelectOrCreateQuestionMenu'

interface ApplyOnSubMenuProps {
  parts: Part[]
  questionType: QuestionType
  onChange: (part: Part) => void
  onCreateQuestion: () => void
}

const ApplyOnSubMenu: React.FC<ApplyOnSubMenuProps> = ({ parts, questionType, onChange, onCreateQuestion }) => {
  const questions = useSelector(questionsSelectors.questionsSelector)

  const handleChange = (question: Question) => {
    const part = parts.find(part => {
      const partMainQuestionField = constants.parts.mainQuestionFields[part.type]
      const mainQuestionId = part[partMainQuestionField] as string
      return mainQuestionId === question.id
    })

    if (part) onChange(part)
  }

  const selectableQuestions = parts.map(part => {
    const partMainQuestionField = constants.parts.mainQuestionFields[part.type]
    const mainQuestionId = part[partMainQuestionField] as string
    return questions[mainQuestionId]
  })

  return (
    <SelectOrCreateQuestionMenu
      questionType={questionType}
      questions={selectableQuestions}
      onSelectQuestion={handleChange}
      onCreateQuestion={onCreateQuestion}
    />
  )
}

export default ApplyOnSubMenu
