import { BrandUserRole } from '@packages/types'
import React from 'react'
import { useParams } from 'react-router-dom'

import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import { NetworkError } from 'common/api/types/error'
import Button from 'common/components/Button'
import IconButton from 'common/components/IconButton'
import Modal from 'common/components/modal/Modal'
import Popover from 'common/components/popover/Popover'
import useModal from 'common/components/modal/useModal'
import usePopover from 'common/components/popover/usePopover'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import useGoBack from 'common/hooks/useGoBack'
import { trpc } from 'common/hooks/trpc'
import * as usersUtils from 'common/users/utils'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'

import ChangePasswordForm from './ChangePasswordForm'
import EditAccountForm from './EditAccountForm'

const roleOptions = [{ value: BrandUserRole.Admin, label: 'Admin' }]

const EditUser = () => {
  const { id } = useParams<{ id: string }>()
  const goBack = useGoBack()
  const { openToast, openGenericErrorToast } = useToast()
  const popover = usePopover({ placement: 'bottom-end', offsetConfig: 8 })
  const deleteUserModal = useModal()
  const trpcContext = trpc.useContext()

  const { data: user, isLoading } = trpc.user.get.useQuery(id)

  const { mutate: updateUser } = trpc.user.update.useMutation({
    onSuccess: updatedUser => {
      trpcContext.user.get.setData(id, updatedUser)
      openToast('User was successfully updated!', ToastType.success)
    },
    onError: (error: NetworkError, { changeSet: { email } }) => {
      if (error.data?.code === 'CONFLICT') {
        openToast(`The email ${email} is already in use by another user`, ToastType.warning)
      } else {
        openGenericErrorToast('User has not been updated.')
      }
    },
  })

  const { mutate: updateUserPassword } = trpc.user.changePassword.useMutation({
    onSuccess: () => openToast('User password was successfully changed!', ToastType.success),
    onError: () => openGenericErrorToast('Password has not been changed.'),
  })

  const { mutate: deleteUser, isLoading: isDeletingUser } = trpc.user.delete.useMutation({
    onSuccess: () => {
      openToast('User was successfully deleted!', ToastType.success)
      goBack()
    },
    onError: (error: NetworkError) => {
      if (error.data?.code === 'CONFLICT') {
        openToast('Could not delete user. There must be at least one user.', ToastType.warning)
      } else {
        openGenericErrorToast('User has not been deleted.')
      }
    },
  })

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      {user && !isLoading && (
        <>
          <Page>
            <Page.Header>
              <div className="flex items-center">
                <IconButton Icon={LeftArrow} onClick={goBack} className="mr-2" aria-label="Go back" />
                <h1>
                  {user.firstName}&nbsp;{user.lastName}
                </h1>
              </div>
              {user.role === BrandUserRole.Admin && (
                <div className="flex relative">
                  <IconButton {...popover.referenceProps} Icon={MoreIcon} aria-label="More options" />
                  <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
                    <Popover.Action
                      className="text-tertiary-red-700"
                      onClick={() => {
                        popover.close()
                        deleteUserModal.open()
                      }}
                    >
                      Delete
                    </Popover.Action>
                  </Popover>
                </div>
              )}
            </Page.Header>

            <Page.Section>
              <Page.Aside title="Account" description="Generic informations, we won't show this to anyone" />
              <Page.Content>
                <EditAccountForm
                  user={user}
                  onSubmit={(values, helpers) =>
                    updateUser(
                      { id, changeSet: { ...values, role: values.role as BrandUserRole } },
                      { onSettled: () => helpers.setSubmitting(false) }
                    )
                  }
                  roleOptions={roleOptions}
                />
              </Page.Content>
            </Page.Section>

            <Page.Separator />

            <Page.Section>
              <Page.Aside title="Change password" description="Don't forget it after changing it!" />
              <Page.Content>
                <ChangePasswordForm
                  onSubmit={(values, helpers) =>
                    updateUserPassword(
                      { id, password: values.password },
                      {
                        onSettled: () => helpers.setSubmitting(false),
                        onSuccess: () => helpers.resetForm(),
                      }
                    )
                  }
                />
              </Page.Content>
            </Page.Section>
          </Page>

          {deleteUserModal.isVisible && (
            <Modal {...deleteUserModal.modalProps}>
              <Modal.CloseButton onClick={deleteUserModal.close} />
              <Modal.Title>Delete user</Modal.Title>

              <Modal.Details>
                Are you sure you want to delete the user {usersUtils.getFullName(user)}? This action cannot be undone.
              </Modal.Details>

              <Modal.Actions>
                <Button
                  type="button"
                  variant="default"
                  className="px-4"
                  onClick={deleteUserModal.close}
                  disabled={isDeletingUser}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="error"
                  className="px-4"
                  onClick={() => deleteUser(id)}
                  isLoading={isDeletingUser}
                  disabled={isDeletingUser}
                >
                  Delete
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </>
      )}
    </main>
  )
}

export default EditUser
