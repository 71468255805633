import {
  EntityType,
  AnswerType,
  Answer,
  OverflowBehavior,
  Dimensions,
  PrintAreaTextPositionAnswer,
  PrintAreaLogoPositionAnswer,
} from '@packages/types'
import { without, mapValues, omit, cloneDeep } from 'lodash'
import { AnyAction } from 'redux'

import * as viewsTypes from 'builder/build/settings/actionTypes'
import * as constants from 'common/customizerProducts/constants'

import * as types from './actionTypes'

const DEFAULT_ANSWERS = constants.answers.default
const DEFAULT_DIMENSIONS = constants.defaultDimensions

export interface State {
  [key: string]: Answer
}

const addViewToAnswer = (answer: Answer) => {
  if (!answer.views) return answer

  switch (answer.type) {
    case AnswerType.Material:
    case AnswerType.Color:
    case AnswerType.Text:
    case AnswerType.Logo:
    case AnswerType.Font:
    case AnswerType.FontSize:
    case AnswerType.Position:
    case AnswerType.LogoPosition:
    case AnswerType.Outline:
      return { ...answer, views: [...answer.views, { ...answer.views[0] }] } as typeof answer
    case AnswerType.Image:
      return { ...answer, views: [...answer.views, { type: answer.type }] } as typeof answer
    default:
      return answer
  }
}

const reorderAnswerViews = (answer: Answer, originalIndex: number, newIndex: number) => {
  if (!answer.views) return answer

  const views = [...answer.views]
  const movedView = views[originalIndex]
  const reorderedViews = without(views, movedView)
  reorderedViews.splice(newIndex, 0, movedView)
  return { ...answer, views: reorderedViews } as typeof answer
}

type BuildAnswer = { id: string; type: AnswerType; name?: string }

export const buildAnswer = ({ id, type, name = 'Untitled answer' }: BuildAnswer) =>
  ({
    id,
    entityType: EntityType.Answer,
    thumbnail: null,
    description: '',
    type,
    name,
    showDescription: false,
  }) as Answer

const answerFactory = (payload: {
  id: string
  type: AnswerType
  name: string
  views: Answer['views']
  view: any
  productDimensions: Dimensions
  position?: PrintAreaTextPositionAnswer['position'] | PrintAreaLogoPositionAnswer['position']
}): Answer => {
  const answer = buildAnswer(payload)
  const defaultViewData = payload.view ?? {}

  switch (answer.type) {
    case AnswerType.Image:
    case AnswerType.Material:
    case AnswerType.Text:
    case AnswerType.FontSize:
    case AnswerType.Color:
    case AnswerType.Outline:
    case AnswerType.Logo:
      answer.views = Array.from(Array(payload.views)).map(() => ({
        ...DEFAULT_ANSWERS[answer.type],
        ...defaultViewData,
      }))
      break
    case AnswerType.LogoPosition: {
      const width = payload.productDimensions.width ?? DEFAULT_DIMENSIONS.width
      const height = payload.productDimensions.height ?? DEFAULT_DIMENSIONS.height

      const logoX = width / 2
      const logoY = height / 2

      answer.views = Array.from(Array(payload.views)).map((_, i) => ({
        ...DEFAULT_ANSWERS[answer.type],
        x: logoX,
        y: logoY,
        hidden: i !== 0,
        ...defaultViewData,
      }))
      break
    }
    case AnswerType.Position: {
      const width = payload.productDimensions.width ?? DEFAULT_DIMENSIONS.width
      const height = payload.productDimensions.height ?? DEFAULT_DIMENSIONS.height

      const bezierX = width / 2 - 75
      const bezierY = height / 2

      const textAreaX = width / 2
      const textAreaY = height / 2

      answer.views = Array.from(Array(payload.views)).map((_, i) => ({
        ...DEFAULT_ANSWERS[answer.type],
        bezier: [bezierX, bezierY, bezierX, bezierY, bezierX + 150, bezierY, bezierX + 150, bezierY],
        x: textAreaX,
        y: textAreaY,
        overflowBehavior: OverflowBehavior.Clip,
        hidden: i !== 0,
        ...defaultViewData,
      }))
      break
    }
    case AnswerType.Font:
      answer.views = Array.from(Array(payload.views)).map(() => ({
        ...DEFAULT_ANSWERS[AnswerType.Font],
        font: payload.id,
        ...defaultViewData,
      }))
      break
    case AnswerType.PrintAreaTextPosition:
    case AnswerType.PrintAreaLogoPosition:
      answer.position = payload.position!
      break
  }

  return answer
}

const changeAnswersType = (
  state: State,
  payload: { ids: string[]; type: AnswerType; views: Answer['views'] }
): State => {
  return payload.ids.reduce((state: State, id: string) => {
    const answer = cloneDeep(state[id])
    answer.type = payload.type

    switch (answer.type) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      case AnswerType.Logo: {
        delete answer.value
      }
      case AnswerType.Image:
      case AnswerType.Font:
      case AnswerType.Text:
      case AnswerType.FontSize:
      case AnswerType.Outline:
      case AnswerType.Position:
      case AnswerType.Logo:
      case AnswerType.LogoPosition: {
        const views = Array.from(Array(payload.views)).map(() => DEFAULT_ANSWERS[answer.type]) as typeof answer.views
        answer.views = views
        break
      }
      case AnswerType.Color:
      case AnswerType.Material: {
        const views = Array.from(Array(payload.views)).map((_, i) => {
          const previousView = answer.views?.[i]

          return {
            ...DEFAULT_ANSWERS[AnswerType.Color],
            ...(previousView.color ? { color: previousView.color } : {}),
          }
        }) as typeof answer.views
        answer.views = views
        break
      }
      default:
        delete answer.views
    }
    return { ...state, [id]: answer }
  }, state)
}

export default (state: State = {}, action: AnyAction): State => {
  switch (action.type) {
    case types.CREATE_ANSWER:
    case types.CREATE_ANSWER_PREVIEW:
    case types.ADD_ANSWER:
      return { ...state, [action.payload.id]: answerFactory(action.payload) }
    case types.DELETE_ANSWER:
      return omit(state, action.payload.answerId)
    case types.ARCHIVE_ANSWER: {
      const answer = state[action.payload.answerId]
      return { ...state, [action.payload.answerId]: { ...answer, archived: true } }
    }
    case types.RESTORE_ANSWER: {
      const answer = state[action.payload.answerId]
      return { ...state, [action.payload.answerId]: { ...answer, archived: false } }
    }
    case types.COPY_ANSWERS:
      return {
        ...state,
        ...action.payload.answerIds.reduce((newAnswers: { [key: string]: Answer }, answerId: string, index: number) => {
          const answer = state[answerId]
          const newAnswerId = action.payload.newAnswerIds[index]
          return { ...newAnswers, [newAnswerId]: { ...answer, id: newAnswerId } }
        }, {}),
      }
    case types.DUPLICATE_ANSWER:
      return {
        ...state,
        [action.payload.newAnswerId]: { ...state[action.payload.answerId], id: action.payload.newAnswerId },
      }
    case viewsTypes.ADD_VIEW:
      return mapValues(state, answer => addViewToAnswer(answer))
    case viewsTypes.DELETE_VIEW:
      return mapValues(state, answer => {
        if (!answer.views) return answer

        const views = [...answer.views]
        views.splice(action.payload.viewIndex, 1)
        return { ...answer, views } as typeof answer
      })
    case viewsTypes.REORDER_VIEWS:
      return mapValues(state, answer => {
        return reorderAnswerViews(answer, action.payload.originalIndex, action.payload.newIndex)
      })
    case types.CHANGE_TYPE:
      return changeAnswersType(state, action.payload)
    case types.UNLINK_ANSWER:
      return {
        ...state,
        [action.payload.newAnswerId]: { ...state[action.payload.answerId], id: action.payload.newAnswerId },
      }
    default:
      return state
  }
}
