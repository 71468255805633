import { OnlineStore } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import ECommerceLogo from 'cms/onlineStores/components/ECommerceLogo'
import { isCustomStore, isShopify } from 'cms/onlineStores/utils'
import CardTabs from 'common/components/tabs/CardTabs'
import Tag from 'common/components/Tag'
import Tooltip from 'common/components/Tooltip'

export interface OnlineStoreCardProps {
  onlineStore: OnlineStore
  selected: boolean
}

const OnlineStoreCard = ({ onlineStore, selected }: OnlineStoreCardProps) => {
  const shopUrl = isShopify(onlineStore) || isCustomStore(onlineStore) ? onlineStore.domain : onlineStore.url
  return (
    <CardTabs.Tab isSelected={selected}>
      <CardTabs.LeftIconContainer>
        <ECommerceLogo eCommerce={onlineStore.eCommerce} className="basis-4 shrink-0 w-4 h-4" />
      </CardTabs.LeftIconContainer>
      <CardTabs.TextContainer>
        <Tooltip content={onlineStore.name} containerClassName="truncate">
          <CardTabs.PrimaryText>{onlineStore.name}</CardTabs.PrimaryText>
        </Tooltip>
        <span className="text-xs text-neutral-600 overflow-hidden whitespace-nowrap text-ellipsis">
          <Tooltip
            content={shopUrl}
            containerClassName={classNames('text-xs truncate', {
              'text-neutral-600': !selected,
              'text-primary-default': selected,
            })}
          >
            {shopUrl}
          </Tooltip>
        </span>
      </CardTabs.TextContainer>
      <div className="basis-8 shrink-0 ml-auto">
        <Tag className="bg-neutral-100 text-neutral-600 text-[10px] mt-0.5 ml-1">{onlineStore.currency}</Tag>
      </div>
    </CardTabs.Tab>
  )
}

export default OnlineStoreCard
