import classNames from 'classnames'
import React from 'react'

import PatchTextInput from 'builder/build/common/components/PatchTextInput'
import HorizontalField from 'builder/build/common/components/HorizontalField'
import VerticalFields from 'builder/build/common/components/VerticalFields'
import { useSelector, useDispatch } from 'cms/hooks'
import { min } from 'utils/validators'

import { updateDimensions } from '../actions'
import { productSettingsSelector } from '../selectors'

const maxSize = 1200

const ProductSettingsCanvasSizeSection = () => {
  const dispatch = useDispatch()
  const productSettings = useSelector(productSettingsSelector)

  const onChangeDimensions = (key: 'width' | 'height', value: string | number) => {
    const newValue = value !== '' && +value > 0 ? +value : undefined
    if (newValue === undefined) return

    dispatch(
      updateDimensions({
        [key]: newValue,
      })
    )
  }

  return (
    <div className="py-6 px-4">
      <div className="flex items-center justify-center mb-4">
        <span className="mr-auto">Canvas size</span>
      </div>
      <div className="text-xs text-neutral-400 mb-6">Your canvas size must match the size of your images.</div>

      <VerticalFields>
        <HorizontalField>
          <label className="flex items-center text-sm font-normal leading-6" htmlFor="canvasWidth">
            Width
          </label>
          <PatchTextInput
            filled={false}
            value={productSettings.width}
            type="number"
            id="canvasWidth"
            name="canvasWidth"
            className={classNames('w-24', {
              'patch-text-input--danger': productSettings.width > maxSize,
            })}
            onChange={e => onChangeDimensions('width', e.target.value)}
            min="0"
            validator={min(0)}
            inputClassName="appearance-number-none"
            rightAddon="px"
          />
        </HorizontalField>
        <HorizontalField>
          <label className="flex items-center text-sm font-normal leading-6" htmlFor="canvasHeight">
            Height
          </label>
          <PatchTextInput
            filled={false}
            value={productSettings.height}
            type="number"
            id="canvasHeight"
            name="canvasHeight"
            className={classNames('w-24', {
              'patch-text-input--danger': productSettings.width > maxSize,
            })}
            rightAddon="px"
            onChange={e => onChangeDimensions('height', e.target.value)}
            min="0"
            inputClassName="appearance-number-none"
            validator={min(0)}
          />
        </HorizontalField>
        {(productSettings.width > maxSize || productSettings.height > maxSize) && (
          <div className="text-xs text-warning-dark">
            For performance reasons, the maximum recommended size is 1200x1200px.
          </div>
        )}
      </VerticalFields>
    </div>
  )
}

export default ProductSettingsCanvasSizeSection
