import { PartType } from '@packages/types'
import { deburr } from 'lodash'
import React, { useState } from 'react'

import Card from 'builder/build/common/components/cards/Card'
import IconButton from 'common/components/IconButton'
import Button from 'common/components/Button'
import Checkbox from 'common/components/inputs/Checkbox'
import Input from 'common/components/inputs/Input'
import XIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/close.svg'

import TypeIcon from '../TypeIcon'

type Props = {
  masks: {
    name: string
    id: string
  }[]
  initialSelectedMasks: string[]
  onApply: (masks: string[]) => void
}

const MaskMenu = ({ masks, initialSelectedMasks, onApply }: Props) => {
  const [filter, setFilter] = useState('')
  const [selectedMasks, setSelectedMasks] = useState(initialSelectedMasks)

  const toggleAnswer = (maskId: string) => {
    if (selectedMasks.includes(maskId)) return setSelectedMasks(selectedMasks.filter(id => id !== maskId))
    setSelectedMasks([...selectedMasks, maskId])
  }

  const filteredMasks = masks.filter(mask => deburr(mask.name.toLowerCase()).includes(deburr(filter.toLowerCase())))

  return (
    <>
      <div className="popper-menu-header-section">
        <div className="popper-menu-header-section__title">Edit mask</div>
      </div>
      <div className="px-4 my-2">
        <Input
          placeholder="Search layers..."
          value={filter}
          onChange={e => setFilter(e.target.value)}
          rightAddon={
            filter !== '' && (
              <IconButton
                small
                variant="subtle"
                className="-mr-2"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setFilter('')
                }}
                Icon={XIcon}
              />
            )
          }
        />
      </div>
      <div className="max-h-96 overflow-auto">
        {masks.length === 0 && <div className="ml-4 mb-4 mt-2">You don't have any layers, yet</div>}
        {filteredMasks.map(mask => (
          <Card className="mx-2 mb-2" onClick={() => toggleAnswer(mask.id)}>
            <TypeIcon entityType="DISPLAY_TYPE" specificType={PartType.Image} className="w-5 h-5 rounded p-1 mr-2" />
            <label className="mr-auto hover:cursor-pointer">{mask.name}</label>
            <Checkbox checked={selectedMasks.includes(mask.id)} aria-label={mask.name} />
          </Card>
        ))}
      </div>
      <div className="popper-menu-footer-section">
        <Button variant="primary" onClick={() => onApply(selectedMasks)}>
          Apply
        </Button>
      </div>
    </>
  )
}

export default MaskMenu
