import classNames from 'classnames'
import React, { useContext, useRef, useState } from 'react'

import ContainerContext from './ContainerContext'

export interface ContainerProps extends React.PropsWithChildren {
  x: number
  y: number
  width: number
  height: number
  rotation?: number
  scale?: number
}

const Container = ({ x, y, width, height, rotation = 0, scale = 1, children }: ContainerProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, forceUpdate] = useState(0)
  const { scale: parentScale, isRoot } = useContext(ContainerContext)

  const absoluteScale = scale * parentScale

  const scaledX = x * parentScale
  const scaledY = y * parentScale
  const scaledWidth = width * parentScale
  const scaledHeight = height * parentScale

  return (
    <ContainerContext.Provider
      value={{
        scale: absoluteScale,
        containerRef: ref,
        isRoot: false,
      }}
    >
      <div
        ref={el => {
          const prev = ref.current
          ref.current = el
          if (prev !== el) forceUpdate(1)
        }}
        className={classNames('rounded-sm', {
          absolute: !isRoot,
          fixed: isRoot,
          'z-[3]': isRoot,
        })}
        style={{
          boxShadow: '0px 0px 0px 1px rgba(75,85,99)',
          width: `${scaledWidth}px`,
          height: `${scaledHeight}px`,
          left: `${scaledX}px`,
          top: `${scaledY}px`,
          transform: `rotate(${rotation}deg)`,
          transformOrigin: 'top left',
          pointerEvents: 'all',
        }}
      >
        {children}
      </div>
    </ContainerContext.Provider>
  )
}

export default Container
