import classNames from 'classnames'
import React from 'react'

const PageContent = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div {...props} className={classNames('relative w-full md:w-3/5 2xl:w-1/2', className)}>
    {children}
  </div>
)

export default PageContent
