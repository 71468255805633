import { Answer } from '@packages/types'
import React, { useEffect, useRef } from 'react'

import Panel from 'builder/build/common/components/panels/Panel'
import PopperPanelHeader from 'builder/build/common/components/panels/MovablePanel/PopperPanelHeader'
import Button from 'common/components/Button'

import AnswerPanelFactory from './AnswerPanelFactory'

import './AnswerPanel.scss'

const portal = document.createElement('div')
document.body.appendChild(portal)

interface CreateAnswerPanelProps {
  answer: Answer
  cancel: () => void
  create: () => void
}

const CreateAnswerPanel = ({ answer, cancel, create }: CreateAnswerPanelProps) => {
  const shallCancel = useRef(true)
  useEffect(() => {
    return () => {
      if (shallCancel.current) cancel()
    }
  }, [])
  return (
    <Panel side="right" className="flex flex-col flex-grow max-h-full overflow-hidden builder">
      <PopperPanelHeader>
        <div className="popper-menu-header-section__title">Create answer</div>
      </PopperPanelHeader>
      <AnswerPanelFactory answer={answer} />
      <div className="popper-menu-footer-section">
        <Button
          onClick={() => {
            shallCancel.current = false
            cancel()
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            shallCancel.current = false
            create()
          }}
        >
          Create
        </Button>
      </div>
    </Panel>
  )
}

export default CreateAnswerPanel
