import classNames from 'classnames'
import React from 'react'

export interface ModalDividerProps {
  className?: string
}

const ModalDivider = ({ className }: ModalDividerProps) => (
  <hr className={classNames('w-full border-b border-neutral-75', className)} />
)

export default ModalDivider
