const imageToDataURL = img => {
  const canvas = document.createElement('canvas')
  canvas.width = img.naturalWidth
  canvas.height = img.naturalHeight

  canvas.getContext('2d').drawImage(img, 0, 0)

  return canvas.toDataURL('image/png')
}

export default imageToDataURL
