import { Answer, Rule } from '@packages/types'

const doesRuleContainsArchivedAnswers = (rule: Rule, answers: Record<string, Answer>) => {
  const thenHasArchivedAnswers = rule.then.reduce((acc, { payload, answerId }) => {
    const payloadHasArchivedValues = !!payload?.some(item => !!answers[item]?.archived)
    const singleValueIsArchived = !!answerId && !!answers[answerId]?.archived

    return payloadHasArchivedValues || singleValueIsArchived || acc
  }, false)

  const whenHasArchivedAnswers = rule.when.reduce((acc, { value }) => {
    if (!value) return acc

    const containsArchived = Array.isArray(value)
      ? value.some(item => !!answers[item]?.archived)
      : !!answers[value]?.archived

    return containsArchived || acc
  }, false)

  return thenHasArchivedAnswers || whenHasArchivedAnswers
}

export default doesRuleContainsArchivedAnswers
