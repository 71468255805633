import classNames from 'classnames'
import React from 'react'

interface HorizontalFieldProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: [React.ReactNode, React.ReactNode]
}

const HorizontalField = ({ children, className }: HorizontalFieldProps) => {
  return (
    <div className={classNames('flex items-center h-8', className)}>
      <div className="mr-auto">{children[0]}</div>
      <div className="w-20 flex justify-end">{children[1]}</div>
    </div>
  )
}

export default HorizontalField
