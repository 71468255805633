import { OnlineStore, StartingPoint, DenormalizedProduct } from '@packages/types'
import { formatDistanceToNow } from 'date-fns'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Tooltip from 'common/components/Tooltip'
import Button from 'common/components/Button'
import IconButton from 'common/components/IconButton'
import Popover from 'common/components/popover/Popover'
import useModal from 'common/components/modal/useModal'
import usePopover from 'common/components/popover/usePopover'
import Tag from 'common/components/Tag'
import NavVerticalIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import AlertCircle from 'icons/regular/01-Interface Essential/14-Alerts/alert-circle.svg'
import imageLoader, { dimensionStrings } from 'utils/loaders/ImageLoader'

import ListItem from './listItems/ListItem'
import RemoveStartingPointModal from './modals/RemoveStartingPointModal'

const MESSAGE: { [x: string]: string } = {
  product:
    'This starting point is not up-to-date with your current product and may display incorrectly.\nChanges to your product may be the cause.\nEditing your starting point will solve this problem.',
  rule: 'Your starting point configuration conflicts \nwith a rule. One or more answer cannot be selected by a rule.',
}

interface StoreCardProps {
  startingPoint: StartingPoint
  product: DenormalizedProduct
  warningMessage: string | null
  onlineStores: OnlineStore[]
}

interface MatchParams {
  productId: string
  brandName: string
}

const StoreCard = ({ product, warningMessage, startingPoint, onlineStores }: StoreCardProps) => {
  const history = useHistory()
  const match = useRouteMatch<MatchParams>()
  const removeStartingPointModal = useModal()
  const popover = usePopover({ placement: 'bottom' })

  let previewImageSrc = startingPoint.previewImage
  if (previewImageSrc) {
    previewImageSrc = imageLoader.buildSrc(previewImageSrc, { dimensionString: dimensionStrings.large })
  }

  const handleEditInBuilder = () => {
    history.push(`${match.url.split('/').slice(0, -1).join('/')}/builder`)
  }

  const handleEditSartingPoint = () =>
    history.push(`${match.url.split('/').slice(0, -1).join('/')}/startingpoints/${startingPoint.id}`)

  const handlePreviewStartingPoint = (startingPointId: string) => {
    const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : '/admin'
    window.open(`${baseUrl}/preview?productId=${match.params.productId}&startingPointId=${startingPointId}`, '_blank')
  }

  return (
    <>
      <div className="flex w-full rounded-xl bg-white shadow-sm mb-8 p-6">
        <div className="pr-6 h-[275px] flex justify-center w-1/3">
          <LazyLoadImage src={previewImageSrc} crossOrigin="anonymous" className="rounded-lg object-cover" />
        </div>
        <div className="border border-neutral-75" />
        <div className="pl-4 flex flex-col flex-1">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <h3 className="font-bold mb-1 flex">
                {startingPoint.name}
                {!startingPoint.isDefault && !startingPoint.upToDate && warningMessage && (
                  <Tooltip content={MESSAGE[warningMessage]}>
                    <AlertCircle className="w-4 h-4 ml-2" />
                  </Tooltip>
                )}
              </h3>
              <span className="text-xs text-neutral-300">
                Updated {formatDistanceToNow(new Date(startingPoint.updatedAt), { addSuffix: true })}
              </span>
              {startingPoint.isDefault && (
                <Tag className="w-fit mt-4 bg-tertiary-green-75 text-tertiary-green-600">Default</Tag>
              )}
            </div>
            {startingPoint.isDefault ? (
              <Button onClick={handleEditInBuilder}>Edit in builder</Button>
            ) : (
              <>
                <IconButton
                  {...popover.referenceProps}
                  onClick={e => {
                    e.stopPropagation()
                    popover.referenceProps.onClick(e)
                  }}
                  Icon={NavVerticalIcon}
                  role="button"
                  aria-label="Open starting point actions"
                  className="fill-black p-[9px]"
                />
                <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
                  <Popover.Action
                    onClick={() => {
                      handleEditSartingPoint()
                      popover.close()
                    }}
                    aria-label="edit starting point"
                  >
                    Edit
                  </Popover.Action>
                  <Popover.Action
                    onClick={() => {
                      handlePreviewStartingPoint(startingPoint.id)
                      popover.close()
                    }}
                    aria-label="preview starting point"
                  >
                    Preview
                  </Popover.Action>
                  <Popover.Action
                    onClick={() => {
                      removeStartingPointModal.open()
                      popover.close()
                    }}
                    className="text-tertiary-red-700"
                    aria-label="remove starting point"
                  >
                    Remove
                  </Popover.Action>
                </Popover>
              </>
            )}
          </div>
          <div className="flex flex-col space-y-2 mt-4">
            {onlineStores.map(onlineStore => {
              return (
                <ListItem
                  key={`onlinestore-${onlineStore.id}`}
                  onlineStore={onlineStore}
                  startingPoint={startingPoint}
                  productId={match.params.productId}
                />
              )
            })}
          </div>
        </div>
      </div>

      {removeStartingPointModal.isVisible && (
        <RemoveStartingPointModal
          onClose={removeStartingPointModal.close}
          productId={product.id}
          startingPointName={startingPoint.name}
          startingPointId={startingPoint.id}
          {...removeStartingPointModal.modalProps}
        />
      )}
    </>
  )
}

export default StoreCard
