import classNames from 'classnames'
import React from 'react'

interface VerticalFieldsProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode
}

const VerticalFields = ({ children, className = '' }: VerticalFieldsProps) => {
  return <div className={classNames('flex flex-col space-y-4', className)}>{children}</div>
}

export default VerticalFields
