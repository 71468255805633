import { EntityType, QuestionType } from '@packages/types'
import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import VisualEditorActionBar from 'builder/build/navigation/components/VisualEditorActionBar'
import EditPrintAreaActionBar from 'builder/build/navigation/components/EditPrintAreaActionBar'
import ViewSettings from 'builder/build/navigation/components/ViewSettings'
import * as partsSelectors from 'builder/build/parts/selectors'
import * as previewActions from 'builder/build/preview/actions'
import * as previewSelectors from 'builder/build/preview/selectors'
import { useSelector, useDispatch } from 'cms/hooks'
import PreviewModeControl from 'common/preview/components/PreviewModeControl'
import { PreviewMode } from 'common/preview/types/previewMode'
import * as customizationActions from 'customizer/customization/actions'

import { entitiesSelector } from '../selectors'

const EDITABLE_QUESTION_TYPES = [
  QuestionType.Text,
  QuestionType.Color,
  QuestionType.Font,
  QuestionType.FontSize,
  QuestionType.Outline,
  QuestionType.Logo,
]

const BuilderToolBar = () => {
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const params = useParams<{ id: 'string' }>()
  const entities = useSelector(state => entitiesSelector(state, [params.id]))
  const part = useSelector(state => partsSelectors.partFromQuestionIdSelector(state, params.id))
  const printAreaMatch = useRouteMatch('/questions/:questionId/print-area')
  const isPrintAreaOpen = !!printAreaMatch?.isExact
  const previewMode = useSelector(previewSelectors.previewModeSelector)
  const disabledPreviewModes = useSelector(previewSelectors.disabledPreviewModesSelector)
  const isPreviewReady = useSelector(previewSelectors.isPreviewReadySelector)
  const isOpenEntityAnEditableQuestion =
    part && entities?.[0]?.entityType === EntityType.Question && EDITABLE_QUESTION_TYPES.includes(entities?.[0]?.type)

  const handleCheckClick = () => customizerDispatch(customizationActions.stopEditPart())

  const handleMoveClick = () => customizerDispatch(customizationActions.editPart(part!.id))

  const handlePreviewModeChange = (newMode: PreviewMode) => {
    dispatch(previewActions.changePreviewMode(newMode))
    customizerDispatch(previewActions.changePreviewMode(newMode))
    customizerDispatch(customizationActions.stopEditPart())
  }

  return (
    <div className="flex justify-center">
      <div className="flex items-center my-2 border bg-white border-solid border-neutral-75 rounded-md shadow-xs">
        <PreviewModeControl
          selectedMode={previewMode}
          availableModes={[PreviewMode.DESKTOP, PreviewMode.MOBILE, PreviewMode.PRODUCT]}
          disabledModes={disabledPreviewModes}
          disabled={!isPreviewReady}
          onChange={handlePreviewModeChange}
        />
        <div className="h-8 w-[1px] bg-neutral-50 mr-3" />
        <ViewSettings />
        {!isPrintAreaOpen && isOpenEntityAnEditableQuestion && (
          <VisualEditorActionBar part={part} onCloseClick={handleCheckClick} onMoveClick={handleMoveClick} />
        )}
        {part && isPrintAreaOpen && <EditPrintAreaActionBar printAreaId={part.printArea!} />}
      </div>
    </div>
  )
}

export default BuilderToolBar
