import { AdminUser } from '@packages/types'

import type { CreateInvitedUserFormValues, UserInviteFormValues } from 'cms/users/types/form'
import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'

export type GenerateImpersonationTokenParams = { tenant: string; userId: string }
export type GenerateImpersonationTokenResponse = { token: string }

const useAdminUserInviteService = () => {
  return {
    fetchInvitedUsers: createQuery(['invited-admin-users'], async () => {
      const { data } = await apiClient.get<AdminUser[]>(`/admin/users/invite`)
      return data
    }),
    invite: async (formValues: UserInviteFormValues) => {
      const { data } = await apiClient.post<AdminUser>(`/admin/users/invite`, formValues)
      return data
    },
    updateToken: async (email: string) => {
      const { data } = await apiClient.post<AdminUser>(`/admin/users/resend-invite`, { email })
      return data
    },
    delete: (id: string) => {
      return apiClient.delete<void, void>(`/admin/users/invite/${id}`)
    },
    validateInviteToken: createQuery(['validate-invite-token'], async (token: string) => {
      return apiClient.get(`/admin/users/accept-admin-invite/?token=${token}`)
    }),
    acceptInvite: async (token: string, formValues: CreateInvitedUserFormValues) => {
      const { data } = await apiClient.post<string>(`/admin/users/accept-admin-invite/?token=${token}`, formValues)
      return data
    },
  }
}

export default useAdminUserInviteService
