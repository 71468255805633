import { DemoProduct, DenormalizedProduct, Product } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import React, { useContext, useEffect, useRef } from 'react'
import { useStore } from 'react-redux'
import { Link, MemoryRouter, Route, RouteComponentProps } from 'react-router-dom'

import * as coreSelectors from 'builder/build/core/selectors'
import * as coreUtils from 'builder/build/core/utils'
import CustomizerIframeProvider from 'builder/build/customizer/CustomizerIframeProvider'
import * as customizerProductsActions from 'builder/build/customizerProducts/actions'
import * as customizerProductsHooks from 'builder/build/customizerProducts/hooks/index'
import BuilderErrorBoundary from 'builder/build/customizerProducts/components/BuilderErrorBoundary'
import BuilderLeftPanel from 'builder/build/customizerProducts/components/BuilderLeftPanel'
import BuilderRightPanel from 'builder/build/customizerProducts/components/BuilderRightPanel'
import BuilderToolBar from 'builder/build/customizerProducts/components/BuilderToolBar'
import DemoUseAsTemplateDiscard from 'builder/build/customizerProducts/components/DemoUseAsTemplateDiscard'
import PublishDiscard from 'builder/build/customizerProducts/components/PublishDiscard'
import Editor from 'builder/build/editor/components/Editor'
import * as navigationSelectors from 'builder/build/navigation/selectors'
import BuilderNavigator from 'builder/build/navigation/components/BuilderNavigator'
import BuilderUniversalSearch from 'builder/build/navigation/components/BuilderUniversalSearch/BuilderUniversalSearch'
import { BuilderMode } from 'builder/build/navigation/types/builderMode'
import OnBoardingBuilder from 'builder/build/onboarding/components/OnBoardingBuilder'
import * as previewActions from 'builder/build/preview/actions'
import * as previewSelectors from 'builder/build/preview/selectors'
import builderToPreviewMiddleware from 'builder/build/preview/builderToPreviewMiddleware'
import previewToBuilderMiddleware from 'builder/build/preview/previewToBuilderMiddleware'
import previewCustomizationReducer from 'builder/build/preview/previewCustomizationReducer'
import previewChoicePanelReducer from 'builder/build/preview/previewChoicePanelReducer'
import previewDisplayerReducer from 'builder/build/preview/previewDisplayerReducer'
import builderToPreviewBulkOrderMiddleware from 'builder/build/preview/builderToPreviewBulkOrderMiddleware'
import syncConfigurationMiddleware from 'builder/build/questions/syncConfigurationMiddleware'
import Rules from 'builder/build/rules/components/Rules'
import Loader from 'builder/common/components/Loader'
import GlobalRouterContext, { MatchParams } from 'builder/common/GlobalRouterContext'
import TopBar from 'builder/topBar/components/TopBar'
import TopBarSection from 'common/components/topBar/TopBarSection'
import TopBarProductName from 'builder/topBar/components/TopBarProductName'
import TopBarBackButton from 'builder/topBar/components/TopBarBackButton'
import TopBarTabs from 'builder/topBar/components/TopBarTabs'
import TopBarProductActions from 'builder/topBar/components/TopBarProductActions'
import { useDispatch, useSelector } from 'cms/hooks'
import IconButton from 'common/components/IconButton'
import Tooltip from 'common/components/Tooltip'
import { trpc } from 'common/hooks/trpc'
import PreviewIframe from 'common/preview/components/PreviewIframe'
import useProductService from 'common/products/hooks/useProductService'
import * as customizationActions from 'customizer/customization/actions'
import EyeIcon from 'icons/core-solid/interface-essential/interface-edit-view.svg'
import { EnhancedStore } from 'redux-inject-reducer'

import ImageSizeContextProvider from './ImageSizeContextProvider'

import './Builder.scss'

const ProductBuilder = () => {
  const dispatch = useDispatch()
  const { match: appMatch } = useContext(GlobalRouterContext)
  const productService = useProductService()

  const { data: product, isLoading: isLoadingProduct } = useQuery(
    [...productService.fetch.queryKeys, appMatch.params.productId, { fields: ['live', 'draft'] }],
    () => productService.fetch(appMatch.params.productId, { params: { fields: ['live', 'draft'] } }),
    {
      enabled: !!appMatch.params.productId,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        const payload = { productName: data.name, customizerProduct: data.draft ?? data.live }
        dispatch(customizerProductsActions.startEditing(payload))
      },
    }
  )

  return <Builder product={product} isLoadingProduct={isLoadingProduct} />
}

const DemoBuilder = () => {
  const dispatch = useDispatch()
  const { match: appMatch } = useContext(GlobalRouterContext)

  const { data: demo, isLoading: isLoadingDemo } = trpc.demoProduct.get.useQuery(appMatch.params.productId, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onSuccess: data => {
      const payload = { productName: data.name, customizerProduct: data.live }
      dispatch(customizerProductsActions.startEditing(payload))
    },
  })

  return <Builder product={demo} isLoadingProduct={isLoadingDemo} />
}

const Builder = ({
  product,
  isLoadingProduct,
}: {
  product: DenormalizedProduct | Product | DemoProduct | undefined
  isLoadingProduct: boolean
}) => {
  const dispatch = useDispatch()
  const store = useStore() as EnhancedStore
  const { match: appMatch } = useContext(GlobalRouterContext)
  const baseUrl = appMatch.params.brandName ? `/brands/${appMatch.params.brandName}` : '/admin'
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const productBuilder = useSelector(coreSelectors.productBuilderSelector)
  const customizerProduct = useSelector(coreSelectors.customizerProductSelector)
  const previewMode = useSelector(previewSelectors.previewModeSelector)
  const isPreviewReady = useSelector(previewSelectors.isPreviewReadySelector)
  const builderMode = useSelector(navigationSelectors.builderModeSelector)
  const isDemoProduct = coreUtils.isPathDemoProduct(appMatch.path)

  customizerProductsHooks.useAutoSave(
    appMatch.params.productId,
    !product || !customizerProduct?.id || (product as Product).archived || !!(product as DemoProduct).demoAttributes
  )

  const handleIFrameLoaded = () => {
    const iframeStore = iframeRef.current?.contentWindow?.customizerApp?.store

    if (!iframeStore) return

    iframeStore.injectReducer({
      customization: previewCustomizationReducer,
      questionPanel: previewChoicePanelReducer,
      displayer: previewDisplayerReducer,
    })
    store.addMiddleware(builderToPreviewBulkOrderMiddleware(iframeStore), builderToPreviewMiddleware(iframeStore))
    iframeStore.addMiddleware(previewToBuilderMiddleware(store))
    iframeStore.dispatch(customizationActions.startCustomization({ customizerProduct }))
    iframeStore.dispatch(previewActions.updatePreview(productBuilder))
    dispatch(previewActions.setIsPreviewReady(true))
  }

  const handleIFrameUnloaded = () => {
    iframeRef.current?.contentWindow?.customizerApp?.unmount()
    dispatch(previewActions.setIsPreviewReady(false))
  }

  useEffect(() => {
    store.addMiddleware(syncConfigurationMiddleware)

    return () => {
      store.removeMiddleware(syncConfigurationMiddleware)
      dispatch(customizerProductsActions.reset())
      dispatch(previewActions.setIsPreviewReady(false))
    }
  }, [])

  if (isLoadingProduct || !product || !customizerProduct) return <Loader />

  return (
    <>
      {!isPreviewReady && <Loader />}
      <ImageSizeContextProvider>
        <CustomizerIframeProvider iframeRef={iframeRef} store={iframeRef.current?.contentWindow?.customizerApp?.store}>
          <div className="builder flex flex-grow flex-col h-screen">
            <TopBar>
              <TopBarSection className="pl-5">
                <TopBarBackButton />
                <TopBarProductName />
                <TopBarProductActions />
              </TopBarSection>
              <TopBarTabs />
              <TopBarSection right>
                <BuilderUniversalSearch />
                <BuilderNavigator />
                <div className="top-bar__vertical-divider top-bar__vertical-divider--small-left-margin" />
                <div className="flex space-x-2 mr-4">
                  <Tooltip content="Preview" containerClassName="flex">
                    <Link to={`${baseUrl}/preview?productId=${appMatch.params.productId}`} target="_blank">
                      <IconButton Icon={EyeIcon} onClick={e => e.stopPropagation()} />
                    </Link>
                  </Tooltip>
                  {isDemoProduct ? (
                    <DemoUseAsTemplateDiscard />
                  ) : (
                    !(product as Product).archived && <PublishDiscard productId={appMatch.params.productId} />
                  )}
                </div>
              </TopBarSection>
            </TopBar>
            <div className="builder__panels-container bg-neutral-50 mt-[52px]">
              <BuilderLeftPanel />
              {builderMode === BuilderMode.logic && (
                <div className="builder__center-section">
                  <Rules />
                </div>
              )}
              <div
                className={classNames('builder__center-section', {
                  'builder__center-section--hidden': builderMode === BuilderMode.logic,
                })}
              >
                {builderMode === BuilderMode.customizer && <BuilderToolBar />}
                <div className="flex flex-grow bg-neutral-50 relative max-w-full max-h-full pb-12 px-2 xl:px-4 2xl:px-8 3xl:px-12">
                  <PreviewIframe
                    ref={iframeRef}
                    src={
                      appMatch.params.brandName
                        ? `${location.origin}/customize/preview?tenant=${appMatch.params.brandName}`
                        : `${location.origin}/customize/preview`
                    }
                    className="shadow rounded-lg"
                    previewMode={previewMode}
                    onLoad={handleIFrameLoaded}
                    onUnload={handleIFrameUnloaded}
                  />
                </div>
                <Editor key={previewMode} />
              </div>
              <BuilderRightPanel />
            </div>
          </div>
          <OnBoardingBuilder />
        </CustomizerIframeProvider>
      </ImageSizeContextProvider>
    </>
  )
}

const BuilderRouter = ({ history, location, match }: RouteComponentProps<MatchParams>) => {
  const BuilderEntryPoint = coreUtils.isPathDemoProduct(match.path) ? DemoBuilder : ProductBuilder

  return (
    <GlobalRouterContext.Provider value={{ history, location, match }}>
      <MemoryRouter initialEntries={['/']} initialIndex={0}>
        <Route
          exact
          path={[
            '/',
            '/questions/:id',
            '/questions/:id/answers/import',
            '/questions/:questionId/answers/:id',
            '/questions/:questionId/printareas/:id',
            '/questions/:id/print-area',
            '/groups/:id',
            '/submit-action',
          ]}
          render={() => (
            <BuilderErrorBoundary>
              <BuilderEntryPoint />
            </BuilderErrorBoundary>
          )}
        />
      </MemoryRouter>
    </GlobalRouterContext.Provider>
  )
}

export default BuilderRouter
