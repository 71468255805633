import { PaymentStrategy, ShopifyOnlineStore, WixOnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import Card from 'common/components/card/Card'
import FullPageModal from 'common/components/modal/FullPageModal'
import { ModalProps } from 'common/components/modal/Modal'
import useSubscriptionService from './../../hooks/useSubscriptionService'
import AddonsList from './../cards/AddonsList'
import PlanCard from './../cards/PlanCard'
import ShopifyActivationCard from './../cards/ShopifyActivationCard'
import StripeActivationCard from './../cards/StripeActivationCard'
import StripeActivationFeedbackCard from './../cards/StripeActivationFeedbackCard'
import WixActivationCard from './../cards/WixActivationCard'
import SubscriptionCheckList from '../SubscriptionCheckList'
import SubscriptionQuestionAndAnswer from '../SubscriptionQuestionAndAnswer'
import { customizationExperiencesAdvantages, adminTools, questionAnswerTuples } from './subscriptionPageData'

interface FullPageBillingPlansProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
}

const ActivateCustomPlanModal = ({ onClose, ...modalProps }: FullPageBillingPlansProps) => {
  const [showStripeActivationFeedBack, setShowStripeActivationFeedBack] = useState(false)

  const subscriptionService = useSubscriptionService()
  const onlineStoreService = useOnlineStoreService()

  const { data: subscription, refetch: refetchSubscription } = useQuery(
    subscriptionService.fetchOne.queryKeys,
    subscriptionService.fetchOne
  )
  const { data: onlineStores } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll, {
    initialData: [],
  })

  const handleClose = () => {
    refetchSubscription()
    onClose()
  }

  if (!subscription || !onlineStores) return null

  let totalCard = null

  if (subscription.paymentStrategy === PaymentStrategy.Shopify)
    totalCard = (
      <ShopifyActivationCard
        onlineStores={onlineStores as ShopifyOnlineStore[]}
        plan={subscription.plan}
        subscription={subscription}
        addons={[]}
      />
    )
  if (subscription.paymentStrategy === PaymentStrategy.Wix)
    totalCard = (
      <WixActivationCard
        onlineStores={onlineStores as WixOnlineStore[]}
        plan={subscription.plan}
        subscription={subscription}
        addons={[]}
        onSuccess={handleClose}
      />
    )
  if (subscription.paymentStrategy === PaymentStrategy.Stripe)
    totalCard = (
      <StripeActivationCard
        plan={subscription.plan}
        addons={[]}
        onSuccess={() => setShowStripeActivationFeedBack(true)}
      />
    )

  return (
    <FullPageModal className="flex" onClose={handleClose} {...modalProps}>
      <div className="flex max-w-4xl items-start w-[410px] md:w-auto">
        {!showStripeActivationFeedBack && (
          <div className="flex-col">
            <div className="flex items-start space-x-6 mt-4">
              <div className="w-full flex flex-col">
                <div className="w-full items-center md:items-start md:flex-row flex md:space-x-6 md:space-y-0 flex-col space-y-6">
                  <PlanCard
                    plan={subscription.plan}
                    addonsSubscribed={[]}
                    addons={
                      <AddonsList availableAddons={[]} subscriptionAddons={[]} onAdd={() => {}} onRemove={() => {}} />
                    }
                  />
                  <div className="basis-1/2 shrink-0">
                    {totalCard}
                    <div className="flex flex-col space-y-3 pt-8 px-4 font-p2">
                      <span>* All prices are in US dollars</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-8 space-y-16">
              <Card shadow={false} className="flex-col border border-neutral-100 w-full">
                <Card.Section>
                  <h1>What's included</h1>
                </Card.Section>
                <Card.Section className="pt-2">
                  <div className="grid grid-cols-2 gap-12 w-full">
                    <SubscriptionCheckList
                      list={customizationExperiencesAdvantages.checklist}
                      title={customizationExperiencesAdvantages.title}
                    />
                    <SubscriptionCheckList list={adminTools.checklist} title={adminTools.title} />
                  </div>
                </Card.Section>
              </Card>
              <div>
                <h1 className="pb-5">Frequently asked questions</h1>
                <div className="grid grid-cols-2 gap-12 pb-20">
                  {questionAnswerTuples.map((item, index) => {
                    return <SubscriptionQuestionAndAnswer key={index} question={item.question} answer={item.answer} />
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {showStripeActivationFeedBack && <StripeActivationFeedbackCard />}
      </div>
    </FullPageModal>
  )
}

export default ActivateCustomPlanModal
