import React, { forwardRef } from 'react'

import Card from 'builder/build/common/components/cards/Card'
import TypeIcon from 'builder/build/common/components/TypeIcon'
import classMerge from 'utils/classMerge'

import { PartLayer } from '../types'

export type PartLayerCardProps = {
  isDragged: boolean
  isDragOverlay: boolean
  layer: PartLayer
}

const PartLayerCard = forwardRef<HTMLDivElement, PartLayerCardProps>(
  ({ isDragged, isDragOverlay, layer, ...props }, ref) => (
    <Card
      className={classMerge('bg-white', {
        'opacity-50': isDragged,
        'opacity-80': isDragOverlay,
        'mx-2': !isDragOverlay,
      })}
      ref={ref}
      {...props}
    >
      <TypeIcon
        entityType="DISPLAY_TYPE"
        specificType={layer.type}
        className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem] rounded p-1 mr-2"
      />
      <span>{layer.name}</span>
    </Card>
  )
)

export default PartLayerCard
