import { PrestaShopOnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import Tooltip from 'common/components/Tooltip'
import WarningIcon from 'icons/bold/01-Interface Essential/14-Alerts/alert-circle.svg'

import usePrestashopProxyService from './../../hooks/usePrestashopProxyService'

type PrestashopOnlineStoreConnectionStatusProps = {
  onlineStore: PrestaShopOnlineStore
}

const PrestashopOnlineStoreConnectionStatus = ({ onlineStore }: PrestashopOnlineStoreConnectionStatusProps) => {
  const prestashopService = usePrestashopProxyService()

  const { isError } = useQuery(
    [...prestashopService.test.queryKeys, onlineStore.id],
    () => {
      return prestashopService.test(onlineStore.id)
    },
    {
      retry: false,
    }
  )
  if (isError) {
    return (
      <Tooltip content="Connection issue">
        <WarningIcon aria-label="Connection issue" className="fill-secondary-orange-200 ml-1 relative h-4 w-4" />
      </Tooltip>
    )
  }
  return null
}

export default PrestashopOnlineStoreConnectionStatus
