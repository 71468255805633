import { customizerProduct } from '@packages/normalizer'
import { Answer, AnswerType, CustomizerProduct, NormalizedCustomizerProduct, PositionView } from '@packages/types'

const { Normalizer } = customizerProduct

export const normalize = (customizerProduct: CustomizerProduct) => {
  return {
    rules: {},
    questions: {},
    parts: {},
    groups: {},
    answers: {},
    printAreas: {},
    ...(Normalizer.run(customizerProduct, 'customizerProducts') as Partial<NormalizedCustomizerProduct>),
  } as NormalizedCustomizerProduct
}

export const isMultilineTextAnswer = (answer: Answer | undefined) => {
  return answer?.type === AnswerType.PrintAreaTextPosition
    ? !!answer?.position?.shape
    : !!answer?.views?.find(view => (view as PositionView).shape)
}
