import React from 'react'

export interface GroupAddSectionProps {
  children: React.ReactNode
  label: string
}

const GroupAddSection = ({ children, label }: GroupAddSectionProps) => {
  return (
    <div className="flex mx-4 mb-2 items-center">
      <div className="font-medium">{label}</div>
      <div className="ml-auto">{children}</div>
    </div>
  )
}

export default GroupAddSection
