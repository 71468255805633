import { Group } from '@packages/types'
import { without } from 'lodash'
import { AnyAction } from 'redux'

import { getDeletionUpdatedForceView, getReorderingUpdatedForceView } from 'builder/build/core/utils'
import * as questionsTypes from 'builder/build/questions/actionTypes'
import * as viewsTypes from 'builder/build/settings/actionTypes'
import { insert, remove } from 'utils/arrayUtils'

import * as types from './actionTypes'

export interface State {
  [key: string]: Group
}

const addChild = (state: State, action: AnyAction): State => {
  return Object.keys(state).reduce((nextState, groupId) => {
    const group = state[groupId]

    if (groupId === action.payload.parentId)
      return {
        ...nextState,
        [groupId]: {
          ...group,
          children:
            action.payload.index == null
              ? [...group.children, action.payload.childId]
              : insert(group.children, action.payload.index, action.payload.childId),
        },
      }

    return {
      ...nextState,
      [groupId]: {
        ...group,
        children: group.children.filter(childId => childId !== action.payload.childId),
      },
    }
  }, {})
}

const moveChild = (state: State, action: AnyAction): State => {
  const sourceParentId = action.payload.source.parentId
  const sourceIndex = action.payload.source.index
  const destinationParentId = action.payload.destination.parentId
  const destinationIndex = action.payload.destination.index

  const childId = state[sourceParentId].children[sourceIndex]

  const stateWithUpdatedSource = childId
    ? {
        ...state,
        [sourceParentId]: {
          ...state[sourceParentId],
          children: remove(state[sourceParentId].children, sourceIndex),
        },
      }
    : state

  return {
    ...stateWithUpdatedSource,
    [destinationParentId]: {
      ...stateWithUpdatedSource[destinationParentId],
      children: insert(
        stateWithUpdatedSource[destinationParentId].children,
        destinationIndex ?? stateWithUpdatedSource[destinationParentId].children.length - 1,
        childId
      ),
    },
  }
}

const moveBehindTheScene = (state: State, action: AnyAction): State => {
  return Object.keys(state).reduce((nextState, groupId) => {
    if (groupId === action.payload) return nextState

    return {
      ...nextState,
      [groupId]: {
        ...state[groupId],
        children: state[groupId].children.filter(childId => childId !== action.payload),
      },
    }
  }, {})
}

const createGroup = (state: State, action: AnyAction): State => ({
  ...state,
  [action.payload.id]: action.payload,
})

const deleteGroup = (state: State, action: AnyAction): State => {
  return Object.keys(state).reduce((nextState, groupId) => {
    if (groupId === action.payload) return nextState

    return {
      ...nextState,
      [groupId]: {
        ...state[groupId],
        children: without(state[groupId].children, action.payload),
      },
    }
  }, {})
}

const removeQuestion = (state: State, action: AnyAction): State => {
  return Object.values(state).reduce((newState, group) => {
    const newGroup = { ...group }

    newGroup.children = newGroup.children.filter(childId => childId !== action.payload.questionId)

    return { ...newState, [newGroup.id]: newGroup }
  }, {})
}

const reorderViewsForceViewsUpdate = (state: State, action: AnyAction): State => {
  const { originalIndex, newIndex } = action.payload

  return Object.keys(state).reduce((newState, id) => {
    const groupForceView = state[id].forceView

    return {
      ...newState,
      [id]: {
        ...state[id],
        forceView: getReorderingUpdatedForceView(originalIndex, newIndex, groupForceView),
      },
    }
  }, {})
}

const deleteViewForceViewsUpdate = (state: State, action: AnyAction): State => {
  const { viewIndex } = action.payload
  return Object.keys(state).reduce((newState, id) => {
    const groupForceView = state[id].forceView

    return {
      ...newState,
      [id]: {
        ...state[id],
        forceView: getDeletionUpdatedForceView(viewIndex, groupForceView),
      },
    }
  }, {})
}

const updateGroup = (state: State, action: AnyAction): State => {
  return {
    ...state,
    [action.payload.id]: {
      ...state[action.payload.id],
      ...action.payload.update,
    },
  }
}

export default (state: State = {}, action: AnyAction): State => {
  switch (action.type) {
    case types.CREATE_GROUP:
      return createGroup(state, action)
    case types.DELETE_GROUP:
      return deleteGroup(state, action)
    case types.MOVE_BEHIND_THE_SCENE:
      return moveBehindTheScene(state, action)
    case types.ADD_CHILD:
      return addChild(state, action)
    case types.MOVE_CHILD:
      return moveChild(state, action)
    case types.UPDATE_GROUP:
      return updateGroup(state, action)
    case questionsTypes.DELETE_QUESTION:
      return removeQuestion(state, action)
    case viewsTypes.REORDER_VIEWS:
      return reorderViewsForceViewsUpdate(state, action)
    case viewsTypes.DELETE_VIEW:
      return deleteViewForceViewsUpdate(state, action)
    default:
      return state
  }
}
