import React from 'react'

import BlankState from 'common/components/blankState/BlankState'
import SkateboardIcon from 'icons/custom/bold-skateboard.svg'

export type DeletedBlankStateProps = {
  onClick: () => void
}

const DeletedBlankState = ({ onClick }: DeletedBlankStateProps) => {
  return (
    <>
      <BlankState.Icon Icon={SkateboardIcon} className="text-neutral-300" />
      <BlankState.Title>There are no deleted products, yet</BlankState.Title>
      <BlankState.Details>
        Once you delete products they will be shown here so Kickflip admins can restore them.
      </BlankState.Details>
      <BlankState.Action onClick={onClick}>Go to active products</BlankState.Action>
    </>
  )
}

export default DeletedBlankState
