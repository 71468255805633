import { Part, PartType } from '@packages/types'
import { createSelector } from 'reselect'

import * as coreUtils from 'builder/build/core/utils'
import createCustomizerProductEntitySelectors from 'builder/build/core/createCustomizerProductEntitySelectors'
import * as questionsSelectors from 'builder/build/questions/selectors'
import { RootState } from 'cms/store'
import * as constants from 'common/customizerProducts/constants'

import { getPartQuestions } from './utils'

type ProductBuilderWithPartName = Omit<RootState['productBuilder'], 'parts'> & {
  parts: { [key: string]: Part }
}

const { partByIdSelector, partsSelector, partsAsArraySelector } = createCustomizerProductEntitySelectors(
  'parts',
  (productBuilder): ProductBuilderWithPartName => {
    return {
      ...productBuilder,
      parts: Object.values(productBuilder.parts).reduce((parts, part) => {
        if (!part) return parts

        const mainQuestionId = part[constants.parts.mainQuestionFields[part.type]]

        if (!mainQuestionId) return parts

        return { ...parts, [part.id]: { ...part, name: productBuilder.questions[mainQuestionId].name } }
      }, {}),
    }
  }
)

export { partByIdSelector, partsSelector, partsAsArraySelector }

export const partQuestionIdsSelector = createSelector(
  partsSelector,
  questionsSelectors.questionsSelector,
  (_: RootState, partId?: string) => partId,
  (parts, questions, partId) => {
    return partId ? getPartQuestions(parts[partId], questions).map(({ id }) => id) : []
  }
)

export const questionSiblingsSelector = createSelector(
  partsAsArraySelector,
  (_: RootState, questionId: string) => questionId,
  (state: RootState) => state,
  (parts, questionId, state) => {
    const part = coreUtils.getPartFromQuestion(questionId, parts)

    if (!part) return []

    return partQuestionsSelector(state, part.id)
  }
)

export const partQuestionsSelector = createSelector(
  partQuestionIdsSelector,
  questionsSelectors.questionsSelector,
  (questionIds, questions) => {
    return questionIds.map(questionId => questions[questionId])
  }
)

export const masksSelector = createSelector(partsSelector, parts => {
  return Object.values(parts).filter(part => part.type === PartType.Image)
})

export const partFromQuestionIdSelector = createSelector(
  partsAsArraySelector,
  (_: RootState, questionId: string) => questionId,
  (parts, questionId) => {
    return coreUtils.getPartFromQuestion(questionId, parts) as Part | undefined
  }
)
