import React from 'react'

import ArrowLeft from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'

import Button from '../Button'
import IconButton from '../IconButton'
import ModalActions from './ModalActions'

export interface ModalStepperFooterProps {
  stepCount: number
  currentStep: number
  setStep: (step: number) => void
  done: () => void
}

const ModalStepperFooter = ({ stepCount, currentStep, setStep, done }: ModalStepperFooterProps) => {
  return (
    <ModalActions>
      <IconButton Icon={ArrowLeft} onClick={() => setStep(currentStep - 1)} aria-label="Previous step" />
      <span className="mx-2 flex items-center">
        {currentStep + 1} of {stepCount}
      </span>
      {currentStep !== stepCount - 1 && (
        <Button variant="primary" onClick={() => setStep(currentStep + 1)}>
          Next
        </Button>
      )}
      {currentStep === stepCount - 1 && (
        <Button variant="primary" onClick={done}>
          Done
        </Button>
      )}
    </ModalActions>
  )
}

export default ModalStepperFooter
