import { Collision, DndContext, DndContextProps, useDndContext } from '@dnd-kit/core'
import React, { createContext, useState } from 'react'

import { FlattenedItem, TreeItem } from './types'
import { getProjection } from './utilities'

export interface SortableTreesContextProps<TreeItemData extends Record<string, any>> extends DndContextProps {
  flattenedItems: FlattenedItem<TreeItemData>[]
  currentCollision: React.MutableRefObject<Collision | undefined>
  indentationWidth: number
}

interface TreeProjectedContextProps<TreeItemData extends Record<string, any>> extends React.PropsWithChildren {
  flattenedItems: FlattenedItem<TreeItemData>[]
  offsetLeft: number
  currentCollision: React.MutableRefObject<Collision | undefined>
  indentationWidth: number
}

export const ProjectedContext = createContext<{
  projected: ReturnType<typeof getProjection<any>> | undefined
  flattenedItems: TreeItem<any>[]
  currentCollision: React.MutableRefObject<Collision | undefined>
}>({
  projected: undefined,
  flattenedItems: [],
  currentCollision: { current: undefined },
})

const TreeProjectedContext = <TreeItemData extends Record<string, any>>({
  flattenedItems,
  children,
  offsetLeft,
  currentCollision,
  indentationWidth,
}: TreeProjectedContextProps<TreeItemData>) => {
  const { over, active } = useDndContext()

  const projected = getProjection(
    flattenedItems,
    active?.id as string | undefined,
    over?.id as string | undefined,
    offsetLeft,
    indentationWidth
  )

  return (
    <ProjectedContext.Provider value={{ projected, flattenedItems, currentCollision }}>
      {children}
    </ProjectedContext.Provider>
  )
}

const SortableTreesContext = <TreeItemData extends Record<string, any>>({
  flattenedItems,
  children,
  currentCollision,
  indentationWidth,
  ...rest
}: SortableTreesContextProps<TreeItemData>) => {
  const [offsetLeft, setOffsetLeft] = useState(0)
  return (
    <DndContext {...rest} onDragMove={event => setOffsetLeft(event.delta.x)}>
      <TreeProjectedContext
        flattenedItems={flattenedItems}
        currentCollision={currentCollision}
        offsetLeft={offsetLeft}
        indentationWidth={indentationWidth}
      >
        {children}
      </TreeProjectedContext>
    </DndContext>
  )
}

export default SortableTreesContext
