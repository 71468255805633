import { Group } from '@packages/types'
import React from 'react'

import PatchTextInput from 'builder/build/common/components/PatchTextInput'
import { PatchTextInputChangeEvent } from 'common/components/PatchTextInput'
import { useDispatch } from 'cms/hooks'
import InputField from 'common/components/inputs/InputField'

import { patchGroup } from '../actions'

interface TitleSectionProps {
  group: Group
}

const TitleSection = ({ group }: TitleSectionProps) => {
  const dispatch = useDispatch()

  const handleNameChange = (e: PatchTextInputChangeEvent) => dispatch(patchGroup(group.id, { name: e.target.value }))

  return (
    <div className="panel__section">
      <InputField>
        <label className="font-medium mb-4">Title</label>
        <PatchTextInput placeholder="Title" value={group.name} onChange={handleNameChange} />
      </InputField>
    </div>
  )
}

export default TitleSection
