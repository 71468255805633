import {
  Answer,
  DenormalizedCustomizableLogoPart,
  DenormalizedCustomizableTextPart,
  EntityType,
  PartType,
} from '@packages/types'

import * as customizationActions from 'customizer/customization/actions'
import * as customizationSelectors from 'customizer/customization/selectors'
import { AppDispatch, RootState } from 'customizer/store'
import isMobileDisplay from 'utils/isMobileDisplay'

import * as actionTypes from './actionTypes'
import { dimensionsSelector, drawingNodesSelector } from './selectors'
import ProductStage from './stage/ProductStage'
import type { Node } from './types/node'

export const selectStep = (partId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()
  const stepId = customizationSelectors.getStepIdFromPartSelector(state, { partId })!
  const stepForceView = customizationSelectors.firstLevelAncestorSelector(state, stepId)?.forceView
  const highlightGroup = customizationSelectors.partForceHighlightGroupSelector(state, partId)

  if (stepId) {
    dispatch({ type: actionTypes.SELECT_STEP, payload: { stepId } })

    if (stepForceView != null) dispatch(switchView(stepForceView))

    if (highlightGroup) {
      return dispatch(forceHighlight([highlightGroup]))
    }

    dispatch(setForceHighlight(null))
  }
}

export const updatePartPosition =
  (partId: string, position: Answer) => (dispatch: AppDispatch, getState: () => RootState) => {
    const part = customizationSelectors.partsSelector(getState()).find(part => part.id === partId) as
      | DenormalizedCustomizableLogoPart
      | DenormalizedCustomizableTextPart

    part.type === PartType.Logo
      ? dispatch(customizationActions.updatePrintAreaLogoPositionAnswer(part.position.id, position))
      : dispatch(customizationActions.updatePrintAreaTextPositionAnswer(part.position.id, position))
  }

export const scalePartFontSize =
  (partId: string, scale: number) => (dispatch: AppDispatch, getState: () => RootState) => {
    const part = customizationSelectors
      .partsSelector(getState())
      .find(part => part.id === partId) as DenormalizedCustomizableTextPart
    const answer = part.fontSize?.entityType === EntityType.Question ? part.fontSize.selectedAnswer : part.fontSize

    if (answer) {
      part.fontSize?.entityType === EntityType.Question && !answer.isPersonalisation
        ? dispatch(customizationActions.createFontSizeAnswer(part.fontSize.id, { scale }))
        : dispatch(customizationActions.updateFontSizeAnswer(answer.id, { scale }))
    }
  }

export const generateProductImage = (view: number) => {
  return async (_dispatch: AppDispatch | undefined, getState: () => RootState) => {
    const state = getState()

    const dimensions = dimensionsSelector(state)

    const renderer = new ProductStage({
      container: document.createElement('div'),
      width: dimensions.width,
      height: dimensions.height,
    })

    const viewContainer = renderer.renderViewContainer(view)

    await Promise.all(
      drawingNodesSelector(state, view).map((node: Node) => renderer.renderNode(node, viewContainer, false))
    )

    renderer.draw()

    const dataUrl = renderer.toDataURL()
    renderer.destroy()

    return dataUrl
  }
}

export const switchView = (view: number) => {
  return {
    type: actionTypes.SWITCH_VIEW,
    payload: view,
  }
}

export const setForceHighlight = (partIds: string[] | null) => {
  return {
    type: actionTypes.HIGHLIGHT_PART,
    payload: partIds,
  }
}

export const setZoom = (zoom: number) => {
  return { type: actionTypes.SET_ZOOM, payload: zoom }
}

export const disableZoom = () => {
  return { type: actionTypes.DISABLE_ZOOM }
}

export const enableZoom = () => {
  return { type: actionTypes.ENABLE_ZOOM }
}

export const nextView = () => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const { currentView } = state.displayer
    const { views } = state.customization.customizerProduct
    const nextView = currentView == views - 1 ? 0 : currentView + 1
    dispatch(setForceHighlight(null))
    dispatch(switchView(nextView))
  }
}

export const previousView = () => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const { currentView } = state.displayer
    const { views } = state.customization.customizerProduct
    const previousView = currentView == 0 ? views - 1 : currentView - 1
    dispatch(setForceHighlight(null))
    dispatch(switchView(previousView))
  }
}

export const forceHighlight = (partIds: string[] | null) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const { forceHighlight } = state.displayer
    if (partIds !== forceHighlight) {
      dispatch(setForceHighlight(partIds))
    }
  }
}

export const updatePrintArea =
  (printAreaId: string, view: number, update: { x: number; y: number; width: number; height: number }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const { scale = 1 } = dimensionsSelector(state)
    dispatch({
      type: actionTypes.UPDATE_PRINT_AREA,
      payload: {
        printAreaId,
        view,
        update: {
          ...update,
          x: update.x / scale,
          y: update.y / scale,
          width: update.width / scale,
          height: update.height / scale,
        },
      },
    })
  }

export const displayGrid = (display: boolean) => ({
  type: actionTypes.DISPLAY_GRID,
  payload: display,
})

export const updateRenderState = (value: boolean) => ({
  type: actionTypes.UPDATE_RENDER_STATE,
  payload: { value },
})

export const editPrintArea = (printAreaId: string | null) => {
  return {
    type: actionTypes.EDIT_PRINT_AREA,
    payload: printAreaId,
  }
}

export const setDisplayerSize = (size: { width: number; height: number }) => ({
  type: actionTypes.SET_DISPLAYER_SIZE,
  payload: size,
})

export const setIsMobile = (isMobile: boolean) => ({ type: actionTypes.SET_IS_MOBILE, payload: isMobile })

export const resizeDisplayer = () => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().displayer.isMobile !== isMobileDisplay()) {
      dispatch({ type: actionTypes.SET_IS_MOBILE, payload: isMobileDisplay() })
    }
  }
}
