import classNames from 'classnames'
import React from 'react'
import { useLocation, useParams } from 'react-router'

import usePopover from 'common/components/popover/usePopover'

import SideMenuGroupIconContainer from './SideMenuGroupIconContainer'
import SideMenuGroupPopoverLink from './SideMenuGroupPopoverLink'
import SideMenuGroupSecondaryLink from './SideMenuGroupSecondaryLink'

interface SideMenuChildrenProps {
  popover: ReturnType<typeof usePopover>
  isActive: boolean
}

export interface SideMenuGroupProps {
  className?: string
  children: (props: SideMenuChildrenProps) => JSX.Element
  paths: string[]
}

const SideMenuGroup = ({ children, className, paths }: SideMenuGroupProps) => {
  const popover = usePopover({ placement: 'right-start', offsetConfig: 8, trigger: 'hover' })
  const { pathname } = useLocation()
  const { brandName } = useParams<{ brandName?: string }>()
  const baseUrl = brandName ? `/brands/${brandName}` : ''

  const isActive = paths.some(path => pathname.replace(baseUrl, '').includes(path))

  return (
    <div aria-selected={isActive} className={classNames('group space-y-4', className)} {...popover.referenceProps}>
      {children({ popover, isActive })}
    </div>
  )
}

SideMenuGroup.SecondaryLink = SideMenuGroupSecondaryLink
SideMenuGroup.PopoverLink = SideMenuGroupPopoverLink
SideMenuGroup.IconContainer = SideMenuGroupIconContainer

export default SideMenuGroup
