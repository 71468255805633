import { useHistory, useLocation } from 'react-router'

const useGoBack = () => {
  const history = useHistory()
  const location = useLocation()

  const goBack = () => history.push(location.pathname.substring(0, location.pathname.lastIndexOf('/')))

  return goBack
}

export default useGoBack
