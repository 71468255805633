import React, { forwardRef } from 'react'

import classMerge from 'utils/classMerge'

export type CardProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'ref'> &
  React.PropsWithChildren & {
    onClick?: () => void
    className?: string
  }

const Card = forwardRef<HTMLDivElement, CardProps>(({ children, onClick, className = '', ...rest }, ref) => {
  return (
    <div
      ref={ref}
      {...rest}
      className={classMerge(
        'text-neutral-900 rounded-md px-2 hover:!cursor-pointer min-h-[32px]',
        'text-xs',
        'flex items-center',
        'hover:bg-neutral-50',
        { 'hover:!cursor-pointer': !!onClick },
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
})

export default Card
