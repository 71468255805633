import {
  Asset,
  AssetAnswer,
  supportedAudioExtensions,
  supportedImageExtensions,
  supportedVideoExtensions,
} from '@packages/types'
import React from 'react'

import { useDispatch } from 'cms/hooks'
import FileUploader from 'common/assets/components/FileUploader'
import withFlag from 'common/users/components/withFlag'

import { patchAnswer } from '../actions'
import GeneralSettingsSection from './sections/GeneralSettingsSection'
import TitleSection from './sections/TitleSection'

interface AssetAnswerPanelProps {
  answer: AssetAnswer
}

export const AssetAnswerPanel = ({ answer }: AssetAnswerPanelProps) => {
  const dispatch = useDispatch()

  const updateAnswer: GeneralSettingsSection['onUpdate'] = (field, value) => {
    dispatch(patchAnswer(answer, { [field]: value }))
  }

  return (
    <div className="panel__content" aria-label="Value answer panel">
      <TitleSection answer={answer} />
      <hr className="panel__divider" />
      <div className="panel__section">
        <div className="panel__group">
          <label className="mb-4 font-medium block">File</label>
          <FileUploader
            file={answer.value as Asset}
            dropText="Drop file, or"
            onChange={asset => dispatch(patchAnswer(answer, { value: asset }))}
            extensions={[...supportedImageExtensions, ...supportedAudioExtensions, ...supportedVideoExtensions]}
          />
        </div>
      </div>
      <hr className="panel__divider" />
      <GeneralSettingsSection answer={answer} onUpdate={updateAnswer} />
    </div>
  )
}

export default withFlag({
  Component: AssetAnswerPanel,
  feature: 'audio_video_uploader',
})
