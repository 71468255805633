import { Asset, FontType, supportedFontExtensions } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { SingleValue } from 'react-select'

import SelectAsync from 'common/components/inputs/select/SelectAsync'
import { trpc } from 'common/hooks/trpc'

import useFilesUploader from './../hooks/useFilesUploader'
import useFontOptionsAsync from './../hooks/useFontOptionsAsync'
import useFontService from './../hooks/useFontService'
import type { FontOption } from './../types/font'
import FontDropdownMenu from './FontDropdownMenu'
import FontMenuSingleValue from './FontMenuSingleValue'

export interface AsyncFontSelectProps {
  onUpload: (asset: Asset | null) => void
  onChange: (option: SingleValue<FontOption>) => void
  fontType?: FontType
  value?: FontOption
  className?: string
  id?: string
  name?: string
}

const AsyncFontSelect = ({ onUpload, fontType = FontType.Google, className, ...rest }: AsyncFontSelectProps) => {
  const [tab, setTab] = useState(fontType || FontType.Google)
  const fontService = useFontService()

  const { data: googleFonts } = useQuery(fontService.fetchAllGoogleFonts.queryKeys, fontService.fetchAllGoogleFonts, {
    initialData: [],
  })

  const { data: customFonts } = trpc.asset.listCustomFonts.useQuery(undefined, { initialData: [] })
  const { loadOptions, options } = useFontOptionsAsync(googleFonts, customFonts, tab)

  const fileUploader = useFilesUploader({
    extensions: supportedFontExtensions,
    onFilesUploaded: assets => {
      if (assets?.[0]) onUpload(assets[0])
    },
  })

  return (
    <>
      <input name="assets-uploader" data-testid="file-upload" {...fileUploader.fileInputProps} />
      <SelectAsync<FontOption>
        cacheUniqs={[tab]}
        className="w-full"
        defaultOptions
        key={options.length}
        loadOptions={loadOptions}
        menuPortalTarget={document.body}
        optimize
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          option: (provided, { data }) => ({
            ...provided,
            fontFamily: data.value,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }),
          menuList: provided => ({ ...provided, overflowX: 'hidden' }),
          singleValue: (provided, { data }) => ({ ...provided, fontFamily: data.value }),
          group: provided => ({ ...provided, paddingTop: 0 }),
        }}
        components={{
          SingleValue: FontMenuSingleValue,
          Menu: props => (
            <FontDropdownMenu onTabClick={setTab} onUploadClick={fileUploader.trigger} fontType={tab} {...props} />
          ),
          GroupHeading: () => null,
        }}
        {...rest}
      />
    </>
  )
}

export default AsyncFontSelect
