import classNames from 'classnames'
import React, { forwardRef } from 'react'

import classMerge from 'utils/classMerge'

import Spinner from './Spinner'

export type ButtonVariant =
  | 'default'
  | 'primary'
  | 'subtle'
  | 'toggle'
  | 'toggle-selected'
  | 'warning'
  | 'error'
  | 'green'
  | 'onColor'
  | 'text'
  | 'custom-color'
  | 'default-error'

export const classes = {
  primary: classNames(
    'shadow-xs',
    'bg-primary-500 text-white',
    'border-primary-600 border border-solid',
    'active:text-neutral-100',
    'focus:ring-primary-100 focus:border-primary-200 focus:ring',
    'hover:shadow'
  ),
  warning: classNames(
    'shadow-xs',
    'bg-white text-tertiary-red-700',
    'border-neutral-50 border',
    'active:text-tertiary-red-500',
    'focus:border-tertiary-red-500 focus:ring-tertiary-red-100 focus:ring',
    'hover:shadow'
  ),
  error: classNames(
    'shadow-xs',
    'bg-error-default text-white',
    'border-neutral-50 border',
    'active:text-error-light',
    'focus:ring-tertiary-red-100 focus:border-tertiary-red-500 focus:ring',
    'hover:shadow'
  ),
  default: classNames(
    'shadow-xs',
    'bg-white text-neutral-800',
    'border-neutral-100 border border-solid',
    'active:text-neutral-600',
    'focus:ring-primary-100 hover:shadow focus:border-primary-200 focus:ring',
    'hover:shadow'
  ),
  subtle: classNames(
    'bg-transparent text-neutral-800',
    'active:text-neutral-600 border border-solid border-transparent',
    'focus:ring-primary-100 focus:border-primary-200 focus:ring',
    'hover:bg-neutral-200/50'
  ),
  text: classNames(
    'bg-transparent text-neutral-800 !p-0',
    'active:text-neutral-600 border border-solid border-transparent',
    'hover:text-black'
  ),
  toggle: classNames(
    'bg-white text-neutral-800',
    'active:text-neutral-600 border border-solid border-transparent',
    'focus:ring-primary-100 focus:border-primary-200 focus:ring',
    'hover:bg-neutral-200/50'
  ),
  'toggle-selected': classNames(
    'bg-neutral-75 text-primary-600',
    'active:text-neutral-600 border border-solid border-transparent',
    'focus:ring-primary-100 focus:border-primary-200 focus:ring',
    'hover:bg-neutral-200/50'
  ),
  green: classNames(
    'shadow-xs',
    'bg-tertiary-green-500 text-white',
    'border-neutral-transparent border',
    'active:text-neutral-100',
    'focus:ring-primary-100 focus:border-primary-200 focus:ring'
  ),
  onColor: classNames(
    'shadow-xs',
    'bg-transparent text-neutral-800',
    'border-neutral-transparent border',
    'active:text-neutral-600',
    'focus:ring-primary-100 hover:shadow focus:border-primary-200 focus:ring'
  ),
  'custom-color': classNames(
    'shadow-xs',
    'border-neutral-100 border border-solid',
    'focus:ring-primary-100 hover:shadow focus:border-primary-200 focus:ring'
  ),
  'default-error': classNames(
    'shadow-xs',
    'bg-white text-tertiary-red-500',
    'border-neutral-100 border border-solid',
    'active:text-tertiary-red-100',
    'focus:ring-tertiary-red-100 hover:shadow focus:border-tertiary-red-200 focus:ring',
    'hover:shadow'
  ),
}

export const spinnerClasses = {
  primary: 'border-white',
  green: 'border-white',
  error: 'border-white',
  warning: 'border-neutral-800',
  default: 'border-neutral-800',
  subtle: 'border-neutral-800',
  toggle: 'border-neutral-800',
  text: 'border-neutral-800',
  'toggle-selected': 'border-neutral-800',
  onColor: 'border-neutral-800',
  disabled: 'border-neutral-200',
  'custom-color': 'border-neutral-800',
  'default-error': 'border-neutral-800',
}

type BasicButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'ref'
>
export interface ButtonProps extends BasicButtonProps {
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  icon?: React.ReactNode
  isLoading?: boolean
  variant?: ButtonVariant
  iconPosition?: 'left' | 'right'
  small?: boolean
}

const defaultDisabledClassNames =
  'border-neutral-50 border border-solid bg-neutral-50 text-neutral-200 fill-neutral-200'

const disabledClassNames = {
  text: 'bg-transparent text-neutral-800/40 !p-0 border border-solid border-transparent',
  primary: defaultDisabledClassNames,
  warning: defaultDisabledClassNames,
  error: defaultDisabledClassNames,
  default: defaultDisabledClassNames,
  subtle: defaultDisabledClassNames,
  toggle: 'bg-white text-neutral-200 fill-neutral-200',
  'toggle-selected': 'bg-neutral-50 text-neutral-200 fill-neutral-200',
  green: defaultDisabledClassNames,
  onColor: defaultDisabledClassNames,
  'custom-color': defaultDisabledClassNames,
  'default-error': defaultDisabledClassNames,
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      icon,
      isLoading,
      onClick,
      small = false,
      variant = 'default',
      iconPosition = 'left',
      ...rest
    },
    ref
  ) => (
    <button
      className={classMerge(
        'flex items-center justify-center rounded-md h-8 px-2 focus:outline-none leading-6 relative transition-colors font-medium focus:z-[2]',
        {
          [classes[variant]]: !disabled,
          '!text-xs h-6 px-1': small,
        },
        className,
        {
          [disabledClassNames[variant]]: disabled,
        }
      )}
      ref={ref}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {isLoading && (
        <div className="absolute flex items-center justify-center w-full">
          <Spinner
            className={classNames('w-4 h-4', disabled ? spinnerClasses.disabled : spinnerClasses[variant])}
            aria-label="loading"
          />
        </div>
      )}

      {!isLoading && icon && iconPosition === 'left' && (
        <div className={classNames('p-0.5', { 'mr-2': !!children && !small, 'mr-1': !!children && small })}>{icon}</div>
      )}

      <span
        className={classNames('flex h-full items-center justify-center whitespace-nowrap w-full', {
          'opacity-0': isLoading,
        })}
      >
        {children}
      </span>

      {!isLoading && icon && iconPosition === 'right' && (
        <div className={classNames('p-0.5', { 'ml-2': !!children && !small, 'ml-1': !!children && small })}>{icon}</div>
      )}
    </button>
  )
)

export default Button
