import { Equation, EquationLineType, NormalizedCustomizerProduct } from '@packages/types'

export const generateBaseSchemaForEquation = (equation: Equation) => {
  return equation.lines
    .map((line, index) => (index < equation.lines.length - 1 ? `$${index + 1} ${line.operator}` : `$${index + 1}`))
    .join(' ')
}

export const renderEquation = (equation: Equation, product: NormalizedCustomizerProduct) => {
  let schema = equation.options?.schema
  if (!schema) schema = generateBaseSchemaForEquation(equation)

  if (!schema || schema == null) return ''
  schema = schema.replace(/\s/g, '')

  let numberOfQuestions = 0
  equation.lines.map((line, i) => {
    let value = line.value
    if (line.type === EquationLineType.Question) {
      numberOfQuestions++
      value = product.questions[line.value]?.name || `Question ${numberOfQuestions}`
    } else {
      value = value || '0'
    }

    schema = schema?.replaceAll(`$${i + 1}`, value)
  })

  schema = schema.replace(/[+\-*/]/g, ' $& ')

  return schema
}
