import React, { useRef, useContext, useCallback, useEffect, useState } from 'react'
import { OnDrag, OnResize, OnRotate } from 'react-moveable'
import { useCallbackRef } from 'use-callback-ref'

import ContainerContext from 'common/components/editor/containers/ContainerContext'

type Origin = 'center' | 'top left' | 'top center' | 'bottom center'

const useBoxEditor = <
  T extends { width?: number; height?: number; x: number; y: number; scale: number; rotation: number; origin?: Origin },
>(
  box: T,
  allowedResizes: ('width' | 'height')[] = ['width', 'height']
) => {
  const [, forceUpdate] = useState<number>(0)

  type Width = T['width'] extends number ? number : undefined
  type Height = T['height'] extends number ? number : undefined

  const origin = box.origin ?? 'center'

  const targetRef = useCallbackRef<HTMLDivElement>(null, () => forceUpdate(c => c + 1))

  const { scale: parentScale } = useContext(ContainerContext)

  const scale = box.scale * parentScale

  const getFrame = () => {
    const halfWidth = origin !== 'top left' && box.width != null ? (box.width * scale) / 2 : 0
    const halfHeight = origin !== 'top left' && box.height != null ? (box.height * scale) / 2 : 0
    return {
      rotation: box.rotation,
      left: box.x * scale - halfWidth,
      top: box.y * scale - halfHeight,
      width: box.width != null ? box.width * scale : undefined,
      height: box.height != null ? box.height * scale : undefined,
    }
  }

  const frame = useRef(getFrame())

  const updateDimensions = ({ width, height }: { width?: number; height?: number }) => {
    return {
      width: width != null ? width / scale : undefined,
      height: height != null ? height / scale : undefined,
    }
  }

  const updatePosition = (
    { left, top }: { left: number; top: number },
    { width, height }: { width?: number; height?: number }
  ) => {
    const halfWidth = origin !== 'top left' && width != null ? (width * scale) / 2 : 0
    const halfHeight = origin !== 'top left' && height != null ? (height * scale) / 2 : 0

    const x = (left + halfWidth) / scale
    const y = (top + halfHeight) / scale
    return { x, y }
  }

  const getTransformedBoundingBox = () => {
    const dimensions = updateDimensions(frame.current)
    const position = updatePosition(frame.current, dimensions)
    return {
      width: dimensions.width as Width,
      height: dimensions.height as Height,
      x: position.x,
      y: position.y,
      rotation: frame.current.rotation,
    }
  }

  const getOffset = () => {
    if (origin === 'center') return `translate(${box.width == null ? '-50%' : 0}, ${box.height == null ? '-50%' : 0})`

    if (origin === 'top left') return ''

    if (origin === 'top center') return `translate(${box.width == null ? '-50%' : 0}, 0)`

    if (origin === 'bottom center') return `translate(${box.width == null ? '-50%' : 0}, -100%)`

    return ''
  }

  const Target = useCallback(
    ({ children }: React.PropsWithChildren) => {
      return (
        <div
          className="target"
          ref={targetRef}
          style={{
            position: `absolute`,
            transformOrigin: origin,
            zIndex: 9,
            width: `${frame.current.width}px`,
            height: `${frame.current.height}px`,
            transform: `translate(${frame.current.left}px, ${frame.current.top}px) ${getOffset()} rotate(${
              frame.current.rotation
            }deg)`,
          }}
        >
          {children}
        </div>
      )
    },
    [origin]
  )

  useEffect(() => {
    const newFrame = getFrame()

    const shouldUpdate = (Object.keys(newFrame) as (keyof typeof newFrame)[]).reduce((shouldUpdate, key) => {
      if (newFrame[key] == null) return frame.current[key] == null
      if (frame.current[key] == null) return newFrame[key] == null

      return shouldUpdate || Math.abs(newFrame[key]! - frame.current[key]!) > 0.01
    }, false)

    if (shouldUpdate) {
      frame.current = getFrame()
      forceUpdate(c => c + 1)
    }
  }, [scale, origin, box.width, box.height, box.x, box.y, box.rotation])

  return {
    targetRef,
    getTransformedBoundingBox,
    scale,
    Target,
    boundingBox: frame.current,
    onDrag: (e: OnDrag) => {
      frame.current = {
        ...frame.current,
        left: e.beforeTranslate[0],
        top: e.beforeTranslate[1],
      }
    },
    onResize: (e: OnResize) => {
      frame.current = {
        ...frame.current,
        left: e.drag.beforeTranslate[0],
        top: e.drag.beforeTranslate[1],
        width: e.width,
        height: allowedResizes.includes('height') ? e.height : frame.current.height,
      }
    },
    onRotate: (e: OnRotate) => {
      frame.current = {
        ...frame.current,
        left: e.drag.beforeTranslate[0],
        top: e.drag.beforeTranslate[1],
        rotation: e.rotation,
      }
    },
  }
}

export default useBoxEditor
