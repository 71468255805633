import React from 'react'
import { Provider } from 'react-redux'
import { AnyAction } from 'redux'

import { RootState } from 'customizer/store'
import configureStore, { EnhancedStore, Middlewares } from 'redux-inject-reducer'

import CustomizerIframeContext from './CustomizerIframeContext'
import CustomizerIframeProviderContext, { NOT_READY } from './CustomizerIframeProviderContext'

const getStoreFromIframeRef = (iframeRef: React.RefObject<HTMLIFrameElement>) =>
  iframeRef.current?.contentWindow?.customizerApp?.store ??
  ({ subscribe: () => {}, dispatch: () => {}, getState: () => NOT_READY } as unknown as EnhancedStore<
    any,
    AnyAction,
    Middlewares<any>
  >)

interface CustomizerIframeProviderProps {
  children?: React.ReactNode
  iframeRef: React.RefObject<HTMLIFrameElement>
  store?: ReturnType<typeof configureStore<RootState>>
}

const CustomizerIframeProvider = ({ children, iframeRef, store }: CustomizerIframeProviderProps) => {
  return (
    <Provider context={CustomizerIframeProviderContext} store={store ?? getStoreFromIframeRef(iframeRef)}>
      <CustomizerIframeContext.Provider value={{ iframe: iframeRef.current }}>
        {children}
      </CustomizerIframeContext.Provider>
    </Provider>
  )
}

export default CustomizerIframeProvider
