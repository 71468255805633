import { Part, Question } from '@packages/types'

import * as constants from 'common/customizerProducts/constants'

export const getPartQuestions = (part: Part | null | undefined, questions: Record<string, Question>): Question[] => {
  const partQuestionFields = part ? constants.parts.questionFields[part.type] : null

  if (!partQuestionFields) return []

  return partQuestionFields.reduce((partQuestions: Question[], { field }) => {
    const questionId = part![field]

    if (questionId == null || questions[questionId] == null) return partQuestions

    return [...partQuestions, questions[questionId]]
  }, [])
}

export const getPartEntitiesId = (part: Part | null | undefined): string[] => {
  const partQuestionFields = part ? constants.parts.questionFields[part.type] : null

  if (!partQuestionFields) return []

  return partQuestionFields.reduce((ids: string[], { field }) => {
    const questionId = part![field]

    if (questionId == null) return ids

    return [...ids, questionId]
  }, [])
}
