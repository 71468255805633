import classNames from 'classnames'
import React from 'react'

import CalendarIcon from 'icons/bold/01-Interface Essential/21-Date-Calendar/calendar.svg'
import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'

import Button from '../Button'
import IconButton from '../IconButton'
import DateRangePickerInput from './../inputs/dateRange/DateRangePickerInput'
import Popover from './../popover/Popover'
import usePopover from './../popover/usePopover'

export interface DatesFilterProps {
  name: string
  startDate?: Date | null
  setStartDate: (value?: Date | null) => void
  endDate?: Date | null
  setEndDate: (value?: Date | null) => void
}

const DatesFilter = ({ name, startDate, endDate, setStartDate, setEndDate }: DatesFilterProps) => {
  const popover = usePopover({
    placement: 'bottom',
    useArrow: true,
    offsetConfig: 12,
    autoUpdateOnAnimationFrame: true,
  })

  const isEmpty = startDate == null && endDate == null
  const isOnlyStart = startDate != null && endDate == null
  const isOnlyEnd = startDate == null && endDate != null
  const isBetween = startDate != null && endDate != null

  const dateContainerClassName =
    'h-full pl-2 pr-0.5 space-x-1 flex items-center bg-neutral-50 text-neutral-600 hover:cursor-pointer'

  return (
    <div
      className={classNames(
        'shadow-xs border h-7 rounded-xl font-medium flex items-center overflow-hidden text-xs focus-within:border-primary-200 focus-within:ring border-neutral-100',
        {
          'border-dashed': isEmpty,
        }
      )}
    >
      <Button
        variant="text"
        className="!text-xs mx-1.5 text-neutral-400"
        {...popover.referenceProps}
        icon={<CalendarIcon className="w-2.5 h-2.5 fill-current" />}
      >
        {name}
      </Button>
      {!isEmpty && <div className="h-full w-[1px] bg-neutral-75" />}
      <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
        <div className="px-4 pt-4 font-medium">Filter {name.toLowerCase()} by date</div>
        <div className="flex p-4">
          <DateRangePickerInput
            className="w-64"
            startDatePickerProps={{
              onValueChange: (value?: Date | null) => setStartDate(value),
              selected: startDate ? new Date(startDate) : undefined,
              portalId: 'portal-d',
            }}
            endDatePickerProps={{
              onValueChange: (value?: Date | null) => setEndDate(value),
              selected: endDate ? new Date(endDate) : undefined,
              portalId: 'portal-d',
            }}
          />
        </div>
      </Popover>
      {isOnlyStart && (
        <span className={dateContainerClassName} onClick={() => popover.open()}>
          <span>after</span>
          <span className="font-normal text-neutral-900">
            {new Date(startDate).toLocaleDateString('en', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <IconButton
            small
            variant="subtle"
            aria-label="Clear date filter"
            Icon={Remove}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setStartDate(null)
              setEndDate(null)
            }}
          />
        </span>
      )}
      {isOnlyEnd && (
        <span className={dateContainerClassName} onClick={() => popover.open()}>
          <span>before</span>
          <span className="font-normal text-neutral-900">
            {new Date(endDate).toLocaleDateString('en', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <IconButton
            small
            variant="subtle"
            aria-label="Clear date filter"
            Icon={Remove}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setStartDate(null)
              setEndDate(null)
            }}
          />
        </span>
      )}
      {isBetween && (
        <span className={dateContainerClassName} onClick={() => popover.open()}>
          <span className="font-normal text-neutral-900">
            {new Date(startDate).toLocaleDateString('en', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <span>-</span>
          <span className="font-normal text-neutral-900">
            {new Date(endDate).toLocaleDateString('en', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </span>
          <IconButton
            small
            variant="subtle"
            Icon={Remove}
            aria-label="Clear date filter"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setStartDate(null)
              setEndDate(null)
            }}
          />
        </span>
      )}

      <div id="portal-d" className="z-[61]" />
    </div>
  )
}

export default DatesFilter
