import { Addon, CustomPlan, Plan } from '@packages/types'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormikHelpers, useFormik } from 'formik'
import React, { useState, useContext } from 'react'
import * as yup from 'yup'

import type { ActivateResponse, ActivateSubscriptionBody } from 'cms/subscription/types/service'
import TrackerContext from 'cms/tracking/components/TrackerContext'
import { NetworkError } from 'common/api/types/error'
import Button from 'common/components/Button'
import Card from 'common/components/card/Card'
import HelperText from 'common/components/inputs/HelperText'
import Input from 'common/components/inputs/Input'
import InputField from 'common/components/inputs/InputField'
import Label from 'common/components/inputs/Label'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import classMerge from 'utils/classMerge'

import useSubscriptionService from './../../hooks/useSubscriptionService'

export interface StripeActivationCardProps {
  plan: Plan | CustomPlan
  addons: Addon[]
  onSuccess: () => void
}

interface StripeActivationCardFormValues {
  plan: Plan | CustomPlan
  addons: Addon[]
  name: string
  stripePaymentMethodId: string
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter your name'),
  stripePaymentMethodId: yup.string().required('Please enter your card informations'),
})

const StripeActivationCard = ({ onSuccess, addons, plan }: StripeActivationCardProps) => {
  const [cardIsFocus, setCardIsFocus] = useState<boolean>(false)
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const stripe = useStripe()
  const elements = useElements()
  const queryClient = useQueryClient()
  const { openGenericErrorToast, openToast } = useToast()
  const subscriptionService = useSubscriptionService()
  const tracker = useContext(TrackerContext)

  const { mutate: activate } = useMutation<ActivateResponse, NetworkError, ActivateSubscriptionBody>(
    subscriptionService.activate,
    {
      onSuccess: () => {
        openToast('Your subscription was activated.', ToastType.success)
        queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
        tracker.send('account_subscription_activated')
        onSuccess()
      },
    }
  )

  const handleSubmit = async (
    values: StripeActivationCardFormValues,
    { setSubmitting, setFieldError }: FormikHelpers<StripeActivationCardFormValues>
  ) => {
    if (!stripe || !elements) return

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      openGenericErrorToast('Unable to complete subscription.')
      setSubmitting(false)
      return
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: values.name,
      },
    })

    if (error) {
      openGenericErrorToast('Unable to complete subscription.')
      setSubmitting(false)
      return
    }

    setHasSubmitted(true)
    return activate(
      { ...values, stripePaymentMethodId: paymentMethod.id },
      {
        onSettled: () => setSubmitting(false),
        onError: error => {
          setHasSubmitted(false)

          if (error.data.details) {
            openToast(error.data.details, ToastType.warning)
            setFieldError('stripePaymentMethodId', error.data.details)
          } else {
            openGenericErrorToast('Your subscription was not activated.')
          }
        },
      }
    )
  }

  const formik = useFormik<StripeActivationCardFormValues>({
    initialValues: { name: '', stripePaymentMethodId: '', addons, plan },
    onSubmit: handleSubmit,
    validationSchema,
  })

  const cardInputEventHandlers = {
    onChange: async (event: StripeCardElementChangeEvent) => {
      if (event.empty) {
        formik.setErrors({ stripePaymentMethodId: undefined })
      } else {
        formik.setErrors({ stripePaymentMethodId: event.error?.message })
      }

      if (event.complete) formik.setFieldValue('stripePaymentMethodId', 'completed')
    },
    onBlur: () => setCardIsFocus(false),
    onFocus: () => {
      formik.setFieldTouched('stripePaymentMethodId', true)
      setCardIsFocus(true)
    },
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card className="flex-col w-[450px]">
        <Card.Section className="md:flex-col space-y-6">
          <InputField>
            <Label htmlFor="name" className="text-neutral-500">
              Name on the card
            </Label>
            <Input
              placeholder="Enter name here"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={formik.touched.name && formik.errors.name != null}
            />
            {formik.touched.name && formik.errors.name != null && (
              <HelperText hasError>{formik.errors.name}</HelperText>
            )}
          </InputField>
          <InputField>
            <Label htmlFor="stripePaymentMethodId" className="text-neutral-500">
              Card information
            </Label>
            <CardElement
              id="stripePaymentMethodId"
              className={classMerge('border-solid border p-2 border-neutral-75 rounded', {
                'ring ring-primary-100 border-primary-200 z-[1]': cardIsFocus,
                'border-error-default':
                  formik.touched.stripePaymentMethodId && formik.errors.stripePaymentMethodId != null && !cardIsFocus,
              })}
              options={{
                style: {
                  base: {
                    fontSmoothing: 'antialiased',
                    fontSize: '14px',
                    '::placeholder': {
                      color: '#9CA3AF',
                    },
                  },
                  invalid: {
                    color: '#D23E3E',
                    iconColor: '#D23E3E',
                  },
                },
              }}
              {...cardInputEventHandlers}
            />

            {formik.touched.stripePaymentMethodId && formik.errors.stripePaymentMethodId != undefined && (
              <HelperText hasError>{formik.errors.stripePaymentMethodId}</HelperText>
            )}
          </InputField>
        </Card.Section>
        <Card.Footer onClick={e => e.stopPropagation()}>
          <Button type="submit" variant="primary" className="w-full" isLoading={formik.isSubmitting || hasSubmitted}>
            Subscribe
          </Button>
        </Card.Footer>
      </Card>
    </form>
  )
}

export default StripeActivationCard
