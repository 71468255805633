import classNames from 'classnames'
import React from 'react'

const ModalActionsMessage = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div className={classNames('text-xs text-neutral-500 font-medium mr-auto', props.className)}>{props.children}</div>
  )
}

export default ModalActionsMessage
