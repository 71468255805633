import { PaymentStrategy, SubscriptionStatus } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import Button from 'common/components/Button'
import useModal from 'common/components/modal/useModal'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import { trpc } from 'common/hooks/trpc'
import useCurrentUser from 'common/users/hooks/useCurrentUser'
import useSubscriptionService from './../hooks/useSubscriptionService'
import ShopifyPaymentCard from './cards/paymentCard/ShopifyPaymentCard'
import WixPaymentCard from './cards/paymentCard/WixPaymentCard'
import StripePaymentCard from './cards/paymentCard/StripePaymentCard'
import SubscriptionCard from './cards/subscriptionCard/SubscriptionCard'
import ClientCustomPlanAlert from './ClientCustomPlanAlert'
import MasterCustomPlanAlert from './MasterCustomPlanAlert'
import CustomPlanModal from './modals/CustomPlanModal'
import ExtendFreeTrialModal from './modals/ExtendFreeTrialModal'
import ActivateCustomPlanModal from './modals/ActivateCustomPlanModal'
import SubscriptionFullPageModal from './modals/SubscriptionFullPageModal'

const getPaymentDetailsMessage = (paymentStrategy: PaymentStrategy) => {
  if (paymentStrategy === PaymentStrategy.Stripe) {
    return 'Charges are automatically added to your card each month.'
  } else if (paymentStrategy === PaymentStrategy.Shopify) {
    return 'Charges are automatically added to your Shopify subscription each month.'
  } else if (paymentStrategy === PaymentStrategy.Wix) {
    return 'Charges are automatically added to your Wix subscription each month.'
  }

  return ''
}

const BillingAndSubscription = () => {
  const [isEditingStripeCard, setIsEditingStripeCard] = useState(false)
  const subscriptionService = useSubscriptionService()
  const { isMasterUser, isMCZRUser } = useCurrentUser()
  const { openToast, openGenericErrorToast } = useToast()
  const customPlanModal = useModal()
  const extendFreeTrialModal = useModal()
  const subscribeModal = useModal()

  const {
    data: subscription,
    isLoading,
    refetch: refetchSubscription,
  } = useQuery(subscriptionService.fetchOne.queryKeys, subscriptionService.fetchOne)

  const isFreeTrialExtendable =
    subscription && [SubscriptionStatus.FreeTrial, SubscriptionStatus.Canceled].includes(subscription.status)

  const isManualOrNone =
    !!subscription?.paymentStrategy &&
    [PaymentStrategy.None, PaymentStrategy.Manual].includes(subscription.paymentStrategy)

  const canHaveCustomPlan =
    subscription &&
    [PaymentStrategy.Shopify, PaymentStrategy.Wix, PaymentStrategy.Manual, PaymentStrategy.Stripe].includes(
      subscription.paymentStrategy
    )

  const willActivateCustomPlan = subscription?.plan.custom === true && subscription.status !== SubscriptionStatus.Active

  const { mutate: continueFreeTrial } = trpc.billingAndSubscription.continueFreeTrial.useMutation({
    onSuccess: () => {
      openToast('Your free trial was successfully reactivated.', ToastType.success)
      refetchSubscription()
    },
    onError: () => {
      openGenericErrorToast('Your free trial was not reactivated.')
    },
  })

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />
      <Page aria-label="billing page">
        <Page.Header>
          <h1 aria-label="page title">Billing & subscription</h1>
          {isMasterUser && !subscription?.proposedCustomPlan && canHaveCustomPlan && (
            <Button aria-label="create custom plan" onClick={customPlanModal.open}>
              Create custom plan
            </Button>
          )}
        </Page.Header>
        <Page.Section>
          {isMasterUser && !!subscription && !!subscription.proposedCustomPlan && (
            <MasterCustomPlanAlert handleEditModal={customPlanModal.open} subscription={subscription} />
          )}
        </Page.Section>
        <Page.Section>
          <Page.Aside
            title="Subscription"
            description="Take a look at our website to learn more about our different plans."
          >
            {isMasterUser && isFreeTrialExtendable && (
              <Button aria-label="extend free trial" onClick={extendFreeTrialModal.open} className="mt-4">
                Extend free trial
              </Button>
            )}
            {!isMCZRUser && !!subscription && !!subscription.proposedCustomPlan && (
              <ClientCustomPlanAlert subscription={subscription} />
            )}
          </Page.Aside>
          <Page.Content>
            <SubscriptionCard
              subscription={!isLoading ? subscription : undefined}
              onPlanChange={() => {
                setIsEditingStripeCard(false)
                subscribeModal.open()
              }}
              onContinueFreeTrial={() => continueFreeTrial()}
            />
          </Page.Content>
        </Page.Section>
        {subscription && !isManualOrNone && (
          <>
            <Page.Separator />
            <Page.Section>
              <Page.Aside title="Payment method" description={getPaymentDetailsMessage(subscription.paymentStrategy)} />
              <Page.Content>
                {subscription.paymentStrategy === PaymentStrategy.Shopify && (
                  <ShopifyPaymentCard subscription={!isLoading ? subscription : undefined} />
                )}
                {subscription.paymentStrategy === PaymentStrategy.Wix && (
                  <WixPaymentCard subscription={!isLoading ? subscription : undefined} />
                )}
                {subscription.paymentStrategy === PaymentStrategy.Stripe && (
                  <StripePaymentCard
                    subscription={!isLoading ? subscription : undefined}
                    isEditing={isEditingStripeCard}
                    setIsEditing={setIsEditingStripeCard}
                  />
                )}
              </Page.Content>
            </Page.Section>
          </>
        )}
      </Page>
      {subscribeModal.isVisible && (
        <>
          {!willActivateCustomPlan && (
            <SubscriptionFullPageModal onClose={subscribeModal.close} {...subscribeModal.modalProps} />
          )}
          {willActivateCustomPlan && (
            <ActivateCustomPlanModal onClose={subscribeModal.close} {...subscribeModal.modalProps} />
          )}
        </>
      )}
      {customPlanModal.isVisible && subscription && (
        <CustomPlanModal
          customPlanFormValues={subscription.proposedCustomPlan}
          onClose={customPlanModal.close}
          {...customPlanModal.modalProps}
        />
      )}
      {extendFreeTrialModal.isVisible && subscription && (
        <ExtendFreeTrialModal onClose={extendFreeTrialModal.close} {...extendFreeTrialModal.modalProps} />
      )}
    </main>
  )
}

export default BillingAndSubscription
