import React from 'react'

import Alert from 'common/components/alert/Alert'
import Button from 'common/components/Button'
import Input from 'common/components/inputs/Input'
import useCopyToClipboard from 'common/hooks/useCopyToClipboard'
import CopyFile from 'icons/regular/16-Files-Folders/01-Common-Files/common-file-double-1.svg'

export interface ConnectionWarningAlertProps {
  connectionString: string
}

const ConnectionWarningAlert = ({ connectionString }: ConnectionWarningAlertProps) => {
  const copyToClipboard = useCopyToClipboard()

  return (
    <Alert className="mt-6" variant="warning">
      <Alert.Body>
        <Alert.Title>We can't establish a connection with your online store.</Alert.Title>
        <Alert.Details className="!mb-4">
          Make sure the URL is valid. If you're developing locally or your server is unreachable for other reason you
          can copy the sync code below and use it in the Kickflip app settings on your online store admin.
        </Alert.Details>
        <Input
          value={connectionString}
          readOnly
          rightAddon={
            <Button
              variant="subtle"
              small
              className="whitespace-nowrap w-fit ml-6"
              icon={<CopyFile className="w-3 h-3 fill-current text-current" />}
              onClick={() => copyToClipboard(connectionString)}
            >
              Copy
            </Button>
          }
        />
      </Alert.Body>
    </Alert>
  )
}

export default ConnectionWarningAlert
