import React from 'react'

export interface TableLoaderProps {
  colSpan: number
  className?: string
}

const TableLoader = ({ colSpan, className = '' }: TableLoaderProps) => (
  <tr className={className}>
    <td colSpan={colSpan} className="p-0 absolute w-full">
      <div className="bg-neutral-100 overflow-hidden h-1 top-0">
        <div className="animate-table-loading w-full origin-left h-1">
          <div className="bg-primary-600 h-1 w-1/2"></div>
        </div>
      </div>
    </td>
  </tr>
)

export default TableLoader
