import React, { useEffect, useRef, useState } from 'react'

export type SnappedStatus = 'none' | 'next-to-panel' | 'over-panel'

const OFFSCREEN_X_PADDING = 80
const OFFSCREEN_Y_PADDING = 30

const isOffscreen = (x: number, y: number) => {
  return (
    x < -OFFSCREEN_X_PADDING ||
    y < 0 ||
    x > window.innerWidth - OFFSCREEN_X_PADDING ||
    y > window.innerHeight - OFFSCREEN_Y_PADDING
  )
}

const getInitialPosition = () => {
  const x = 286
  const y = (window.innerHeight - 488) / 2

  return { right: `${x}px`, top: `${y}px`, height: '600px' }
}

const canSnapNextToRightPanelPosition = (x: number) => {
  return x > window.innerWidth - 370 && x < window.innerWidth - 252
}

const canSnapOverRightPanelPosition = (x: number) => {
  return x > window.innerWidth - 100 && x < window.innerWidth
}

const getDragMouseMoveEventListener =
  (
    ref: React.MutableRefObject<HTMLElement | null>,
    dragRef: HTMLElement,
    offset: React.MutableRefObject<{ x: number; y: number }>,
    snappedStatus: SnappedStatus,
    setSnappedStatus: (status: SnappedStatus) => void,
    setIsDragging: (isDragging: boolean) => void,
    setCanSnapNextToRightPanel: (canSnap: boolean) => void,
    setCanSnapOverRightPanel: (canSnap: boolean) => void
  ) =>
  (e: MouseEvent) => {
    if (!ref.current) return

    e.preventDefault()

    setIsDragging(true)

    if (snappedStatus) setSnappedStatus('none')

    setCanSnapNextToRightPanel(canSnapNextToRightPanelPosition(e.clientX))
    setCanSnapOverRightPanel(canSnapOverRightPanelPosition(e.clientX))

    const x = e.clientX - (offset?.current ? offset.current.x : 0)
    const y = e.clientY - (offset?.current ? offset.current.y : 0)

    if (isOffscreen(x, y)) return

    ref.current.style.left = `${x}px`
    ref.current.style.top = `${y}px`

    localStorage.setItem(
      'movablePanelPosition',
      JSON.stringify({ left: ref.current.style.left, top: ref.current.style.top, height: ref.current.style.height })
    )

    dragRef.style.cursor = 'grabbing'
  }

const getDragMouseDownEventListener =
  (
    dragRef: HTMLElement,
    offset: React.MutableRefObject<{ x: number; y: number }>,
    setSnappedStatus: (status: SnappedStatus) => void,
    setIsDragging: (isDragging: boolean) => void,
    setCanSnapNextToRightPanel: (canSnap: boolean) => void,
    setCanSnapOverRightPanel: (canSnap: boolean) => void,
    mouseMoveEventListener: (e: MouseEvent) => void
  ) =>
  (e: MouseEvent) => {
    if (!offset?.current) return

    e.preventDefault()

    const rect = dragRef.getBoundingClientRect()

    offset.current.x = e.clientX - rect.left
    offset.current.y = e.clientY - rect.top

    const mouseUpEventListener = (e: MouseEvent) => {
      let snappedStatus: SnappedStatus

      if (canSnapNextToRightPanelPosition(e.clientX)) {
        snappedStatus = 'next-to-panel'
      } else if (canSnapOverRightPanelPosition(e.clientX)) {
        snappedStatus = 'over-panel'
      } else {
        snappedStatus = 'none'
      }

      setSnappedStatus(snappedStatus)
      localStorage.setItem('movablePanelSnappedStatus', snappedStatus)

      dragRef.style.cursor = ''
      document.removeEventListener('mousemove', mouseMoveEventListener)
      document.removeEventListener('mouseup', mouseUpEventListener)
      setCanSnapNextToRightPanel(false)
      setCanSnapOverRightPanel(false)
      setIsDragging(false)
    }

    document.addEventListener('mousemove', mouseMoveEventListener)
    document.addEventListener('mouseup', mouseUpEventListener)
  }

const getResizeMouseDownEventHandler =
  (
    ref: React.MutableRefObject<HTMLElement | null>,
    isDragging: boolean,
    setIsDragging: (isDragging: boolean) => void
  ) =>
  (e: React.MouseEvent) => {
    if (!ref?.current) return

    const rect = ref.current.getBoundingClientRect()

    const offsetY = e.clientY

    const mouseMoveEventListener = (e: MouseEvent) => {
      if (!ref?.current) return

      e.preventDefault()

      if (!isDragging) setIsDragging(true)

      const y = e.clientY - offsetY

      if (rect.height + y < 250) return

      ref.current.style.height = `${rect.height + y}px`
    }

    const mouseUpEventListener = () => {
      if (!ref?.current) return

      document.removeEventListener('mousemove', mouseMoveEventListener)
      document.removeEventListener('mouseup', mouseUpEventListener)

      localStorage.setItem(
        'movablePanelPosition',
        JSON.stringify({ left: ref.current.style.left, top: ref.current.style.top, height: ref.current.style.height })
      )

      setIsDragging(false)
    }

    document.addEventListener('mousemove', mouseMoveEventListener)
    document.addEventListener('mouseup', mouseUpEventListener)
  }

const useMovablePanel = (ref: React.MutableRefObject<HTMLElement | null>, dragRef?: HTMLElement) => {
  const offset = useRef({ x: 0, y: 0 })

  const [isDragging, setIsDragging] = useState(false)

  const [snappedStatus, setSnappedStatus] = useState<SnappedStatus>(
    (localStorage.getItem('movablePanelSnappedStatus') as SnappedStatus) ?? 'next-to-panel'
  )
  const [canSnapNextToRightPanel, setCanSnapNextToRightPanel] = useState(false)
  const [canSnapOverRightPanel, setCanSnapOverRightPanel] = useState(false)

  const savedPositionString = localStorage.getItem('movablePanelPosition')
  const savedPosition = savedPositionString && JSON.parse(savedPositionString)

  const initialPosition = savedPosition
    ? isOffscreen(parseInt(savedPosition.left), parseInt(savedPosition.top))
      ? getInitialPosition()
      : savedPosition
    : getInitialPosition()

  useEffect(() => {
    let dragRefMouseDownEventListener: ((e: MouseEvent) => void) | undefined
    let mouseMoveEventListener: ((e: MouseEvent) => void) | undefined

    if (dragRef) {
      mouseMoveEventListener = getDragMouseMoveEventListener(
        ref,
        dragRef,
        offset,
        snappedStatus,
        setSnappedStatus,
        setIsDragging,
        setCanSnapNextToRightPanel,
        setCanSnapOverRightPanel
      )

      dragRefMouseDownEventListener = getDragMouseDownEventListener(
        dragRef,
        offset,
        setSnappedStatus,
        setIsDragging,
        setCanSnapNextToRightPanel,
        setCanSnapOverRightPanel,
        mouseMoveEventListener
      )

      dragRef.addEventListener('mousedown', dragRefMouseDownEventListener)
    }

    return () => {
      if (mouseMoveEventListener) document.removeEventListener('mousemove', mouseMoveEventListener)
      if (dragRefMouseDownEventListener) document.removeEventListener('mousedown', dragRefMouseDownEventListener)
    }
  }, [dragRef])

  const handleResizeHandleMouseDown = getResizeMouseDownEventHandler(ref, isDragging, setIsDragging)

  return {
    isDragging,
    setIsDragging,
    handleResizeHandleMouseDown,
    snappedStatus,
    canSnapNextToRightPanel,
    canSnapOverRightPanel,
    initialPosition,
  }
}

export default useMovablePanel
