export const ADD_ANSWER = 'answers/add-answer'
export const ARCHIVE_ANSWER = 'answers/archive-answer'
export const RESTORE_ANSWER = 'answers/restore-answer'
export const UNLINK_ANSWER = 'answers/unlink-answer'
export const DELETE_ANSWER = 'answers/delete-answer'
export const COPY_ANSWERS = 'answers/copy-answers'
export const CREATE_ANSWER = 'answers/create-answer'
export const CREATE_ANSWER_PREVIEW = 'answers/create-answer-preview'
export const CHANGE_TYPE = 'answers/change-type'
export const DUPLICATE_ANSWER = 'answers/duplicate-answer'
