import { useContext } from 'react'

import type { Translation } from 'cms/translations/types/translation'
import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import type { Paginated } from 'common/api/types/pagination'
import TenantContext from 'common/tenant/TenantContext'

export interface CreateTranslationValues {
  code: string
  name: string
}

const useTranslationService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetchAll: createQuery([tenant, 'translations'], async () => {
      const { data } = await apiClient.get<Translation[]>(`/brands/${tenant}/translations`)
      return data
    }),
    fetch: createQuery([tenant, 'translation'], async languageCode => {
      const { data } = await apiClient.get<Paginated<Translation>>(
        `/brands/${tenant}/translations?code=${languageCode}`
      )
      return data.results[0]
    }),
    create: async (translation: CreateTranslationValues) => {
      const { data } = await apiClient.post<Translation>(`/brands/${tenant}/translations`, translation)
      return data
    },
    update: async (id: string, translation: Translation) => {
      const { data } = await apiClient.post<Translation>(`/brands/${tenant}/translations/${id}`, translation)
      return data
    },
    delete: async (id: string) => {
      return apiClient.delete<void, void>(`/brands/${tenant}/translations/${id}`)
    },
  }
}

export default useTranslationService
