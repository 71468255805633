import { trpc } from 'common/hooks/trpc'

import { FormikVariants, VariantFormValue } from '../types'
import { getInventoryItem } from '../utils'
import useSharedSkus from './useSharedSkus'

const useSharedQuantities = (locationId: string, variant: VariantFormValue, formik: FormikVariants) => {
  const inventoryItem = getInventoryItem(formik, variant)

  const skuSharedData = useSharedSkus(locationId, inventoryItem.sku, formik)

  const committedQueries = trpc.useQueries(t => skuSharedData.variantIds.map(id => t.variant.getCommittedById(id)))

  const totalCommitted = committedQueries.reduce((total, query) => (query.data?.committed ?? 0) + total, 0)

  if (skuSharedData.variantIds.length > 1) {
    return {
      committed: committedQueries.find(query => query.data?.id === variant.id)?.data?.committed ?? 0,
      totalCommitted: totalCommitted,
      available: (inventoryItem.stock ?? 0) - totalCommitted,
      isShared: true,
    }
  }

  return {
    committed: inventoryItem.committed,
    totalCommitted: inventoryItem.committed,
    available: (inventoryItem.stock ?? 0) - inventoryItem.committed,
    isShared: false,
  }
}

export default useSharedQuantities
