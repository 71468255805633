import { difference, intersection, uniq, without } from 'lodash'
import { useEffect, useState } from 'react'

export type BulkSelection = {
  selectAll: () => void
  deselectAll: () => void
  select: (ids: string[]) => void
  deselect: (ids: string[]) => void
  isSelected: (id: string) => boolean
  areAllSelected: () => boolean
  areNoneSelected: () => boolean
  selectedCount: () => number
  selectedIds: string[]
}

export type Group = {
  id: string
  count: number
}

const useBulkSelection = (ids: string[]): BulkSelection => {
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const selectAll = () => {
    setSelectedIds(ids)
  }

  const deselectAll = () => {
    setSelectedIds([])
  }

  const select = (ids: string[]) => {
    setSelectedIds(selectedIds => uniq([...selectedIds, ...ids]))
  }

  const deselect = (ids: string[]) => {
    setSelectedIds(selectedIds => without(selectedIds, ...ids))
  }

  const isSelected = (id: string) => {
    return selectedIds.includes(id)
  }

  const areAllSelected = () => {
    return difference(ids, selectedIds).length === 0
  }

  const areNoneSelected = () => {
    return selectedIds.length === 0
  }

  const selectedCount = () => {
    return selectedIds.length
  }

  useEffect(() => {
    setSelectedIds(selectedIds => intersection(selectedIds, ids))
  }, [ids])

  return {
    selectAll,
    deselectAll,
    select,
    deselect,
    isSelected,
    areNoneSelected,
    selectedCount,
    selectedIds,
    areAllSelected,
  }
}

export default useBulkSelection
