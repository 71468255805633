import { Brand } from '@packages/types'
import { useContext } from 'react'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import TenantContext from 'common/tenant/TenantContext'

const useBrandService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetchAccount: createQuery(['account'], async () => {
      const { data } = await apiClient.get<Brand>(`/brands/${tenant}/brands/account`)
      return data
    }),
    update: async (payload: Partial<Pick<Brand, 'business' | 'logo'>>) => {
      const { data } = await apiClient.post<Brand>(`/brands/${tenant}/brands`, payload)
      return data
    },
  }
}

export default useBrandService
