import { FontType } from '@packages/types'
import { createSelector } from 'reselect'

import { RootState } from 'customizer/store'

export const themeSelector = ({ theme }: RootState) => theme

type ThemeSettings = Record<string, Record<string, string | { fontFamily: string; type?: FontType }>>

export const fontsSelector = createSelector(themeSelector, ({ settings }) => {
  return Object.values((settings as ThemeSettings | undefined) || {}).reduce<
    Record<string, { fontFamily: string; type?: FontType }>
  >((fonts, setting) => {
    return Object.values(setting).reduce((fonts, value) => {
      if (typeof value !== 'string' && value?.fontFamily != null) {
        fonts[value.fontFamily] = value
      }
      return fonts
    }, fonts)
  }, {})
})

export const themeConfigSelector = (state: RootState, { themeName, id }: { themeName: string; id: string }) => {
  return state.customization.customizerProduct.themesConfiguration?.[themeName]?.[id]
}
