import React, { useEffect, useState } from 'react'

const useDoubleScrollbar = () => {
  const [width, setWidth] = useState('auto')
  const [hasScroll, setHasScroll] = useState(false)
  const [ref, setRef] = useState<HTMLDivElement | null>(null)
  const [outerRef, setOuterRef] = useState<HTMLDivElement | null>(null)

  const calculateWidth = () => {
    const { width, hasScroll } = getChildWrapperWidth()

    setWidth(width)
    setHasScroll(hasScroll)
  }

  useEffect(calculateWidth)

  useEffect(() => {
    calculateWidth()

    if (!ref || !outerRef) return

    window.addEventListener('resize', calculateWidth)

    const outerRefScrollHandler = () => {
      ref.scrollLeft = outerRef.scrollLeft
    }

    const refScrollHandler = () => {
      outerRef.scrollLeft = ref.scrollLeft
    }

    outerRef.addEventListener('scroll', outerRefScrollHandler)
    ref.addEventListener('scroll', refScrollHandler)
    return () => {
      outerRef.removeEventListener('scroll', outerRefScrollHandler)
      ref.removeEventListener('scroll', refScrollHandler)
    }
  }, [ref, outerRef])

  const getChildWrapperWidth = () => {
    return {
      width: ref ? ref.scrollWidth + 'px' : 'auto',
      hasScroll: ref?.scrollWidth !== ref?.getBoundingClientRect().width,
    }
  }

  return {
    componentProps: { ref: setRef },
    outerComponentProps: {
      ref: setOuterRef,
      style: { overflowX: 'auto', marginTop: hasScroll ? '-7px' : 0 } as React.CSSProperties,
    },
    innerComponentProps: { style: { paddingTop: hasScroll ? '1px' : 0, width } as React.CSSProperties },
  }
}

export default useDoubleScrollbar
