import React from 'react'

interface ColorThumbnailProps {
  color?: string
}

const ColorThumbnail = ({ color }: ColorThumbnailProps) => {
  return (
    <div
      className="rounded-full shrink-0 w-5 h-5 overflow-hidden mr-2 border border-solid border-neutral-75"
      style={{ backgroundColor: color }}
    />
  )
}

export default ColorThumbnail
