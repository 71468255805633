import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import useDocumentMoveHandlers from 'common/hooks/useDocumentMoveHandlers'

type CircleSliderValue = { x: number; y: number }

export interface CircleSliderProps {
  value: CircleSliderValue
  onChange: (value: CircleSliderValue) => void
  className?: string
  thumbClassName?: string
  thumbStyle?: (value: CircleSliderValue) => React.CSSProperties
}

const clampToRadius = (x: number, y: number) => {
  let clampedX = x
  let clampedY = y

  if (Math.pow(x, 2) + Math.pow(y, 2) > 1) {
    const angle = Math.atan2(y, x)

    clampedX = Math.cos(angle) * 1
    clampedY = Math.sin(angle) * 1
  }

  return { x: clampedX, y: clampedY }
}

const getClientPosition = (e: MouseEvent | React.MouseEvent | TouchEvent) => {
  if (e instanceof TouchEvent) return { x: e.touches[0].clientX, y: e.touches[0].clientY }

  return { x: e.clientX, y: e.clientY }
}

const CircleSlider = ({
  value: { x, y },
  onChange,
  className,
  thumbClassName,
  thumbStyle = () => ({}),
}: CircleSliderProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [innerValues, setInnerValues] = useState({ x, y })

  const getNewPosition = (e: MouseEvent | React.MouseEvent | TouchEvent) => {
    const rect = ref.current?.getBoundingClientRect()

    if (!rect) return

    const clientPosition = getClientPosition(e)

    const x = ((clientPosition.x - rect.x - 48) / 48) * -1
    const y = ((clientPosition.y - rect.y - 48) / 48) * -1

    return clampToRadius(x, y)
  }

  const handleMove = (_delta: { deltaX: number; deltaY: number }, e: MouseEvent | TouchEvent) => {
    const position = getNewPosition(e)

    if (!position) return

    setInnerValues(position)
  }

  const handleMoveEnd = (e: MouseEvent | TouchEvent) => {
    const position = getNewPosition(e)

    if (!position) return

    onChange(position)
  }

  const { onMouseDown, onTouchStart, isMoving } = useDocumentMoveHandlers({
    onMove: handleMove,
    onMoveEnd: handleMoveEnd,
  })

  useEffect(() => {
    if (isMoving.current) return

    setInnerValues(clampToRadius(x, y))
  }, [x, y])

  return (
    <div
      ref={ref}
      className={classNames('rounded-full w-24 h-24 relative', className)}
      onClick={e => {
        const position = getNewPosition(e)

        if (!position) return

        onChange(position)
      }}
    >
      <div
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        className={classNames(
          'w-4 h-4 rounded-full border-2 bg-primary-500 border-white shadow-xs absolute top-1/2 left-1/2 cursor-pointer hover:scale-110 transition-transform',
          thumbClassName
        )}
        style={{
          translate: `calc(${-innerValues.x * 48}px - 50%) calc(${-innerValues.y * 48}px - 50%)`,
          ...thumbStyle(innerValues),
        }}
      />
    </div>
  )
}

export default CircleSlider
