export type ApiFunctionWithQueryKey<T extends (...args: any[]) => any> = {
  (...args: Parameters<T>): ReturnType<T>
  queryKeys: string[]
}

function createQuery<T extends (...args: any[]) => any>(queryKeys: string[], callback: T): ApiFunctionWithQueryKey<T> {
  const newCallback = callback as any
  newCallback.queryKeys = queryKeys

  return newCallback as ApiFunctionWithQueryKey<T>
}

export default createQuery
