import { AnyAction } from 'redux'

import { PreviewMode } from 'common/preview/types/previewMode'
import * as customizationTypes from 'customizer/customization/actionTypes'
import isMobileDisplay from 'utils/isMobileDisplay'

import * as actionTypes from './actionTypes'

export interface TwoDDisplayerState {
  currentView: number
  zoom: number
  forceHighlight: string | null
  productOnly: boolean
  isZoomDisabled: boolean
  hiddenParts: string[]
  editedPrintArea: string | null
  renderedPrintArea: string | null
  editedPart?: string | null
  displayGrid: boolean
  isRenderCompleted: boolean
  preventTransform: boolean
  viewMode: PreviewMode
  isMobile: boolean
  size: { width: number; height: number }
}

export const initialState = {
  currentView: 0,
  zoom: 0,
  forceHighlight: null,
  productOnly: false,
  isZoomDisabled: false,
  hiddenParts: [],
  editedPrintArea: null,
  renderedPrintArea: null,
  editedPart: null,
  displayGrid: false,
  isRenderCompleted: false,
  viewMode: PreviewMode.DESKTOP,
  preventTransform: false,
  isMobile: isMobileDisplay(),
  size: { width: 0, height: 0 },
}

export default (state: TwoDDisplayerState = initialState, action: AnyAction): TwoDDisplayerState => {
  switch (action.type) {
    case actionTypes.HIGHLIGHT_PART:
      return { ...state, forceHighlight: action.payload }
    case actionTypes.SET_ZOOM:
      if (!state.editedPart) return { ...state, zoom: action.payload }

      return {
        ...state,
        zoom: action.payload,
        editedPart: null,
        hiddenParts: state.hiddenParts.filter(part => part !== state.editedPart),
      }
    case actionTypes.DISABLE_ZOOM:
      return { ...state, zoom: 0, isZoomDisabled: true }
    case actionTypes.ENABLE_ZOOM:
      return { ...state, isZoomDisabled: false }
    case actionTypes.SWITCH_VIEW:
      return action.payload !== state.currentView
        ? { ...state, editedPart: null, zoom: 0, hiddenParts: [], currentView: action.payload }
        : state
    case actionTypes.DISPLAY_GRID:
      return { ...state, displayGrid: action.payload }
    case actionTypes.UPDATE_RENDER_STATE:
      return { ...state, isRenderCompleted: action.payload.value }
    case customizationTypes.EDIT_PART:
      return {
        ...state,
        editedPart: action.payload.part.id,
        currentView: action.payload.view ?? state.currentView,
        hiddenParts: [action.payload.part.id],
        zoom: 0,
      }
    case customizationTypes.STOP_EDIT_PART:
      return { ...state, editedPart: null, hiddenParts: [] }
    case actionTypes.SET_IS_MOBILE:
      return { ...state, isMobile: action.payload }
    case actionTypes.SET_DISPLAYER_SIZE:
      return { ...state, size: action.payload }
    default:
      return state
  }
}
