import { FormikHelpers, useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import Button from 'common/components/Button'
import HelperText from 'common/components/inputs/HelperText'
import PasswordInput from 'common/components/inputs/PasswordInput'
import InputField from 'common/components/inputs/InputField'
import Label from 'common/components/inputs/Label'
import Card from 'common/components/card/Card'
import type { PasswordFormValues } from './../types/form'

interface ChangePasswordFormProps {
  onSubmit: (values: PasswordFormValues, helpers: FormikHelpers<PasswordFormValues>) => void
}

const validationSchema = yup.object().shape({
  password: yup.string().required('Please enter a new password').min(5, 'Password must be at least 5 characters long'),
  passwordConfirmation: yup
    .string()
    .required('Please confirm your password')
    .equals([yup.ref('password')], 'The specified password does not match'),
})

const ChangePasswordForm = ({ onSubmit }: ChangePasswordFormProps) => {
  const formik = useFormik<PasswordFormValues>({
    initialValues: { password: '', passwordConfirmation: '' },
    validationSchema,
    onSubmit,
  })

  return (
    <Card className="flex flex-1 flex-col">
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="p-6">
          <InputField className="mb-6">
            <Label htmlFor="password">New password</Label>
            <PasswordInput
              id="password"
              name="password"
              placeholder="Enter password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={formik.touched.password && formik.errors.password != null}
            />
            {formik.touched.password && formik.errors.password != null && (
              <HelperText hasError>{formik.errors.password}</HelperText>
            )}
          </InputField>
          <InputField>
            <Label htmlFor="passwordConfirmation">Confirm password</Label>
            <PasswordInput
              id="passwordConfirmation"
              name="passwordConfirmation"
              placeholder="Enter password"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation != null}
            />
            {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation != null && (
              <HelperText hasError>{formik.errors.passwordConfirmation}</HelperText>
            )}
          </InputField>
        </div>
        <Card.Footer>
          <Button type="reset" onClick={() => formik.resetForm()} disabled={!formik.dirty || formik.isSubmitting}>
            Discard
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            isLoading={formik.isSubmitting}
          >
            Save
          </Button>
        </Card.Footer>
      </form>
    </Card>
  )
}

export default ChangePasswordForm
