import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'

import Button from 'common/components/Button'
import TopBarEnvTag from 'common/components/topBar/TopBarEnvTag'
import TenantContext from 'common/tenant/TenantContext'
import useCurrentUser from 'common/users/hooks/useCurrentUser'
import LeftArrowIcon from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left.svg'
import BrandIcon from 'icons/custom/streamlinehq-money-shopping-online-business-money-shopping-250.svg'
import SkaterLogo from 'icons/logos/skater.svg'

const Header = () => {
  const history = useHistory()
  const { currentUser } = useCurrentUser()

  const handleLogOut = () => {
    localStorage.removeItem('bearer')
    history.push('/login')
  }

  const isAdminApp = window.location.host === `admin.${DOCUMENT_DOMAIN}`
  const tenant = useContext(TenantContext)

  if (!currentUser) return null

  return (
    <header className="print:hidden bg-white fixed top-0 flex justify-between items-center w-full border-b border-b-neutral-100 h-[52px] py-4 pl-5 pr-5 md:pr-[44px] z-[5]">
      <TopBarEnvTag />

      <Link to="/" className="flex items-center space-x-4">
        <SkaterLogo className="w-4" />
        {isAdminApp && tenant && (
          <>
            <div className="flex h-6 bg-neutral-100 w-[1px]" />
            <div aria-label="Impersonate brand" className="flex items-center hover:text-primary-500">
              <LeftArrowIcon className="w-2.5 h-2.5 mr-2 fill-current" />
              <BrandIcon className="w-4 h-4 mr-2 fill-current" /> {tenant}
            </div>
          </>
        )}
      </Link>

      <Button variant="subtle" onClick={handleLogOut}>
        Log out
      </Button>
    </header>
  )
}

export default Header
