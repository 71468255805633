import createCustomizerProductEntitySelectors from 'builder/build/core/createCustomizerProductEntitySelectors'
import { RootState } from 'cms/store'

import { getDescendantAnswers } from './utils'

const { answersSelector, answersAsArraySelector, answerByIdSelector } =
  createCustomizerProductEntitySelectors('answers')

export { answersSelector, answersAsArraySelector, answerByIdSelector }

export const getDescendantAnswersSelector = ({ productBuilder }: RootState, id: string) => {
  return getDescendantAnswers(productBuilder, id) || []
}
