import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'

const useCopyToClipboard = () => {
  const { openToast } = useToast()

  return (value: string, successMessage = 'Copied to clipboard') => {
    if (navigator.clipboard == null) {
      openToast('Clipboard is only available in https', ToastType.warning)
      return
    }

    return navigator.clipboard
      .writeText(value)
      .then(() => openToast(successMessage, ToastType.success, { dismissable: false, duration: 3000 }))
      .catch(() => openToast('Could not copy to clipboard', ToastType.warning))
  }
}

export default useCopyToClipboard
