import React from 'react'

import TopBarPublishDiscardButton from 'common/components/topBar/TopBarPublishDiscardButton'

interface PublishDiscardProps {
  onPublish: () => void
  onDiscard: () => void
  isDirty?: boolean
  isSubmitting?: boolean
  isDiscarding?: boolean
  isPublished?: boolean
  texts?: {
    publish?: string
    published?: string
  }
}

const PublishDiscard = ({
  onPublish,
  onDiscard,
  isDirty,
  isSubmitting,
  isDiscarding,
  isPublished,
  texts,
}: PublishDiscardProps) => {
  return (
    <TopBarPublishDiscardButton
      disabled={!isDirty || isSubmitting || isDiscarding || isPublished || false}
      isPublishing={isSubmitting || false}
      isPublished={isPublished || false}
      onPublish={onPublish}
      onDiscard={onDiscard}
      texts={texts}
    />
  )
}

export default PublishDiscard
