import { EquationLine, EquationLineType } from '@packages/types'
import { mapValues } from 'lodash'
import React from 'react'
import * as yup from 'yup'

import OperatorAddIcon from 'icons/custom/operation-add.svg'
import OperatorDivideIcon from 'icons/custom/operation-divide.svg'
import OperatorMultiplyIcon from 'icons/custom/operation-multiply.svg'
import OperatorSubstractIcon from 'icons/custom/operation-substract.svg'

export const equationSchema = yup.object().shape({
  options: yup
    .object()
    .shape({
      schema: yup.string(),
      visible: yup.boolean(),
      min: yup
        .number()
        .typeError('Please enter a valid value')
        .noWhitespace()
        .min(0, 'To avoid negative pricing, only positive values are allowed'),
      max: yup
        .number()
        .typeError('Please enter a valid value')
        .noWhitespace()
        .min(0, 'To avoid negative pricing, only positive values are allowed'),
    })
    .test('minMax', 'Min value must be less than max value', function (value) {
      if (value.min && value.max && value.min > value.max) {
        return this.createError({
          path: 'options.minMax',
          message: 'Min value must be less than max value',
        })
      }

      return true
    }),
  lines: yup
    .array()
    .of(
      yup.lazy((line: EquationLine) => {
        const base = yup.object().shape({
          operator: yup.string().oneOf(['+', '-', '*', '/'], 'Please select a valid type').required('Required'),
          type: yup
            .string()
            .oneOf([EquationLineType.Question, EquationLineType.Constant], 'Please select a valid type')
            .required('Required'),
          answersMap: yup
            .object()
            .shape(
              mapValues(line.answersMap, () =>
                yup
                  .number()
                  .required()
                  .typeError('Please enter a valid value')
                  .noWhitespace()
                  .min(0, 'To avoid negative pricing, only positive values are allowed')
              )
            ),
        })

        if (line.type === EquationLineType.Question) {
          return base.shape({
            value: yup.string().required('Required'),
          })
        }

        return base.shape({
          value: yup
            .number()
            .typeError('Please enter a valid value')
            .min(0, 'To avoid negative pricing, only positive values are allowed')
            .required('Required')
            .noWhitespace(),
        })
      })
    )
    .required('Required')
    .min(2, 'Equation must have at least 2 lines'),
})

export const operatorOptions = [
  { value: '+', label: <OperatorAddIcon aria-label="+" /> },
  { value: '-', label: <OperatorSubstractIcon aria-label="-" /> },
  { value: '*', label: <OperatorMultiplyIcon aria-label="*" /> },
  { value: '/', label: <OperatorDivideIcon aria-label="/" /> },
]
