import { Part } from '@packages/types'
import { capitalize } from 'lodash'
import React from 'react'

import * as customizerSelectors from 'builder/build/customizer/selectors'
import useCustomizerSelector from 'builder/build/customizer/hooks/useCustomizerSelector'
import * as displayerActions from 'builder/build/editor/actions'
import * as displayerSelectors from 'builder/build/editor/selectors'
import * as printAreasSelectors from 'builder/build/printAreas/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import Tooltip from 'common/components/Tooltip'
import Checkbox from 'common/components/inputs/Checkbox'

interface VisibilitySettingProps {
  part: Part
}

const VisibilitySetting = ({ part }: VisibilitySettingProps) => {
  const dispatch = useDispatch()
  const currentView = useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0
  const printAreas = useSelector(printAreasSelectors.printAreasSelector)
  const printArea = part.printArea ? printAreas[part.printArea] : null
  const visibility = useSelector(state =>
    displayerSelectors.visibilitySelector(state, printArea ? '' : part.id, currentView)
  )
  const printAreaVisibility = !printArea?.productPreview.views[currentView].hidden

  return (
    <>
      <Tooltip
        content={`${capitalize(part?.type)} visibility depends on ${printArea?.name}`}
        disabled={printArea == null || printArea.productPreview.designView !== currentView}
      >
        <Checkbox
          className="mr-2"
          aria-label="Show"
          onChange={() => dispatch(displayerActions.applyVisibilityChange(part.id, !visibility, currentView))}
          disabled={printArea != null}
          checked={printArea != null ? printAreaVisibility : !!visibility}
        />
      </Tooltip>
      <div className="h-8 w-[1px] bg-neutral-75 ml-1 mr-1" />
    </>
  )
}

export default VisibilitySetting
