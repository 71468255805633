import * as coreTypes from 'builder/build/core/actionTypes'

import * as types from './actionTypes'

export const addView = {
  type: types.ADD_VIEW,
}

export const deleteView = viewIndex => {
  return {
    type: types.DELETE_VIEW,
    payload: {
      viewIndex,
    },
  }
}

export const reorderViews = (originalIndex, newIndex) => {
  return {
    type: types.REORDER_VIEWS,
    payload: {
      originalIndex,
      newIndex,
    },
  }
}

export const updateDimensions = dimensions => ({
  type: coreTypes.SET_DIMENSIONS,
  payload: dimensions,
})
