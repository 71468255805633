import countryLanguages from '@packages/data/countryLanguages.json'
import { DefaultTheme } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import SettingsHeader from 'cms/layout/SettingsHeader'
import Page from 'cms/layout/page/Page'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import Button from 'common/components/Button'
import useModal from 'common/components/modal/useModal'
import { trpc } from 'common/hooks/trpc'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'

import useTranslationService from './../hooks/useTranslationService'
import AddLanguageModal from './AddLanguageModal'
import TranslationsTable from './TranslationsTable'

const Translations = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const queryClient = useQueryClient()
  const addLanguageModal = useModal()
  const translationService = useTranslationService()

  const translationsQuery = useQuery(translationService.fetchAll.queryKeys, translationService.fetchAll)
  const activeThemeQuery = trpc.theme.getCurrent.useQuery()

  const unhandledLanguages = countryLanguages.filter(
    lang => !translationsQuery.data?.find(({ code }) => code === lang.iso639_1)
  )

  const { mutate: addLanguage } = useMutation(translationService.create, {
    onSuccess: newTranslation => {
      addLanguageModal.close()
      queryClient.invalidateQueries(translationService.fetchAll.queryKeys)
      history.push(`${match.url}/${newTranslation.code}`)
    },
  })

  const isLoading = translationsQuery.isLoading || activeThemeQuery.isLoading
  const isFetching = translationsQuery.isFetching || activeThemeQuery.isFetching

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page aria-label="transaltions page">
        <Page.Header>
          <h1>Translations</h1>
          <Button
            aria-label="Add language"
            variant="primary"
            onClick={addLanguageModal.open}
            icon={<AddIcon className="w-3 h-3 fill-white" />}
          >
            Language
          </Button>
        </Page.Header>

        <TranslationsTable
          translations={translationsQuery.data || []}
          activeTheme={activeThemeQuery.data?.name || DefaultTheme}
          isLoading={isLoading}
          isFetching={isFetching}
          onAddTranslationClick={addLanguageModal.open}
        />
      </Page>

      {addLanguageModal.isVisible && (
        <AddLanguageModal
          onAdd={addLanguage}
          onClose={addLanguageModal.close}
          languages={unhandledLanguages}
          {...addLanguageModal.modalProps}
        />
      )}
    </main>
  )
}

export default Translations
