import React from 'react'
import { ReactReduxContextValue } from 'react-redux'
import { AnyAction } from 'redux'

import { RootState } from 'customizer/store'

export const NOT_READY = 'NOT_READY'

const CustomizerIframeProviderContext = React.createContext({} as ReactReduxContextValue<RootState, AnyAction>)

export default CustomizerIframeProviderContext
