import React from 'react'

import classMerge from 'utils/classMerge'

type TCell = React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>

export interface TableCellProps extends TCell {
  disabled?: boolean
  noFocus?: boolean
}

const TableCell = ({ children, className, disabled, noFocus, ...rest }: TableCellProps) => (
  <td
    className={classMerge(
      'px-1 py-2 group-focus-within:bg-primary-50',
      { 'opacity-25': disabled, 'group-focus-within:bg-white': noFocus },
      className
    )}
    {...rest}
  >
    {children}
  </td>
)

export default TableCell
