import { StripeSubscription, Subscription, SubscriptionStatus } from '@packages/types'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormikHelpers, useFormik } from 'formik'
import { capitalize } from 'lodash'
import React, { MouseEventHandler, useState } from 'react'
import * as yup from 'yup'

import useSubscriptionService from 'cms/subscription/hooks/useSubscriptionService'
import { NetworkError } from 'common/api/types/error'
import Button from 'common/components/Button'
import Card from 'common/components/card/Card'
import HelperText from 'common/components/inputs/HelperText'
import IconButton from 'common/components/IconButton'
import Input from 'common/components/inputs/Input'
import InputField from 'common/components/inputs/InputField'
import Label from 'common/components/inputs/Label'
import usePopover from 'common/components/popover/usePopover'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import useCurrentUser from 'common/users/hooks/useCurrentUser'
import CreditCardIcon from 'icons/bold/08-Money-Payments-Finance/09-Credit-Card-Payments/credit-card-1-alternate.svg'
import classMerge from 'utils/classMerge'

import type { PaymentDetailsBody } from './../../../types/service'
import { subscriptionHasError } from '../../../utils'
import PaymentCardSkeleton from '../skeletons/PaymentCardSkeleton'
import StripeFakeStaticCardInput from './StripeFakeStaticCardInput'
import StripePaymentErrorAlert from './StripePaymentErrorAlert'
import TestCardPopover from './TestCardPopover'

export interface StripePaymentCardProps {
  subscription?: StripeSubscription
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}

interface StripePaymentCardFormValues {
  name: string
  stripePaymentMethodId: string
}

const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter your name'),
  stripePaymentMethodId: yup.string().required('Please enter your card informations'),
})

const StripePaymentCard = ({ subscription, isEditing, setIsEditing }: StripePaymentCardProps) => {
  const [cardIsFocus, setCardIsFocus] = useState<boolean>(false)
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const subscriptionService = useSubscriptionService()
  const stripe = useStripe()
  const elements = useElements()
  const { isMCZRUser } = useCurrentUser()
  const { openGenericErrorToast, openToast } = useToast()
  const popover = usePopover({ placement: 'top-end', offsetConfig: 0 })

  const { mutate: updatePaymentDetails } = useMutation<Subscription, NetworkError, PaymentDetailsBody>(
    subscriptionService.updatePaymentDetails,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
        setIsEditing(false)
        setHasSubmitted(false)
      },
      onError: () => {
        setHasSubmitted(false)
      },
    }
  )

  const handleSubmit = async (
    values: StripePaymentCardFormValues,
    { setSubmitting, setFieldError }: FormikHelpers<StripePaymentCardFormValues>
  ) => {
    if (!isEditing || !stripe || !elements) return

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      openGenericErrorToast('Unable to complete subscription.')
      return
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: values.name,
      },
    })

    if (error) {
      openGenericErrorToast('Unable to complete subscription.')
      return
    }

    setHasSubmitted(true)
    return updatePaymentDetails(
      { name: values.name, stripePaymentMethodId: paymentMethod.id },
      {
        onSettled: () => setSubmitting(false),
        onError: error => {
          if (error.data.details) {
            openToast(error.data.details, ToastType.warning)
            setFieldError('stripePaymentMethodId', error.data.details)
          } else {
            openGenericErrorToast('Your subscription was not activated.')
          }
        },
      }
    )
  }

  const formik = useFormik<StripePaymentCardFormValues>({
    initialValues: {
      name: subscription?.stripeBillingDetails?.name || '',
      stripePaymentMethodId: subscription?.stripePaymentMethodId || '',
    },
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  })

  const handleChange = async (event: StripeCardElementChangeEvent) => {
    if (event.empty) {
      formik.setErrors({ stripePaymentMethodId: undefined })
    } else {
      formik.setErrors({ stripePaymentMethodId: event.error?.message })
    }

    if (event.complete) formik.setFieldValue('stripePaymentMethodId', 'completed')
  }

  const handleCancel: MouseEventHandler = e => {
    e.preventDefault()
    if (!stripe || !elements) {
      return
    }

    setCardIsFocus(false)

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) throw new Error("Can't get stripe CardElement")

    cardElement.clear()

    formik.resetForm()
    setIsEditing(false)
  }

  if (!subscription) return <PaymentCardSkeleton />
  const hasError = subscriptionHasError(subscription)

  return (
    <Card className="flex flex-1 flex-col">
      <form onSubmit={formik.handleSubmit}>
        <Card.Section className="space-x-6">
          <div className="flex flex-col flex-grow">
            <span className="text-neutral-400 text-xs leading-5 mb-4">Payment provider</span>
            <span className="text-neutral-900 text-sm">{capitalize(subscription.paymentStrategy)}</span>
          </div>
        </Card.Section>
        <Card.Separator />
        {(subscription.status !== SubscriptionStatus.FreeTrial || subscription.stripePaymentMethodId) && (
          <>
            <Card.Section className="flex-wrap">
              {hasError && <StripePaymentErrorAlert subscription={subscription} />}
              <InputField className="flex-1">
                <Label htmlFor="name" className="text-neutral-400 text-xs leading-5">
                  Name on card
                </Label>
                <Input
                  id="name"
                  name="name"
                  value={formik.values.name}
                  disabled={!isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.name && formik.errors.name != undefined}
                />
                {formik.touched.name && formik.errors.name != undefined && (
                  <HelperText hasError>{formik.errors.name}</HelperText>
                )}
              </InputField>
            </Card.Section>
            <Card.Separator />
            <Card.Section>
              <InputField className="flex-1 relative">
                <Label htmlFor="stripePaymentMethodId" className="text-neutral-400 text-xs leading-5">
                  Card Informations
                </Label>
                {isMCZRUser && (
                  <IconButton
                    {...popover.referenceProps}
                    className="absolute right-0 top-[-0.5rem]"
                    type="button"
                    variant="text"
                    iconClassName="w-4 fill-neutral-400"
                    Icon={CreditCardIcon}
                    onClick={popover.open}
                  />
                )}

                {!isEditing && subscription.stripeCard && (
                  <StripeFakeStaticCardInput
                    {...subscription.stripeCard}
                    postalCode={subscription.stripeBillingDetails?.postalCode}
                  />
                )}
                {isEditing && (
                  <>
                    <CardElement
                      id="stripePaymentMethodId"
                      className={classMerge('border-solid border p-2 border-neutral-75 rounded', {
                        'ring ring-primary-100 border-primary-200 z-[1]': cardIsFocus,
                        'border-error-default':
                          formik.touched.stripePaymentMethodId && formik.errors.stripePaymentMethodId != null,
                      })}
                      options={{
                        disabled: !isEditing,
                        style: {
                          base: {
                            fontSmoothing: 'antialiased',
                            fontSize: '16px',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: '#e24040',
                            iconColor: '#e24040',
                          },
                        },
                      }}
                      onFocus={() => {
                        formik.setFieldTouched('stripePaymentMethodId', true)
                        setCardIsFocus(true)
                      }}
                      onBlur={() => setCardIsFocus(false)}
                      onChange={handleChange}
                    />
                    {formik.touched.stripePaymentMethodId && formik.errors.stripePaymentMethodId != undefined && (
                      <HelperText hasError>{formik.errors.stripePaymentMethodId}</HelperText>
                    )}
                  </>
                )}
              </InputField>
            </Card.Section>
            <Card.Footer>
              {isEditing ? (
                <>
                  <Button type="button" onClick={handleCancel} disabled={formik.isSubmitting}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    onClick={() => formik.handleSubmit()}
                    isLoading={formik.isSubmitting || hasSubmitted}
                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || hasSubmitted}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button type="button" isLoading={formik.isSubmitting} onClick={() => setIsEditing(true)}>
                  Edit
                </Button>
              )}
            </Card.Footer>
          </>
        )}
      </form>
      <TestCardPopover {...popover.floatingProps} isOpen={popover.isOpen} />
    </Card>
  )
}

export default StripePaymentCard
