import { AppDispatch, RootState } from 'customizer/store'
import generatePrice from 'utils/generatePrice'

import { SET_PRICING } from './actionTypes'
import type { Pricing } from './types/pricing'

export const setPricing = (pricing?: Pricing) => ({ type: SET_PRICING, payload: pricing })

export const createFakePricing = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()
  const extraPrices =
    Object.values(state.customization.questions).reduce<Pricing['extraPrices']>(
      (result, question) => ({
        ...result,
        [question.id]: question.answers.reduce<Record<string, string>>(
          (answerResult, answerId) => ({ ...answerResult, [answerId]: generatePrice(10, 20) }),
          {}
        ),
      }),
      {}
    ) || {}

  dispatch({ type: SET_PRICING, payload: { basePrice: generatePrice(30, 50), currency: 'USD', extraPrices } })
}
