import { DropAnimationFunction, defaultDropAnimation, defaultDropAnimationSideEffects } from '@dnd-kit/core'
import { MutableRefObject } from 'react'

const { duration, easing, keyframes } = defaultDropAnimation

const sideEffects = defaultDropAnimationSideEffects({
  styles: {
    active: {
      opacity: '0.5',
    },
  },
})

const getDropanimation = (indentationDeltaX: MutableRefObject<number>): DropAnimationFunction => {
  return ({ active, dragOverlay, transform, ...rest }) => {
    if (!duration) {
      return
    }

    const delta = {
      x: dragOverlay.rect.left - active.rect.left,
      y: dragOverlay.rect.top - active.rect.top,
    }
    const scale = {
      scaleX: transform.scaleX !== 1 ? (active.rect.width * transform.scaleX) / dragOverlay.rect.width : 1,
      scaleY: transform.scaleY !== 1 ? (active.rect.height * transform.scaleY) / dragOverlay.rect.height : 1,
    }
    const finalTransform = {
      x: transform.x - delta.x + indentationDeltaX.current,
      y: transform.y - delta.y,
      ...scale,
    }

    const animationKeyframes = keyframes({
      ...rest,
      active,
      dragOverlay,
      transform: { initial: transform, final: finalTransform },
    })

    const [firstKeyframe] = animationKeyframes
    const lastKeyframe = animationKeyframes[animationKeyframes.length - 1]

    if (JSON.stringify(firstKeyframe) === JSON.stringify(lastKeyframe)) {
      // The start and end keyframes are the same, infer that there is no animation needed.
      return
    }

    const cleanup = sideEffects?.({ active, dragOverlay, ...rest })
    const animation = dragOverlay.node.animate(animationKeyframes, {
      duration,
      easing,
      fill: 'forwards',
    })

    return new Promise(resolve => {
      animation.onfinish = () => {
        cleanup?.()
        resolve()
      }
    })
  }
}

export default getDropanimation
