import { Product } from '@packages/types'

export interface AnswerRef {
  questionId: string
  answerId: string
}

export interface InventoryLevel {
  stock: number | null
  committed: number
}

export interface Variant<T extends Partial<Product> | string = string> {
  _id: string
  id: string
  name: string
  customizerProduct: string
  product: T
  productName: string
  combinationName: string
  answersRefs: AnswerRef[]
  createdAt: string
  updatedAt: string
  isNewFromPublish: boolean
  inventoryItem: InventoryItem
  continueSelling: boolean
}

export enum UpdateTypes {
  adjust = 'adjust',
  set = 'set',
}

export interface InventoryLevel {
  stock: number | null
  committed: number
}

export interface InventoryItem {
  _id: string
  id: string
  sku: string
  createdAt: string
  updatedAt: string
  inventoryLevelByLocationId: Record<string, InventoryLevel>
}

export interface InventoryItemWithStock extends Omit<InventoryItem, 'inventoryLevelByLocationId'> {
  stock: number | null
  committed: number
}

export interface Location {
  id: string
  isDefault: boolean
}

export type Group = {
  productName: string
  questionNames: string
  key: string
  variantIds: string[]
}

export type CombinationFilters = {
  _id: { productId: string; questionIds: string[] }
  productName: string
  questionNames: string
}[]

export interface PaginatedVariants {
  pagination: {
    collectionSize: number
    lastIndex: number
    resultSize: number
    sortKey: string
    sortOrder: 'ascending' | 'descending'
  }
  results: Variant[]
}

export type VariantIdsByCombination = {
  _id: { productId: string; questionIds: string[] }
  ids: string[]
}[]

export type InventorySortKeys = 'variant' | 'stock' | 'sku'
