import { Rule } from '@packages/types'

interface Params {
  rule: Rule
  answerId: string
  questionId: string
}

const isAnswerInRule = ({ rule, answerId, questionId }: Params) => {
  const isQuestionInThen = rule.then.reduce((acc, ruleThen) => ruleThen.questionId === questionId || acc, false)
  const isAnswerInThen = rule.then.reduce(
    (acc, ruleThen) => ruleThen.answerId === answerId || ruleThen.payload?.includes(answerId) || acc,
    false
  )

  const isQuestionInWhen = rule.when.reduce((acc, { path }) => path[1] === questionId || acc, false)
  const isAnswerInWhen = rule.when.reduce((acc, { value }) => {
    if (!value) return acc

    const isInRule = Array.isArray(value) ? value.includes(answerId) : value === answerId
    return isInRule || acc
  }, false)

  return (isQuestionInThen && isAnswerInThen) || (isQuestionInWhen && isAnswerInWhen)
}

export default isAnswerInRule
