import { Placement } from '@floating-ui/react'
import { EntityType } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import Tooltip from 'common/components/Tooltip'

import useHasImagesWithWrongDimensions from './../hooks/useHasImagesWithWrongDimensions'

const messages = {
  [EntityType.Group]: 'This group contains images that do not match the canvas size.',
  [EntityType.Question]: 'This question contains images that do not match the canvas size.',
  [EntityType.Answer]: 'This image size does not match the canvas size.',
} as { [key in EntityType.Group | EntityType.Question | EntityType.Answer]: string }

export interface AnswersDimensionsWarningProps {
  entityId: string
  entityType: EntityType.Group | EntityType.Question | EntityType.Answer
  placement?: Placement
  className?: string
  view?: number
}

const AnswersDimensionsWarning = ({
  entityId,
  entityType,
  placement,
  className,
  view,
}: AnswersDimensionsWarningProps) => {
  const hasUnmatchingDimensions = useHasImagesWithWrongDimensions(entityId, view)
  const message = hasUnmatchingDimensions ? messages[entityType] : ''

  if (!message) return null

  return (
    <Tooltip
      content={message}
      placement={placement}
      containerClassName={classNames('ml-1 mb-2 relative block', className)}
    >
      <div aria-label="Image size notification">
        <span className="flex h-2 w-2 items-center justify-center">
          <span className="animate-[ping_5s_cubic-bezier(0,0,1,8)_infinite] absolute inline-flex h-full w-full rounded-full opacity-75 bg-tertiary-red-300" />
          <span className={classNames('relative inline-flex rounded-full h-1.5 w-1.5 bg-tertiary-red-400')} />
        </span>
      </div>
    </Tooltip>
  )
}

export default AnswersDimensionsWarning
