import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import SettingsHeader from 'cms/layout/SettingsHeader'
import Page from 'cms/layout/page/Page'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import Alert from 'common/components/alert/Alert'
import BetaTag from 'common/components/BetaTag'
import BlankState from 'common/components/blankState/BlankState'
import Button from 'common/components/Button'
import Table from 'common/components/table/Table'
import Tag from 'common/components/Tag'
import useCopyToClipboard from 'common/hooks/useCopyToClipboard'
import { trpc } from 'common/hooks/trpc'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import KeyIcon from 'icons/regular/01-Interface Essential/04-Login-Logout/login-key.svg'

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}

const Tokens = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const copyToClipboard = useCopyToClipboard()

  const { data, isLoading, isFetching } = trpc.token.list.useQuery()

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page>
        <Page.Header>
          <h1>
            API Keys
            <BetaTag className="ml-2" />
          </h1>
          <Button
            aria-label="Create Key"
            variant="primary"
            onClick={() => history.push(`${match.url}/create`)}
            icon={<AddIcon className="w-3 h-3 fill-white" />}
          >
            Create key
          </Button>
        </Page.Header>

        <Alert className="mb-10">
          <Alert.Body>
            <Alert.Details>
              Our API is continuously in development. You will be notified of upcoming changes ahead of time so keep a
              look out for any updates.
            </Alert.Details>
          </Alert.Body>
        </Alert>

        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell className="w-3/12">
                <span>Name</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-4/12">
                <span>Scopes</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-3/12">
                <span>Creation date</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-2/12" />
            </Table.HeaderRow>
          </Table.Header>

          {isLoading && <Table.BodyLoader numberOfColumns={4} numberOfRows={10} />}

          {!isLoading && (
            <Table.Body>
              {isFetching && <Table.Loader colSpan={4} />}
              {data?.map(token => (
                <Table.Row key={token.id} className="hover:bg-white">
                  <Table.Cell>
                    <div className="min-w-fit">{token.name}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="min-w-fit space-x-1">
                      {token.scopes.map(scope => (
                        <Tag key={scope} className="bg-neutral-50 text-tertiary-red-700">
                          {scope}
                        </Tag>
                      ))}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    {token.createdAt ? (
                      <div className="min-w-fit">{formatDate(token.createdAt)}</div>
                    ) : (
                      <Table.EmptyCellIndicator />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="min-w-fit flex justify-end">
                      <Button variant="subtle" onClick={() => copyToClipboard(token.token)}>
                        Copy key
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}

          {!isLoading && !data?.length && (
            <Table.Body>
              <Table.Row className="hover:bg-white border-b-0">
                <Table.Cell colSpan={4} noFocus>
                  <div className="flex items-center justify-center py-32">
                    <BlankState>
                      <BlankState.Icon Icon={KeyIcon} />
                      <BlankState.Title>There are no API keys, yet</BlankState.Title>
                      <BlankState.Details>Create your first key now and get full access to our API.</BlankState.Details>
                      <BlankState.Action onClick={() => history.push(`${match.url}/create`)}>
                        Create API key
                      </BlankState.Action>
                    </BlankState>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Page>
    </main>
  )
}

export default Tokens
