import { OverflowBehavior } from '@packages/types'

import type { Node, MultilineTextNode, TextOnPathNode } from 'customizer/2dDisplayer/types/node'

import BoxTextEditor from './editors/BoxTextEditor'
import ImageEditor from './editors/ImageEditor'
import MultilineTextEditor from './editors/MultilineTextEditor'
import PathTextEditor from './editors/PathTextEditor'
import SingleLineTextEditor from './editors/SingleLineTextEditor'
import { EditorProps } from './editors/types'

export const isArc = (bezier: number[]) =>
  bezier[2] !== bezier[0] || bezier[3] !== bezier[1] || bezier[4] !== bezier[6] || bezier[5] !== bezier[7]

export const getTransformedNode = <T extends Node = Node>(
  editedNodeId: string,
  drawingNodes: Node[]
): T | undefined => {
  for (const node of drawingNodes) {
    if (node.id === editedNodeId) {
      return node as T
    } else if (node.type === 'clippingGroup') {
      const foundNode = getTransformedNode(editedNodeId, node.objects)
      if (foundNode) return foundNode as T
    }
  }
  return
}

export const getEditor = <T extends Node>(node: T): React.FunctionComponent<EditorProps<T>> => {
  if (node.type === 'image') return ImageEditor as React.FunctionComponent<EditorProps<T>>

  if ((node as MultilineTextNode).position.shape) {
    return (node as MultilineTextNode).allowedTransforms?.resize ||
      (node as MultilineTextNode).position.overflowBehavior === OverflowBehavior.Visible
      ? (MultilineTextEditor as React.FunctionComponent<EditorProps<T>>)
      : (BoxTextEditor as React.FunctionComponent<EditorProps<T>>)
  }

  if (isArc((node as TextOnPathNode).position.bezier)) return PathTextEditor as React.FunctionComponent<EditorProps<T>>

  return SingleLineTextEditor as React.FunctionComponent<EditorProps<T>>
}
