import 'react'

import { trpc } from 'common/hooks/trpc'

const useSharedSkus = (sku: string) => {
  const { data: skuSharedData } = trpc.variant.getSkuSharedData.useQuery(sku, {
    enabled: sku != null && sku !== '',
  })

  return skuSharedData
}

export default useSharedSkus
