import { DenormalizedGroup, DenormalizedQuestion, EntityType, GroupType, QuestionInputType } from '@packages/types'
import { uniq, isEqual } from 'lodash'
import React, { useState, useContext, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { createSelector } from 'reselect'

import CustomizerIframeContext from 'builder/build/customizer/CustomizerIframeContext'
import * as customizerProductsSelectors from 'builder/build/customizerProducts/selectors'
import { useSelector, useDispatch } from 'cms/hooks'
import { RootState } from 'cms/store'
import type { Containers } from 'common/components/sortableTree/types'
import SortableTree from 'common/components/sortableTree/SortableTree'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'

import { updateGroup } from '../actions'
import { rootGroupIdSelector } from '../selectors'
import BehindTheSceneContainer from './BehindTheSceneContainer'
import {
  bulkOrderInsideGroupsWarning,
  groupInsideBulkOrderWarning,
  inputTypeNoneWarning,
  linkedQuestionWarning,
} from './ToastTexts'
import TreeContainer from './TreeContainer'
import EntityTreeItem from './TreeItem/EntityTreeItem'
import { EntityTreeItem as EntityTreeItemType, TreeItemData } from './types'

const PADDING_PER_LEVEL = 14

export interface TreeProps {
  onCreateQuestionClick: () => void
}

const getTreeItem = (
  entity: DenormalizedGroup | DenormalizedQuestion,
  expandedItems: string[],
  parentId?: string
): EntityTreeItemType => {
  if (entity.entityType === EntityType.Group) {
    return {
      id: entity.id,
      value: entity,
      canHaveChildren: true,
      children: entity.children.map(item => getTreeItem(item, expandedItems, entity.id)),
      collapsed: !expandedItems.includes(entity.id),
      parentId,
    }
  }

  return {
    id: entity.id,
    canHaveChildren: false,
    value: entity,
    parentId,
  }
}

const expandedItemsSelector = (_: RootState, expandedItems: string[]) => expandedItems

const treeItemsSelector = createSelector(
  customizerProductsSelectors.denormalizedCustomizerProductSelector,
  customizerProductsSelectors.behindTheSceneQuestionIdsSelector,
  expandedItemsSelector,
  (denormalizedCustomizerProduct, behindTheSceneQuestionIds, expandedItems) => {
    if (!denormalizedCustomizerProduct.tree) return { tree: [], behindTheScene: [] }

    const behindTheSceneItems = denormalizedCustomizerProduct.questions
      .filter(question => behindTheSceneQuestionIds.includes(question.id))
      .map(question => ({ id: question.id, canHaveChildren: false, value: question, behindTheScene: true }))

    return {
      tree: denormalizedCustomizerProduct.tree.children.map(item => getTreeItem(item, expandedItems)),
      behindTheScene: behindTheSceneItems,
    }
  }
)

const Tree = ({ onCreateQuestionClick }: TreeProps) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { openToast } = useToast()
  const [expandedItems, setExpandedItems] = useState<string[]>([])
  const { iframe } = useContext(CustomizerIframeContext)
  const treeGroupId = useSelector(rootGroupIdSelector)
  const items: Containers<TreeItemData> = useSelector(state => treeItemsSelector(state, expandedItems))
  const match = useRouteMatch<{ questionId?: string; id?: string }>()

  const handleToggleCollapse = (_containerId: string, itemId: string) => {
    setExpandedItems(expandedItems =>
      expandedItems.includes(itemId)
        ? expandedItems.filter(expandedItemId => expandedItemId != itemId)
        : [...expandedItems, itemId]
    )
  }

  useEffect(() => {
    if (location.pathname !== '/') return
    const firstItem = items.tree?.[0] ?? items.behindTheScene[0]

    if (firstItem?.value.entityType === EntityType.Question) return history.push(`/questions/${firstItem.id}`)
    else if (firstItem?.value.entityType === EntityType.Group) return history.push(`/groups/${firstItem.id}`)
  }, [location.pathname, items])

  const removeEvents = () => {
    if (iframe) iframe.style.pointerEvents = 'none'
    document.body.style.pointerEvents = 'none'
  }

  const addEvents = () => {
    if (iframe) iframe.style.pointerEvents = ''
    document.body.style.pointerEvents = ''
  }

  const handleDragStart = () => removeEvents()

  useEffect(() => {
    if (!match.params.id) return

    let item = getItem(match.params?.id, { children: items.tree } as EntityTreeItemType)

    const idsToExpand: string[] = []
    while (item != null && (item = getItem(item.parentId, { children: items.tree } as EntityTreeItemType))) {
      if (item?.canHaveChildren && item?.collapsed) idsToExpand.push(item.id)
      item = getItem(item.parentId, { children: items.tree } as EntityTreeItemType)
    }
    setExpandedItems(expandedItems => uniq([...expandedItems, ...idsToExpand]))
  }, [match.params?.id])

  const getItem = (id: string | undefined, item: EntityTreeItemType): EntityTreeItemType | undefined => {
    if (id == null) return

    if (item.id === id) return item

    if (item.children) {
      for (const childItem of item.children) {
        const foundItem = getItem(id, childItem)
        if (foundItem) return foundItem
      }
    }

    return
  }

  const validateNewChildren = (items: EntityTreeItemType[]) => {
    for (const item of items) {
      if (item.value.entityType === EntityType.Question) {
        if (item.value.inputType === QuestionInputType.None) {
          openToast(inputTypeNoneWarning, ToastType.warning)
          return false
        }

        if (item.value.linkedQuestionId) {
          openToast(linkedQuestionWarning, ToastType.warning)
          return false
        }
      }

      if (item.value.entityType === EntityType.Group) {
        const isBulkOrder = item.value.type === GroupType.BulkOrder
        const containsAGroup = !!item.children?.find(child => child.value.entityType === EntityType.Group)
        const containsABulkOrder = !!item.children?.find(child => child.value.type === GroupType.BulkOrder)

        if (isBulkOrder && containsAGroup) {
          openToast(groupInsideBulkOrderWarning, ToastType.warning)
          return false
        }

        if (containsABulkOrder) {
          openToast(bulkOrderInsideGroupsWarning, ToastType.warning)
          return false
        }
      }
    }

    return true
  }

  const getUpdates = (newItems: Containers<TreeItemData>) => {
    const newTreeChildren = newItems.tree.map(item => item)
    const treeChildren = items.tree.map(item => item.id)

    const isRootDiff = !isEqual(newTreeChildren, treeChildren)

    const getDiffs = (diffs: EntityTreeItemType[], item: EntityTreeItemType): EntityTreeItemType[] => {
      if (item.children) {
        const oldItem = getItem(item.id, { children: items.tree } as EntityTreeItemType)
        const newTreeChildren = item.children.map(item => item.id)
        const treeChildren = oldItem!.children!.map(item => item.id)

        const childrenDiffs = item.children.reduce(getDiffs, [])
        if (!isEqual(newTreeChildren, treeChildren)) return [...diffs, item, ...childrenDiffs]

        return [...diffs, ...childrenDiffs]
      }

      return diffs
    }

    const treeDiff = newItems.tree.reduce(getDiffs, [])

    return [isRootDiff ? newTreeChildren : [], treeDiff]
  }

  const validateDragEnd = (newItems: Containers<TreeItemData>) => {
    const [newRootChildren, treeDiff] = getUpdates(newItems)

    return (
      !newItems.behindTheScene.some(item => item.canHaveChildren) &&
      (newRootChildren.length > 0 ? validateNewChildren(newRootChildren) : true) &&
      treeDiff.every(item => validateNewChildren(item.children!))
    )
  }

  const handleDragEnd = () => addEvents()

  const handleReorder = (newItems: Containers<TreeItemData>) => {
    const [newRootChildren, treeDiff] = getUpdates(newItems)

    if (newRootChildren.length > 0)
      dispatch(updateGroup(treeGroupId, { children: newRootChildren.map(item => item.id) }))
    treeDiff.forEach(item => {
      dispatch(updateGroup(item.id, { children: item.children!.map(item => item.id) }))
    })
  }

  return (
    <SortableTree
      className="flex flex-col flex-grow overflow-hidden"
      indentationWidth={PADDING_PER_LEVEL}
      containers={items}
      Containers={{
        tree: TreeContainer,
        behindTheScene: BehindTheSceneContainer,
      }}
      containersProps={{ tree: { onCreateQuestionClick } }}
      validateDragEnd={validateDragEnd}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onReorder={handleReorder}
      onToggleCollapse={handleToggleCollapse}
      Item={EntityTreeItem}
    />
  )
}

export default Tree
