import { Question, QuestionType } from '@packages/types'
import classNames from 'classnames'
import React, { useState } from 'react'

import * as selectors from 'builder/build/answers/selectors'
import CreateAnswerPanel from 'builder/build/answers/components/CreateAnswerPanel'
import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import * as previewActions from 'builder/build/preview/actions'
import { useDispatch, useSelector } from 'cms/hooks'
import Tooltip from 'common/components/Tooltip'
import IconButton from 'common/components/IconButton'
import usePopover from 'common/components/popover/usePopover'
import Popover from 'common/components/popover/Popover'
import { trpc } from 'common/hooks/trpc'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import LibraryIcon from 'icons/custom/new-library.svg'

import { importAnswers, previewCreateAnswer } from '../../actions'
import ImportAnswersPanel from '../ImportAnswersPanel'

const answerListSectionTitles = {
  [QuestionType.Color]: 'Color answers',
  [QuestionType.Font]: 'Font answers',
  [QuestionType.FontSize]: 'Font size answers',
  [QuestionType.Image]: 'Image answers',
  [QuestionType.Material]: 'Color answers',
  [QuestionType.Text]: 'Text answers',
  [QuestionType.Value]: 'Value answers',
  [QuestionType.Logo]: 'Logo answers',
  [QuestionType.Outline]: 'Outline answers',
}

interface AnswerListTitleProps {
  question: Question
  allowImport?: boolean
  isClosing?: boolean
}

const AnswerListTitle = ({ question, allowImport = true, isClosing = false }: AnswerListTitleProps) => {
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const [createdAnswerId, setCreatedAnswerId] = useState<string | null>()
  const importPopover = usePopover({ placement: 'bottom-end' })
  const createPopover = usePopover({
    placement: 'bottom-end',
    shiftConfig: { mainAxis: false, crossAxis: true, padding: 8 },
  })

  const handleCancel = () => {
    createPopover.close()
    customizerDispatch(previewActions.removeAnswerPreview(question.id, createdAnswerId!))
  }

  const handleCreateAnswer = () => {
    if (createPopover.isOpen) {
      handleCancel()
    } else {
      const { payload } = dispatch(previewCreateAnswer(question.id, question.type))
      customizerDispatch(previewActions.addAnswerPreview(question.id, payload.id))
      setCreatedAnswerId(payload.id)
      createPopover.open()
    }
  }

  const createdAnswer = useSelector(state => selectors.answerByIdSelector(state, { id: createdAnswerId }))
  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation()

  return (
    <div className={classNames('mx-4 flex items-center', { 'mb-2': question.answers.length > 0 })}>
      <span className="font-medium mr-auto">{answerListSectionTitles[question.type]}</span>
      <div className="flex space-x-1">
        {allowImport && (
          <Tooltip content="Import" containerClassName="flex">
            <IconButton Icon={LibraryIcon} {...importPopover.referenceProps} />
            <Popover
              {...importPopover.floatingProps}
              isOpen={importPopover.isOpen && !isClosing}
              className="w-[252px] builder"
            >
              <ImportAnswersPanel questionId={question.id} onImport={importPopover.close} />
            </Popover>
          </Tooltip>
        )}
        <IconButton
          {...createPopover.referenceProps}
          onClick={handleCreateAnswer}
          Icon={AddIcon}
          smallIcon
          iconClassName={classNames('transition-transform', {
            'rotate-45': createPopover.isOpen,
          })}
        />
        <Popover
          {...createPopover.floatingProps}
          isOpen={createPopover.isOpen}
          className="!w-[252px] h-full max-h-[524px]"
        >
          <CreateAnswerPanel
            answer={createdAnswer}
            cancel={() => {
              handleCancel()
            }}
            create={() => {
              dispatch(importAnswers(question.id, [createdAnswerId]))
              setCreatedAnswerId(null)
              createActivityLog({ type: 'createAnswer' })
              createPopover.close()
            }}
          />
        </Popover>
      </div>
    </div>
  )
}

export default AnswerListTitle
