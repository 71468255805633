import React from 'react'

import classMerge from 'utils/classMerge'

const UniversalSearchListTitle = ({ className, children, ...props }: React.ComponentProps<'div'>) => {
  return (
    <div {...props} className={classMerge('text-neutral-500 px-3 flex items-center space-x-2', className)}>
      {children}
    </div>
  )
}

export default UniversalSearchListTitle
