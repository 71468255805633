import { TypedUseSelectorHook, createSelectorHook } from 'react-redux'

import { RootState } from 'customizer/store'

import CustomizerIframeProviderContext, { NOT_READY } from '../CustomizerIframeProviderContext'

const useCustomizerSelector = createSelectorHook(CustomizerIframeProviderContext)

const useCustomizerSelectorIfReady = (selector: Parameters<typeof useCustomizerSelector>[0]) => {
  const state = useCustomizerSelector(state => state) as RootState | typeof NOT_READY
  const isReady = state !== NOT_READY
  return useCustomizerSelector(isReady ? selector : () => undefined)
}

export default useCustomizerSelectorIfReady as TypedUseSelectorHook<RootState>
