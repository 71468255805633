import { RuleThenType, GroupType, EntityType, QuestionInputType } from '@packages/types'
import { union, filter } from 'lodash'

import * as constants from 'common/customizerProducts/constants'

const createTextModifier = (name, fn) => {
  fn.label = name
  return fn
}

export const getActiveAnswers = answers => filter(answers, e => !e.archived)

export const textModifiers = {
  uppercase: createTextModifier('Uppercase', text => text.toUpperCase()),
  lowercase: createTextModifier('Lowercase', text => text.toLowerCase()),
}

export const applyTextModifiers = (modifiers = [], text) => {
  return modifiers.reduce((modifiedText, { name: modifierName }) => textModifiers[modifierName](text), text)
}

export const getQuestionRestrictedAnswers = question =>
  Object.values(question.restrictions ?? {}).reduce(
    (allRestrictions, { answers }) => union(allRestrictions, answers),
    []
  )

const getRestrictionsTypes = question =>
  Object.values(question.restrictions ?? {}).reduce((result, restriction) => [...result, restriction.type], [])

export const questionHasAvailableAnswers = question => {
  const restrictedAnswers = getQuestionRestrictedAnswers(question)
  const activeAnswers = getActiveAnswers(question.answers)
  const restrictedActiveAnswers = restrictedAnswers.filter(
    restrictedAnswer => !!activeAnswers.find(activeAnswer => activeAnswer.id === restrictedAnswer)
  )
  const restrictionsTypes = getRestrictionsTypes(question)

  if (restrictionsTypes.includes(RuleThenType.DisableQuestion)) return false

  return (
    restrictedActiveAnswers.length < activeAnswers.length ||
    (restrictedActiveAnswers.length === 0 &&
      [QuestionInputType.Text, QuestionInputType.File].includes(question.inputType))
  )
}

export const questionIsAvailable = (question, ignoreIsHiddenWhenEmpty) => {
  if (!ignoreIsHiddenWhenEmpty && !question.isHiddenWhenEmpty) return true

  return questionHasAvailableAnswers(question)
}

export const stepHasQuestionWithAvailableAnswers = (step, ignoreIsHiddenWhenEmpty = true) => {
  if (step.type === GroupType.BulkOrder) {
    return step
  }

  if (step?.entityType === EntityType.Question) return questionIsAvailable(step, ignoreIsHiddenWhenEmpty)

  return !!step?.children?.reduce(
    (allChoicesRestricted, question) =>
      allChoicesRestricted || stepHasQuestionWithAvailableAnswers(question, ignoreIsHiddenWhenEmpty),
    false
  )
}

export const stepIsAvailable = step => {
  if (step?.entityType === EntityType.Question) return questionIsAvailable(step)

  return step?.children?.reduce((isAvailable, question) => {
    return isAvailable || stepIsAvailable(question)
  }, false)
}

export const isAncestor = (step, childId) => {
  if (step.entityType === EntityType.Question) return step.id === childId

  if (step.id === childId) return true

  for (const child of step.children) {
    if (isAncestor(child, childId)) return true
  }

  return false
}

export const getDenormalizedPartFromQuestion = (questionId, parts) => {
  return parts.find(part => {
    const fields = constants.parts.questionFields[part.type]

    return fields.find(({ field }) => part[field]?.id === questionId)
  })
}

export const getPartFromQuestion = (questionId, parts) => {
  return parts.find(part => {
    const fields = constants.parts.questionFields[part.type]

    return fields.find(({ field }) => part[field] === questionId)
  })
}

export const getFirstLevelAncestorId = (groups, entityId, rootId) => {
  const groupParent = groups.find(group => group.children.includes(entityId))

  if (groupParent && groupParent.id !== rootId) return getFirstLevelAncestorId(groups, groupParent.id, rootId)

  return entityId
}

export const isBehindTheScene = (groups, question) => {
  return (
    Object.values(groups).find(group => group.children.includes(question.id)) == null &&
    constants.questions.editableTypes.includes(question.type)
  )
}

export const countSelectionsLeft = question => {
  const hasMaxSelections =
    typeof question.maxSelections === 'number' &&
    !isNaN(question.maxSelections) &&
    question.maxSelections < question.answers.length

  if (!hasMaxSelections || question.selectedAnswers == null) return null

  return question.maxSelections - question.selectedAnswers.length
}
