import { useRef, useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as ResizeObserver from 'resize-observer-polyfill'

import CustomizerIframeContext from 'builder/build/customizer/CustomizerIframeContext'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as previewModeSelectors from 'builder/build/preview/selectors'
import useObserveDimensionsOnResize from 'common/hooks/useObserveDimensionsOnResize'
import * as twoDDisplayerSelectors from 'customizer/2dDisplayer/selectors'
import type { Dimensions } from 'customizer/2dDisplayer/types/dimensions'

const useObserveDisplayerDimensions = () => {
  const { iframe } = useContext(CustomizerIframeContext)

  const previewMode = useSelector(previewModeSelectors.previewModeSelector)
  const dimensions = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.dimensionsSelector)

  const [displayerDimensions, setDisplayerDimensions] = useState<{
    width: number
    height: number
    x: number
    y: number
    scale: number
  }>()

  const getIframePosition = (displayerDimensions: Dimensions) => {
    const canvasRect = iframe?.contentWindow?.document?.querySelector('.canvas-container')?.getBoundingClientRect()
    const iframeRect = iframe?.getBoundingClientRect()

    const stageScale = Math.min(
      displayerDimensions.width / dimensions.width,
      displayerDimensions.height / dimensions.height
    )

    let offsetInfos = {
      x: 0,
      y: 0,
      scale: stageScale,
    }

    if (canvasRect != null && iframeRect != null) {
      const iframeScale = previewMode !== 'mobile' && iframeRect.width < 1000 ? iframeRect.width / 1000 : 1

      const canvasTopOffset = ((canvasRect.height - dimensions.height * stageScale) / 2) * iframeScale
      const canvasLeftOffset = ((canvasRect.width - dimensions.width * stageScale) / 2) * iframeScale
      const screenTopOffset = canvasRect.top * iframeScale + iframeRect.top + canvasTopOffset
      const screenLeftOffset = canvasRect.left * iframeScale + iframeRect.left + canvasLeftOffset

      offsetInfos = {
        x: screenLeftOffset,
        y: screenTopOffset,
        scale: stageScale * iframeScale,
      }
    }
    return offsetInfos
  }

  const onDimensionsChange = (displayerDimensions: Dimensions) => {
    const iframePosition = getIframePosition(displayerDimensions)

    setDisplayerDimensions({
      ...dimensions,
      ...iframePosition,
    })
  }

  const displayerRef = useRef((iframe?.contentWindow?.document?.querySelector('.displayer') as HTMLElement) ?? null)

  useObserveDimensionsOnResize(displayerRef, onDimensionsChange)

  const resizeObserver = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    resizeObserver.current = new ResizeObserver.default(() => {
      const rect = iframe?.contentWindow?.document?.querySelector('.displayer')?.getBoundingClientRect()

      if (!rect) return

      onDimensionsChange({ width: rect.width, height: rect.height })
    })

    resizeObserver.current.observe(document.body)

    return () => {
      resizeObserver.current?.disconnect()
      resizeObserver.current = null
    }
  }, [])

  return displayerDimensions
}

export default useObserveDisplayerDimensions
