import React from 'react'

import BlankState from 'common/components/blankState/BlankState'
import SkateboardIcon from 'icons/custom/bold-skateboard.svg'

export interface DemoSearchBlankStateProps {
  clearFilters: React.MouseEventHandler
}

const DemoSearchBlankState = ({ clearFilters }: DemoSearchBlankStateProps) => {
  return (
    <div className="w-[780px] min-[1120px]:w-[1040px] min-[1380px]:w-[1300px] min-[1640px]:w-[1560px] flex items-center justify-center pt-16">
      <BlankState className="w-full md:w-[400px]">
        <BlankState.Icon Icon={SkateboardIcon} className="text-neutral-300" />
        <BlankState.Title>No demo to display</BlankState.Title>
        <BlankState.Details>Change or clear filters</BlankState.Details>
        <BlankState.Action aria-label="clear filters" onClick={clearFilters}>
          Clear Filters
        </BlankState.Action>
      </BlankState>
    </div>
  )
}

export default DemoSearchBlankState
