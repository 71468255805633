import React from 'react'

import Modal from 'common/components/modal/Modal'

const UpdateCount = ({ text, count = 0 }: { text: string; count: number }) => {
  if (count === 0) return null
  return (
    <Modal.ActionsMessage className="text-neutral-500 flex items-center space-x-2">
      <span>
        <span className="text-primary-600">{count}</span> {text}
        {count > 1 ? 's' : ''} will be updated
      </span>
    </Modal.ActionsMessage>
  )
}

export default UpdateCount
