import { NormalizedCustomizerProduct } from '@packages/types'

export const getCombinationName = (combination: string[], customizerProduct: NormalizedCustomizerProduct) =>
  combination.map(questionId => customizerProduct.questions[questionId]?.name).join(' / ')

export const getCombinationVariantsCount = (combination: string[], customizerProduct: NormalizedCustomizerProduct) =>
  combination.reduce(
    (count, questionId) => (count === 0 ? 1 : count) * customizerProduct.questions[questionId]?.answers.length,
    0
  )
