import classNames from 'classnames'
import React from 'react'

import useToggleWithDelay from 'builder/build/core/useToggleWithDelay'
import Checkbox from 'common/components/inputs/Checkbox'

import './ActivatableInput.scss'

export interface ActivatableInputProps {
  id?: string
  title: React.ReactNode
  Icon?: React.ReactNode
  children: React.ReactNode
  onActivationChange: (isActive: boolean) => void
  isActive?: boolean
  titleClassName?: string
}

const ActivatableInput = ({
  Icon,
  title,
  titleClassName,
  onActivationChange,
  isActive,
  children,
  id,
}: ActivatableInputProps) => {
  const [internalIsActive, onInternalIsActiveChange] = useToggleWithDelay(isActive, onActivationChange, 300)

  return (
    <div className={classNames('activatable-input', { 'activatable-input--active': internalIsActive })}>
      <div className={classNames('flex', { 'mb-4': internalIsActive })}>
        {Icon}
        <label htmlFor={id} className={classNames('mr-auto hover:cursor-pointer', titleClassName)}>
          {title}
        </label>
        <Checkbox id={id} onChange={onInternalIsActiveChange} checked={internalIsActive} />
      </div>

      <div className="activatable-input__wrapper">
        <div className="activatable-input__field">{children}</div>
      </div>
    </div>
  )
}

export default ActivatableInput
