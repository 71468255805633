import React from 'react'

import Tooltip, { TooltipProps } from 'common/components/Tooltip'

const NewTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      {...props}
      content={
        <span>
          New variants are created when you add answers and <span className="font-medium">publish</span> your product.
        </span>
      }
    />
  )
}

export default NewTooltip
