import classNames from 'classnames'
import React from 'react'

type TRow = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>

export type TableRowVariant = 'default' | 'grouped'

export interface TableRowProps extends TRow {
  disabled?: boolean
  variant?: TableRowVariant
}

const TableRow = ({ children, className, variant = 'default', disabled, ...rest }: TableRowProps) => (
  <tr
    {...rest}
    className={classNames('border-b last:border-b-0 border-neutral-100 group', className, {
      'bg-neutral-50': disabled || variant === 'grouped',
      'hover:bg-neutral-50': rest.onClick != null,
    })}
  >
    {children}
  </tr>
)

TableRow.Grouped = (props: Omit<TableRowProps, 'variant'>) => <TableRow {...props} variant="grouped" />

export default TableRow
