import { Question } from '@packages/types'
import classNames from 'classnames'
import { History } from 'history'
import React from 'react'
import { useHistory } from 'react-router-dom'

import TypeIcons from 'builder/build/common/components/TypeIcons'
import * as partsSelectors from 'builder/build/parts/selectors'
import { useSelector } from 'cms/hooks'
import Tooltip from 'common/components/Tooltip'

import QuestionPanelScrim from './QuestionPanelScrim'

interface QuestionTabProps {
  question: Question
  history: History
  isActive: boolean
}

const QuestionTab = ({ question, history, isActive }: QuestionTabProps) => {
  const handleClick = () => history.push(`/questions/${question.id}`)

  return (
    <Tooltip
      content={question.name}
      placement="left"
      onClick={handleClick}
      containerClassName={classNames(
        'group/icons origin-right min-w-[42px] left-[1px] w-max -translate-x-full bg-white rounded-l-md mb-1 flex border-t border-l border-b border-neutral-100 items-center h-[42px] relative overflow-hidden',
        {
          'z-[2]': isActive,
        }
      )}
    >
      <QuestionPanelScrim />
      <div
        className={classNames('flex pl-1 pr-1', {
          'opacity-60 group/icons-hover:opacity-100': !isActive,
        })}
        aria-label={question.name}
      >
        <TypeIcons entity={question} />
      </div>
    </Tooltip>
  )
}

interface QuestionTabsProps {
  question: Question
}

const QuestionTabs = ({ question }: QuestionTabsProps) => {
  const history = useHistory()
  const partQuestions = useSelector(state => partsSelectors.questionSiblingsSelector(state, question.id))
  const questions = partQuestions.length > 0 ? partQuestions : [question]

  return (
    <div className="absolute left-0 top-[74px] transition-[top] duration-[ease-in-out] delay-[0.2s]">
      {questions.map(partQuestion => (
        <QuestionTab
          key={partQuestion.id}
          history={history}
          question={partQuestion}
          isActive={partQuestion.id === question.id}
        />
      ))}
    </div>
  )
}

export default QuestionTabs
