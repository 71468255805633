import React from 'react'

import Button from 'common/components/Button'
import Modal, { ModalProps } from 'common/components/modal/Modal'
import PlayIcon from 'icons/bold/15-Video-Movies-TV/06-Controls/controls-play.svg'
import OnBoadingImage from 'img/how-to-build-a-customizer-in-2-minutes.png'

interface OnBoardingBuilderModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
  openVideoModal: () => void
}

const OnBoardingBuilderModal = ({ onClose, openVideoModal, ...modalProps }: OnBoardingBuilderModalProps) => {
  return (
    <Modal size="medium" {...modalProps}>
      <Modal.Image>
        <img src={OnBoadingImage} className="h-[375px]" />
      </Modal.Image>
      <Modal.Title>
        <span className="text-xl">Get the basics in this short video</span>
      </Modal.Title>
      <Modal.Details>See how we use Kickflip to create this jersey customizer in just 2 minutes.</Modal.Details>
      <Modal.Actions>
        <span
          onClick={onClose}
          className="cursor-pointer text-neutral-600 font-poppins text-sm font-medium leading-12px mr-3"
        >
          Skip
        </span>
        <Button variant="green" icon={<PlayIcon className="w-3 h-3 fill-white" />} onClick={openVideoModal}>
          2min
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default OnBoardingBuilderModal
