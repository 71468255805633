import { FontAssets, FontType } from '@packages/types'
import React, { MutableRefObject, useRef } from 'react'

import { getCenterFromPoints, getRotationFromPoints, rotatePoint } from 'utils/math'

import SVGPathText from './SVGPathText'

export interface SingleLineTextProps {
  bezier: number[]
  targetRef: MutableRefObject<HTMLDivElement | null>
  fontSize: number
  fontFamily: string
  fontType?: FontType
  color: string
  text: string
  showPath?: boolean
  textAlign: 'left' | 'center' | 'right'
  outlineColor?: string
  outlineWidth?: number
  fontAssets: FontAssets
}

const SingleLineText = ({ bezier, targetRef, ...rest }: SingleLineTextProps) => {
  const pathRef = useRef<SVGPathElement>(null)

  const center = getCenterFromPoints([
    { x: bezier[0], y: bezier[1] },
    { x: bezier[2], y: bezier[3] },
    { x: bezier[6], y: bezier[7] },
    { x: bezier[4], y: bezier[5] },
  ])
  const rotation = getRotationFromPoints([
    { x: bezier[0], y: bezier[1] },
    { x: bezier[6], y: bezier[7] },
  ])

  const leftX = bezier[0]
  const leftY = bezier[1]
  const rightX = bezier[6]
  const rightY = bezier[7]

  const unrotatedLeft = rotatePoint({ x: leftX, y: leftY }, -rotation, center)
  const unrotatedRight = rotatePoint({ x: rightX, y: rightY }, -rotation, center)

  const width = Math.abs(unrotatedLeft.x - unrotatedRight.x)

  const offsetY = (Math.cos(rotation) * rest.fontSize) / 2 + rest.fontSize / 2
  const offsetX = (Math.sin(rotation) * rest.fontSize) / 2

  return (
    <div
      ref={targetRef}
      className="flex items-center"
      style={{
        width: `${width}px`,
        position: 'absolute',
        zIndex: 9,
        fontSize: `${rest.fontSize}px`,
        transform: `translate(${unrotatedLeft.x + offsetX}px, ${unrotatedLeft.y - offsetY}px) rotate(${rotation}rad)`,
        height: `${rest.fontSize}px`,
        textAlign: rest.textAlign,
        fontFamily: rest.fontFamily,
        color: rest.color,
        whiteSpace: 'nowrap',
        transformOrigin: 'center',
      }}
    >
      <div className="top-full absolute">
        <SVGPathText bezier={[0, 0, 0, 0, width, 0, width, 0]} pathRef={pathRef} {...rest} />
      </div>
    </div>
  )
}

export default SingleLineText
