import { CustomizationDesign } from '@packages/types'
import React from 'react'

import Card from 'common/components/card/Card'
import Table from 'common/components/table/Table'

interface DesignCardSummarySectionProps {
  design?: CustomizationDesign
  isLoading?: boolean
}

const DesignCardSummarySection = ({ design, isLoading }: DesignCardSummarySectionProps) => {
  return (
    <Card.Section className="flex-1 md:flex-col border-l border-neutral-100 p-0 px-6 my-6 print:px-6 print:border-l-0">
      <span className="text-xs font-medium text-neutral-600 mb-4 print:hidden">Customization details</span>
      <Table>
        {isLoading && <Table.BodyLoader numberOfColumns={2} numberOfRows={10} />}

        {!isLoading && design && (
          <Table.Body>
            {design.productionData?.map(({ key, value, values }, index) => (
              <Table.Row key={`${key}-${value}-${index}`}>
                <Table.Cell className="w-1/2">{key}</Table.Cell>
                <Table.Cell className="w-1/2 text-neutral-600 whitespace-pre-wrap">
                  {values ? values.join(', ') : value}
                </Table.Cell>
              </Table.Row>
            ))}

            {design.customExtraPrices?.map(({ description }, index) => (
              <Table.Row key={`${description}-${index}`}>
                <Table.Cell className="w-1/2">Custom detail #{index + 1}</Table.Cell>
                <Table.Cell className="w-1/2 text-neutral-600 whitespace-pre-wrap">{description}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
    </Card.Section>
  )
}

export default DesignCardSummarySection
