import { Group } from '@packages/types'

export const isDescendant = (groups: Group[], groupId: string, entityId: string): boolean => {
  const parent = groups.find(group => group.children.includes(entityId))

  if (!parent) return false

  if (parent.id === groupId) return true

  return isDescendant(groups, groupId, parent.id)
}
