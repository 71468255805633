import React from 'react'

import classMerge from 'utils/classMerge'

export interface HelperTextProps {
  hasError?: boolean
  className?: string
  children: React.ReactNode
}

const HelperText = ({ children, className, hasError }: HelperTextProps) => {
  return (
    <span className={classMerge('mt-2 text-xs text-neutral-600', className, { 'text-error-default': hasError })}>
      {children}
    </span>
  )
}

export default HelperText
