import React from 'react'

import IconButton from 'common/components/IconButton'
import Popover from 'common/components/popover/Popover'
import usePopover from 'common/components/popover/usePopover'
import useCopyToClipboard from 'common/hooks/useCopyToClipboard'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'

interface UsersActionsProps {
  email: string
  isInvited: boolean
  onDelete: () => void
  resendInvite?: () => void
}

const UsersActions = ({ email, isInvited, onDelete, resendInvite }: UsersActionsProps) => {
  const popover = usePopover({ placement: 'top-end', offsetConfig: 8 })
  const copyToClipboard = useCopyToClipboard()
  return (
    <div className="relative">
      <IconButton variant="subtle" {...popover.referenceProps} Icon={MoreIcon} aria-label="More options" small />
      <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
        <Popover.Action
          onClick={() => {
            copyToClipboard(email)
            popover.close()
          }}
        >
          Copy email
        </Popover.Action>
        {isInvited && (
          <Popover.Action
            onClick={() => {
              popover.close()
              resendInvite!()
            }}
          >
            Resend invite
          </Popover.Action>
        )}
        <Popover.Action
          className="text-tertiary-red-700"
          onClick={() => {
            popover.close()
            onDelete()
          }}
        >
          Delete
        </Popover.Action>
      </Popover>
    </div>
  )
}

export default UsersActions
