import {
  CustomizerIntegration,
  LegacyCustomizerIntegrationType,
  ShopifyInstalledTheme,
  ShopifyOnlineStore,
} from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import useShopifyProxyService from 'cms/onlineStores/hooks/useShopifyProxyService'
import Button from 'common/components/Button'
import Modal, { ModalProps } from 'common/components/modal/Modal'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'

import { CUSTOMIZER_INTEGRATION_CHOICES } from './ShopifyIntegrationDetails'
import ShopifyIntegrationPreview from './ShopifyIntegrationPreview'

interface ShopifyIntegrationModalProps extends Omit<ModalProps, 'children'> {
  onlineStore: ShopifyOnlineStore
  currentIntegration: CustomizerIntegration
  selectedTheme: ShopifyInstalledTheme
  onClose: () => void
}

const ShopifyIntegrationModal = ({
  onlineStore,
  selectedTheme,
  onClose,
  currentIntegration,
  ...modalProps
}: ShopifyIntegrationModalProps) => {
  const queryClient = useQueryClient()
  const shopifyProxyService = useShopifyProxyService()
  const onlineStoreService = useOnlineStoreService()
  const { openToast } = useToast()

  const { mutate: updateIntegration, isLoading: isUpdatingIntegration } = useMutation(
    async (integrationType: LegacyCustomizerIntegrationType) => {
      await shopifyProxyService.updateIntegration(onlineStore.id, { themeId: selectedTheme.id, integrationType })

      let customizerIntegrations: CustomizerIntegration[] = onlineStore.customizerIntegrations || []

      const doesSelectedCustomizerIntegrationExist = customizerIntegrations?.find(
        integration => integration.themeId === selectedTheme.id
      )
      if (doesSelectedCustomizerIntegrationExist) {
        customizerIntegrations = customizerIntegrations.map(customizerIntegration => {
          const isSelectedTheme = customizerIntegration.themeId === selectedTheme.id
          return isSelectedTheme ? { themeId: selectedTheme.id, integrationType } : customizerIntegration
        })
      } else {
        customizerIntegrations.push({ themeId: selectedTheme.id, integrationType })
      }

      await onlineStoreService.update(onlineStore.id, { customizerIntegrations })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...onlineStoreService.fetch.queryKeys, onlineStore.id])
        onClose()
        openToast(`Successfully updated ${selectedTheme.name} integration.`, ToastType.success)
      },
      onError: () => {
        openToast(
          `There was an error while trying to update ${selectedTheme.name} integration. Please read our docs in order to do it manually.`,
          ToastType.warning
        )
      },
    }
  )

  return (
    <Modal {...modalProps}>
      <Modal.Title>Select your integration for {selectedTheme.name}</Modal.Title>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Details>Decide how your customers will access your customizer from your e-commerce.</Modal.Details>

      <Modal.Details className="flex flex-col text-neutral-800 space-y-10">
        <div className="flex space-x-10">
          <div className="flex-1 flex flex-col space-y-3">
            <span className="font-p1">
              {CUSTOMIZER_INTEGRATION_CHOICES[LegacyCustomizerIntegrationType.CustomizeButton].label}
            </span>
            <span className="text-xs text-neutral-600">
              {CUSTOMIZER_INTEGRATION_CHOICES[LegacyCustomizerIntegrationType.CustomizeButton].description}
            </span>
            <Button
              aria-label="select customize button"
              variant="primary"
              className="w-fit"
              disabled={
                isUpdatingIntegration ||
                currentIntegration.integrationType === LegacyCustomizerIntegrationType.CustomizeButton
              }
              onClick={() => updateIntegration(LegacyCustomizerIntegrationType.CustomizeButton)}
              isLoading={
                isUpdatingIntegration &&
                currentIntegration.integrationType !== LegacyCustomizerIntegrationType.CustomizeButton
              }
            >
              Select
            </Button>
          </div>

          <ShopifyIntegrationPreview
            className="flex-1"
            integrationType={LegacyCustomizerIntegrationType.CustomizeButton}
          />
        </div>

        <div className="flex space-x-10">
          <div className="flex-1 flex flex-col space-y-3">
            <span className="font-p1">
              {CUSTOMIZER_INTEGRATION_CHOICES[LegacyCustomizerIntegrationType.CustomizerOnly].label}
            </span>
            <span className="text-xs text-neutral-600">
              {CUSTOMIZER_INTEGRATION_CHOICES[LegacyCustomizerIntegrationType.CustomizerOnly].description}
            </span>
            <Button
              aria-label="select customizer only"
              variant="primary"
              className="w-fit"
              disabled={
                isUpdatingIntegration ||
                currentIntegration.integrationType === LegacyCustomizerIntegrationType.CustomizerOnly
              }
              onClick={() => updateIntegration(LegacyCustomizerIntegrationType.CustomizerOnly)}
              isLoading={
                isUpdatingIntegration &&
                currentIntegration.integrationType !== LegacyCustomizerIntegrationType.CustomizerOnly
              }
            >
              Select
            </Button>
          </div>

          <ShopifyIntegrationPreview
            className="flex-1"
            integrationType={LegacyCustomizerIntegrationType.CustomizerOnly}
          />
        </div>
      </Modal.Details>
    </Modal>
  )
}

export default ShopifyIntegrationModal
