import { UseMutateFunction } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import { NetworkError } from 'common/api/types/error'
import Button from 'common/components/Button'
import HelperText from 'common/components/inputs/HelperText'
import InputField from 'common/components/inputs/InputField'
import Label from 'common/components/inputs/Label'
import Modal, { ModalProps } from 'common/components/modal/Modal'
import PasswordInput from 'common/components/inputs/PasswordInput'

import { LoginResponse } from '../hooks/useAuthService'

const formValidation = yup.object().shape({
  password: yup
    .string()
    .required('Please fill the password field')
    .min(5, 'Password should be at least 5 characters long'),
})

export interface SessionExpiredModalProps extends Omit<ModalProps, 'children'> {
  isLoginIn?: boolean
  hasLoginFailed?: boolean
  onLogin: UseMutateFunction<LoginResponse, NetworkError, string>
}

const SessionExpiredModal = ({ isLoginIn, hasLoginFailed, onLogin, ...modalProps }: SessionExpiredModalProps) => {
  const formik = useFormik({
    initialValues: { password: '' },
    validationSchema: formValidation,
    onSubmit: ({ password }, { setSubmitting }) => onLogin(password, { onSettled: () => setSubmitting(false) }),
  })

  return (
    <Modal {...modalProps}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Modal.Title>Your session has expired</Modal.Title>

        <Modal.Details>
          <InputField>
            <Label htmlFor="password">To continue, please re-enter your password.</Label>
            <PasswordInput
              id="password"
              name="password"
              placeholder="Enter password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={formik.touched.password && formik.errors.password != null}
            />
            {formik.touched.password && (hasLoginFailed || formik.errors.password) && (
              <HelperText hasError>{hasLoginFailed ? 'Invalid credentials' : formik.errors.password}</HelperText>
            )}
          </InputField>
        </Modal.Details>
        <Modal.Actions>
          <Button type="submit" variant="primary" isLoading={isLoginIn} disabled={isLoginIn}>
            Login
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  )
}

export default SessionExpiredModal
