import React from 'react'

import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import { printAreaSelector } from 'builder/build/printAreas/selectors'
import { useSelector } from 'cms/hooks'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'
import * as twoDDisplayerSelectors from 'customizer/2dDisplayer/selectors'
import type { ClippingGroup } from 'customizer/2dDisplayer/types/node'

import Canvas from './containers/Canvas'
import Info from './editors/Info'
import PrintAreaNodeEditor from './editors/PrintAreaEditor'
import { getTransformedNode } from './utils'

export interface PrintAreaEditorProps {
  printAreaId: string
}

const PrintAreaEditor = ({ printAreaId }: PrintAreaEditorProps) => {
  const editedPrintAreaId = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.editedPrintAreaSelector)!
  const printArea = useSelector(state => printAreaSelector(state, printAreaId))
  const currentView = customizerHooks.useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0
  const customizerDispatch = customizerHooks.useCustomizerDispatch()

  if (!printArea) return null

  const drawingNodes = customizerHooks.useCustomizerSelector(state =>
    twoDDisplayerSelectors.currentViewClippingGroupNodeSelector(state, printAreaId)
  )

  const stopEditing = () => customizerDispatch(twoDDisplayerActions.editPrintArea(null))

  const transformedNode = getTransformedNode<ClippingGroup>(editedPrintAreaId, drawingNodes)

  if (!transformedNode)
    return (
      <Canvas>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2">
          <Info
            message={`${printArea.name} is not shown on view ${currentView + 1}`}
            tooltip="You can toggle the visibility in the toolbar."
            onDoneClick={stopEditing}
          />
        </div>
      </Canvas>
    )

  return (
    <Canvas>
      <PrintAreaNodeEditor node={transformedNode} entity={printArea} currentView={currentView} />
    </Canvas>
  )
}

export default PrintAreaEditor
