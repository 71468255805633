import React from 'react'

import Button from 'common/components/Button'
import ReorderDownIcon from 'icons/core-solid/interface-essential/interface-align-layers-1.svg'

export interface LayersOrderingEmptyStateProps {
  onGoToQuestionsClick: () => void
}

const LayersOrderingEmptyState = ({ onGoToQuestionsClick }: LayersOrderingEmptyStateProps) => (
  <div className="flex flex-col h-full">
    <div className="px-6 mt-28">
      <div className="w-8 h-8 bg-neutral-75 rounded-md mb-4 p-2.5">
        <ReorderDownIcon />
      </div>
      <div className="font-medium mb-2">There are no layers, yet</div>
      <div className="text-neutral-600 mb-4">Your layers will be created as you create questions.</div>
      <div>
        <Button onClick={onGoToQuestionsClick}>Go to questions</Button>
      </div>
    </div>
  </div>
)

export default LayersOrderingEmptyState
