import { Subscription } from '@packages/types'
import { PaymentIntent } from '@stripe/stripe-js'
import { useContext } from 'react'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import TenantContext from 'common/tenant/TenantContext'

import type {
  ActivateResponse,
  AcceptCustomPlanResponse,
  ActivateSubscriptionBody,
  CancelSubscriptionBody,
  CreateCustomPlanBody,
  PaymentDetailsBody,
} from './../types/service'

const useSubscriptionService = () => {
  const tenant = useContext(TenantContext)

  const baseUrl = `/brands/${tenant}/subscriptions`

  return {
    fetchOne: createQuery([tenant, 'subscription'], async () => {
      /**
       * At the moment the backend route use the brand associated with the request to
       * fetch this brand in the DB then get the subscription's id associated with it.
       * An alternative would be to do the same thing but from the frontend. Could be
       * useful if we need a specific subscription.
       *
       * Refactoring this if needed is fairly easy.
       */
      const { data } = await apiClient.get<Subscription>(baseUrl)

      return data
    }),
    update: async (body: Partial<Subscription>) => {
      const { data } = await apiClient.post<Subscription>(`${baseUrl}/${body.id}`, body)

      return data
    },
    activate: async <T = ActivateResponse>(body: ActivateSubscriptionBody) => {
      const { data } = await apiClient.post<T>(`${baseUrl}/activate`, body)

      return data
    },
    changePlan: async <T = ActivateResponse>(body: ActivateSubscriptionBody) => {
      const { data } = await apiClient.post<T>(`${baseUrl}/change-plan`, body)
      return data
    },
    cancel: async (body: CancelSubscriptionBody) => {
      const { data } = await apiClient.post<Subscription>(`${baseUrl}/cancel`, body)
      return data
    },
    createCustomPlan: async (body: CreateCustomPlanBody) => {
      const { data } = await apiClient.post<Subscription>(`${baseUrl}/custom-plan`, body)

      return data
    },
    updatePaymentDetails: async (body: PaymentDetailsBody) => {
      const { data } = await apiClient.post<Subscription>(`${baseUrl}/payment-details`, body)

      return data
    },
    updatePaymentIntent: async (body: PaymentIntent) => {
      const { data } = await apiClient.post<Subscription>(`${baseUrl}/stripe/payment-intent`, body)

      return data
    },
    deleteCustomPlan: async () => {
      const { data } = await apiClient.delete(`/brands/${tenant}/subscriptions/custom-plan`)

      return data
    },
    acceptCustomPlan: async <T = AcceptCustomPlanResponse>() => {
      const { data } = await apiClient.post<T>(`/brands/${tenant}/subscriptions/custom-plan/accept`)

      return data
    },
  }
}

export default useSubscriptionService
