import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as groupsActions from 'builder/build/groups/actions'
import { useDispatch } from 'cms/hooks'
import * as customizationActions from 'customizer/customization/actions'

const { createQuestionFromDefaultAnswer } = groupsActions
const { useCustomizerDispatch } = customizerHooks

const useCreateQuestionFromDefaultAnswer = (part, answerId, questionType) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const customizerDispatch = useCustomizerDispatch()

  return useCallback(() => {
    const questionId = dispatch(createQuestionFromDefaultAnswer(part, questionType, answerId))

    customizerDispatch(customizationActions.selectAnswerWhereAvailable(answerId))

    history.push(`/questions/${questionId}`)
  }, [dispatch, customizerDispatch, part, questionType, answerId, history])
}

export default useCreateQuestionFromDefaultAnswer
