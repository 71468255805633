import classNames from 'classnames'
import React, { forwardRef } from 'react'

export interface ModalDetailsProps {
  children: React.ReactNode
  className?: string
}

const ModalDetails = forwardRef<HTMLDivElement, ModalDetailsProps>(({ children, className }, ref) => (
  <div className={classNames('text-neutral-600 overflow-auto px-6 pb-6 pt-3', className)} ref={ref}>
    {children}
  </div>
))

export default ModalDetails
