import { Asset, FontAssets, FontType, supportedFontExtensions } from '@packages/types'

import type { Font, FontOption } from './../types/font'

export const getFontValue = (asset: Asset) => `CUSTOM-FONT-${asset.id}`
export const getFontLabel = (asset: Asset) => asset.originalFilename.split('.')[0].replace('-', ' ').replace('_', ' ')

export const getFirstFontAssets = (fontAssets: FontAssets) => {
  const firstFontFormat = supportedFontExtensions.find(format => fontAssets[format] != null)

  return firstFontFormat ? fontAssets[firstFontFormat] : undefined
}

export const formatCustomFontOption = (asset: Asset): FontOption => {
  return { label: getFontLabel(asset), value: getFontValue(asset), asset, type: FontType.Custom }
}

export const formatGoogleFontOption = (googleFont: Font): FontOption => {
  return { label: googleFont.family, value: googleFont.family, type: FontType.Google }
}

export const formatFontAssetsOption = (fontAssets: FontAssets, fontType: FontType = FontType.Custom) => {
  if (fontType === FontType.Google) {
    return { label: fontAssets.google, value: fontAssets.google, type: FontType.Google } as FontOption
  }

  const firstAsset = getFirstFontAssets(fontAssets)
  return firstAsset ? formatCustomFontOption(firstAsset) : undefined
}

export const formatFontOptions = (googleFonts: Font[], customFonts: Asset[] = []) => {
  const googleFontOptions = googleFonts.map(formatGoogleFontOption)
  const customFontOptions = customFonts.map(formatCustomFontOption)

  return [...customFontOptions, ...googleFontOptions]
}
