import React from 'react'

import defaultThumbnailImg from 'img/variation.png'
import imageLoader, { dimensionStrings } from 'utils/loaders/ImageLoader'

interface ImageThumbnailProps {
  url?: string
}

const ImageThumbnail = ({ url }: ImageThumbnailProps) => (
  <span className="w-6 h-6 mr-2 shrink-0 rounded overflow-hidden border border-solid border-neutral-75">
    <img
      src={
        url
          ? imageLoader.buildSrc(url, { preventResize: false, dimensionString: dimensionStrings.regular })
          : defaultThumbnailImg
      }
      className="object-cover h-full w-full"
    />
  </span>
)

export default ImageThumbnail
