import { Part } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import * as partsActions from 'builder/build/parts/actions'
import { useDispatch } from 'cms/hooks'
import Switch from 'common/components/Switch'

interface FiltersSectionProps {
  part: Part
  isDisabled?: boolean
}

const { toggleFilter } = partsActions

const FiltersSection = ({ part, isDisabled }: FiltersSectionProps) => {
  const dispatch = useDispatch()

  const handleToggleFilter = (filter: string) => {
    dispatch(toggleFilter(part.id, filter))
  }

  const hasFilter = (filter: string) => {
    return part.modifiers?.filters?.find(({ type }) => type === filter) != null
  }

  const className = classNames('panel__section', {
    'panel__section-disabled': isDisabled,
  })

  return (
    <div className={className}>
      <div className="panel__title">
        <span className="panel__text">Filters</span>
      </div>
      <div className="panel__row">
        <label className="panel__field-label">Black and white</label>
        <Switch
          disabled={isDisabled}
          checked={hasFilter('blackAndWhite')}
          onChange={() => handleToggleFilter('blackAndWhite')}
        />
      </div>
      <div className="panel__row panel__row--no-margin-bottom">
        <label className="panel__field-label">Transparent</label>
        <Switch
          disabled={isDisabled}
          checked={hasFilter('blackToTransparency')}
          onChange={() => handleToggleFilter('blackToTransparency')}
        />
      </div>
    </div>
  )
}

export default FiltersSection
