import React from 'react'

import DefaultLogo from 'img/logo-part.png'
import ImageLoader from 'utils/loaders/ImageLoader'

export interface ImageProps {
  src?: string
}

const Image = ({ src }: ImageProps) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!src && (
        <img src={DefaultLogo} alt="Default logo" style={{ width: '80%', height: '80%', objectFit: 'contain' }} />
      )}
      {src && (
        <img
          src={ImageLoader.buildSrc(src, { preventResize: true })}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      )}
    </div>
  )
}

export default Image
