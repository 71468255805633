import ReactPDF, { Svg, Line, Polyline, Path } from '@react-pdf/renderer'
import React from 'react'

interface ExpandIconProps {
  style?: ReactPDF.Styles
}

const ExpandIcon = ({ style }: ExpandIconProps) => {
  return (
    <Svg viewBox="0 0 24 24" stroke="blue" strokeWidth="2" style={style?.redirectIcon}>
      <Polyline points="23.251 7.498 23.251 0.748 16.501 0.748" />
      <Line x1="23.251" y1="0.748" x2="8.251" y2="15.748" />
      <Path d="M11.251,5.248h-9a1.5,1.5,0,0,0-1.5,1.5v15a1.5,1.5,0,0,0,1.5,1.5h15a1.5,1.5,0,0,0,1.5-1.5v-9" />
    </Svg>
  )
}

export default ExpandIcon
