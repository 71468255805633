import { Rule, RuleAssertion, RuleThen, RuleWhen, NormalizedCustomizerProduct } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import LeftHand from './LeftHand'

export interface RuleBuilderProps {
  rule: Rule
  updateRule?: (rule: Rule) => void
  readonly?: boolean
  warning?: boolean
  children?: React.ReactNode
  customizerProduct: NormalizedCustomizerProduct
  className?: string
  questionsToHighlight?: string[]
  answersToHighlight?: string[]
}

const RuleBuilder = ({
  updateRule,
  rule,
  customizerProduct,
  readonly,
  children,
  warning,
  className = '',
  questionsToHighlight = [],
  answersToHighlight = [],
}: RuleBuilderProps) => {
  const updateWhen = (index: number, update: Partial<RuleWhen>) => {
    const updatedWhen = [...rule.when]
    updatedWhen[index] = { ...rule.when[index], ...update }

    updateRule?.({ ...rule, when: updatedWhen })
  }

  const updateAction = (index: number, update: RuleThen) => {
    const updatedThen = [...rule.then]
    updatedThen[index] = update

    updateRule?.({ ...rule, then: updatedThen })
  }

  const updateAfter = (index: number, { value }: { value: 'then' | 'and' }) => {
    if (value === 'then') {
      updateRule?.({ ...rule, when: rule.when.slice(0, index + 1) })
    } else if (rule.when.length === index + 1) {
      updateRule?.({ ...rule, when: [...rule.when, { path: [], assertion: RuleAssertion.Is, value: '' }] })
    }
  }

  if (readonly)
    return (
      <div className="flex space-x-1 items-center flex-wrap">
        <div>When</div>
        <LeftHand
          rule={rule}
          customizerProduct={customizerProduct}
          readonly
          updateWhen={updateWhen}
          updateAfter={updateAfter}
          updateAction={updateAction}
          predicateIndex={0}
          questionsToHighlight={questionsToHighlight}
          answersToHighlight={answersToHighlight}
        />
      </div>
    )

  return (
    <div className={classNames('rounded-lg bg-white shadow', className, { warning })}>
      <div className="pt-6 pb-2 px-6">
        <div className="flex space-x-2 items-center flex-wrap">
          <div className="mb-2">When</div>
          <LeftHand
            rule={rule}
            customizerProduct={customizerProduct}
            readonly={readonly}
            updateWhen={updateWhen}
            updateAfter={updateAfter}
            updateAction={updateAction}
            predicateIndex={0}
            questionsToHighlight={questionsToHighlight}
            answersToHighlight={answersToHighlight}
          />
        </div>
      </div>
      {children}
    </div>
  )
}

export default RuleBuilder
