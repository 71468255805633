import { MutableRefObject, useEffect, useState } from 'react'
import Moveable from 'react-moveable'

const useKeyboard = (moveableRef: MutableRefObject<Moveable | null>) => {
  const [isShiftPressed, setIsShiftPressed] = useState(false)
  const [isAltPressed, setIsAltPressed] = useState(false)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const moveable = moveableRef.current
      if (!moveable) return

      const x = moveable.moveable.state.left
      const y = moveable.moveable.state.top

      switch (e.key) {
        case 'Alt':
          setIsAltPressed(true)
          break
        case 'Shift':
          setIsShiftPressed(true)
          break
        case 'ArrowUp':
          moveable.request('draggable', { y: y - 1, isInstant: true })
          break
        case 'ArrowDown':
          moveable.request('draggable', { y: y + 1, isInstant: true })
          break
        case 'ArrowLeft':
          moveable.request('draggable', { x: x - 1, isInstant: true })
          break
        case 'ArrowRight':
          moveable.request('draggable', { x: x + 1, isInstant: true })
          break
        default:
          break
      }
    }

    const handleKeyUp = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Alt':
          setIsAltPressed(false)
          break
        case 'Shift':
          setIsShiftPressed(false)
          break
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return {
    isShiftPressed,
    isAltPressed,
  }
}

export default useKeyboard
