import classNames from 'classnames'
import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

export interface SideMenuLinkProps extends NavLinkProps {
  baseUrl?: string
  children: React.ReactNode
  exact?: boolean
  className?: string
}

const SideMenuLink = ({ className, children, exact, activeClassName, baseUrl, to, ...rest }: SideMenuLinkProps) => (
  <NavLink
    activeClassName={classNames(
      '!text-primary-600 !fill-primary-600',
      'after:absolute after:!bg-warning-default after:rounded after:w-1 after:bottom-1 after:top-0 after:-right-[2.5px]',
      'before:absolute before:!bg-warning-default before:rounded-full before:w-1.5 before:h-1.5 before:top-2 before:right-[3px]',
      activeClassName
    )}
    className={classNames(
      'relative flex items-center font-medium text-neutral-800 hover:text-neutral-900 py-1',
      'hover:after:absolute hover:after:bg-warning-light hover:after:rounded hover:after:w-1 hover:after:bottom-1 hover:after:top-0 hover:after:-right-[2.5px]',
      'hover:before:absolute hover:before:bg-warning-light hover:before:rounded-full hover:before:w-1.5 hover:before:h-1.5 hover:before:top-2 hover:before:right-[3px]',
      className
    )}
    exact={exact}
    to={baseUrl ? `${baseUrl}${to}` : to}
    {...rest}
  >
    {children}
  </NavLink>
)

export default SideMenuLink
