import classNames from 'classnames'
import React, { useContext } from 'react'
import Moveable, {
  Draggable as DefaultDraggable,
  Snappable,
  MoveableProps,
  OnDragEnd,
  OnResizeEnd,
  OnRotateEnd,
  Resizable,
  makeMoveable,
  Able,
  Rotatable,
} from 'react-moveable'

import ContainerContext from '../containers/ContainerContext'
import BoxDraggable from './ables/BoxDraggable'
import Infoable from './ables/Infoable'
import RotatableViewer from './ables/RotatableViewer'
import Scrimable from './ables/Scrimable'
import useKeyboard from './useKeyboard'

import './Transformer.scss'

export interface BoxTransformerProps extends Partial<MoveableProps> {
  onTransformEnd?: (e: OnDragEnd | OnRotateEnd | OnResizeEnd) => void
  info?: React.ReactNode
  portalEl?: HTMLElement
  allowedResizes?: ('width' | 'height')[]
}

const CustomMoveable = makeMoveable([
  BoxDraggable,
  DefaultDraggable,
  Snappable as Able,
  Resizable,
  Rotatable,
]) as unknown as typeof Moveable

const BoxTransformer = ({
  target,
  onTransformEnd,
  onRotate,
  onDrag,
  onResize,
  portalEl,
  info,
  allowedResizes = ['width', 'height'],
  ables = [],
  className,
  props = {},
  onBeforeResize,
  ...rest
}: BoxTransformerProps) => {
  const { containerRef } = useContext(ContainerContext)
  const moveableRef = React.useRef<Moveable>(null)

  const { isShiftPressed, isAltPressed } = useKeyboard(moveableRef)

  if (!target) return null

  return (
    <CustomMoveable
      ref={moveableRef}
      ables={[Scrimable, Infoable, RotatableViewer, ...ables]}
      rootContainer={document.body}
      target={target}
      className={classNames('moveable-custom', className)}
      dragTarget=".box-drag-target"
      draggable
      resizable
      rotatable
      snappable
      snapContainer={containerRef}
      useResizeObserver
      useMutationObserver
      snapRotationThreshold={2}
      snapRotationDegrees={[0, 90, 180, 270]}
      onBeforeResize={e => {
        if (isAltPressed) {
          e.startFixedDirection = [0, 0]
          e.setFixedDirection([0, 0])
        }
        onBeforeResize?.(e)
      }}
      onBeforeRenderStart={e => {
        e.moveable.setState({
          originalInputEvent: e.inputEvent,
        })

        if (e.inputEvent?.target)
          e.inputEvent.target.className = `${e.inputEvent.target.className} moveable-control-active`
      }}
      onRenderEnd={e => {
        if (!e.moveable.state.originalInputEvent) return
        e.moveable.state.originalInputEvent.target.className =
          e.moveable.state.originalInputEvent.target.className.replace('moveable-control-active', '')
        e.moveable.setState({
          originalInputEvent: undefined,
        })
      }}
      onDrag={e => {
        e.target.style.transform = e.transform

        onDrag?.(e)
      }}
      onResize={e => {
        if (allowedResizes.includes('width')) e.target.style.width = `${e.width}px`
        if (allowedResizes.includes('height')) e.target.style.height = `${e.height}px`
        e.target.style.transform = e.drag.transform

        onResize?.(e)
      }}
      onRotate={e => {
        e.target.style.transform = e.drag.transform

        onRotate?.(e)
      }}
      props={{
        [BoxDraggable.name]: true,
        [RotatableViewer.name]: true,
        [Scrimable.name]: true,
        [Infoable.name]: true,
        info,
        ...props,
      }}
      onDragEnd={onTransformEnd}
      onResizeEnd={onTransformEnd}
      onRotateEnd={onTransformEnd}
      snapDirections={{ top: true, left: true, bottom: true, right: true, center: true, middle: true }}
      verticalGuidelines={['0%', '50%', '100%']}
      horizontalGuidelines={['0%', '50%', '100%']}
      keepRatio={isShiftPressed}
      {...rest}
    />
  )
}

export default BoxTransformer
