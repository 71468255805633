import { History } from 'history'
import { useEffect, useRef } from 'react'

import useModal from './useModal'

const defaultOptions: {
  ignoreQueryParams: boolean | string[]
} = {
  ignoreQueryParams: false,
}

const useNavigationBlockingModal = (history: History, deps: boolean[], options = defaultOptions) => {
  const initialLocation = useRef(history.location)
  const forceNavigate = useRef<() => void>()
  const modal = useModal()
  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (options.ignoreQueryParams === true && initialLocation.current.pathname === location.pathname) return

      if (Array.isArray(options.ignoreQueryParams)) {
        const initialSearchParams = new URLSearchParams(initialLocation.current.search)

        let shouldBlock = false

        for (const [key, value] of new URLSearchParams(location.search).entries()) {
          if (options.ignoreQueryParams.includes(key)) continue

          shouldBlock = shouldBlock || value !== initialSearchParams.get(key)
        }

        if (!shouldBlock) return
      }

      if (deps.find(dep => dep)) {
        modal.open()

        forceNavigate.current = () => {
          unblock()
          action === 'REPLACE' ? history.replace(location) : history.push(location)
        }

        return false
      }

      return
    })

    return () => {
      modal.close()
      unblock()
    }
  }, deps)

  return { ...modal, forceNavigate: forceNavigate.current! }
}

export default useNavigationBlockingModal
