import { DenormalizedCustomizablePart } from '@packages/types'
import React, { useEffect } from 'react'

import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as previewSelectors from 'builder/build/preview/selectors'
import { useSelector } from 'cms/hooks'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'
import * as twoDDisplayerSelectors from 'customizer/2dDisplayer/selectors'
import type { Node } from 'customizer/2dDisplayer/types/node'

import PartEditor from './PartEditor'
import PrintAreaEditor from './PrintAreaEditor'

export type EditorType = React.FunctionComponent<{
  node: Node
  part: DenormalizedCustomizablePart
  currentView: number
}>

const Editor = () => {
  const customizerDispatch = customizerHooks.useCustomizerDispatch()
  const isPreviewReady = useSelector(previewSelectors.isPreviewReadySelector)
  const editedPartId = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.editedPartSelector)!
  const editedPrintAreaId = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.editedPrintAreaSelector)!

  const isEditing = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.isEditingSelector)

  useEffect(() => {
    isEditing
      ? customizerDispatch(twoDDisplayerActions.disableZoom())
      : customizerDispatch(twoDDisplayerActions.enableZoom())

    return () => {
      customizerDispatch(twoDDisplayerActions.enableZoom())
    }
  }, [isEditing])

  if (!isPreviewReady) return null

  if (editedPartId) return <PartEditor partId={editedPartId} />

  if (editedPrintAreaId) return <PrintAreaEditor printAreaId={editedPrintAreaId} />

  return null
}

export default Editor
