import { useQuery } from '@tanstack/react-query'

import * as usersUtils from '../utils'
import useUserService from './useUserService'

const useCurrentUser = () => {
  const userService = useUserService()

  const { data: currentUser, isLoading: isLoadingCurrentUser } = useQuery(
    userService.fetchCurrent.queryKeys,
    userService.fetchCurrent,
    {
      enabled: !!localStorage.getItem('bearer'),
      retry: false,
    }
  )

  return {
    currentUser,
    isMasterUser: usersUtils.isMasterUser(currentUser),
    isMCZRUser: usersUtils.isMCZRUser(currentUser),
    isLoadingCurrentUser,
  }
}

export default useCurrentUser
