import classNames from 'classnames'
import React from 'react'

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export interface RadioProps extends InputProps {
  className?: string
  disabled?: boolean
}

const Radio = ({ className, name, id, disabled, ...rest }: RadioProps) => (
  <input
    disabled={disabled}
    className={classNames(
      'form-radio border-neutral-100 h-4 w-4 focus:outline-none focus:ring-primary-100 focus:ring-offset-0 focus:ring rounded-full',
      className,
      {
        'hover:border-primary-600 text-primary-600': !disabled,
        'bg-neutral-50 text-primary-100': disabled,
        'cursor-pointer': !disabled,
      }
    )}
    type="radio"
    name={name}
    id={id}
    role="radio"
    {...rest}
  />
)

export default Radio
