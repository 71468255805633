import { EntityType, Group } from '@packages/types'
import React from 'react'
import { useHistory } from 'react-router'

import CardList, { ReorderDetails } from 'builder/build/common/components/cards/CardList'
import { useDispatch, useSelector } from 'cms/hooks'

import { moveChild } from '../../actions'
import { groupChildrenSelector } from '../../selectors'
import FolderElementCard from './FolderElementCard'

export interface FolderElementListProps {
  group: Group
}

const FolderElementList = ({ group }: FolderElementListProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const groupElements = useSelector(state => groupChildrenSelector(state, group))

  const handleReorder = ({ sourceIndex, destinationIndex }: ReorderDetails) => {
    dispatch(moveChild({ index: sourceIndex, parentId: group.id }, { index: destinationIndex, parentId: group.id }))
  }

  const handleElementClick = (entityType: EntityType, id: string) => {
    if (entityType === EntityType.Group) {
      history.push(`/groups/${id}`)
    } else if (entityType === EntityType.Question) {
      history.push(`/questions/${id}`)
    }
  }

  if (groupElements.length === 0) {
    return (
      <div className="not-italic font-medium text-sm leading-6 text-[rgba(0,0,0,0.38)] text-center block">
        There are no elements, yet
      </div>
    )
  }

  return (
    <CardList
      onReorder={handleReorder}
      CardComponent={FolderElementCard}
      className="space-y-2"
      items={groupElements.map(element => ({
        id: element.id,
        props: {
          text: element.name,
          entity: element,
          onClick: () => handleElementClick(element.entityType, element.id),
        },
      }))}
    />
  )
}

export default FolderElementList
