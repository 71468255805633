import { Question, PrintArea } from '@packages/types'
import React from 'react'

import TypeIcons from 'builder/build/common/components/TypeIcons'
import UniversalSearchList from 'common/components/universalSearch/UniversalSearchList'

interface PrintAreaSearchResultProps {
  query: string
  question: Question
  printArea: PrintArea
  onClick: () => void
}

const PrintAreaSearchResult = ({ query, question, printArea, onClick }: PrintAreaSearchResultProps) => {
  return (
    <UniversalSearchList.Result onClick={onClick}>
      <div className="flex items-center">
        <TypeIcons entity={printArea} />
        <UniversalSearchList.HighlightableText className="ml-2.5" text={printArea.name} highlight={query} />
      </div>
      <UniversalSearchList.SupportingText>{question.name}</UniversalSearchList.SupportingText>
    </UniversalSearchList.Result>
  )
}

export default PrintAreaSearchResult
