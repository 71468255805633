import createInHousePlugin from '../createInHousePlugin'
import Plugin from './initialize'

const version = '1.0.0'

export default createInHousePlugin(
  "Dick's Sporting Goods Plugin",
  'dsgPlugin',
  version,
  Plugin,
  [],
  undefined,
  undefined,
  undefined
)
