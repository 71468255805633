import { DemoProduct, Product } from '@packages/types'
import React, { ReactNode } from 'react'

import classMerge from 'utils/classMerge'

import DemoProductCard from './DemoProductCard'
import ProductCard from './ProductCard'
import ProductCardBone from './ProductCardBone'

export interface ProductsListProps {
  products: Product[] | DemoProduct[]
  isLoading: boolean
  isFetchingNextPage?: boolean
  className?: string
  lastCard?: ReactNode
}

const ProductsList = ({ products, isLoading, isFetchingNextPage, className, lastCard }: ProductsListProps) => {
  const isEmpty = !products.length

  return (
    <>
      <div className={classMerge('w-fit flex flex-wrap', className)}>
        {isLoading && [...Array(products?.length || 0)].map((_, index) => <ProductCardBone key={index} />)}

        {!isLoading && !isEmpty && (
          <>
            {products.map(product =>
              (product as DemoProduct).demoAttributes ? (
                <DemoProductCard key={product.id} product={product as DemoProduct} />
              ) : (
                <ProductCard key={product.id} product={product as Product} />
              )
            )}
            {lastCard}
          </>
        )}

        {!isEmpty && isFetchingNextPage && [...Array(3)].map((_, index) => <ProductCardBone key={index} />)}
      </div>
    </>
  )
}

export default ProductsList
