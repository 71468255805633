import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
  $getSelection,
  $isRangeSelection,
  TextFormatType,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  COMMAND_PRIORITY_LOW,
} from 'lexical'
import React, { useCallback, useEffect, useState } from 'react'

import BoldIcon from 'icons/bold/01-Interface Essential/34-Text-Formating/text-bold.svg'
import ItalicIcon from 'icons/bold/01-Interface Essential/34-Text-Formating/text-italic.svg'
import UnderlineIcon from 'icons/bold/01-Interface Essential/34-Text-Formating/text-underline.svg'

import ToolbarButton from '../ToolbarButton'

const TextFormatPlugin = () => {
  const [editor] = useLexicalComposerContext()
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)

  const handleFormatTextCommand = (e: React.MouseEvent<HTMLButtonElement>, type: TextFormatType) => {
    e.preventDefault()
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, type)
  }

  const updatePlugin = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
    }
  }, [])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => updatePlugin())
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updatePlugin()
          return false
        },
        COMMAND_PRIORITY_LOW
      )
    )
  }, [])

  return (
    <>
      <ToolbarButton onMouseDown={e => handleFormatTextCommand(e, 'bold')} isActive={isBold} aria-label="Format Bold">
        <BoldIcon width={16} height={16} />
      </ToolbarButton>
      <ToolbarButton
        onMouseDown={e => handleFormatTextCommand(e, 'italic')}
        isActive={isItalic}
        aria-label="Format Italics"
      >
        <ItalicIcon width={16} height={16} />
      </ToolbarButton>
      <ToolbarButton
        onMouseDown={e => handleFormatTextCommand(e, 'underline')}
        isActive={isUnderline}
        aria-label="Format Underline"
      >
        <UnderlineIcon width={16} height={16} />
      </ToolbarButton>
    </>
  )
}

export default TextFormatPlugin
