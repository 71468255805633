import React from 'react'

import type { TranslationText } from 'cms/translations/types/translation'
import RichTextEditor from 'common/components/richTextEditor/RichTextEditor'
import SanitizedHtml from 'common/components/SanitizedHtml'
import ArrowRight from 'icons/regular/01-Interface Essential/40-Keyboard/keyboard-arrow-right.svg'

interface TranslationRowWithRichTextProps {
  text: TranslationText
  value: string
  onChange: (value: string) => void
}

const TranslationRowWithRichText = React.forwardRef<HTMLLabelElement, TranslationRowWithRichTextProps>(
  ({ text, value, onChange }, ref) => {
    return (
      <li className="flex flex-col md:flex-row py-4 px-6">
        <label ref={ref} id={text.keys[0]} className="flex-1 text-sm font-medium text-neutral-400 mb-4 md:mb-0">
          <SanitizedHtml className="[&>*]:text-sm [&>*]:font-medium [&>*]:text-neutral-400" dirtyHtml={text.text} />
        </label>
        <ArrowRight className="h-4 ml-4 mr-4 text-neutral-500 self-center hidden md:visible" />
        <div className="flex-1">
          <RichTextEditor
            aria-label={text.keys[0]}
            aria-abelled-by={text.keys[0]}
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder="Enter your translation here"
            className="h-full"
          />
        </div>
      </li>
    )
  }
)

export default TranslationRowWithRichText
