import React, { useEffect, useState } from 'react'

import TextArea, { TextAreaProps } from './TextArea'

export interface UncontrolledTextAreaProps extends Omit<TextAreaProps, 'onChange' | 'onBlur'> {
  onChange: (value: string) => void
}

const UncontrolledTextArea = ({ value = '', onChange, ...props }: UncontrolledTextAreaProps) => {
  const [internalValue, setInternalValue] = useState(value)

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => setInternalValue(e.target.value)

  const handleBlur = () => onChange(internalValue)

  useEffect(() => {
    if (value !== internalValue) setInternalValue(value)
  }, [value])

  return <TextArea value={internalValue} onChange={handleChange} onBlur={handleBlur} {...props} />
}

export default UncontrolledTextArea
