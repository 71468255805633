import classNames from 'classnames'
import React from 'react'

const PageSeparator = ({
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>) => (
  <hr {...props} className={classNames('border-neutral-75 mb-10', className)} />
)

export default PageSeparator
