// https://stackoverflow.com/questions/49474775/chrome-65-blocks-cross-origin-a-download-client-side-workaround-to-force-down
const forceDownload = (blob: Blob, filename: string) => {
  const blobURL = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.download = filename
  a.href = blobURL
  a.target = '_blank'

  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a)
  a.click()
  a.remove()
  window.URL.revokeObjectURL(blobURL)
}

// Current blob size limit is around 500MB for browsers
export const downloadResource = (url: string) => {
  const filename = url.split('\\').pop()!.split('/').pop()!

  fetch(url, { headers: new Headers({ Origin: location.origin }) })
    .then(response => response.blob())
    .then(blob => forceDownload(blob, filename))
    .catch(e => console.error(e))
}
