import { EntityType } from '@packages/types'

import * as layersSelectors from 'builder/build/layers/selectors'
import { AppDispatch, RootState } from 'cms/store'

import { ReorderDetails } from './../common/components/cards/CardList'
import * as actionTypes from './actionTypes'

const getSourcePartIds = (state: RootState, sourceId: string) => {
  const layer = layersSelectors.layersByIdSelector(state, sourceId)

  if (layer?.entityType === EntityType.PrintArea) {
    return layer.children
      .map(({ id }) => id)
      .slice()
      .reverse()
  }

  return [sourceId]
}

const getDestinationPartId = (state: RootState, destinationId: string, isMovingUp: boolean) => {
  const layer = layersSelectors.layersByIdSelector(state, destinationId)

  if (layer?.entityType === EntityType.PrintArea) {
    const firstChild = layer.children[0]
    const lastChild = layer.children[layer.children.length - 1]

    return isMovingUp ? firstChild.id : lastChild.id
  }

  return destinationId
}

export const moveLayer =
  ({ sourceId, sourceIndex, destinationId, destinationIndex }: ReorderDetails) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const flattenedLayers = layersSelectors.flattenedLayersSelector(state)

    const isMovingUp = sourceIndex > destinationIndex

    const sourcePartIds = getSourcePartIds(state, sourceId)
    const destinationPartId = getDestinationPartId(state, destinationId, isMovingUp)

    const updatedLayers = flattenedLayers.filter(id => !sourcePartIds.includes(id))

    updatedLayers.splice(updatedLayers.indexOf(destinationPartId) + (isMovingUp ? 1 : 0), 0, ...sourcePartIds)

    return dispatch({ type: actionTypes.UPDATE_LAYERS_ORDER, payload: { layers: updatedLayers } })
  }
