import classNames from 'classnames'
import React from 'react'

import CheckboxButtonGroupOption from './CheckboxButtonGroupOption'

const CheckboxButtonGroup = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div
      className={classNames('flex border border-solid border-neutral-100 shadow-xs rounded-md', className)}
      {...props}
    >
      {children}
    </div>
  )
}

CheckboxButtonGroup.Option = CheckboxButtonGroupOption

export default CheckboxButtonGroup
