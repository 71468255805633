import { MoveableManagerInterface, Renderer } from 'react-moveable'

const BoxDraggable = {
  name: 'box-draggable-target',
  props: [],
  events: [],
  render: (moveable: MoveableManagerInterface, React: Renderer) => {
    const state = moveable.getState()

    return (
      <div
        key="box-draggable-target"
        className="box-drag-target moveable-control-drag-target absolute"
        style={{
          width: state.width,
          height: state.height,
          transform: `translate(${state.renderPoses[0][0]}px, ${state.renderPoses[0][1]}px) rotate(${state.rotation}rad)`,
          transformOrigin: 'top left',
        }}
      />
    )
  },
}

export default BoxDraggable
