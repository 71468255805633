import classNames from 'classnames'
import React from 'react'

import CloseIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/close.svg'

import IconButton from '../IconButton'

interface ToastCloseButtonProps {
  onClick: () => void
  className?: string
}

const ToastCloseButton = ({ onClick, className }: ToastCloseButtonProps) => (
  <IconButton
    onClick={onClick}
    smallIcon
    variant="subtle"
    Icon={CloseIcon}
    className={classNames('ml-6 fill-neutral-200 hover:fill-neutral-900', className)}
    aria-label="Close icon"
  />
)

export default ToastCloseButton
