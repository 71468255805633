import { Answer, BulkOrder, EntityType, Group, GroupType, NormalizedCustomizerProduct, Question } from '@packages/types'
import { sumBy, reduce } from 'lodash'

import type { TranslationText, TranslationData } from 'cms/translations/types/translation'
import type { Plugin } from 'plugins/types/plugin'

const placeholdersRegex = /(\{\{[a-zA-Z]+\}\})/g

export const getPlaceholders = (value: string) => value.match(placeholdersRegex) || []

export const getNumberOfFilledTexts = (
  texts: Record<string, { key: string; text: string }>,
  translations: Record<string, TranslationData> = {}
) => {
  return sumBy(Object.values(texts), ({ key }) => {
    return translations[key]?.text && translations[key].text.trim().length > 0 ? 1 : 0
  })
}

export const getTexts = (texts: Record<string, { key: string; text: string }>) => {
  return Object.values(texts).reduce(
    (result, { key, text }) => {
      return { ...result, [text]: { keys: [key], text, hasPlaceholders: !!text.match(placeholdersRegex) } }
    },
    {} as Record<string, TranslationText>
  )
}

const removeHTMLTags = (html: string) => {
  const div = document.createElement('div')
  div.innerHTML = html
  return div.textContent?.trim() || div.innerText?.trim() || ''
}

const getEntityTranslationTexts = (entityType: EntityType, entity: Question | Answer | Group): TranslationText[] => {
  const texts: TranslationText[] = []

  if (entity.name.trim() !== '') {
    texts.push({ keys: [`${entityType}s/${entity.id}/name`], text: entity.name })
  }

  if (entity.showDescription && entity.description && removeHTMLTags(entity.description) !== '') {
    texts.push({ keys: [`${entityType}s/${entity.id}/description`], text: entity.description, isRichText: true })
  }

  if ((entity as Group).type === GroupType.BulkOrder) {
    if ((entity as BulkOrder).quantity?.name?.trim()) {
      texts.push({
        keys: [`${entityType}s/${entity.id}/quantity/name`],
        text: (entity as BulkOrder).quantity.name!,
      })
    }

    if ((entity as BulkOrder).quantity?.label?.trim()) {
      texts.push({
        keys: [`${entityType}s/${entity.id}/quantity/label`],
        text: (entity as BulkOrder).quantity.label!,
      })
    }

    if ((entity as BulkOrder).addItemLabel?.trim()) {
      texts.push({
        keys: [`${entityType}s/${entity.id}/addItemLabel`],
        text: (entity as BulkOrder).addItemLabel,
      })
    }

    if ((entity as BulkOrder).itemLabel?.trim()) {
      texts.push({
        keys: [`${entityType}s/${entity.id}/itemLabel`],
        text: (entity as BulkOrder).itemLabel,
      })
    }
  }

  if ((entity as Question).bulkConfig?.label?.trim()) {
    texts.push({
      keys: [`${entityType}s/${entity.id}/bulkConfig/label`],
      text: (entity as Question).bulkConfig!.label!,
    })
  }

  if ((entity as Answer).placeholder?.trim()) {
    texts.push({ keys: [`${entityType}s/${entity.id}/placeholder`], text: (entity as Answer).placeholder! })
  }

  return texts
}

export const getCustomizerProductTexts = (customizerProduct: NormalizedCustomizerProduct) => {
  if (!customizerProduct.questions && !customizerProduct.groups) return {}
  return reduce(
    { ...customizerProduct.questions, ...customizerProduct.answers, ...customizerProduct.groups },
    (texts, entity) => {
      const entityTexts = getEntityTranslationTexts(entity.entityType, entity)

      entityTexts.forEach(text => {
        if (texts[text.text]) {
          texts[text.text].keys.push(...text.keys)
        } else {
          texts[text.text] = text
        }
      })

      return texts
    },
    {} as Record<string, TranslationText>
  )
}

export const getPluginTexts = (plugin: Plugin) => {
  return Object.entries(plugin.texts).reduce(
    (result, [key, { text }]) => {
      return { ...result, [text]: { keys: [key], text } }
    },
    {} as Record<string, TranslationText>
  )
}

export const translationsToFormValues = (
  texts: Record<string, TranslationText>,
  translations: Record<string, TranslationData>
) => {
  return Object.values(texts).reduce((result, { keys }) => {
    const key = keys[0]
    return { ...result, [key]: translations[key]?.text || '' }
  }, {})
}

export const formValuesToTranslations = (texts: Record<string, TranslationText>, values: Record<string, string>) => {
  return Object.values(texts).reduce<Record<string, TranslationData>>((result, { keys, text }) => {
    const value = values[keys[0]]
    keys.forEach(key => {
      result[key] = { key, from: text, text: value }
    })
    return result
  }, {})
}
