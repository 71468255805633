import React from 'react'

const TableFooter = ({
  children,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>) => (
  <tfoot {...rest}>{children}</tfoot>
)

export default TableFooter
