export const PATCH = 'builder/patch'
export const START_EDITING = 'builder/start-editing'
export const UPDATE_PRISTINE = 'builder/update-pristine'
export const UPDATE_PRODUCT_NAME = 'builder/update-product-name'
export const SET_DIMENSIONS = 'builder/set-dimensions'
export const REMOVE_PERSONALISED_ANSWERS = 'builder/remove-custom-answers'
export const SAVE_SUCCESS = 'builder/save-success'
export const RESET_PRODUCTBUILDER = 'builder/reset-productbuilder'
export const UPDATE_THEME_CONFIGURATION = 'builder/update-theme-configuration'
export const DELETE_THEME_CONFIGURATION = 'builder/delete-theme-configuration'
export const REPLACE_DRAFT = 'builder/replace-draft'
