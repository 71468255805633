import {
  supportedAudioExtensions,
  supportedFontExtensions,
  supportedImageExtensions,
  supportedVideoExtensions,
} from '@packages/types'

export enum FileType {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  Font = 'font',
}

export const EXTENSIONS_BY_FILE_TYPE = {
  image: supportedImageExtensions,
  video: supportedVideoExtensions,
  audio: supportedAudioExtensions,
  font: supportedFontExtensions,
} as { [key in FileType]: string[] }

export const getFileType = (extension?: string): FileType | null => {
  if (!extension) return null

  for (const type in EXTENSIONS_BY_FILE_TYPE) {
    if (EXTENSIONS_BY_FILE_TYPE[type as FileType].includes(extension)) {
      return type as FileType
    }
  }

  return null
}
