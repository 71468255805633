import React from 'react'

import CheckList from 'common/components/CheckList'

interface SubscriptionCheckListProps {
  title: string
  list: string[]
}

const SubscriptionCheckList = ({ title, list }: SubscriptionCheckListProps) => {
  return (
    <div className="pb-3">
      <span className="font-p3 text-neutral-600">{title}</span>
      <hr className="border-neutral-75 my-3" />
      <CheckList items={list} />
    </div>
  )
}

export default SubscriptionCheckList
