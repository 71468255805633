import { NormalizedCustomizerProduct, RuleAssertion, RuleWhen } from '@packages/types'
import React from 'react'

import ProductQuestionsDropdown from 'common/components/ProductQuestionsDropdown'
import QuestionAnswersDropdown from 'common/components/QuestionAnswersDropdown'

export type OnSelect = (value: { value: string } | { value: string[] }) => void
export type OnSelectMulti = (values: { value: string }[]) => void

interface QuestionsOrAnswersDropdownProps {
  customizerProduct: NormalizedCustomizerProduct
  exclude: string[]
  assertion: RuleAssertion
  questionId: string
  value?: RuleWhen['value']
  onSelect: OnSelect | OnSelectMulti
  readonly?: boolean
  isMulti?: boolean
  className?: string
  questionsToHighlight?: string[]
  answersToHighlight?: string[]
}

const QuestionsOrAnswersDropdown = (props: QuestionsOrAnswersDropdownProps) => {
  switch (props.assertion) {
    case RuleAssertion.MatchesInState:
    case RuleAssertion.DoesNotMatchInState:
      return (
        <ProductQuestionsDropdown
          {...props}
          onSelect={({ value }: { value: string }) => {
            const onSelect = props.onSelect as OnSelect
            const question = props.customizerProduct.questions[props.questionId]
            onSelect({ value: ['questions', value, question?.isMultiAnswer ? 'selectedAnswers' : 'selectedAnswer'] })
          }}
          value={props.value ? props.value[1] : undefined}
          questionsToHighlight={props.questionsToHighlight}
        />
      )
    case RuleAssertion.Is:
    case RuleAssertion.IsNot:
    case RuleAssertion.Includes:
    case RuleAssertion.DoesNotInclude:
      return <QuestionAnswersDropdown {...props} logicalOperator="and" answersToHighlight={props.answersToHighlight} />
    case RuleAssertion.IsOneOf:
      return <QuestionAnswersDropdown {...props} logicalOperator="or" answersToHighlight={props.answersToHighlight} />
    default:
      return null
  }
}

export default QuestionsOrAnswersDropdown
