import { CustomizerProductSubmitAction } from '@packages/types'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import useCustomizerSelector from 'builder/build/customizer/hooks/useCustomizerSelector'
import { useDispatch, useSelector } from 'cms/hooks'
import IconButton from 'common/components/IconButton'
import CheckboxButtonGroup from 'common/components/inputs/checkboxButtonGroup'
import withFlag from 'common/users/components/withFlag'
import * as quoteActions from 'customizer/quote/actions'
import * as quoteSelectors from 'customizer/quote/selectors'
import EyeIcon from 'icons/core-solid/interface-essential/interface-edit-view.svg'

import { updateSubmitAction } from '../actions'
import { submitActionSelector } from '../selectors'

const SubmitActionRightPanel = () => {
  const customizerDispatch = useCustomizerDispatch()
  const isQuoteFormVisible = useCustomizerSelector(quoteSelectors.quoteFormVisibilitySelector)
  const submitAction = useSelector(submitActionSelector)
  const dispatch = useDispatch()
  const location = useLocation()

  const handleSubmitActionClick = (newSubmitAction: CustomizerProductSubmitAction) => {
    if (isQuoteFormVisible) customizerDispatch(quoteActions.closeForm())
    dispatch(updateSubmitAction(newSubmitAction))
  }

  const handlePreviewModalClick = () => {
    customizerDispatch(isQuoteFormVisible ? quoteActions.closeForm() : quoteActions.openForm())
  }

  useEffect(() => {
    if (!location.pathname.includes('/submit-action') && isQuoteFormVisible) {
      customizerDispatch(quoteActions.closeForm())
    }
  }, [location.pathname])

  return (
    <div className="w-full h-full" aria-label="submit action configuration">
      <div className="panel panel--right w-full">
        <div className="flex flex-col px-6 py-6 space-y-2">
          <span className="font-medium text-sm">Submit Action</span>

          <div className="flex justify-between shadow-xs rounded-[4px]">
            <CheckboxButtonGroup className="flex-1">
              <CheckboxButtonGroup.Option
                isChecked={submitAction === CustomizerProductSubmitAction.AddToCart}
                onClick={() => handleSubmitActionClick(CustomizerProductSubmitAction.AddToCart)}
              >
                Add to cart
              </CheckboxButtonGroup.Option>
              <CheckboxButtonGroup.Option
                isChecked={submitAction === CustomizerProductSubmitAction.GetAQuote}
                onClick={() => handleSubmitActionClick(CustomizerProductSubmitAction.GetAQuote)}
              >
                Get a quote
              </CheckboxButtonGroup.Option>
            </CheckboxButtonGroup>
          </div>
        </div>
        {submitAction === CustomizerProductSubmitAction.GetAQuote && (
          <>
            <hr className="border-neutral-100" />
            <div className="flex flex-col px-6 py-6 space-y-2">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium">Quote request form</span>
                <IconButton
                  aria-label="Preview quote request form"
                  variant="subtle"
                  Icon={EyeIcon}
                  onClick={handlePreviewModalClick}
                />
              </div>
              <span className="text-xs text-neutral-400">
                Display the form shown to the user when they request a quote.
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default withFlag({
  Component: SubmitActionRightPanel,
  feature: 'quote_phase_1',
})
