import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import TopBarEnvTag from 'common/components/topBar/TopBarEnvTag'
import ArrowLeft from 'icons/regular/01-Interface Essential/40-Keyboard/keyboard-arrow-left.svg'

const SettingsHeader = () => {
  const history = useHistory()
  const params = useParams<{ brandName: string }>()
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''

  const handleLogOut = () => {
    localStorage.removeItem('bearer')
    history.push('/login')
  }

  return (
    <header className="bg-white fixed w-full top-0 left-0 flex justify-between items-center border-b border-b-neutral-100 h-[52px] py-4 pl-5 pr-5 md:pr-[52px] z-[2]">
      <TopBarEnvTag />
      <Link to={`${baseUrl}/products`}>
        <span className="text-neutral-600 fill-neutral-600 hover:text-neutral-800 hover:fill-neutral-800 flex items-center space-x-2">
          <ArrowLeft className="h-6 mr-2" />
          <span className="mt-0.5">Leave Settings</span>
        </span>
      </Link>
      <button onClick={handleLogOut}>Log out</button>
    </header>
  )
}

export default SettingsHeader
