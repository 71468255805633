export const pathToBezier = (path: string) => {
  const splittedPath = path.split(',')
  const bezier = []
  for (let i = 0; i < splittedPath.length; i++) {
    const splittedSubPath = splittedPath[i].split(' ')
    for (let j = 0; j < splittedSubPath.length; j++) {
      const parsedValue = parseFloat(splittedSubPath[j])
      if (!isNaN(parsedValue)) {
        bezier.push(parsedValue)
      }
    }
  }

  return bezier
}

export const bezierMaxY = (bezier: number[]) => {
  let maxY = 0

  for (let i = 1; i < bezier.length; i = i + 2) {
    if (bezier[i] > maxY) maxY = bezier[i]
  }

  return maxY
}

export const bezierToPath = (bezier: number[]) => {
  let d = 'M '
  for (let i = 0; i <= bezier.length - 2; i += 2) {
    d += `${bezier[i]},${bezier[i + 1]} `
    if (i == 0) {
      d += 'C '
    }
  }
  return d
}

export const getTextAnchor = (align: 'left' | 'right' | 'center' | undefined) => {
  switch (align) {
    case 'left':
      return 'start'
    case 'right':
      return 'end'

    case 'center':
    default:
      return 'middle'
  }
}

export const getStartOffset = (align: 'left' | 'right' | 'center' | undefined) => {
  switch (align) {
    case 'left':
      return '0%'
    case 'right':
      return '100%'
    case 'center':
    default:
      return '50%'
  }
}

export const fitFontSizeToPath = (
  path?: SVGPathElement | null,
  textPath?: SVGTextPathElement | null,
  fullWidthTextPath?: SVGTextPathElement | null
) => {
  if (!path || !fullWidthTextPath || !textPath) return

  const fontSize = parseFloat(textPath.getAttributeNS(null, 'font-size') ?? '')

  const step = Math.max(fontSize / 100, 0.1)
  const maxLength = path.getTotalLength()
  let adjustedFontSize = fontSize

  while (fullWidthTextPath.getComputedTextLength() > maxLength && adjustedFontSize > 1) {
    adjustedFontSize = Math.max(adjustedFontSize - step, 1)
    fullWidthTextPath.setAttributeNS(null, 'font-size', `${adjustedFontSize}px`)
  }

  textPath.setAttributeNS(null, 'font-size', `${adjustedFontSize}px`)

  fullWidthTextPath.setAttributeNS(null, 'font-size', `${fontSize}px`)

  return adjustedFontSize
}
