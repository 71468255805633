import classNames from 'classnames'
import React from 'react'

import Button, { ButtonProps } from '../../Button'
interface CheckboxButtonGroupOptionProps extends ButtonProps {
  isChecked?: boolean
}

const CheckboxButtonGroupOption = ({ className, children, isChecked, ...props }: CheckboxButtonGroupOptionProps) => {
  return (
    <Button
      variant={isChecked ? 'toggle-selected' : 'toggle'}
      className={classNames('flex-1 rounded-none first:rounded-l-md last:rounded-r-md [&>*]:justify-center', className)}
      aria-pressed={isChecked ? 'true' : 'false'}
      {...props}
    >
      {children}
    </Button>
  )
}

export default CheckboxButtonGroupOption
