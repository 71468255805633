import classNames from 'classnames'
import React from 'react'

export interface TabProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isSelected: boolean
  className?: string
  children: React.ReactNode
  onClick: (e: React.MouseEvent) => void
}

const Tab = ({ children, isSelected, className, onClick, ...rest }: TabProps) => (
  <div
    role="tab"
    aria-selected={isSelected ? 'true' : 'false'}
    className={classNames(
      'h-[52px] relative flex items-center justify-center min-w-[22px] shrink-0 hover:after:block after:absolute after:h-0.5 after:w-full after:bottom-0 after:-right-0 font-medium hover:cursor-pointer',
      className,
      {
        'text-primary-600 border-b-primary-600 after:bg-primary-600': isSelected,
        'text-neutral-300 border-neutral-300 after:bg-neutral-300 after:hidden': !isSelected,
      }
    )}
    onClick={onClick}
    {...rest}
  >
    {children}
  </div>
)

export default Tab
