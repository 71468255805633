import { Plan, CustomPlan, TransactionFeeType, TransactionFeeTier, Addon } from '@packages/types'
import React from 'react'

import useModal from 'common/components/modal/useModal'
import Modal from 'common/components/modal/Modal'
import classMerge from 'utils/classMerge'

import { sortTransactionFeeTiers } from '../utils'

export type TransactionFeesProps = { plan: Plan | CustomPlan }

const formatPrice = (amount?: number) => {
  if (amount == null) return ''

  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'short',
  })
}

const GraduatedPricingExample = ({ amount, txFeesSteps }: { amount: number; txFeesSteps: TransactionFeeTier[] }) => {
  const sortedTxFeesSteps = sortTransactionFeeTiers(txFeesSteps)

  const lines = []

  let remaining = amount
  let previousAmount = 0
  let previousStep: null | TransactionFeeTier = null
  let total = 0

  while (remaining > 0 && sortedTxFeesSteps.length > 0) {
    const step = sortedTxFeesSteps.shift()

    if (!step) break

    const amount = step?.upTo ? Math.min(remaining, step.upTo - previousAmount) : remaining
    const lineTotal = amount * (step?.percentage / 100)
    remaining = remaining - amount
    total += lineTotal

    lines.push(
      <div className="flex justify-between items-center">
        {formatPrice(previousStep?.upTo ?? 0)} to {formatPrice(step?.upTo)}:
        <span className="p-1 bg-neutral-75 rounded-sm text-xs">
          {formatPrice(amount)} * {step?.percentage}% = {formatPrice(lineTotal)}
        </span>
      </div>
    )

    previousAmount = amount
    previousStep = step
  }

  return (
    <>
      {lines}
      <div className="flex justify-between items-center">
        Total:
        <span className="p-1 bg-neutral-75 rounded-sm text-xs">
          {total.toLocaleString('en-us', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
    </>
  )
}

const transactionFeesVariants = {
  default: {
    percentages: 'text-lg w-full',
    description: 'text-sm font-medium',
  },
  small: {
    percentages: 'text-sm',
    description: 'text-xs',
  },
}

const TransactionFees = ({ plan, variant = 'default' }: { plan: Plan | CustomPlan; variant?: 'default' | 'small' }) => {
  const modal = useModal()

  if (plan.price.type === TransactionFeeType.Flat)
    return (
      <>
        <div className={classMerge('font-normal', transactionFeesVariants[variant].percentages)}>
          <span className="text-sm">+</span> {plan.price.transactionFeePercentage}%
        </div>
        <span className={classMerge('pt-1 text-neutral-500', transactionFeesVariants[variant].description)}>
          per custom product sale
        </span>
      </>
    )

  const sortedFees = sortTransactionFeeTiers(plan.price.transactionFeeTiers)

  return (
    <>
      <div className={classMerge('font-normal', transactionFeesVariants[variant].percentages)}>
        <span className="text-sm">+</span> {sortedFees[0].percentage}% <span className="text-sm">to</span>{' '}
        {sortedFees[sortedFees.length - 1].percentage}%
      </div>
      <span className={classMerge('pt-1 text-neutral-500', transactionFeesVariants[variant].description)}>
        <span>per custom product sale</span>
        <span
          className="ml-2 text-neutral-400 underline underline-offset-2 decoration-dashed hover:cursor-pointer"
          onClick={modal.open}
        >
          Learn more
        </span>
      </span>
      {modal.isVisible && (
        <Modal {...modal.modalProps} onBackdropClick={modal.close}>
          <Modal.CloseButton onClick={modal.close} />
          <Modal.Title>Transaction fees</Modal.Title>
          <Modal.Details>
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                <span className="basis-3/4 text-xs font-medium text-neutral-600">Monthly custom product sales</span>
                <span className="basis-1/4 text-xs font-medium text-neutral-600">Fee</span>
              </div>
              {sortedFees.map((txFee, index) => {
                const fromStep = sortedFees[index - 1]
                const fromAmount = fromStep ? fromStep.upTo : 0

                const toAmount = sortedFees[index].upTo

                const getSeparator = (toAmount?: number) => (toAmount ? ' to ' : '+')

                return (
                  <div key={index} className="flex items-center space-x-2">
                    <span className="basis-3/4">
                      {formatPrice(fromAmount)}
                      {getSeparator(toAmount)}
                      {formatPrice(toAmount)}
                    </span>
                    <span className="basis-1/4">{txFee.percentage}%</span>
                  </div>
                )
              })}
            </div>
            <div className="bg-neutral-50 p-4 rounded-md mt-4">
              <div className="font-medium mb-1 border-neutral-100">Example</div>
              <div className="italic mb-4">You sell $252k in custom products in a month.</div>
              <div className="flex flex-col space-y-1">
                <GraduatedPricingExample amount={252000} txFeesSteps={sortedFees} />
              </div>
            </div>
          </Modal.Details>
        </Modal>
      )}
    </>
  )
}

interface PlanPriceProps {
  plan: Plan | CustomPlan
  addonsSubscribed: Addon[]
  variant?: 'default' | 'small'
  discount?: number
}

const priceVariants = {
  default: 'text-3xl',
  small: 'text-lg leading-6',
}

const periodVariants = {
  default: 'w-fit font-medium text-sm mb-1 ml-2 self-end text-neutral-500',
  small: 'w-fit font-medium text-sm mb-[1px] ml-2 self-end text-neutral-500',
}

const PlanPrice = ({ plan, addonsSubscribed, variant = 'default', discount = 0 }: PlanPriceProps) => {
  const totalAddonPrice = addonsSubscribed.reduce<number>((acc, cur) => acc + cur.price, 0)

  return (
    <>
      <div className="flex">
        {discount > 0 && (
          <span
            className={classMerge(
              'w-fit font-medium text-neutral-300 line-through decoration-2 decoration-tertiary-red-400 mr-2',
              priceVariants[variant]
            )}
          >
            ${Math.max(plan.price.recurringAmount + totalAddonPrice, 0)}
          </span>
        )}
        <span
          className={classMerge(
            'w-fit font-medium bg-gradient-blue bg-clip-text text-transparent active:text-transparent',
            priceVariants[variant]
          )}
        >
          ${Math.max(plan.price.recurringAmount + totalAddonPrice - discount, 0)}
        </span>
        <span className={periodVariants[variant]}>/ month</span>
      </div>
      <TransactionFees plan={plan} variant={variant} />
    </>
  )
}

export default PlanPrice
