import classNames from 'classnames'
import React, { forwardRef } from 'react'

const TableHeader = forwardRef<
  HTMLTableSectionElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>
>(({ children, className, ...rest }, ref) => {
  return (
    <thead
      {...rest}
      className={classNames('group h-10 is-header border-b border-neutral-100 group-[.is-header]:opacity-1', className)}
      ref={ref}
    >
      {children}
    </thead>
  )
})

export default TableHeader
