import React from 'react'

import BlankState from 'common/components/blankState/BlankState'

export interface FiltersBlankStateProps {
  handleClearFiltersClick: (e: React.MouseEvent) => void
}

const FiltersBlankState = ({ handleClearFiltersClick: handleClearFilters }: FiltersBlankStateProps) => {
  return (
    <BlankState className="max-w-sm">
      <BlankState.Title>No variants to display with the specified filters</BlankState.Title>
      <BlankState.Details>Remove or clear the applied filters</BlankState.Details>
      <BlankState.Action onClick={handleClearFilters}>Clear all filters</BlankState.Action>
    </BlankState>
  )
}

export default FiltersBlankState
