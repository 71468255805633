import { keyBy } from 'lodash'
import { useEffect } from 'react'

import type { Variant } from 'common/variants/types/variant'

import type { InventoryFormikContext } from './../components/types/form'

const useInfiniteFormReset = (locationId: string, variants: Variant[], formik: InventoryFormikContext) => {
  useEffect(() => {
    if (formik.status === 'submitted') {
      return formik.resetForm({
        values: keyBy(
          variants?.map(variant => ({
            ...variant.inventoryItem,
            ...variant.inventoryItem.inventoryLevelByLocationId[locationId],
          })),
          'id'
        ),
      })
    }

    const newValues = variants.map(variant => {
      const formValue = formik.values[variant.inventoryItem.id]

      if (
        formValue == null ||
        formik.touched[variant.inventoryItem.id]?.stock === false ||
        formik.touched[variant.inventoryItem.id]?.stock === undefined
      ) {
        return {
          ...variant.inventoryItem,
          ...variant.inventoryItem.inventoryLevelByLocationId[locationId],
        }
      }

      return {
        ...variant.inventoryItem,
        stock: formValue?.stock ?? variant.inventoryItem.inventoryLevelByLocationId[locationId].stock,
        committed: variant.inventoryItem.inventoryLevelByLocationId[locationId].committed,
      }
    })

    formik.resetForm({
      values: keyBy(
        variants?.map(variant => ({
          ...variant.inventoryItem,
          ...variant.inventoryItem.inventoryLevelByLocationId[locationId],
        })),
        'id'
      ),
      touched: formik.touched,
    })
    formik.setValues(keyBy(newValues, 'id'))
  }, [variants])
}

export default useInfiniteFormReset
