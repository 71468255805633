import { useContext } from 'react'

import { AppDispatch } from 'customizer/store'

import CustomizerIframeProviderContext from '../CustomizerIframeProviderContext'

const useCustomizerDispatch = () => {
  const { store } = useContext(CustomizerIframeProviderContext)
  return store.dispatch as AppDispatch
}

export default useCustomizerDispatch
