import { useState, useRef, useEffect } from 'react'

const useToggleWithDelay = (value, onUpdate, delay) => {
  const [internalValue, setInternalValue] = useState(value)
  const timer = useRef(null)

  const handleOnChange = () => {
    setInternalValue(!internalValue)

    if (timer.current) clearTimeout(timer.current)

    timer.current = setTimeout(() => {
      onUpdate(!internalValue)
      timer.current = null
    }, delay)
  }

  useEffect(() => {
    if (value !== internalValue) setInternalValue(value)
  }, [value])

  return [internalValue, handleOnChange]
}

export default useToggleWithDelay
