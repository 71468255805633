import {
  CancelDrop,
  closestCenter,
  CollisionDetection,
  getFirstCollision,
  MouseSensor,
  TouchSensor,
  useSensors,
  useSensor,
  MeasuringStrategy,
  Collision,
  UniqueIdentifier,
} from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import CustomDragOverlay from './CustomDragOverlay'
import { rectIntersection } from './customIntersection'
import customSorting from './customSorting'
import DroppableContainer from './DroppableContainer'
import SortableTreeItem from './SortableTreeItem'
import SortableTreesContext from './SortableTreesContext'
import { ContainerComponents, Containers, FlattenedContainers, FlattenedItem, TreeItemComponentType } from './types'
import { flattenTree, getProjection, hasCollapsedParent, isAncestor, moveItem } from './utilities'

export interface SortableTreeProps<
  T extends Record<string, any>,
  TElement extends HTMLElement,
  C extends Record<string, React.ComponentType>,
> {
  adjustScale?: boolean
  cancelDrop?: CancelDrop
  containers: Containers<T>
  Item: TreeItemComponentType<T, TElement>
  Containers: ContainerComponents<C>
  scrollable?: boolean
  className?: string
  indentationWidth?: number
  onReorder(containers: Containers<T>): void
  onDragEnd(): void
  onDragStart?: () => void
  validateDragEnd?: (containers: Containers<T>) => boolean
  onToggleCollapse?: (containerId: string, itemId: string) => void
  containersProps: Record<string, any>
}

const SortableTree = <
  T extends Record<string, any>,
  TElement extends HTMLElement,
  C extends Record<string, React.ComponentType>,
>({
  cancelDrop,
  containers: initialContainers,
  Item,
  Containers,
  className = '',
  onReorder = () => ({}),
  onDragEnd = () => ({}),
  onDragStart = () => ({}),
  onToggleCollapse = () => ({}),
  validateDragEnd = () => true,
  indentationWidth = 14,
  containersProps,
}: SortableTreeProps<T, TElement, C>) => {
  const [containers, setContainers] = useState<Containers<T>>(() => initialContainers)
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

  const currentCollision = useRef<Collision>()
  const dragStartTimeout = useRef<ReturnType<typeof setTimeout>>()

  const flattenedContainers: Record<string, FlattenedItem<T>[]> = Object.keys(containers).reduce(
    (flattenedItems, containerId) => {
      return {
        ...flattenedItems,
        [containerId]: flattenTree(containers[containerId]).filter(item => {
          const isParentBeingDragged = !!activeId && isAncestor(activeId, item)
          return !isParentBeingDragged && !hasCollapsedParent(item)
        }),
      }
    },
    {}
  )

  const isFirstRender = useRef(true)
  const containerIds = Object.keys(flattenedContainers) as string[]

  const lastOverId = useRef<UniqueIdentifier | null>(null)
  const recentlyMovedToNewContainer = useRef(false)
  const flattenedItems: FlattenedItem<T>[] = Object.values(flattenedContainers).reduce(
    (flattenedItems: FlattenedItem<T>[], items) => [...flattenedItems, ...items],
    []
  )

  const getItem = (id: string) => {
    return flattenedItems.find(item => item.id === id)
  }

  const collisionDetectionStrategy: CollisionDetection = useCallback(
    args => {
      const [intersections, realIntersections] = rectIntersection(args)
      const realCollision = realIntersections.filter(
        coll => coll.id !== args.active.id && !flattenedContainers[coll.id]
      )[0]

      const overCollision = getFirstCollision(intersections)

      currentCollision.current = realCollision

      let overId = overCollision?.id

      if (overId == null) {
        const isContainer = realIntersections[0]?.data?.droppableContainer.data.current?.type === 'container'
        if (isContainer && realIntersections[0].id !== findContainer(args.active.id)) {
          overId = realIntersections[0].id
        }
      }

      if (overId != null) {
        if (overId in flattenedContainers) {
          const containerItems = flattenedContainers[overId]
          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                container => container.id !== overId && containerItems.find(item => item.id === container.id)
              ),
            })[0]?.id
          }
        }

        lastOverId.current = overId

        return [{ id: overId }]
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : []
    },
    [activeId, flattenedContainers]
  )
  const [clonedContainers, setClonedContainers] = useState<Containers<T> | null>(null)
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 3 },
    }),
    useSensor(TouchSensor)
  )
  const findContainer = (id: UniqueIdentifier) => {
    if (id in flattenedContainers) {
      return id
    }

    return Object.keys(flattenedContainers).find(key => !!flattenedContainers[key].find(item => item.id === id))
  }

  const onDragCancel = () => {
    if (clonedContainers) setContainers(clonedContainers)

    setActiveId(null)
    setClonedContainers(null)
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [flattenedContainers])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    if (!activeId) onReorder(containers)
  }, [activeId])

  useEffect(() => {
    setContainers(initialContainers)
  }, [initialContainers])

  const getContainerItems = (containerId: string) => {
    return flattenedContainers[containerId].filter(item => {
      const isParentBeingDragged = item?.parent?.id === activeId

      return !isParentBeingDragged && (item?.parent ? !item.parent.collapsed : true)
    })
  }

  const collapsedItems: FlattenedContainers<T> = Object.keys(flattenedContainers).reduce(
    (collapsedItems, containerId) => ({ ...collapsedItems, [containerId]: getContainerItems(containerId) }),
    {}
  )

  return (
    <SortableTreesContext
      indentationWidth={indentationWidth}
      currentCollision={currentCollision}
      flattenedItems={flattenedItems}
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        flattenedItems.forEach(item => {
          if (item.canHaveChildren && item.children?.length === 0 && !item.collapsed) onToggleCollapse('', item.id)
        })

        // this timeout prevents wrong position for dragged item when the
        // scroll container changes by collapsing a dragged folder
        dragStartTimeout.current = setTimeout(() => {
          setActiveId(active.id)
          onDragStart()
          setClonedContainers(initialContainers)
        }, 1)
      }}
      onDragOver={({ active, over, delta }) => {
        const overId = over?.id

        if (overId == null || activeId == null) {
          return
        }

        const projected = getProjection(flattenedItems, active?.id, over?.id, delta.x, indentationWidth)

        const overContainer = findContainer(overId)
        const activeContainer = findContainer(active.id)

        if (!overContainer || !activeContainer) {
          return
        }

        if (activeContainer !== overContainer) {
          recentlyMovedToNewContainer.current = true
          const isBelowOver = (active.rect.current.translated?.bottom || 0) > (over?.rect.bottom || 0)

          setContainers(containers =>
            moveItem<T>(
              containers,
              flattenedContainers,
              activeContainer,
              overContainer,
              active.id,
              overId,
              projected,
              isBelowOver ? 'append' : 'replace'
            )
          )
        }
      }}
      onDragEnd={({ active, over, delta }) => {
        clearTimeout(dragStartTimeout.current)
        onDragEnd()
        const realOverItem =
          currentCollision.current?.id !== active.id &&
          currentCollision.current?.data &&
          currentCollision.current?.data.value < 0.6
            ? flattenedItems.find(item => item.id === currentCollision.current?.id)
            : undefined

        const projected = getProjection(flattenedItems, active?.id, over?.id, delta.x, indentationWidth)
        const overItem = realOverItem?.collapsed ? realOverItem : flattenedItems.find(item => item.id === over?.id)

        const isOverCollapsedFolder = active != null && Boolean(realOverItem?.collapsed)

        if (isOverCollapsedFolder && overItem && projected) projected.parentId = overItem.id

        const activeContainer = findContainer(active.id)
        if (!activeContainer) {
          setActiveId(null)
          return
        }

        const overId = over?.id

        if (overId == null) {
          setActiveId(null)
          return
        }

        const overContainer = findContainer(overId)

        if (overContainer) {
          const overItem = flattenedContainers[overContainer].find(item => item.id === overId)

          setContainers(containers => {
            const newContainers = moveItem(
              containers,
              flattenedContainers,
              activeContainer,
              overContainer,
              active.id,
              overId,
              projected
            )

            if (validateDragEnd(newContainers)) return newContainers

            return clonedContainers ?? containers
          })

          const destinationParentId = projected?.parentId ?? overItem?.parentId

          if (destinationParentId) {
            const parent = flattenedContainers[overContainer].find(item => item.id === destinationParentId)
            if (parent?.collapsed) onToggleCollapse(overContainer as string, destinationParentId)
          }
        }

        setActiveId(null)
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
    >
      <div className={className}>
        {containerIds.map(containerId => (
          <DroppableContainer
            key={containerId}
            id={containerId}
            items={collapsedItems[containerId]}
            Container={Containers[containerId]}
            {...(containersProps[containerId] ? containersProps[containerId] : {})}
          >
            <SortableContext items={collapsedItems[containerId]} strategy={customSorting}>
              {collapsedItems[containerId].map(item => {
                return (
                  <SortableTreeItem<T, TElement>
                    key={item.id}
                    id={item.id}
                    item={item}
                    containerId={containerId}
                    onToggleCollapse={onToggleCollapse}
                    Item={Item}
                    indentationWidth={indentationWidth}
                  />
                )
              })}
            </SortableContext>
          </DroppableContainer>
        ))}
      </div>
      {createPortal(
        <CustomDragOverlay<T, TElement>
          item={getItem(activeId as string)}
          Item={Item}
          indentationWidth={indentationWidth}
        />,
        document.body
      )}
    </SortableTreesContext>
  )
}

export default SortableTree
