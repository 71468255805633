import classNames from 'classnames'
import React from 'react'

export interface ModalImageProps {
  children?: React.ReactNode
  className?: string
}

const ModalImage = ({ children, className }: ModalImageProps) => (
  <div className={classNames('text-neutral-600 overflow-auto', className)}>{children}</div>
)

export default ModalImage
