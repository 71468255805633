import classNames from 'classnames'
import React from 'react'

const TableHeaderCell = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) => (
  <th
    {...rest}
    role="columnheader"
    className={classNames('text-left px-1 py-2 text-neutral-500 font-p4 !font-normal', className)}
  >
    {children}
  </th>
)

export default TableHeaderCell
