import { createContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'

export type MatchParams = {
  productId: string
  startingPointId?: string
  brandName?: string
}

type GlobalRouterContextValue = RouteComponentProps<MatchParams>

const GlobalRouterContext = createContext<GlobalRouterContextValue>({} as GlobalRouterContextValue)

export default GlobalRouterContext
