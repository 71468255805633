import { NormalizedCustomizerProduct } from '@packages/types'

export const maximumAllowedVariants = 5000

const useTotalVariantsCount = (questionIds: string[], customizerProduct: NormalizedCustomizerProduct) => {
  const totalCount = questionIds.reduce((count, questionId) => {
    if (!questionId) return count

    return (count == 0 ? 1 : count) * customizerProduct.questions[questionId].answers.length
  }, 0)

  return { totalCount, isOverMax: totalCount > maximumAllowedVariants }
}

export default useTotalVariantsCount
