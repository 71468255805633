import { createContext } from 'react'

import { useFetchQuote } from './hooks/useFetchQuote'

export interface QuoteContextType extends ReturnType<typeof useFetchQuote> {
  isEditing: boolean
  isSaving: boolean
  setIsEditing: (isEditing: boolean) => void
  isRevision: boolean
  currenciesInputProps?: { rightAddon?: string; leftAddon?: string }
}

const QuoteContext = createContext<QuoteContextType>({
  isEditing: false,
  isSaving: false,
  setIsEditing: () => {},
  isRevision: false,
  currenciesInputProps: {},
  ...({} as ReturnType<typeof useFetchQuote>),
})

export default QuoteContext
