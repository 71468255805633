import classNames from 'classnames'
import React from 'react'

export interface ProgressBarProps {
  containerClassName?: string
  className?: string
  percentage: number
  onClick?: () => void
}

export interface FiniteProgressBarProps {
  containerClassName?: string
  className?: string
  totalSteps: number
  currentStep: number
}

export interface StepProgressBarProps extends FiniteProgressBarProps {
  onStepClick?: (stepIndex: number) => void
}

const ProgressBar = ({ containerClassName, className, percentage, onClick }: ProgressBarProps) => (
  <div
    className={classNames('w-full rounded-full h-2 my-4 bg-neutral-100', containerClassName)}
    role="progressbar"
    onClick={onClick}
  >
    <div
      className={classNames('bg-secondary-orange-200 rounded-full h-2', className)}
      style={{ width: `${percentage}%` }}
    ></div>
  </div>
)

const FiniteProgressBar = ({ className, totalSteps, currentStep }: FiniteProgressBarProps) => {
  const getStepPercentage = (stepIndex: number) => {
    return currentStep >= stepIndex ? 100 : 0
  }

  return (
    <div className={classNames('w-full flex space-x-2')} role="progressbar">
      {Array.from(new Array(totalSteps)).map((_, index) => (
        <ProgressBar key={`progress-step-${index}`} className={className} percentage={getStepPercentage(index)} />
      ))}
    </div>
  )
}

const StepProgressBar = ({
  containerClassName,
  className,
  totalSteps,
  currentStep,
  onStepClick,
}: StepProgressBarProps) => {
  const currentStepPercent = 1 / totalSteps
  const gapsCount = totalSteps - 1

  return (
    <div
      className={classNames(containerClassName, 'w-full flex space-x-2 relative')}
      style={{ containerType: 'size' }}
      role="progressbar"
    >
      {Array.from(new Array(totalSteps)).map((_, index) => (
        <ProgressBar
          key={`progress-step-${index}`}
          containerClassName={classNames({ 'hover:cursor-pointer': onStepClick != null })}
          className={className}
          percentage={0}
          onClick={() => onStepClick?.(index)}
        />
      ))}
      <div
        className="absolute !ml-0 will-change-transform"
        style={{
          width: `calc(${currentStepPercent} * (100cqw - ${0.5 * gapsCount}rem))`,
          left: 0,
          transform: `translateX(calc(${currentStep * 100}% + ${0.5 * currentStep}rem))`,
          transition: 'transform 0.2s ease-in-out',
        }}
      >
        <ProgressBar className={classNames(className)} percentage={100} />
      </div>
    </div>
  )
}

ProgressBar.Finite = FiniteProgressBar
ProgressBar.Steps = StepProgressBar

export default ProgressBar
