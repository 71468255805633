import { trpc } from 'common/hooks/trpc'

import { FormikVariants, VariantFormValue } from '../types'
import { getInventoryItemFormValue } from '../utils'

const useSharedSkus = (locationId: string, sku: string, formik: FormikVariants) => {
  const { data: skuSharedData } = trpc.variant.getSkuSharedData.useQuery(sku, {
    enabled: sku != null && sku !== '',
    staleTime: 60 * 1000,
  })

  if (sku === '')
    return {
      inventoryItem: null,
      variantIds: [],
    }

  const additionalVariants = Object.keys(formik.touched.variants ?? {}).reduce(
    (variants: VariantFormValue[], variantId) => {
      const variant = formik.values.variants[variantId]
      const inventoryItem = formik.values.inventoryItems[variant.inventoryItem]
      return inventoryItem.sku === sku && !skuSharedData?.variantIds.includes(variantId)
        ? [...variants, variant]
        : variants
    },
    []
  )

  if (!skuSharedData?.inventoryItem)
    return {
      inventoryItem: formik.values.inventoryItems[additionalVariants[0]?.inventoryItem],
      variantIds: additionalVariants.map(variant => variant.id),
    }

  return {
    inventoryItem:
      formik.values.inventoryItems[additionalVariants[0]?.inventoryItem] ??
      getInventoryItemFormValue(locationId, skuSharedData.inventoryItem),
    variantIds: [
      ...skuSharedData?.variantIds.filter(variantId => {
        if (!formik.touched?.variants?.[variantId]) return true

        const variant = formik.values.variants[variantId]
        const inventoryItem = formik.values.inventoryItems[variant.inventoryItem]

        return inventoryItem.sku === sku
      }),
      ...additionalVariants.map(variant => variant.id),
    ],
  }
}

export default useSharedSkus
