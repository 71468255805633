const bytesIn1Mb = 1024 * 1024

const validateFiles = (
  files: FileList | null,
  { maxFileSizeInMB = 100, extensions = [] }: { maxFileSizeInMB: number; extensions: string[] }
) => {
  const validFiles: File[] = []
  const invalidFiles: File[] = []

  if (!files || files.length === 0) return { validFiles, invalidFiles }

  for (const file of files) {
    const extension = file.name.split('.').pop()?.toLowerCase()
    const isAuthorizedExtension = extension && extensions.includes(extension)
    const isFileValid = file.size <= maxFileSizeInMB * bytesIn1Mb && isAuthorizedExtension

    isFileValid ? validFiles.push(file) : invalidFiles.push(file)
  }

  return { validFiles, invalidFiles }
}

export default validateFiles
