import { UserRole } from '@packages/types'
import { useFlags, useFlagsmith } from 'flagsmith/react'
import { IFlagsmith } from 'flagsmith/types.d'
import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import ErrorPage from 'common/components/ErrorPage'
import useCurrentUser from 'common/users/hooks/useCurrentUser'

export interface ProtectedRouteProps extends RouteProps {
  allowedRoles?: UserRole[]
  feature?: string
}

interface FeatureProtectedRouteProps extends RouteProps {
  feature: string
}

interface FeatureAndRoleProtectedRouteProps extends RouteProps {
  allowedRoles: UserRole[]
  feature: string
}

interface RoleProtectedRouteProps extends RouteProps {
  allowedRoles: UserRole[]
}

const FeatureProtectedRoute = ({ feature, ...props }: FeatureProtectedRouteProps) => {
  const flags = useFlags([feature])
  if (flags[feature].enabled) return <Route {...props} />

  return <ErrorPage title="403" description="Forbidden" />
}

const FeatureAndRoleProtectedRoute = ({ feature, allowedRoles, ...props }: FeatureAndRoleProtectedRouteProps) => {
  const flags = useFlags([feature])
  const { currentUser } = useCurrentUser()
  const flagsmith = useFlagsmith() as IFlagsmith & { isLoading: boolean }
  if (!currentUser || flagsmith.isLoading) return null

  if (allowedRoles.includes(currentUser.role) && flags[feature].enabled) return <Route {...props} />
  return <ErrorPage title="403" description="Forbidden" />
}

const RoleProtectedRoute = ({ allowedRoles, ...props }: RoleProtectedRouteProps) => {
  const { currentUser } = useCurrentUser()

  if (!currentUser) return null
  if (allowedRoles.includes(currentUser.role)) return <Route {...props} />
  return <ErrorPage title="403" description="Forbidden" />
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  if (props.allowedRoles != null && !!props.feature)
    return <FeatureAndRoleProtectedRoute key={props.feature} {...(props as FeatureAndRoleProtectedRouteProps)} />

  if (props.allowedRoles != null) return <RoleProtectedRoute {...(props as RoleProtectedRouteProps)} />

  if (!!props.feature) return <FeatureProtectedRoute key={props.feature} {...(props as FeatureProtectedRouteProps)} />

  return <Route {...props} />
}

export default ProtectedRoute
