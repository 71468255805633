import { useState } from 'react'

export interface ModalType {
  isVisible: boolean
  open: () => void
  close: () => void
  modalProps: { isOpen: boolean; unmount: () => void }
}

const useModal = (): ModalType => {
  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const open = () => {
    setIsOpen(true)
    setIsVisible(true)
  }

  const close = () => setIsOpen(false)

  const unmount = () => setIsVisible(false)

  return { isVisible, open, close, modalProps: { isOpen, unmount } }
}

export default useModal
