import axios from 'axios'

import createQuery from 'common/api/createQuery'

import type { Font } from './../types/font'
import { googleFontUrl } from './../utils/urls'

const useFontService = () => ({
  fetchAllGoogleFonts: createQuery(['google-fonts'], async () => {
    const response = await axios.get<{ items: Font[] }>(googleFontUrl)
    return response.data.items
  }),
})

export default useFontService
