import { Bezier } from 'bezier-js'
import { clone, round } from 'lodash'

import { toRads, rotatePoint } from 'utils/math'

export const computeIsArc = (bezier: number[]) =>
  bezier[2] !== bezier[0] || bezier[3] !== bezier[1] || bezier[4] !== bezier[6] || bezier[5] !== bezier[7]

export const toArc = (bezier: number[]) => {
  const newBezier = clone(bezier)
  newBezier[1] = bezier[1] + 50
  newBezier[3] = bezier[1] - 50
  newBezier[5] = bezier[7] - 50
  newBezier[7] = bezier[7] + 50
  return newBezier
}

export const toStraight = (bezier: number[]) => {
  const newBezier = clone(bezier)
  newBezier[1] = bezier[1] - 50
  newBezier[2] = bezier[0]
  newBezier[3] = bezier[1] - 50
  newBezier[4] = bezier[6]
  newBezier[5] = bezier[7] - 50
  newBezier[7] = bezier[7] - 50
  return newBezier
}

export const getBezier = (boundingBox: { x: number; y: number; width: number; height: number; rotation: number }) => {
  const middleX = boundingBox.x
  const middleY = boundingBox.y

  return [middleX - 75, middleY, middleX - 75, middleY, middleX + 75, middleY, middleX + 75, middleY]
}

const getBoxVertices = ({
  maxWidth,
  maxHeight,
  centerX,
  centerY,
}: {
  maxWidth: number
  maxHeight: number
  centerX: number
  centerY: number
}) => [
  { x: centerX - maxWidth / 2, y: centerY - maxHeight / 2 },
  { x: centerX + maxWidth - maxWidth / 2, y: centerY - maxHeight / 2 },
  { x: centerX + maxWidth - maxWidth / 2, y: centerY + maxHeight - maxHeight / 2 },
  { x: centerX - maxWidth / 2, y: centerY + maxHeight - maxHeight / 2 },
]

const getBoxVerticesFromShape = ({
  maxWidth,
  maxHeight,
  centerX,
  centerY,
  rotation = 0,
}: {
  maxWidth: number
  maxHeight: number
  centerX: number
  centerY: number
  rotation: number
}) => {
  const vertices = getBoxVertices({ maxWidth, maxHeight, centerX, centerY })

  return vertices
    .map(point => rotatePoint(point, toRads(rotation), { x: centerX, y: centerY }))
    .map(point => ({ x: round(point.x, 1), y: round(point.y, 1) }))
}

export const getBezierBoundingBox = (bezier: number[]) => {
  return new Bezier(bezier[0], bezier[1], bezier[2], bezier[3], bezier[4], bezier[5], bezier[6], bezier[7]).bbox()
}

export const getTextBoundingBox = ({
  maxWidth,
  maxHeight,
  x,
  y,
  rotation = 0,
  bezier,
}: {
  maxWidth?: number | null
  maxHeight?: number | null
  x?: number
  y?: number
  rotation?: number
  bezier: number[]
}) => {
  let centerX = x
  let centerY = y

  if (centerX == null || centerY == null) {
    const bezierBoundingBox = new Bezier(
      bezier[0],
      bezier[1],
      bezier[2],
      bezier[3],
      bezier[6],
      bezier[7],
      bezier[4],
      bezier[5]
    ).bbox()

    centerX = bezierBoundingBox.x.mid!
    centerY = bezierBoundingBox.y.mid!
  }

  return {
    x: centerX,
    y: centerY,
    width: maxWidth != null ? maxWidth : 250,
    height: maxHeight != null ? maxHeight : 250,
    rotation,
  }
}

export const getLogoBoundingBox = ({
  maxWidth = 250,
  maxHeight = 250,
  x,
  y,
  rotation = 0,
}: {
  maxWidth: number
  maxHeight: number
  x: number
  y: number
  rotation: number
}) => {
  const centerX = x ?? 0
  const centerY = y ?? 0

  return getBoxVerticesFromShape({
    maxWidth,
    maxHeight,
    centerX,
    centerY,
    rotation,
  })
}

export const boundingBoxToComparableString = (boundingBox: { x: number; y: number }[]) => {
  return JSON.stringify(boundingBox.reduce<number[]>((result, { x, y }) => [...result, x, y], []))
}

export const calculateBoundingBoxPosition = (
  elementProps: {
    x: number
    y: number
    width: number
    height: number
  },
  scale: number
) => {
  const halfElementWidth = (elementProps.width * scale) / 2
  const halfElementHeight = (elementProps.height * scale) / 2
  return {
    left: elementProps.x * scale - halfElementWidth,
    top: elementProps.y * scale - halfElementHeight,
  }
}

export const calculateBoundingBoxDimensions = ({
  width,
  height,
  scale,
}: {
  width: number
  height: number
  scale: number
}) => {
  return {
    width: width * scale,
    height: height * scale,
  }
}
