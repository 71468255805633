import { FloatingPortal } from '@floating-ui/react'
import { useFloating, shift, offset, autoUpdate, Placement } from '@floating-ui/react-dom'
import React, { useEffect, useLayoutEffect } from 'react'

interface VideoPreviewModalProps {
  src: string | null
  containerRef: HTMLElement | null
  placement: Placement
}

const VideoPreviewModal = ({ src, containerRef, placement = 'right-start' }: VideoPreviewModalProps) => {
  const { x, y, strategy, refs, update } = useFloating({
    placement: placement,
    middleware: [shift(), offset(48)],
  })

  useLayoutEffect(() => {
    refs.setReference(containerRef)
  }, [containerRef])

  useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) {
      return
    }

    return autoUpdate(refs.reference.current, refs.floating.current, update)
  }, [refs.reference.current, refs.floating.current, update])

  if (!src) return null

  return (
    <FloatingPortal>
      <div
        className="rounded-md shadow-lg overflow-hidden z-[5] flex items-center h-[200px] align-items-[100%] bg-white"
        ref={refs.setFloating}
        style={{
          position: strategy,
          top: y ?? '',
          left: x ?? '',
        }}
      >
        <video disableRemotePlayback autoPlay loop className="max-w-[300px] max-h-full">
          <source src={src} type="video/webm" />
        </video>
      </div>
    </FloatingPortal>
  )
}

export default VideoPreviewModal
