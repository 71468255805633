import { OnlineStore } from '@packages/types'
import React from 'react'

import Tooltip from 'common/components/Tooltip'
import WarningIcon from 'icons/bold/01-Interface Essential/14-Alerts/alert-circle.svg'

import useOnlineStoreHasWarning from './../../hooks/useOnlineStoreHasWarning'

type ShopifyOnlineStoreConnectionStatusProps = {
  onlineStore: OnlineStore
}

const ShopifyOnlineStoreConnectionStatus = ({ onlineStore }: ShopifyOnlineStoreConnectionStatusProps) => {
  const onlineStoreHasWarning = useOnlineStoreHasWarning(onlineStore.id)

  if (onlineStoreHasWarning === 'warning') {
    return (
      <Tooltip content="Shopify product update is needed">
        <WarningIcon aria-label="Connection issue" className="fill-secondary-orange-200 ml-1 relative h-4 w-4" />
      </Tooltip>
    )
  }

  if (onlineStoreHasWarning === 'info') {
    return (
      <Tooltip content="Shopify product update available">
        <WarningIcon aria-label="Connection issue" className="fill-neutral-400 ml-1 relative h-4 w-4" />
      </Tooltip>
    )
  }
  return null
}

export default ShopifyOnlineStoreConnectionStatus
