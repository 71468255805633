import { EntityType } from '@packages/types'
import React, { forwardRef } from 'react'

import { Layer } from '../types'
import PartLayerCard from './PartLayerCard'
import PrintAreaLayerCard from './PrintAreaLayerCard'

export interface LayersOrderingItemProps {
  isDragged: boolean
  isDragOverlay: boolean
  layer: Layer
}

const LayersOrderingItem = forwardRef<HTMLDivElement, LayersOrderingItemProps>(({ layer, ...props }, ref) =>
  layer.entityType === EntityType.PrintArea ? (
    <PrintAreaLayerCard layer={layer} {...props} ref={ref} />
  ) : (
    <PartLayerCard layer={layer} {...props} ref={ref} />
  )
)

export default LayersOrderingItem
