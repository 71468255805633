import {
  AnswerType,
  OverflowBehavior,
  PositionAnswer,
  PrintAreaTextPositionAnswer,
  TextAlign,
  TextPart,
  TextPositionType,
  TextVerticalAlign,
} from '@packages/types'
import React from 'react'

import * as answerActions from 'builder/build/answers/actions'
import * as answersSelectors from 'builder/build/answers/selectors'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as displayerActions from 'builder/build/editor/actions'
import * as displayerUtils from 'builder/build/editor/utils'
import { useDispatch, useSelector } from 'cms/hooks'
import Button from 'common/components/Button'
import IconButton from 'common/components/IconButton'
import Popover from 'common/components/popover/Popover'
import usePopover from 'common/components/popover/usePopover'
import Tooltip from 'common/components/Tooltip'
import * as twoDDisplayerSelectors from 'customizer/2dDisplayer/selectors'
import * as customizationActions from 'customizer/customization/actions'
import VerticalAlignBottom from 'icons/bold/01-Interface Essential/34-Text-Formating/align-text-bottom.svg'
import VerticalAlignMiddle from 'icons/bold/01-Interface Essential/34-Text-Formating/align-text-middle.svg'
import VerticalAlignTop from 'icons/bold/01-Interface Essential/34-Text-Formating/align-text-top.svg'
import BoundingBoxIcon from 'icons/bold/12-Design/04-Vectors/vectors-anchor-square-1.svg'
import PathIcon from 'icons/bold/12-Design/04-Vectors/vectors-line-path.svg'
import CurvedPathIcon from 'icons/bold/12-Design/04-Vectors/vectors-path-flat.svg'
import DownArrowIcon from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg'
import CenterAlignIcon from 'icons/core-solid/interface-essential/interface-text-formatting-center-align.svg'
import LeftAlignIcon from 'icons/core-solid/interface-essential/interface-text-formatting-left-align.svg'
import RightAlignIcon from 'icons/core-solid/interface-essential/interface-text-formatting-right-align.svg'
import AutoResizeIcon from 'icons/custom/Text-Size--Streamline-Nova.svg'
import ExpandVerticalIcon from 'icons/regular/01-Interface Essential/53-Resize/expand-vertical.svg'
import ClipIcon from 'icons/regular/09-Shopping-Ecommerce/05-Discount-Coupons/coupon-cut.svg'

enum BezierType {
  Straight = 'straight',
  Arc = 'arc',
}

const iconsByTextAlign = {
  left: LeftAlignIcon,
  center: CenterAlignIcon,
  right: RightAlignIcon,
}

const iconsByVerticalAlign = {
  top: VerticalAlignTop,
  middle: VerticalAlignMiddle,
  bottom: VerticalAlignBottom,
}

const iconsByOverflowBehavior = {
  [OverflowBehavior.Visible]: ExpandVerticalIcon,
  [OverflowBehavior.Clip]: ClipIcon,
  [OverflowBehavior.Resize]: AutoResizeIcon,
}

const labelsByTextAlign: Record<TextAlign, string> = {
  left: 'Horizontal align left',
  center: 'Horizontal align center',
  right: 'Horizontal align right',
}

const labelsByVerticalAlign: Record<TextVerticalAlign, string> = {
  top: 'Vertical align top',
  middle: 'Vertical align middle',
  bottom: 'Vertical align bottom',
}

interface TextPositionSettingsProps {
  part: TextPart
  currentView: number
  disableAll?: boolean
}

const TextPositionSettings = ({ part, currentView, disableAll = false }: TextPositionSettingsProps) => {
  const dispatch = useDispatch()
  const customizerDispatch = customizerHooks.useCustomizerDispatch()
  const textAlignPopover = usePopover({ placement: 'bottom' })
  const verticalAlignPopover = usePopover({ placement: 'bottom' })
  const overflowBehaviorPopover = usePopover({ placement: 'bottom' })

  const isEditing = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.isEditingSelector)
  const position = useSelector(state => answersSelectors.answerByIdSelector(state, { id: part.position })) as
    | PrintAreaTextPositionAnswer
    | PositionAnswer
  const positionView =
    position.type === AnswerType.PrintAreaTextPosition ? position.position : position.views[currentView]
  const bezier = positionView.bezier
  const boundingBox = displayerUtils.getTextBoundingBox(positionView)
  const textAlign = positionView.textAlign
  const overflowBehavior =
    'overflowBehavior' in positionView && positionView.overflowBehavior
      ? positionView.overflowBehavior
      : OverflowBehavior.Clip
  const verticalAlign = positionView.verticalAlign ?? TextVerticalAlign.Middle
  const positionType = positionView.shape == null ? TextPositionType.Bezier : TextPositionType.Box
  const isResizable = !!part.allowedTransforms?.resize
  const isArc = displayerUtils.computeIsArc(positionView.bezier)
  const TextAlignIcon = iconsByTextAlign[textAlign]
  const VerticalAlignIcon = iconsByVerticalAlign[verticalAlign]
  const OverflowBehaviorIcon = iconsByOverflowBehavior[overflowBehavior]

  const updateBezier = (newBezier: number[]) => {
    part.printArea
      ? dispatch(displayerActions.applyPrintAreaBezierTextEdition(part.id, newBezier))
      : dispatch(displayerActions.applyBezierTextEdition(part.id, newBezier, currentView))
  }

  const updateBoundingBox = (newBoundingBox: {
    x: number
    y: number
    width: number
    height: number
    rotation: number
  }) => {
    part.printArea
      ? dispatch(displayerActions.applyPrintAreaMultilineTextEdition(part.id, newBoundingBox))
      : dispatch(displayerActions.applyMultilineTextEdition(part.id, newBoundingBox, currentView))
  }

  const updatePositionType = (positionType: TextPositionType, bezierType?: BezierType) => {
    if (!isEditing) customizerDispatch(customizationActions.editPart(part.id))

    if (positionType === TextPositionType.Box) {
      updateBoundingBox(boundingBox)
    } else if (positionType === TextPositionType.Bezier) {
      const newBezier = displayerUtils.getBezier(boundingBox)
      updateBezier(bezierType === BezierType.Arc ? displayerUtils.toArc(newBezier) : newBezier)
    }
  }

  const handleTextAlignClick = (textAlign: TextAlign) => {
    textAlignPopover.close()
    position.type === AnswerType.PrintAreaTextPosition
      ? dispatch(answerActions.patchAnswer(position, { position: { textAlign } }))
      : dispatch(answerActions.patchAnswerView(position, { textAlign }, currentView))
  }

  const handleOverflowBehaviorClick = (overflowBehavior: OverflowBehavior) => {
    overflowBehaviorPopover.close()
    position.type === AnswerType.PrintAreaTextPosition
      ? dispatch(answerActions.patchAnswer(position, { position: { overflowBehavior } }))
      : dispatch(answerActions.patchAnswerViews(position, { overflowBehavior }))
  }

  const handleVerticalAlignClick = (verticalAlign: TextVerticalAlign) => {
    verticalAlignPopover.close()
    position.type === AnswerType.PrintAreaTextPosition
      ? dispatch(answerActions.patchAnswer(position, { position: { verticalAlign } }))
      : dispatch(answerActions.patchAnswerView(position, { verticalAlign }, currentView))
  }

  const toArc = () => {
    if (!isEditing) customizerDispatch(customizationActions.editPart(part.id))
    updateBezier(displayerUtils.toArc(bezier))
  }

  const toStraight = () => {
    if (!isEditing) customizerDispatch(customizationActions.editPart(part.id))
    updateBezier(displayerUtils.toStraight(bezier))
  }

  return (
    <>
      <div>
        <div className="flex w-full">
          <Tooltip content="Text on straight path">
            <IconButton
              onClick={() => {
                if (positionType === TextPositionType.Bezier && !isArc) return
                if (positionType === TextPositionType.Bezier) return toStraight()
                updatePositionType(TextPositionType.Bezier, BezierType.Straight)
              }}
              variant={positionType === TextPositionType.Bezier && !isArc ? 'toggle-selected' : 'toggle'}
              disabled={disableAll}
              Icon={PathIcon}
            />
          </Tooltip>
          <Tooltip content="Text on curved path">
            <IconButton
              onClick={() => {
                if (positionType === TextPositionType.Bezier && isArc) return
                if (positionType === TextPositionType.Bezier) return toArc()
                updatePositionType(TextPositionType.Bezier, BezierType.Arc)
              }}
              variant={positionType === TextPositionType.Bezier && isArc ? 'toggle-selected' : 'toggle'}
              disabled={disableAll}
              Icon={CurvedPathIcon}
            />
          </Tooltip>
          <Tooltip content="Multiline text">
            <IconButton
              onClick={() => {
                if (positionType !== TextPositionType.Box) updatePositionType(TextPositionType.Box)
              }}
              variant={positionType === TextPositionType.Box ? 'toggle-selected' : 'toggle'}
              disabled={disableAll}
              Icon={BoundingBoxIcon}
            />
          </Tooltip>
          {positionType === TextPositionType.Box && !isResizable && (
            <>
              <Tooltip content="Overflow behavior">
                <Button
                  {...overflowBehaviorPopover.referenceProps}
                  disabled={disableAll}
                  icon={
                    <OverflowBehaviorIcon className="w-3.5 fill-current" aria-label={labelsByTextAlign[textAlign]} />
                  }
                  variant="subtle"
                >
                  <DownArrowIcon className="w-2 fill-current" />
                </Button>
              </Tooltip>
              <Popover
                {...overflowBehaviorPopover.floatingProps}
                isOpen={overflowBehaviorPopover.isOpen}
                className="!min-w-0"
              >
                <div className="flex">
                  <Tooltip content="Text area will grow vertically to fit the text according to the defined width">
                    <IconButton
                      variant="subtle"
                      onClick={() => handleOverflowBehaviorClick(OverflowBehavior.Visible)}
                      disabled={disableAll}
                      Icon={ExpandVerticalIcon}
                      aria-label="Expand"
                    />
                  </Tooltip>
                  <Tooltip content="Clip all text that overflows the text area">
                    <IconButton
                      variant="subtle"
                      onClick={() => handleOverflowBehaviorClick(OverflowBehavior.Clip)}
                      disabled={disableAll}
                      Icon={ClipIcon}
                      aria-label="Clip"
                    />
                  </Tooltip>
                  <Tooltip content="Automatically resize text to fit within the area">
                    <IconButton
                      variant="subtle"
                      onClick={() => handleOverflowBehaviorClick(OverflowBehavior.Resize)}
                      disabled={disableAll}
                      Icon={AutoResizeIcon}
                      aria-label="Auto resize"
                    />
                  </Tooltip>
                </div>
              </Popover>
            </>
          )}
        </div>
      </div>
      <div className="h-8 w-[1px] bg-neutral-75 ml-1 mr-1" />
      <div className="flex">
        <Tooltip content="Text align">
          <Button
            {...textAlignPopover.referenceProps}
            disabled={disableAll}
            icon={<TextAlignIcon className="w-3.5 fill-current" aria-label={labelsByTextAlign[textAlign]} />}
            variant="subtle"
          >
            <DownArrowIcon className="w-2 fill-current" />
          </Button>
        </Tooltip>
        <Popover {...textAlignPopover.floatingProps} isOpen={textAlignPopover.isOpen} className="!min-w-0">
          <div className="flex">
            <IconButton
              variant="subtle"
              onClick={() => handleTextAlignClick(TextAlign.Left)}
              disabled={disableAll}
              Icon={LeftAlignIcon}
              aria-label="Align left"
            />
            <IconButton
              variant="subtle"
              onClick={() => handleTextAlignClick(TextAlign.Center)}
              disabled={disableAll}
              Icon={CenterAlignIcon}
              aria-label="Align center"
            />

            <IconButton
              variant="subtle"
              onClick={() => handleTextAlignClick(TextAlign.Right)}
              disabled={disableAll}
              Icon={RightAlignIcon}
              aria-label="Align right"
            />
          </div>
        </Popover>
        {positionType === 'box' && (
          <>
            <Tooltip
              content={
                <>
                  <div>Vertical align</div>
                  <div className="text-xs">This setting is ignored when text resize editing is enabled</div>
                </>
              }
            >
              <Button
                {...verticalAlignPopover.referenceProps}
                disabled={disableAll}
                icon={
                  <VerticalAlignIcon className="w-3.5 fill-current" aria-label={labelsByVerticalAlign[verticalAlign]} />
                }
                variant="subtle"
              >
                <DownArrowIcon className="w-2 fill-current" />
              </Button>
            </Tooltip>
            <Popover {...verticalAlignPopover.floatingProps} isOpen={verticalAlignPopover.isOpen} className="!min-w-0">
              <div className="flex">
                <IconButton
                  variant="subtle"
                  onClick={() => handleVerticalAlignClick(TextVerticalAlign.Top)}
                  disabled={disableAll}
                  Icon={VerticalAlignTop}
                  aria-label="Align top"
                />
                <IconButton
                  variant="subtle"
                  onClick={() => handleVerticalAlignClick(TextVerticalAlign.Middle)}
                  disabled={disableAll}
                  Icon={VerticalAlignMiddle}
                  aria-label="Align middle"
                />

                <IconButton
                  variant="subtle"
                  onClick={() => handleVerticalAlignClick(TextVerticalAlign.Bottom)}
                  disabled={disableAll}
                  Icon={VerticalAlignBottom}
                  aria-label="Align bottom"
                />
              </div>
            </Popover>
          </>
        )}
      </div>
    </>
  )
}

export default TextPositionSettings
