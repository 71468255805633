import React, { forwardRef, useEffect, useRef, useState } from 'react'

import useObserveDimensionsOnResize from 'common/hooks/useObserveDimensionsOnResize'
import { PreviewMode } from 'common/preview/types/previewMode'
import TouchEmulator from 'utils/TouchEmulator'

const stylesByMode: Record<PreviewMode, React.CSSProperties> = {
  [PreviewMode.DESKTOP]: { minWidth: '1005px', width: '100%', height: '100%' },
  [PreviewMode.MOBILE]: { width: '414px', height: '100%' },
  [PreviewMode.PRODUCT]: { minWidth: '1005px', width: '100%', height: '100%' },
}

interface PreviewIFrameProps {
  src: string
  className?: string
  previewMode: PreviewMode
  onLoad: () => void
  onUnload?: () => void
}

const PreviewIframe = forwardRef<HTMLIFrameElement, PreviewIFrameProps>(
  ({ src, className, previewMode, onLoad, onUnload }, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const touchEmulatorRef = useRef<TouchEmulator | null>(null)
    const [dimensions, setDimensions] = useState<{ width?: number; height?: number }>({})

    useObserveDimensionsOnResize(containerRef, setDimensions)

    const handleLoad = (e: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
      touchEmulatorRef.current = new TouchEmulator((e.target as HTMLIFrameElement).contentWindow!)
      if (previewMode === PreviewMode.MOBILE) touchEmulatorRef.current.start()

      const iframe = document.getElementById('preview-iframe') as HTMLIFrameElement
      if (iframe?.contentWindow) iframe.contentWindow.onbeforeunload = () => onUnload?.()

      onLoad()
    }

    useEffect(() => {
      if (previewMode === PreviewMode.MOBILE && touchEmulatorRef.current && !touchEmulatorRef.current.isStarted) {
        touchEmulatorRef.current.start()
      } else if (
        [PreviewMode.DESKTOP, PreviewMode.PRODUCT].includes(previewMode) &&
        touchEmulatorRef.current &&
        touchEmulatorRef.current.isStarted
      ) {
        touchEmulatorRef.current.stop()
      }
    }, [previewMode])

    // https://stackoverflow.com/a/25365973/5103346
    useEffect(() => {
      const iframe = document.getElementById('preview-iframe') as HTMLIFrameElement

      if (!iframe) return

      onUnload?.()
      if (touchEmulatorRef.current?.isStarted) touchEmulatorRef.current.stop()
      touchEmulatorRef.current = null
      const container = iframe.parentNode!
      iframe.remove()
      iframe.src = src
      container.append(iframe)
    }, [src])

    useEffect(() => {
      return () => {
        onUnload?.()
      }
    }, [])

    let style = stylesByMode[previewMode]

    if (
      [PreviewMode.DESKTOP, PreviewMode.PRODUCT].includes(previewMode) &&
      dimensions.width != null &&
      dimensions.width - 32 < 1000
    ) {
      style = { ...style, transform: `scale(${(dimensions.width - 32) / 1000})` }
    }

    return (
      <div className="flex flex-1 justify-center items-center max-w-full max-h-full" ref={containerRef}>
        <iframe
          aria-label="Preview window"
          title="Preview window"
          ref={ref}
          id="preview-iframe"
          src={src}
          className={className}
          style={style}
          onLoad={handleLoad}
          allow="clipboard-write"
        />
      </div>
    )
  }
)

export default React.memo(PreviewIframe)
