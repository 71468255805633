import { Group } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import IconButton from 'common/components/IconButton'
import Popover from 'common/components/popover/Popover'
import usePopover from 'common/components/popover/usePopover'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'

import GroupAddSection from '../GroupAddSection'
import NewQuestionMenu from '../NewQuestionMenu'
import TitleSection from '../TitleSection'
import FolderElementList from './FolderElementList'

export interface FolderTabProps {
  group: Group
}

const FolderTab = ({ group }: FolderTabProps) => {
  const popover = usePopover({
    placement: 'left-start',
    offsetConfig: 8,
    shiftConfig: { mainAxis: true, crossAxis: false, padding: 16 },
  })

  return (
    <>
      <TitleSection group={group} />
      <hr className="panel__divider" />
      <div className="py-6">
        <GroupAddSection label="Group elements">
          <IconButton
            iconClassName={classNames('transition-transform', { 'rotate-45': popover.isOpen })}
            Icon={AddIcon}
            smallIcon
            {...popover.referenceProps}
          />

          <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
            <NewQuestionMenu groupId={group.id} onClose={popover.close} gifPlacement="left-start" />
          </Popover>
        </GroupAddSection>
        <FolderElementList group={group} />
      </div>
    </>
  )
}

export default FolderTab
