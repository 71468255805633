import React from 'react'

interface DatePickerCalendarContainerProps {
  children: React.ReactNode
}

const DatePickerCalendarContainer = ({ children }: DatePickerCalendarContainerProps) => (
  <div
    role="presentation"
    aria-label="calendar container"
    className="inline-block shadow-sm rounded-lg border border-neutral-50 bg-white"
  >
    {children}
  </div>
)

export default DatePickerCalendarContainer
