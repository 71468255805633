import { generateId, generateNextString } from '@packages/unique-string'

import * as coreTypes from 'builder/build/core/actionTypes'
import { productSettingsSelector } from 'builder/build/settings/selectors'
import * as printAreasUtils from 'common/printAreas/utils'

import * as types from './actionTypes'
import { printAreasSelector } from './selectors'

const { convertToPixels } = printAreasUtils

const SCALE_PERCENT = 0.5

const defaultPrintArea = {
  entityType: 'printarea',
  dpi: 300,
  showDpiIndicator: true,
  fileExtension: 'pdf',
  measurementUnit: 'inches',
  width: 12,
  height: 16,
  bleed: 0,
  margins: {
    horizontal: 0,
    vertical: 0,
  },
  productPreview: {
    designView: 0,
    outlineColor: '#000000',
    isShown: true,
    views: [],
    masks: [],
  },
}

export const addPrintArea = (partId, initialData = {}) => {
  return (dispatch, getState) => {
    const state = getState()
    const productSettings = productSettingsSelector(state)
    const printAreas = printAreasSelector(state)
    const id = generateId('PRINT-AREA')

    const printAreaInPixels = convertToPixels(defaultPrintArea)
    const scale =
      Math.max(productSettings.width / printAreaInPixels.width, productSettings.height / printAreaInPixels.height) *
      SCALE_PERCENT

    const action = {
      type: types.CREATE_PRINT_AREA,
      payload: {
        partId,
        printArea: {
          ...defaultPrintArea,
          ...initialData,
          id: id,
          name: generateNextString(Object.values(printAreas), 'name', `Print area`, ' ', true),
          productPreview: {
            ...defaultPrintArea.productPreview,
            views: Array(productSettings.views)
              .fill({
                x: (productSettings.width - printAreaInPixels.width * scale) / 2,
                y: (productSettings.height - printAreaInPixels.height * scale) / 2,
                scale,
                rotation: 0,
              })
              .map((view, i) => ({ ...view, hidden: i !== 0 })),
          },
        },
      },
    }

    dispatch(action)

    return action
  }
}

export const patchPrintArea = (printArea, patch) => {
  return { type: coreTypes.PATCH, payload: { printAreas: [{ id: printArea.id, ...patch }] } }
}

export const deletePrintArea = printAreaId => ({
  type: types.DELETE_PRINT_AREA,
  payload: {
    printAreaId,
  },
})

export const setMask = (printAreaId, masks) => {
  return {
    type: types.SET_MASK,
    payload: {
      printAreaId,
      masks,
    },
  }
}

export const updatePrintAreaMeasurementUnit = (printAreaId, measurementUnit) => {
  return {
    type: types.UPDATE_MEASUREMENT_UNIT,
    payload: {
      printAreaId,
      measurementUnit,
    },
  }
}

export const updatePrintAreaDPI = (printAreaId, dpi) => {
  return {
    type: types.UPDATE_DPI,
    payload: {
      printAreaId,
      dpi,
    },
  }
}
