import React from 'react'

import './SettingsContainer.scss'

interface SettingsContainerProps {
  titleProps?: {
    title: string
    onClickPrevious: () => void
  }
  children: React.ReactNode
}

const SettingsContainer = ({ titleProps, children }: SettingsContainerProps) => {
  return (
    <div id="settings-container" className="w-[252px] h-full overflow-auto">
      {titleProps && (
        <h6 className="settings-container__title text-base">
          {titleProps.onClickPrevious && (
            <div className="previous" onClick={titleProps.onClickPrevious}>
              {'<'}
            </div>
          )}
          {titleProps.title && <div className="title-text">{titleProps.title}</div>}
        </h6>
      )}
      {children}
    </div>
  )
}

export default SettingsContainer
