import { ShopifyInstalledTheme, ShopifyOnlineStore } from '@packages/types'
import React from 'react'

import Page from 'cms/layout/page/Page'

import ShopifyIntegrationSection from './ShopifyIntegrationSection'
import ShopifyThemeSection from './ShopifyThemeSection'

export interface LegacySectionsProps {
  themes?: ShopifyInstalledTheme[]
  onlineStore: ShopifyOnlineStore
}

const LegacySections = ({ themes, onlineStore }: LegacySectionsProps) => {
  return (
    <>
      <ShopifyThemeSection themes={themes} onlineStore={onlineStore} />
      <Page.Separator />
      <ShopifyIntegrationSection themes={themes} onlineStore={onlineStore} />
    </>
  )
}

export default LegacySections
