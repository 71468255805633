import classNames from 'classnames'
import React from 'react'

const PageHeader = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <header
    {...props}
    className={classNames(
      'flex flex-col md:flex-row justify-between md:items-center space-y-4 md:space-y-0 mb-10 md:h-8',
      className
    )}
  >
    {children}
  </header>
)

export default PageHeader
