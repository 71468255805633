import { Quote, ShopifyOnlineStore } from '@packages/types'
import React from 'react'

export type QuoteShopifyDraftOrderCardProps = {
  quote: Omit<Quote, 'store'> & { store: ShopifyOnlineStore }
}

const QuoteShopifyDraftOrderCard = ({ quote }: QuoteShopifyDraftOrderCardProps) => {
  if (!quote.integration?.shopify?.draftOrderId) return null

  const store = quote.store as ShopifyOnlineStore

  return (
    <span className="pr-3 mr-3 divide-x border-r border-neutral-100">
      Shopify draft order:
      <a
        className="text-primary-600 pl-1"
        href={`https://${store.domain}/admin/draft_orders/${quote.integration.shopify.draftOrderId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {quote.integration.shopify.draftOrderName || quote.integration.shopify.draftOrderId}
      </a>
    </span>
  )
}

export default QuoteShopifyDraftOrderCard
