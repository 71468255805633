import currencies from '@packages/data/currencies.json'
import { CustomOnlineStore } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import Page from 'cms/layout/page/Page'
import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import type { CustomOnlineStoreFormValues } from 'cms/onlineStores/types/form'
import Button from 'common/components/Button'
import Card from 'common/components/card/Card'
import HelperText from 'common/components/inputs/HelperText'
import Input from 'common/components/inputs/Input'
import InputField from 'common/components/inputs/InputField'
import Label from 'common/components/inputs/Label'
import Select from 'common/components/inputs/select/Select'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'

const getOnlineStoreValidationSchema = (onlineStores: CustomOnlineStore[]) =>
  yup.object().shape({
    name: yup.string().required('Please enter your online store name.'),
    currency: yup.string().oneOf(Object.keys(currencies)).required('Please choose a currency.'),
    domain: yup
      .string()
      .required("Please enter your store's url.")
      .notOneOf(
        onlineStores.map(onlineStore => onlineStore.domain),
        'A store with that url already exists.'
      ),
  })

const currencyOptions = Object.entries(currencies).map(([key, currency]) => ({
  value: key,
  label: `${currency.symbol} ${key}`,
}))

interface CustomOnlineStoreProps {
  onlineStore: CustomOnlineStore
}

const CustomOnlineStoreContent = ({ onlineStore }: CustomOnlineStoreProps) => {
  const { openToast, openGenericErrorToast } = useToast()
  const queryClient = useQueryClient()
  const onlineStoreService = useOnlineStoreService()

  const { data: onlineStores } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll, {
    initialData: [],
  })

  const { mutate: save } = useMutation(
    (values: CustomOnlineStoreFormValues) => onlineStoreService.update(onlineStore.id, values),
    {
      onSuccess: () => {
        openToast('Store was successfully saved!', ToastType.success)
        queryClient.invalidateQueries(onlineStoreService.fetchAll.queryKeys)
        queryClient.invalidateQueries(onlineStoreService.fetch.queryKeys)
      },
      onError: () => openGenericErrorToast('The online store has not been saved.'),
    }
  )

  const formik = useFormik<CustomOnlineStoreFormValues>({
    initialValues: {
      name: onlineStore.name || '',
      domain: onlineStore.domain || '',
      currency: onlineStore.currency || '',
    },
    validationSchema: getOnlineStoreValidationSchema(
      onlineStores.filter(store => store.id !== onlineStore.id) as CustomOnlineStore[]
    ),
    onSubmit: (values, { setSubmitting }) => save(values, { onSettled: () => setSubmitting(false) }),
    enableReinitialize: true,
  })

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Page.Section>
        <Page.Aside title="Brand" description="This is the information of your ecommerce." />

        <Page.Content>
          <Card className="flex flex-col flex-1">
            <Card.Section className="md:flex-col space-y-6">
              <InputField>
                <Label htmlFor="name">Online store name</Label>
                <Input
                  placeholder="Online store name"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.name && formik.errors.name != null}
                />
                {formik.touched.name && formik.errors.name != null && (
                  <HelperText hasError>{formik.errors.name}</HelperText>
                )}
              </InputField>

              <InputField>
                <Label htmlFor="domain">eCommerce URL</Label>
                <Input
                  placeholder="eCommerce URL"
                  id="domain"
                  name="domain"
                  value={formik.values.domain}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.domain && formik.errors.domain != null}
                />
                {formik.touched.domain && formik.errors.domain != null && (
                  <HelperText hasError>{formik.errors.domain}</HelperText>
                )}
              </InputField>

              <InputField>
                <Label htmlFor="currency">Currency</Label>
                <Select
                  id="currency"
                  name="currency"
                  placeholder="Currency"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  value={currencyOptions.find(({ value }) => value === formik.values.currency)}
                  options={currencyOptions}
                  onChange={option => formik.setFieldValue('currency', option?.value)}
                />
                {formik.touched.currency && formik.errors.currency != null && (
                  <HelperText hasError>{formik.errors.currency}</HelperText>
                )}
              </InputField>
            </Card.Section>

            <Card.Footer>
              <Button
                type="button"
                className="w-fit"
                onClick={() => formik.resetForm()}
                disabled={!formik.dirty || formik.isSubmitting}
              >
                Discard
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="w-fit"
                disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                isLoading={formik.isSubmitting}
              >
                Save
              </Button>
            </Card.Footer>
          </Card>
        </Page.Content>
      </Page.Section>
    </form>
  )
}

export default CustomOnlineStoreContent
