import { Subscription, SubscriptionStatus } from '@packages/types'
import { isPast } from 'date-fns'
import React from 'react'
import { useHistory, useParams } from 'react-router'

import Header from 'cms/layout/Header'
import Button from 'common/components/Button'
import Card from 'common/components/card/Card'
import FullPageModal from 'common/components/modal/FullPageModal'
import { FullPageModalProps } from 'common/components/modal/FullPageModal'

const isCanceled = (subscription: Subscription) => {
  return subscription.status === SubscriptionStatus.Canceled
}

const isFreeTrialExpired = (subscription: Subscription) => {
  return subscription.status === SubscriptionStatus.FreeTrial && isPast(new Date(subscription.freeTrialEndsOn))
}

interface ResubscribeFullPageModalProps extends Omit<FullPageModalProps, 'children'> {
  onClose?: () => void
  subscription: Subscription
}

const ResubscribeFullPageModal = ({ onClose, subscription, ...modalProps }: ResubscribeFullPageModalProps) => {
  const history = useHistory()
  const params = useParams<{ brandName: string }>()
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''

  return (
    <FullPageModal onClose={onClose} {...modalProps}>
      <Header />
      <Card className="w-[550px] flex-col justify-center mt-[30vh]">
        <Card.Section className="flex md:flex-col">
          <h1>Activate your Kickflip account!</h1>
        </Card.Section>
        {isFreeTrialExpired(subscription) && (
          <div className="mx-6 mb-4 font-medium">Your 14 days of free trial has expired.</div>
        )}
        {isCanceled(subscription) && <div className="mx-6 mb-4 font-medium">Your subscription is canceled.</div>}
        <div className="mx-6 mb-6 font-medium">Activate now and keep going with your project!</div>
        <Card.Footer>
          <Button
            variant="green"
            className="w-full mx-1 my-2"
            onClick={() => {
              history.push(`${baseUrl}/subscription`)
              onClose?.()
            }}
          >
            Activate now
          </Button>
        </Card.Footer>
      </Card>
    </FullPageModal>
  )
}

export default ResubscribeFullPageModal
