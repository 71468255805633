import { DenormalizedLogoPart, DenormalizedTextPart, TextPart } from '@packages/types'

import * as answersActions from 'builder/build/answers/actions'
import * as coreSelectors from 'builder/build/core/selectors'
import { patchPart } from 'builder/build/parts/actions.js'
import * as printAreasActionTypes from 'builder/build/printAreas/actionTypes'
import { AppThunk } from 'cms/store'
const { patchAnswer, patchAnswerView } = answersActions

export const applyPrintAreaEdition = (
  editedPrintAreaId: string,
  update: { x: number; y: number; width: number; height: number },
  view: number
) => {
  return {
    type: printAreasActionTypes.UPDATE_PRINT_AREA,
    payload: {
      printAreaId: editedPrintAreaId,
      view,
      update: {
        ...update,
        x: update.x,
        y: update.y,
        width: update.width,
        height: update.height,
      },
    },
  }
}

export const applyPrintAreaBezierTextEdition = (editedPartId: string, bezier: number[]): AppThunk => {
  return (dispatch, getState) => {
    const part = coreSelectors.partSelector(getState(), editedPartId) as TextPart | undefined

    if (!part) return

    dispatch(patchPart(part.id, { allowedTransforms: { move: false, rotate: false, resize: false } }))
    dispatch(patchAnswer(part.position, { position: { shape: null, bezier } }))
  }
}

export const applyBezierTextEdition = (editedPartId: string, bezier: number[], currentView: number): AppThunk => {
  return (dispatch, getState) => {
    const part = coreSelectors.partSelector(getState(), editedPartId) as TextPart | undefined

    if (!part) return

    dispatch(patchAnswerView(part.position, { shape: null, bezier }, currentView))
  }
}

export const applyMultilineTextEdition = (
  editedPartId: string,
  boundingBox: {
    x: number
    y: number
    width?: number
    height?: number
    rotation: number
  },
  currentView: number
): AppThunk => {
  return (dispatch, getState) => {
    const part = coreSelectors.partSelector(getState(), editedPartId) as DenormalizedTextPart | undefined

    if (!part) return

    dispatch(
      patchAnswerView(
        part.position,
        {
          shape: 'rectangle',
          x: boundingBox.x,
          y: boundingBox.y,
          maxWidth: boundingBox.width,
          maxHeight: boundingBox.height,
          rotation: boundingBox.rotation,
        },
        currentView
      )
    )
  }
}

export const applyPrintAreaMultilineTextEdition = (
  editedPartId: string,
  boundingBox: {
    x: number
    y: number
    width?: number
    height?: number
    rotation: number
  }
): AppThunk => {
  return (dispatch, getState) => {
    const part = coreSelectors.partSelector(getState(), editedPartId) as DenormalizedTextPart | undefined

    if (!part) return

    dispatch(
      patchAnswer(part.position, {
        position: {
          shape: 'rectangle',
          x: boundingBox.x,
          y: boundingBox.y,
          maxWidth: boundingBox.width,
          maxHeight: boundingBox.height,
          rotation: boundingBox.rotation,
        },
      })
    )
  }
}
export const applyLogoEdition = (
  editedPartId: string,
  boundingBox: {
    x: number
    y: number
    width: number
    height: number
    rotation: number
  },
  currentView: number
): AppThunk => {
  return (dispatch, getState) => {
    const part = coreSelectors.partSelector(getState(), editedPartId) as DenormalizedLogoPart | undefined

    if (!part) return

    dispatch(
      patchAnswerView(
        part.position,
        {
          x: boundingBox.x,
          y: boundingBox.y,
          maxWidth: boundingBox.width,
          maxHeight: boundingBox.height,
          rotation: boundingBox.rotation,
        },
        currentView
      )
    )
  }
}

export const applyPrintAreaLogoEdition = (
  editedPartId: string,
  boundingBox: { x: number; y: number; width: number; height: number; rotation: number }
): AppThunk => {
  return (dispatch, getState) => {
    const part = coreSelectors.partSelector(getState(), editedPartId) as DenormalizedLogoPart | undefined

    if (!part) return

    dispatch(
      patchAnswer(part.position, {
        position: {
          x: boundingBox.x,
          y: boundingBox.y,
          maxWidth: boundingBox.width,
          maxHeight: boundingBox.height,
          rotation: boundingBox.rotation,
        },
      })
    )
  }
}

export const applyVisibilityChange = (editedPartId: string, visibility: boolean, currentView: number): AppThunk => {
  return (dispatch, getState) => {
    const part = coreSelectors.partSelector(getState(), editedPartId) as
      | DenormalizedTextPart
      | DenormalizedLogoPart
      | undefined

    if (!part) return

    return dispatch(patchAnswerView(part.position, { hidden: !visibility }, currentView))
  }
}
