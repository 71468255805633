import { Design } from '@packages/types'
import { useContext } from 'react'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import type { Paginated } from 'common/api/types/pagination'
import TenantContext from 'common/tenant/TenantContext'
import type { DenormalizedOrder, Order } from './../types/order'
import type { OrdersSortKeys } from './../types/datatable'

export type FetchOrdersParams = {
  status?: string[]
  paymentStatus?: string[]
  sortOrder?: 'descending' | 'ascending'
  sortKey?: OrdersSortKeys
  count?: number
  page?: number
  filter?: string
  endDate?: Date | null
  startDate?: Date | null
  lastIndex?: number
}

const useOrderService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetchAll: createQuery([tenant, 'orders'], async (params: FetchOrdersParams) => {
      const { data } = await apiClient.get<Paginated<Order>>(`/brands/${tenant}/orders`, { params })
      return data
    }),
    fetch: createQuery([tenant, 'order'], async (id: string) => {
      const { data } = await apiClient.get<DenormalizedOrder>(`/brands/${tenant}/orders/${id}`)
      return data
    }),
    fetchRelatedDesigns: createQuery([tenant, 'order', 'designs'], async (id: string) => {
      const { data } = await apiClient.get<Design[]>(`/brands/${tenant}/orders/${id}/designs`)
      return data
    }),
    export: async (params: FetchOrdersParams) => {
      const { data } = await apiClient.get<string>(`/brands/${tenant}/orders/export`, { params })
      return data
    },
  }
}

export default useOrderService
