import { WixOnlineStore } from '@packages/types'
import React from 'react'

import Page from 'cms/layout/page/Page'
import Card from 'common/components/card/Card'
import OpenLinkIcon from 'icons/custom/open-link.svg'

interface WixOnlineStoreContentProps {
  onlineStore: WixOnlineStore
}

const WixOnlineStoreContent = ({ onlineStore }: WixOnlineStoreContentProps) => {
  return (
    <Page.Section>
      <Page.Aside title="Brand" description="This information comes from your ecommerce." />

      <Page.Content>
        <Card className="flex flex-col flex-1">
          <Card.Section>
            <div className="flex flex-col flex-[2] border-r border-neutral-100">
              <span className="text-xs font-medium text-neutral-400 mb-4">Online store name</span>
              <span>{onlineStore.name}</span>
            </div>
            <div className="flex flex-col flex-1 text-right">
              <span className="text-xs font-medium text-neutral-400 mb-4">Default currency</span>
              <span>{onlineStore.currency}</span>
            </div>
          </Card.Section>

          <Card.Separator />

          <Card.Section className="md:flex-col">
            <div className="flex flex-col">
              <span className="text-xs font-medium text-neutral-400 mb-4">eCommerce URL</span>
              <a className="flex items-center" href={onlineStore.url} target="_blank" rel="noopener noreferrer">
                <span className="mr-2 text-sm text-primary-600">{onlineStore.url}</span>
                <OpenLinkIcon className="w-3 h-3" />
              </a>
            </div>
          </Card.Section>
        </Card>
      </Page.Content>
    </Page.Section>
  )
}

export default WixOnlineStoreContent
