import { RootState } from 'cms/store'
import { RootState as CustomizerState } from 'customizer/store'

export const previewModeSelector = (state: RootState) => state.productBuilder.preview.mode

export const isPreviewReadySelector = (state: RootState) => state.productBuilder.preview.isReady

export const disabledPreviewModesSelector = (state: RootState) => state.productBuilder.preview.disabledModes

export const editablePrintAreaSelector = (state: CustomizerState) => {
  return Object.values(state.customization.printAreas).find(printArea => printArea.editable)
}
