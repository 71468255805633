import { NormalizedCustomizerProduct } from '@packages/types'
import classNames from 'classnames'
import React, { useMemo } from 'react'

import AvailableHeader from 'cms/inventory/components/table/AvailableHeader'
import CommittedHeader from 'cms/inventory/components/table/CommittedHeader'
import TotalHeader from 'cms/inventory/components/table/TotalHeader'
import Checkbox from 'common/components/inputs/Checkbox'
import Table from 'common/components/table/Table'
import { BulkSelection } from 'common/hooks/useBulkSelection'

import { FormikVariants, VariantFormValue } from '../types'
import { getInitialInventoryItem, getInventoryItem } from '../utils'
import AvailableCell from './AvailableCell'
import CommittedCell from './CommittedCell'
import SharedCell from './SharedCell'
import SkuCell from './SkuCell'
import StockCell from './StockCell'
import ContinueSellingTag from './tags/ContinueSellingTag'
import VariantsBulkPopover from './VariantsBulkPopover'

type ColumnDef = {
  key: string
  title: (
    formik: FormikVariants,
    bulkSelection: BulkSelection,
    customizerProduct: NormalizedCustomizerProduct,
    filteredVariants: VariantFormValue[]
  ) => React.ReactNode
  style?: React.CSSProperties
  className?: string
  cellClassName?: string
  render: (
    variant: VariantFormValue,
    formik: FormikVariants,
    bulkSelection: BulkSelection,
    customizerProduct: NormalizedCustomizerProduct,
    focusedSku?: string
  ) => React.ReactNode
  isSortable?: boolean
}

const useVariantsColumns = (
  locationId: string,
  variantsCombination: string[],
  notScrolled: boolean,
  fullyScrolled: boolean
) => {
  const columns = useMemo(
    (): ColumnDef[] => [
      {
        key: 'selected',
        title: (formik, bulkSelection, _customizerProduct, filteredVariants) => {
          return (
            <Table.BulkSelectionHeader bulkSelection={bulkSelection} disabled={filteredVariants.length === 0}>
              <VariantsBulkPopover formik={formik} locationId={locationId} bulkSelection={bulkSelection} />
            </Table.BulkSelectionHeader>
          )
        },
        style: {},
        className: classNames('pl-6 w-[58px] min-w-[58px] sticky left-0 bg-white', {
          'after:absolute after:bg-neutral-100 after:w-[1px] after:bottom-0 after:right-0 after:h-full before:h-full before:top-0 before:right-0 before:w-2 before:absolute before:shadow-[6px_0px_12px_rgba(0,0,0,0.05)]':
            notScrolled !== true,
        }),
        cellClassName: classNames('pl-6 sticky left-0 bg-white', {
          'after:absolute after:bg-neutral-100 after:w-[1px] after:bottom-0 after:right-0 after:h-full before:h-full before:top-0 before:right-0 before:w-2 before:absolute before:shadow-[6px_0px_12px_rgba(0,0,0,0.05)]':
            notScrolled !== true,
        }),
        render: (variant, _formik, bulkSelection, _customizerProduct) => {
          return (
            <Checkbox
              aria-label="Select row"
              tabIndex={-1}
              checked={bulkSelection.isSelected(variant.id)}
              onChange={e => {
                if (e.target.checked) return bulkSelection.select([variant.id])

                bulkSelection.deselect([variant.id])
              }}
            />
          )
        },
      },

      ...variantsCombination.map(
        (questionId): ColumnDef => ({
          key: questionId,
          title: (_formik, _bulkSelection, customizerProduct) => customizerProduct.questions[questionId].name,
          className: 'w-[180px] min-w-[180px] max-w-[180px] whitespace-nowrap overflow-hidden text-ellipsis',
          cellClassName: 'max-w-[180px] overflow-hidden whitespace-nowrap text-ellipsis',
          render: (variant, _formik, _bulkSelection, customizerProduct) => {
            const answerRef = variant.answersRefs.find(answerRef => answerRef.questionId === questionId)

            if (!answerRef) return <Table.EmptyCellIndicator />

            return (
              <span title={customizerProduct.answers[answerRef.answerId].name}>
                {customizerProduct.answers[answerRef.answerId].name}
              </span>
            )
          },
        })
      ),
      { key: 'spacing', title: () => '', className: '!p-0 w-full', cellClassName: '!p-0 w-full', render: () => null },

      {
        key: 'commmitted',
        title: () => <CommittedHeader />,
        className: 'w-[160px] min-w-[160px]',
        cellClassName: 'w-[160px] min-w-[160px]',
        render: (variant, formik) => {
          return <CommittedCell variant={variant} formik={formik} locationId={locationId} />
        },
        isSortable: false,
      },
      {
        key: 'available',
        title: () => <AvailableHeader />,
        className: 'w-[160px] min-w-[160px]',
        cellClassName: 'w-[160px] min-w-[160px]',
        render: (variant, formik) => {
          return <AvailableCell variant={variant} formik={formik} locationId={locationId} />
        },
        isSortable: false,
      },
      {
        key: 'tags',
        title: () => '',
        className: 'w-8 min-w-[2rem]',
        cellClassName: 'sticky right-[334px] !p-0',
        render: (variant, formik) => {
          return (
            <span className="flex space-x-1">
              {formik.values.variants?.[variant.id]?.continueSelling && (
                <ContinueSellingTag
                  className={classNames('sticky right-[339px]', { 'rounded-r-none': !fullyScrolled })}
                />
              )}
            </span>
          )
        },
      },
      {
        key: 'shared',
        title: () => null,
        className: classNames('pl-2 pr-4 w-3 min-w-[0.75rem] sticky right-[320px] bg-white', {
          'after:absolute after:bg-neutral-100 after:w-[1px] after:bottom-0 after:left-0 after:h-full before:h-full before:top-0 before:left-0 before:w-2 before:absolute before:shadow-[-6px_0px_12px_rgba(0,0,0,0.05)]':
            fullyScrolled === false && !(notScrolled && fullyScrolled),
        }),
        cellClassName: classNames(
          'pl-2 pr-1 sticky focus-within:z-[2] right-[320px] bg-white after:absolute after:bg-neutral-100',
          {
            'after:absolute after:bg-neutral-100 after:w-[1px] after:bottom-0 after:left-0 after:h-full before:h-full before:top-0 before:left-0 before:w-2 before:absolute before:shadow-[-6px_0px_12px_rgba(0,0,0,0.05)]':
              fullyScrolled === false && !(notScrolled && fullyScrolled),
          }
        ),
        render: (variant, formik, _bulkSelection, _customizerProduct, focusedSku) => {
          return <SharedCell variant={variant} formik={formik} focusedSku={focusedSku} locationId={locationId} />
        },
      },
      {
        key: 'stock',
        title: () => <TotalHeader />,
        className: classNames('pl-2 pr-4 w-[160px] min-w-[160px] sticky right-[160px] bg-white', {}),
        cellClassName: classNames(
          'pl-2 pr-4 sticky focus-within:z-[2] right-[160px] bg-white after:absolute after:bg-neutral-100',
          {}
        ),
        render: (variant, formik) => {
          const { id, stock, sku } = getInventoryItem(formik, variant)
          const { stock: initialStock } = getInitialInventoryItem(formik, variant)
          const updatePath = `inventoryItems.${id}.stock`

          return (
            <StockCell
              variantId={variant.id}
              initialValue={initialStock}
              aria-label="stock"
              value={stock}
              sku={sku}
              formik={formik}
              locationId={locationId}
              onChange={stock => {
                formik.setFieldTouched(updatePath)
                formik.setFieldValue(updatePath, stock)
              }}
            />
          )
        },
      },

      {
        key: 'sku',
        title: () => {
          return (
            <Table.HeaderCellTooltip
              content={
                <div className="flex flex-col space-y-4">
                  <div>
                    <div className="font-medium mb-0.5">Share stocks</div>
                    <div className="text-neutral-400">
                      All items that have the same SKUs will share their stock quantities.
                    </div>
                  </div>
                  <div>
                    <div className="font-medium mb-0.5">Connect</div>
                    <div className="text-neutral-400">
                      SKUs can be used to connect to a 3rd party inventory management solution.
                    </div>
                  </div>
                </div>
              }
            >
              SKU
            </Table.HeaderCellTooltip>
          )
        },
        className: 'pl-1 pr-2 w-[160px] min-w-[160px] sticky right-0 bg-white',
        cellClassName: 'pl-1 pr-2 sticky right-0 bg-white after:absolute after:bg-neutral-100',
        render: (variant, formik) => {
          return <SkuCell variant={variant} formik={formik} locationId={locationId} />
        },
      },
    ],
    [locationId, variantsCombination, notScrolled, fullyScrolled]
  )

  return columns
}

export default useVariantsColumns
