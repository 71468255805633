import classNames from 'classnames'
import React, { useRef, useState } from 'react'

import SearchIcon from 'icons/bold/01-Interface Essential/25-Search/search-1.svg'
import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import Button from '../Button'
import IconButton from '../IconButton'
import Input, { InputProps } from './../inputs/Input'

export interface SearchFilterProps extends InputProps {
  name: string
  search?: string
  setSearch: (search: string) => void
}

const SearchFilter = ({ name, search, setSearch, disabled, ...rest }: SearchFilterProps) => {
  const isSearchEmpty = isNullOrEmpty(search)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFiltering, setIsFiltering] = useState(!isSearchEmpty)
  const [isBlurFromButtonClick, setIsBlurFromButtonClick] = useState(false)

  const isInputVisible = isFiltering || !isSearchEmpty

  return (
    <Input
      {...rest}
      ref={inputRef}
      aria-label={rest['aria-label'] ?? `input of ${name}`}
      name={name}
      className={classNames('!w-auto shrink-0 grow-0 !px-2 shadow-xs !h-7 rounded-xl', {
        'border-dashed ': !isInputVisible,
      })}
      disabled={disabled}
      leftAddon={
        <>
          <span className="pl-1.5">
            <Button
              variant="text"
              className="!text-xs text-neutral-400 !p-0"
              disabled={disabled}
              onClick={() => {
                setIsFiltering(isFiltering => !isFiltering)
                if (inputRef.current && !isFiltering) inputRef.current.focus()
              }}
              onMouseDown={() => setIsBlurFromButtonClick(true)}
              onMouseUp={() => setIsBlurFromButtonClick(false)}
              icon={<SearchIcon className="w-2.5 h-2.5 fill-current" />}
            >
              {name}
            </Button>
          </span>
          {isInputVisible && <div className="h-full w-[1px] bg-neutral-75 ml-2" />}
        </>
      }
      rightAddon={
        isInputVisible && (
          <div className="-mr-1 w-6">
            {!isSearchEmpty && (
              <IconButton
                small
                variant="subtle"
                aria-label="Clear search filter"
                Icon={Remove}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  setSearch('')
                  setIsFiltering(false)
                }}
                disabled={disabled}
              />
            )}
          </div>
        )
      }
      inputClassName={classNames('transition-all h-full', {
        '!w-0 !pl-0': !isInputVisible,
        'w-40': isInputVisible,
      })}
      onChange={e => setSearch(e.target.value)}
      onBlur={() => {
        if (!isBlurFromButtonClick && isSearchEmpty) {
          setIsFiltering(false)
        }
      }}
      value={search}
    />
  )
}

export default SearchFilter
