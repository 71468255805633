import React from 'react'
import { Link } from 'react-router-dom'

import AuthFormContainer from './AuthFormContainer'
import AuthFormDescription from './AuthFormDescription'
import AuthFormTitle from './AuthFormTitle'

const CreateAccountError = () => {
  return (
    <AuthFormContainer>
      <AuthFormTitle>Sorry, your invite has expired.</AuthFormTitle>
      <AuthFormDescription>
        This invite link has expired. Take a look at your emails to see if there is another link sent to you, or
        <Link className="ml-1 text-primary-600" to="/login">
          Sign in
        </Link>
        .
      </AuthFormDescription>
    </AuthFormContainer>
  )
}

export default CreateAccountError
