import classNames from 'classnames'
import React from 'react'
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize'

export interface TextAreaProps extends TextareaAutosizeProps {
  value?: string
  hasError?: boolean
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ hasError, disabled, className, ...props }, ref) => {
    return (
      <TextareaAutosize
        ref={ref}
        role="textbox"
        disabled={disabled}
        className={classNames(
          'relative flex items-center',
          'px-2 py-1.5 w-full font-input text-neutral-800 placeholder-neutral-300',
          'border-neutral-100 rounded-lg border-solid border',
          'focus:outline-none focus:border-primary-200 focus:ring focus:ring-primary-100',
          {
            'bg-neutral-50 text-neutral-200 border-transparent': disabled,
            'border-error-default': hasError,
          },
          className
        )}
        {...props}
      />
    )
  }
)

export default TextArea
