import { BulkOrderDesign, CustomizationDesign, DesignType } from '@packages/types'
import React, { useContext } from 'react'

import QuoteContext from 'cms/quotes/QuoteContext'
import Price from 'common/components/Price'
import Table from 'common/components/table/Table'
import Tooltip from 'common/components/Tooltip'

import { BulkOrderFormItem, CustomizationFormItem, QuoteFormValues } from './Quote'
import QuoteTableCustomExtraPrices from './QuoteTableCustomExtraPrices'
import QuoteTableEditableCell from './QuoteTableEditableCell'
import QuoteTableTaxes from './QuoteTableTaxes'
import { useFormikContext } from 'formik'
import InfoIcon from 'icons/bold/01-Interface Essential/14-Alerts/information-circle.svg'
import classMerge from 'utils/classMerge'

interface QuoteTableBodyProps {
  itemIndex: number
}

const BasePriceTooltip = ({ design }: { design: CustomizationDesign }) => {
  if (!design?.basePriceContainsHiddenExtraPrices) return null

  return (
    <Tooltip content="This base price contains a hidden fee. An extra price or an equation might have been associated to a question not shown in the order sheet. If this was on purpose, you can safely ignore this message.">
      <InfoIcon className="w-3.5 h-3.5 fill-neutral-400" />
    </Tooltip>
  )
}

const QuoteTableBodyBulk = ({ itemIndex }: QuoteTableBodyProps) => {
  const { quote, isEditing } = useContext(QuoteContext)
  const formik = useFormikContext<QuoteFormValues>()
  const designRef = quote!.items[itemIndex].design as BulkOrderDesign

  const item = formik.values.items[itemIndex].design as BulkOrderFormItem

  return (
    <Table.Body data-testid="bulk-order-quote-table-body">
      {designRef.designs.map(({ design: bulkDesign }, index: number) => {
        const currentItem = item.designs[index] || { quantity: 0, design: {} }
        const basePrice = currentItem.design.basePrice ?? 0

        return (
          <React.Fragment key={index}>
            <Table.Row className="border-neutral-100 border-t">
              <Table.Cell className="w-6/12 py-3 px-6">
                <div className="flex items-center">
                  <div className="rounded-sm w-9 h-8 mr-3 flex items-center">
                    <img
                      aria-label={`design ${bulkDesign.productId} image`}
                      src={bulkDesign.designImage?.url}
                      className="rounded-sm w-full"
                    />
                  </div>
                  <span className="text-sm font-medium text-neutral-600 flex flex-row gap-2 items-center">
                    Item {index + 1}
                    <BasePriceTooltip design={bulkDesign} />
                  </span>
                </div>
              </Table.Cell>
              <QuoteTableEditableCell
                className="w-2/12 pr-4"
                type="quantity"
                name={`items.${itemIndex}.design.designs.${index}.quantity`}
                id={`items.${itemIndex}.design.designs.${index}.quantity`}
                value={currentItem.quantity}
                onChange={formik.handleChange}
              />
              <QuoteTableEditableCell
                className="w-2/12"
                type="price"
                name={`items.${itemIndex}.design.designs.${index}.design.basePrice`}
                id={`items.${itemIndex}.design.designs.${index}.design.basePrice`}
                value={basePrice}
                onChange={formik.handleChange}
              />
              <Table.Cell className="w-2/12 text-right py-3 px-6">
                {basePrice === 0 ? (
                  <span aria-label="empty base price" className="font-medium text-sm pl-1 text-neutral-300">
                    &mdash;
                  </span>
                ) : (
                  <Price
                    amount={basePrice * currentItem.quantity}
                    currency={quote?.currency ?? 'USD'}
                    className="font-medium text-sm pl-1"
                  />
                )}
              </Table.Cell>
            </Table.Row>

            {bulkDesign.productionData?.map(({ key, value, values, ref }, childIndex: number) => {
              const pricing = currentItem.design.pricing ?? {}
              const price = pricing?.[ref.questionId] ?? 0

              return (
                <Table.Row key={`${key}-${childIndex}`} className="border-b-0">
                  <Table.Cell className="w-6/12 py-3 pr-6 pl-10 ">
                    {key} / {value ? value : values?.join(', ')}
                  </Table.Cell>
                  <Table.Cell
                    className={classMerge('w-2/12 py-3 pl-2', {
                      'text-neutral-300': isEditing,
                    })}
                  >
                    {currentItem.quantity}
                  </Table.Cell>
                  <QuoteTableEditableCell
                    className="w-2/12"
                    type="price"
                    name={`items.${itemIndex}.design.designs.${index}.design.pricing.${ref.questionId}`}
                    id={`items.${itemIndex}.design.designs.${index}.design.pricing.${ref.questionId}`}
                    value={price}
                    onChange={formik.handleChange}
                  />
                  <Table.Cell className="w-2/12 text-right py-3 px-6">
                    <Price
                      amount={price * currentItem.quantity}
                      currency={quote?.currency ?? 'USD'}
                      className="font-medium text-sm pl-1"
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </React.Fragment>
        )
      })}
      <QuoteTableCustomExtraPrices itemIndex={itemIndex} />
      <QuoteTableTaxes />
    </Table.Body>
  )
}

const QuoteTableBodyCustomization = ({ itemIndex }: QuoteTableBodyProps) => {
  const { quote, isEditing } = useContext(QuoteContext)
  const formik = useFormikContext<QuoteFormValues>()
  const designRef = quote!.items[itemIndex].design as CustomizationDesign

  const item = formik.values.items[itemIndex]
  const design = formik.values.items[itemIndex].design as CustomizationFormItem
  const basePrice = design.basePrice ?? 0

  return (
    <Table.Body data-testid="customization-quote-table-body">
      <Table.Row>
        <Table.Cell className="w-6/12 py-3 px-6">
          <span className="flex flex-row gap-2 items-center">
            <span>Base price</span>
            <BasePriceTooltip design={designRef} />
          </span>
        </Table.Cell>
        <QuoteTableEditableCell
          className="w-2/12 pr-4"
          type="quantity"
          name={`items.${itemIndex}.quantity`}
          id={`items.${itemIndex}.quantity`}
          value={formik.values.items[itemIndex].quantity}
          onChange={formik.handleChange}
        />
        <QuoteTableEditableCell
          className="w-2/12"
          type="price"
          name={`items.${itemIndex}.design.basePrice`}
          id={`items.${itemIndex}.design.basePrice`}
          value={basePrice}
          onChange={formik.handleChange}
        />
        <Table.Cell className="w-2/12 text-right py-3 px-6">
          {item.quantity * basePrice === 0 ? (
            <span aria-label="empty base price" className="font-medium text-sm pl-1 text-neutral-300">
              &mdash;
            </span>
          ) : (
            <Price
              amount={item.quantity * basePrice}
              currency={quote?.currency ?? 'USD'}
              className="font-medium text-sm pl-1"
            />
          )}
        </Table.Cell>
      </Table.Row>
      {designRef.productionData?.map(({ key, value, ref }, index: number) => {
        const price = design.pricing?.[ref.questionId] ?? 0

        return (
          <Table.Row key={`${key}-${value}-${index}`}>
            <Table.Cell className="w-6/12 py-3 px-6">
              {key} / {value}
            </Table.Cell>
            <Table.Cell
              className={classMerge('w-2/12 py-3 pl-2 pr-4', {
                'text-neutral-300': isEditing,
              })}
            >
              {item.quantity}
            </Table.Cell>
            <QuoteTableEditableCell
              className="w-2/12"
              type="price"
              name={`items.${itemIndex}.design.pricing.${ref.questionId}`}
              id={`items.${itemIndex}.design.pricing.${ref.questionId}`}
              value={price}
              onChange={formik.handleChange}
            />
            <Table.Cell className="w-2/12 text-right py-3 px-6">
              <Price
                amount={item.quantity * price}
                currency={quote?.currency ?? 'USD'}
                className="font-medium text-sm pl-1"
              />
            </Table.Cell>
          </Table.Row>
        )
      })}
      <QuoteTableCustomExtraPrices itemIndex={itemIndex} />
      <QuoteTableTaxes />
    </Table.Body>
  )
}

const QuoteTableBody = ({ itemIndex }: QuoteTableBodyProps) => {
  const { quote } = useContext(QuoteContext)
  const { values } = useFormikContext<QuoteFormValues>()
  if (!values.items[itemIndex]?.design) return null
  if (values.items[itemIndex].design.type !== quote!.items[itemIndex].design.type) return null

  if (values.items[itemIndex].design.type === DesignType.BulkOrderDesign) {
    return <QuoteTableBodyBulk itemIndex={itemIndex} />
  }

  return <QuoteTableBodyCustomization itemIndex={itemIndex} />
}

export default QuoteTableBody
