import { Answer, ColorView, Part, QuestionType } from '@packages/types'
import { merge } from 'lodash'
import React, { CSSProperties } from 'react'
import { useHistory } from 'react-router-dom'

import * as answersActions from 'builder/build/answers/actions'
import * as builderConstants from 'builder/build/common/constants'
import PatchSketchPicker from 'builder/build/common/components/PatchSketchPicker'
import { useDispatch } from 'cms/hooks'
import Tooltip from 'common/components/Tooltip'
import usePopover from 'common/components/popover/usePopover'
import Popover from 'common/components/popover/Popover'
import Button from 'common/components/Button'

import AddQuestionButton from './AddQuestionButton'

interface DefaultColorSectionProps {
  part: Part
  answer?: Answer
  disabled?: boolean
  questionId?: string
}

const DefaultColorSection = ({ part, answer, disabled, questionId }: DefaultColorSectionProps) => {
  const dropdownButton = usePopover({ placement: 'bottom' })
  const dispatch = useDispatch()
  const history = useHistory()
  const answerColor = (answer?.views?.[0] as ColorView | undefined)?.color || '#000000'
  const isDisabled = disabled || !!questionId

  const handleOpenQuestionClick = () => history.push(`/questions/${questionId}`)

  const updateAnswerViews = (values: Partial<ColorView>) => {
    dispatch(answersActions.patchAnswerViews(answer, { ...values }))
  }

  return (
    <>
      <Tooltip
        content={
          questionId ? (
            <div className="flex items-center space-x-2">
              <span>Colors are handled by a question.</span>
              <Button onClick={handleOpenQuestionClick} variant="text">
                Open
              </Button>
            </div>
          ) : (
            'Color'
          )
        }
      >
        <Button aria-label="color-picker" variant="subtle" {...dropdownButton.referenceProps} disabled={isDisabled}>
          <div
            className="w-[18px] h-[18px] rounded-full"
            style={{ background: answerColor, opacity: isDisabled ? 0.5 : 1 }}
          />
        </Button>
      </Tooltip>
      <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen} className="w-48">
        <div className="px-4">
          <PatchSketchPicker
            styles={{
              default: merge(builderConstants.colorPickerStyle, {
                saturation: {
                  marginLeft: '-1rem',
                  marginRight: '-1rem',
                  width: 'calc(100% + 2rem)',
                  paddingBottom: '75%',
                  position: 'relative',
                  overflow: 'hidden',
                } as CSSProperties,
              }),
            }}
            disableAlpha
            color={answerColor}
            onChange={color => updateAnswerViews({ color: color.hex })}
          />
        </div>
        <div className="px-2 py-4">
          <AddQuestionButton onClick={dropdownButton.close} part={part} type={QuestionType.Color} />
        </div>
      </Popover>
    </>
  )
}

export default DefaultColorSection
