import { AnswerType, EntityType, QuestionInputType, QuestionType, supportedImageExtensions } from '@packages/types'
import { generateId, generateNextString } from '@packages/unique-string'
import { cloneDeep } from 'lodash'

import * as answersActions from 'builder/build/answers/actions'
import * as answersSelectors from 'builder/build/answers/selectors'
import * as answersTypes from 'builder/build/answers/actionTypes'
import * as coreTypes from 'builder/build/core/actionTypes'
import * as coreSelectors from 'builder/build/core/selectors'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import * as types from './actionTypes'
import {
  defaultStartingPointSelector,
  defaultAnswerIdSelector,
  firstNonArchivedAnswerSelector,
  isAnswerLinkedSelector,
  questionByIdSelector,
  questionsAsArraySelector,
  questionAnswersSelector,
  linkedQuestionsSelector,
} from './selectors'

export const createQuestionByType = (type, initialData) => {
  const questionData = { ...initialData }

  switch (type) {
    case QuestionType.Text:
      questionData.placeholder = 'Your text'
      questionData.inputCharacters = 'text'
      break
    case QuestionType.Value:
      questionData.placeholder = 'Choose an option'
      break
    case QuestionType.Font:
      questionData.name = `${questionData.name} fonts`
      break
    case QuestionType.FontSize:
      questionData.name = `${questionData.name} size`
      break
    case QuestionType.Color:
    case QuestionType.Material:
      questionData.name = `${questionData.name} colors`
      break
    case QuestionType.Outline:
      questionData.name = `${questionData.name} outlines`
      break
    case QuestionType.Logo:
      break
  }

  return createQuestion(
    type,
    questionData,
    questionData.inputType !== QuestionInputType.File && questionData.inputType !== QuestionInputType.Checkbox
  )
}

export const createQuestionByTypeWithNoDefaultAnswer = (type, initialData) => {
  switch (type) {
    case QuestionType.Logo:
      return createLogoQuestion(initialData.name)
    case QuestionType.Text:
      return createTextQuestion(initialData.name)
    case QuestionType.Value:
      return createQuestion(type, { ...initialData, placeholder: 'Choose an option' })
    case QuestionType.Image:
      return createQuestion(type, initialData, false)
    case QuestionType.Font:
      return createQuestion(
        type,
        { ...initialData, name: `${initialData.name} fonts`, inputType: QuestionInputType.Label },
        false
      )
    case QuestionType.FontSize:
      return createQuestion(
        type,
        { ...initialData, name: `${initialData.name} size`, inputType: QuestionInputType.Label },
        false
      )
    case QuestionType.Color:
    case QuestionType.Material:
      return createQuestion(type, { ...initialData, name: `${initialData.name} colors` }, false)
    case QuestionType.Outline:
      return createQuestion(type, { ...initialData, name: `${initialData.name} outlines` }, false)
    default:
      return createQuestion(type, initialData, false)
  }
}

export const createQuestion = (type, initialData = {}, createDefaultAnswer = true) => {
  return dispatch => {
    const id = generateId('QUESTION')

    const createQuestionAction = {
      type: types.CREATE_QUESTION,
      payload: {
        id,
        name: '',
        entityType: EntityType.Question,
        type,
        inputType: QuestionInputType.Thumbnail,
        answers: [],
        isHiddenWhenEmpty: true,
        isInOrdersheet: true,
        isInSummary: true,
        isMultiAnswer: initialData.inputType === QuestionInputType.Checkbox,
        showGradientPicker: initialData.inputType === QuestionInputType.ColorPicker ? true : undefined,
        showCurrentColorsPicker: initialData.inputType == QuestionInputType.ColorPicker ? true : undefined,
        allowedExtensionsByType:
          initialData.inputType === QuestionInputType.File ? { image: supportedImageExtensions } : undefined,
        ...initialData,
      },
    }

    dispatch(createQuestionAction)

    createDefaultAnswer && dispatch(addAnswer(id, type))

    return createQuestionAction
  }
}

export const createTextQuestion = name =>
  createQuestion(QuestionType.Text, {
    placeholder: 'Your text',
    inputType: 'text',
    inputCharacters: 'text',
    name,
  })

export const createLogoQuestion = name => createQuestion(QuestionType.Logo, { inputType: QuestionInputType.File, name })

export const deleteQuestionAndReplaceWithDefaultAnswer = question => {
  return (dispatch, getState) => {
    const state = getState()
    let defaultAnswerId = defaultAnswerIdSelector(state, question.id)

    if (!defaultAnswerId || question.type === QuestionType.Material) {
      defaultAnswerId = dispatch(
        answersActions.createAnswer(question.type, question.type === QuestionType.Material ? { color: '#ffffff' } : {})
      ).payload.id
    }

    dispatch(deleteQuestion(question.id, defaultAnswerId))
  }
}

export const deleteQuestion = (questionId, defaultAnswerId) => {
  return (dispatch, getState) => {
    const linkedQuestions = linkedQuestionsSelector(getState(), questionId)

    if (!isNullOrEmpty(linkedQuestions)) {
      linkedQuestions.forEach(linkedQuestion => {
        dispatch(unlinkQuestion(linkedQuestion.id))
      })
    }

    return dispatch({
      type: types.DELETE_QUESTION,
      payload: { questionId, defaultAnswerId },
    })
  }
}

export const changeInputType = newInputType => ({ type: types.CHANGE_INPUT_TYPE, payload: { inputType: newInputType } })

export const updateQuestionInputType = (question, newInputType, shouldResetAnswers) => {
  return dispatch => {
    const previousInputType = question.inputType
    const patchValues = {
      inputType: newInputType,
      isMultiAnswer: newInputType === QuestionInputType.Checkbox,
    }

    if (patchValues.isMultiAnswer) {
      patchValues.minSelections = question.minSelections ?? 0
      patchValues.maxSelections = question.maxSelections ?? null
      patchValues.showRelativePrice = false
    }

    if (previousInputType === QuestionInputType.Dropdown) {
      patchValues.isInteractionRequired = false
    } else if (previousInputType === QuestionInputType.Text) {
      patchValues.showCharacterCount = false
    }

    if (newInputType === QuestionInputType.File) {
      patchValues.allowedExtensionsByType = { image: supportedImageExtensions }
    }

    if (shouldResetAnswers) {
      dispatch(removeAnswers(question.id, question.answers))
      dispatch(addAnswer(question.id, newInputType === QuestionInputType.File ? AnswerType.Asset : question.type))
    }

    dispatch(patchQuestion(question.id, patchValues))
    dispatch(changeInputType(newInputType))
  }
}

export const changeMultipleSelection = () => ({ type: types.CHANGE_MULTIPLE_SELECTION })

export const changeIsSearchable = () => ({ type: types.CHANGE_IS_SEARCHABLE_DROPDOWN })

export const patchQuestion = (questionId, patch) => {
  return { type: coreTypes.PATCH, payload: { questions: [{ id: questionId, ...patch }] } }
}

export const addAnswer = (questionId, type) => {
  return (dispatch, getState) => {
    const question = questionByIdSelector(getState(), { id: questionId })
    const answers = questionAnswersSelector(getState(), question)
    const answerType =
      question.type === QuestionType.Value && question.inputType === QuestionInputType.File ? AnswerType.Asset : type

    const answerId = generateId('ANSWER')
    const views = coreSelectors.viewsSelector(getState())
    return dispatch({
      type: answersTypes.ADD_ANSWER,
      payload: {
        questionId,
        type: answerType,
        views,
        name: generateNextString(answers, 'name', 'Untitled answer'),
        id: answerId,
      },
    })
  }
}

export const previewCreateAnswer = (questionId, type) => {
  return (dispatch, getState) => {
    const question = questionByIdSelector(getState(), { id: questionId })
    const answers = questionAnswersSelector(getState(), question)
    const answerType =
      type === QuestionType.Value && question.inputType === QuestionInputType.File ? AnswerType.Asset : type

    const answerId = generateId('ANSWER')
    const views = coreSelectors.viewsSelector(getState())
    return dispatch({
      type: answersTypes.CREATE_ANSWER_PREVIEW,
      payload: {
        questionId,
        type: answerType,
        views,
        name: generateNextString(answers, 'name', 'Untitled answer'),
        id: answerId,
      },
    })
  }
}

export const moveAnswer = (questionId, answerId, index) => {
  return dispatch => {
    dispatch({
      type: types.MOVE_ANSWER,
      payload: {
        questionId,
        answerId,
        index,
      },
    })
  }
}

export const importAnswers = (questionId, answerIds) => ({
  type: types.ADD_ANSWERS,
  payload: { questionId, answerIds },
})

export const copyAnswers = (questionId, answerIds) => ({
  type: answersTypes.COPY_ANSWERS,
  payload: {
    questionId,
    answerIds,
    newAnswerIds: answerIds.map(() => generateId('ANSWER')),
  },
})

export const unlinkAnswer = (questionId, answerId) => {
  return {
    type: answersTypes.UNLINK_ANSWER,
    payload: {
      questionId,
      answerId,
      newAnswerId: generateId('ANSWER'),
    },
  }
}

export const addDefaultAnswer = (questionId, answerId) => ({
  type: types.ADD_DEFAULT_ANSWER,
  payload: { questionId, answerId },
})

export const setDefaultAnswer = (questionId, answerId) => ({
  type: types.SET_DEFAULT_ANSWER,
  payload: { questionId, answerId },
})

export const removeDefaultAnswer = (questionId, answerId) => ({
  type: types.REMOVE_DEFAULT_ANSWER,
  payload: { questionId, answerId },
})

export const removeAnswers = (questionId, answerIds) => {
  return (dispatch, getState) => {
    const state = getState()

    const orphanAnswers = answerIds.reduce((result, answerId) => {
      const isAnswerLinked = isAnswerLinkedSelector(state, { answerId, questionsToIgnore: [questionId] })

      if (!isAnswerLinked) result.push(answerId)

      return result
    }, [])

    dispatch({ type: types.REMOVE_ANSWERS, payload: { questionId, answerIds } })

    if (orphanAnswers.length > 0) {
      orphanAnswers.forEach(answerId => dispatch(answersActions.deleteAnswer(answerId)))
    }
  }
}

export const removeAnswer = (questionId, answerId) => {
  return (dispatch, getState) => {
    const isAnswerLinked = isAnswerLinkedSelector(getState(), {
      answerId,
      questionsToIgnore: [questionId],
    })

    dispatch({
      type: types.REMOVE_ANSWER,
      payload: { questionId, answerId },
    })

    if (!isAnswerLinked) {
      dispatch(answersActions.deleteAnswer(answerId))
    }
  }
}

export const setMinSelections = (questionId, minSelections) => {
  return {
    type: types.SET_MIN_SELECTIONS,
    payload: { questionId, minSelections },
  }
}

export const setMaxSelections = (questionId, maxSelections) => {
  return {
    type: types.SET_MAX_SELECTIONS,
    payload: { questionId, maxSelections },
  }
}

export const syncConfiguration = () => (dispatch, getState) => {
  const state = getState()

  const configuration = defaultStartingPointSelector(state)
  const questions = questionsAsArraySelector(state)
  const answers = answersSelectors.answersSelector(state)

  questions.forEach(question => {
    if (question.isMultiAnswer) {
      const defaultConfiguration = Array.isArray(configuration[question.id]) ? configuration[question.id] : []
      const hasMaxSelections = typeof question.maxSelections === 'number' && !isNaN(question.maxSelections)

      const updatedDefaultConfiguration = defaultConfiguration.reduce((defaultConfiguration, answerId, i) => {
        if (
          question.answers.includes(answerId) === false ||
          answers[answerId]?.archived === true ||
          (hasMaxSelections && i >= question.maxSelections)
        )
          return defaultConfiguration

        return [...defaultConfiguration, answerId]
      }, [])

      dispatch(setDefaultAnswer(question.id, updatedDefaultConfiguration))
    } else {
      const defaultConfiguration = configuration[question.id]
      const selectedAnswerId = Array.isArray(defaultConfiguration) ? undefined : defaultConfiguration
      const firstNonArchivedAnswer = firstNonArchivedAnswerSelector(state, question.id)

      if (
        !selectedAnswerId ||
        !question.answers.includes(selectedAnswerId) ||
        answers[selectedAnswerId]?.archived === true
      ) {
        dispatch(setDefaultAnswer(question.id, firstNonArchivedAnswer?.id))
      }
    }
  })
}

export const linkQuestion = (questionId, linkedQuestionId) => {
  return { type: types.LINK_QUESTION, payload: { questionId, linkedQuestionId } }
}

export const unlinkQuestion = questionId => {
  return { type: types.UNLINK_QUESTION, payload: { questionId } }
}

export const duplicateQuestion = (id, shouldCopyAnswers) => (dispatch, getState) => {
  const state = getState()
  const question = questionByIdSelector(state, { id })
  const name = generateNextString(questionsAsArraySelector(state), 'name', `Copy of ${question.name}`)
  const duplicatedQuestion = { ...cloneDeep(question), id: generateId('QUESTION'), name }

  dispatch({ type: types.CREATE_QUESTION, payload: duplicatedQuestion })

  if (shouldCopyAnswers) {
    dispatch(copyAnswers(duplicatedQuestion.id, question.answers))
    dispatch({
      type: types.REMOVE_ANSWERS,
      payload: { questionId: duplicatedQuestion.id, answerIds: question.answers },
    })
  }

  return duplicatedQuestion.id
}
