import React from 'react'

import './Slider.css'
import classMerge from 'utils/classMerge'

export interface SliderProps {
  max?: number
  min?: number
  value: number
  step?: number
  name?: string
  onChange: (value: number) => void
  hasNumberInput?: boolean
  gradient?: [string, string]
  className?: string
}

const Slider = ({
  max = 100,
  min = 0,
  step = 1,
  value,
  onChange,
  name,
  className,
  gradient = ['rgb(0,0,255)', 'rgb(225,225,235)'],
}: SliderProps) => {
  const getValidValue = (val: number) => {
    if (val > max) return max
    if (val < min) return min

    return step % 1 ? Number(val) : Math.round(val)
  }

  const fillPercent = ((getValidValue(value) - min) * 100) / (max - min)

  return (
    <div className={classMerge('h-12 flex items-center justify-center', className)}>
      <input
        className="slider"
        type="range"
        name={name}
        onChange={e => onChange(getValidValue(+e.target.value))}
        value={getValidValue(value)}
        min={min}
        max={max}
        step={step}
        style={{
          background: `linear-gradient(90deg, ${gradient[0]} 0% ${fillPercent}%, ${gradient[1]} ${fillPercent}% 100%)`,
        }}
      />
    </div>
  )
}

export default Slider
