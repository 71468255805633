import { CustomPlan } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import Modal, { ModalProps } from 'common/components/modal/Modal'
import Button from 'common/components/Button'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import useCustomPlanForm from './../../hooks/useCustomPlanForm'
import useSubscriptionService from './../../hooks/useSubscriptionService'
import PlanCard from './../cards/PlanCard'
import CustomPlanForm from '../CustomPlanForm'

export interface CustomPlanModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
  customPlanFormValues?: CustomPlan | undefined
}

const CreateOrUpdateCustomPlanModal = ({
  onClose,
  customPlanFormValues = undefined,
  ...modalProps
}: CustomPlanModalProps) => {
  const subscriptionService = useSubscriptionService()
  const { openToast } = useToast()
  const queryClient = useQueryClient()

  const { mutate: createCustomPlan } = useMutation(subscriptionService.createCustomPlan, {
    onSuccess: () => {
      openToast(`Custom plan successfully ${!customPlanFormValues ? 'created' : 'updated'}`, ToastType.success)
      queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
      onClose()
    },
  })

  const formik = useCustomPlanForm({
    initialValues: customPlanFormValues,
    onSubmit: (values, { setSubmitting }) => {
      createCustomPlan({ plan: values }, { onSettled: () => setSubmitting(false) })
    },
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal {...modalProps} size="large">
      <div className="flex">
        <form onSubmit={formik.handleSubmit} className="basis-1/2 flex flex-col">
          <Modal.CloseButton onClick={handleClose} />
          <Modal.Title>
            {!customPlanFormValues && <span>Create</span>}
            {!!customPlanFormValues && <span>Edit</span>} custom plan
          </Modal.Title>

          <Modal.Details className="grow">
            <CustomPlanForm formik={formik} />
          </Modal.Details>

          <Modal.Actions>
            <Button
              type="button"
              variant="default"
              className="px-4"
              onClick={handleClose}
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              id="create-custom-plan"
              type="submit"
              variant="primary"
              className="px-4"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!customPlanFormValues ? <span>Create</span> : <span>Save</span>}
            </Button>
          </Modal.Actions>
        </form>
        <div className="p-12 border-l border-neutral-100 flex items-center justify-center grow bg-neutral-50">
          <PlanCard plan={formik.values} addons={null} addonsSubscribed={[]} />
        </div>
      </div>
    </Modal>
  )
}

export default CreateOrUpdateCustomPlanModal
