export default (version: string) => {
  const versionComponents = version.split('.')

  if (versionComponents.length < 3) return
  return {
    major: Number(versionComponents[0]),
    minor: Number(versionComponents[1]),
    patch: Number(versionComponents[2]),
  }
}
