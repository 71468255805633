export const CREATE_QUESTION = 'questions/create'
export const DELETE_QUESTION = 'questions/delete'
export const MOVE_ANSWER = 'questions/move-answer'
export const ADD_ANSWERS = 'questions/add-answers'
export const ADD_DEFAULT_ANSWER = 'questions/add-default-answer'
export const SET_DEFAULT_ANSWER = 'questions/set-default-answer'
export const REMOVE_ANSWER = 'questions/remove-answer'
export const REMOVE_ANSWERS = 'questions/remove-answers'
export const REMOVE_DEFAULT_ANSWER = 'questions/remove-default-answer'
export const LINK_QUESTION = 'questions/link-question'
export const UNLINK_QUESTION = 'questions/unlink-question'
export const MOVE_BEHIND_THE_SCENE = 'questions/move-behind-the-scene'
export const CHANGE_INPUT_TYPE = 'questions/change-input-type'
export const CHANGE_MULTIPLE_SELECTION = 'questions/change-multiple-selection'
export const SET_MIN_SELECTIONS = 'questions/set-min-selections'
export const SET_MAX_SELECTIONS = 'questions/set-max-selections'
export const CHANGE_IS_SEARCHABLE_DROPDOWN = 'questions/change-is-searchable-dropdown'
