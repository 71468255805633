import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'

type LoginParams = { email: string; password: string }
export type LoginResponse = { token: string }

const useAuthService = () => {
  return {
    login: async ({ email, password }: LoginParams) => {
      const { data } = await apiClient.post<LoginResponse>(LOGIN_URL, { username: email, password })
      return data
    },
    changePassword: async (token: string, password: string) => {
      return apiClient.post<void, void>(`${CHANGE_PASSWORD_URL}?token=${token}`, { password })
    },
    sendResetPasswordRequest: async (email: string) => {
      return apiClient.post<void, void>(FORGOT_PASSWORD_URL, { email })
    },
    validatePasswordResetToken: createQuery(['validate-password-reset-token'], async (token: string) => {
      return apiClient.get<void, void>(`${CHANGE_PASSWORD_URL}?token=${token}`)
    }),
    updateToken: async () => {
      const { data } = await apiClient.get<{ token: string }>(UPDATE_TOKEN_URL)
      return data
    },
  }
}

export default useAuthService
