import { unflatten } from 'flat'
import { pick } from 'lodash'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { useQueryParams, QueryParamConfig, EncodedQuery } from 'use-query-params'

import { DataTablePersistence } from './types/persistence'

const searchStringToObject = (searchString: string) => {
  return qs.parse(searchString.slice(1)) as EncodedQuery
}

const objectToSearchString = (object: EncodedQuery) => {
  return (qs.stringify(object, { encode: false, skipNulls: true }) as string) ?? undefined
}

const useDataTableQuery = <TModel extends object>(
  initialValues: TModel,
  config: Record<string, QueryParamConfig<any>>,
  defaultValues = {}
): DataTablePersistence<TModel> => {
  const [query, setQuery] = useQueryParams(config, {
    removeDefaultsFromUrl: true,
    updateType: 'replace',
    searchStringToObject,
    objectToSearchString,
  })
  const [state, setState] = useState<TModel>({ ...initialValues, ...unflatten(query) })

  const reset = () => setState(initialValues)

  useEffect(() => {
    setQuery({ ...pick(state, Object.keys(config)), ...defaultValues })
  })

  return { state, setState, reset }
}

export default useDataTableQuery
