import React from 'react'

import Container from 'common/components/editor/containers/Container'

import useObserveDisplayerDimensions from '../../hooks/useObserveDisplayerDimensions'

const Canvas = ({ children }: React.PropsWithChildren) => {
  const canvasDimensions = useObserveDisplayerDimensions()

  if (!canvasDimensions) return null

  const { width, height, x, y, scale } = canvasDimensions

  return (
    <Container x={x} y={y} width={width * scale} height={height * scale} scale={scale}>
      {children}
    </Container>
  )
}

export default Canvas
