import React from 'react'

import Button from 'common/components/Button'
import Card from 'common/components/card/Card'
import type { Plugin } from 'plugins/types/plugin'

interface InstalledPluginCardProps {
  plugin: Plugin
  isOutdated?: boolean
  onUpdate: () => void
  onUninstall: () => void
}

const InstalledPluginCard = ({ plugin, isOutdated, onUpdate, onUninstall }: InstalledPluginCardProps) => {
  return (
    <Card className="mb-6 flex flex-col w-[320px] hover:shadow-lg cursor-pointer">
      <Card.Section className="flex md:flex-col">
        <h2>{plugin.name}</h2>
        <span className="font-p2 mt-2">
          Installed&nbsp;
          {new Date(plugin.installDate).toLocaleDateString('en', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </span>
      </Card.Section>
      <Card.Footer>
        {isOutdated && (
          <Button
            onClick={e => {
              e.preventDefault()
              onUpdate()
            }}
          >
            Update
          </Button>
        )}
        <Button
          variant="primary"
          onClick={e => {
            e.preventDefault()
            onUninstall()
          }}
        >
          Uninstall
        </Button>
      </Card.Footer>
    </Card>
  )
}

InstalledPluginCard.Bone = () => <Card className="mb-6 flex flex-col w-[320px] h-[140px] animate-pulse" />

export default InstalledPluginCard
