import { merge, without, omit } from 'lodash'
import { set, unset } from 'lodash/fp'
import { AnyAction } from 'redux'

import * as answersTypes from 'builder/build/answers/actionTypes'
import * as coreTypes from 'builder/build/core/actionTypes'
import * as groupsTypes from 'builder/build/groups/actionTypes'
import * as layersTypes from 'builder/build/layers/actionTypes'
import * as partsTypes from 'builder/build/parts/actionTypes'
import * as printAreaTypes from 'builder/build/printAreas/actionTypes'
import * as questionsTypes from 'builder/build/questions/actionTypes'
import * as rulesTypes from 'builder/build/rules/actionTypes'
import * as settingsTypes from 'builder/build/settings/actionTypes'
import { insert } from 'utils/arrayUtils'

export interface State {
  id?: string
  _id?: string
  name: string
  isDraft?: boolean
  tree: string
  parts: string[]
  questions: string[]
  rules: string[]
  printAreas: string[]
  views: number
  dimensions?: {
    width: number
    height: number
  }
  defaultConfiguration: {
    [key: string]: string | string[] | null
  }
  themesConfiguration: {
    [key: string]: {
      [key: string]: any
    }
  }
}

const defaultConfigurationReducer = (
  state: State['defaultConfiguration'],
  { type, payload }: AnyAction
): State['defaultConfiguration'] => {
  const currentConfiguration = state?.[payload?.questionId]

  switch (type) {
    case questionsTypes.CREATE_QUESTION:
      return { ...state, [payload.id]: null }
    case questionsTypes.DELETE_QUESTION:
      return omit(state, payload.questionId)
    case questionsTypes.SET_DEFAULT_ANSWER:
      return { ...state, [payload.questionId]: payload.answerId }
    case questionsTypes.ADD_DEFAULT_ANSWER:
      return {
        ...state,
        [payload.questionId]:
          !!currentConfiguration && Array.isArray(currentConfiguration)
            ? [...currentConfiguration, payload.answerId]
            : [payload.answerId],
      }
    case questionsTypes.REMOVE_DEFAULT_ANSWER:
      if (Array.isArray(currentConfiguration)) {
        const filteredConfiguration = currentConfiguration.filter(answerId => answerId !== payload.answerId)
        return { ...state, [payload.questionId]: [...filteredConfiguration] }
      }

      return { ...omit(state, payload.questionId) }
    default:
      return state
  }
}

const removeFromThemesConfiguration = (
  themesConfiguration: State['themesConfiguration'] = {},
  id: string
): State['themesConfiguration'] => {
  return Object.keys(themesConfiguration).reduce((result, key) => {
    return { ...result, [key]: omit(themesConfiguration[key], id) }
  }, {})
}

const themesConfigurationReducer = (
  state: State['themesConfiguration'],
  action: AnyAction
): State['themesConfiguration'] => {
  switch (action.type) {
    case coreTypes.UPDATE_THEME_CONFIGURATION:
      const { theme, id, key, value } = action.payload
      return set(`${theme}.${id}.${key}`, value, state)
    case coreTypes.DELETE_THEME_CONFIGURATION:
      return unset(`${action.payload.theme}.${action.payload.id}.${action.payload.key}`, state)
    case groupsTypes.DELETE_GROUP:
      return removeFromThemesConfiguration(state, action.payload)
    case partsTypes.DELETE_PART:
      return removeFromThemesConfiguration(state, action.payload.partId)
    case questionsTypes.DELETE_QUESTION:
      return removeFromThemesConfiguration(state, action.payload.questionId)
    case answersTypes.DELETE_ANSWER:
      return removeFromThemesConfiguration(state, action.payload.answerId)
    default:
      return state
  }
}

export const initialCustomizerProduct: State = {
  name: '',
  tree: '',
  parts: [],
  questions: [],
  rules: [],
  printAreas: [],
  views: 0,
  defaultConfiguration: {},
  themesConfiguration: {},
}

const baseReducer = (state: State, action: AnyAction): State => {
  switch (action.type) {
    case coreTypes.REPLACE_DRAFT: {
      const { id, _id, isDraft } = action.payload
      return { ...state, id, _id, isDraft }
    }
    case coreTypes.START_EDITING:
      if (!action.payload.customizerProduct.customizerProducts) return state
      return Object.values(action.payload.customizerProduct.customizerProducts)[0] as State
    case coreTypes.UPDATE_PRODUCT_NAME:
      return { ...state, name: action.payload.productName }
    case coreTypes.PATCH:
      if (!action.payload.customizerProducts) return state
      return merge({}, state, action.payload.customizerProducts[0])
    case coreTypes.RESET_PRODUCTBUILDER:
      return initialCustomizerProduct
    case coreTypes.SET_DIMENSIONS:
      return { ...state, dimensions: { ...state.dimensions, ...action.payload } }
    case settingsTypes.ADD_VIEW:
      return { ...state, views: state.views + 1 }
    case settingsTypes.DELETE_VIEW:
      return { ...state, views: state.views - 1 }
    case partsTypes.CREATE_PART:
      return { ...state, parts: [...state.parts, action.payload.id] }
    case partsTypes.DELETE_PART:
      return { ...state, parts: without(state.parts, action.payload.partId) }
    case layersTypes.UPDATE_LAYERS_ORDER:
      return { ...state, parts: action.payload.layers }
    case questionsTypes.CREATE_QUESTION:
      return { ...state, questions: [...state.questions, action.payload.id] }
    case questionsTypes.DELETE_QUESTION:
      return { ...state, questions: without(state.questions, action.payload.questionId) }
    case rulesTypes.ADD_RULE:
      return {
        ...state,
        rules: insert(
          state.rules,
          action.payload.index != null ? action.payload.index : state.rules.length,
          action.payload.rule.id
        ),
      }
    case rulesTypes.DELETE_RULE:
      return { ...state, rules: without(state.rules, action.payload.ruleId) }
    case printAreaTypes.CREATE_PRINT_AREA:
      return { ...state, printAreas: [...state.printAreas, action.payload.printArea.id] }
    case printAreaTypes.DELETE_PRINT_AREA:
      return { ...state, printAreas: without(state.printAreas, action.payload.printAreaId) }
    default:
      return state
  }
}

export default (state = initialCustomizerProduct, action: AnyAction): State => {
  const updatedState = baseReducer(state, action)

  return {
    ...updatedState,
    defaultConfiguration: defaultConfigurationReducer(updatedState.defaultConfiguration, action),
    themesConfiguration: themesConfigurationReducer(updatedState.themesConfiguration, action),
  }
}
