import { EntityType, PartType, QuestionType, QuestionInputType, GroupType } from '@packages/types'
import classNames from 'classnames'
import React, { CSSProperties } from 'react'

import PrinterIcon from 'icons/bold/01-Interface Essential/30-Print/print-picture.svg'
import CheckIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/check.svg'
import StarIcon from 'icons/bold/22-Social-Medias-Rewards-Rating/06-Rating/rating-star.svg'
import DropDownIcon from 'icons/core-solid/interface-essential/interface-arrows-button-down.svg'
import FileUploadIcon from 'icons/core-solid/interface-essential/interface-arrows-top-alternate.svg'
import NoneIcon from 'icons/core-solid/interface-essential/interface-block.svg'
import ThumbnailsIcon from 'icons/core-solid/interface-essential/interface-dashboard-layout-square.svg'
import MaterialIcon from 'icons/core-solid/interface-essential/interface-edit-color-drop-pick.svg'
import FolderIcon from 'icons/core-solid/interface-essential/interface-folder.svg'
import TextIcon from 'icons/core-solid/interface-essential/interface-text-formatting-text-style.svg'
import TextColorIcon from 'icons/core-solid/interface-essential/interface-text-formatting-text.svg'
import BulkOrderIcon from 'icons/core-solid/interface-essential/interface-user-add.svg'
import ColorPickerIcon from 'icons/custom/color-picker.svg'
import LabelIcon from 'icons/custom/label-button.svg'
import FontIcon from 'icons/custom/new-project.svg'
import OutlineIcon from 'icons/custom/outline.svg'
import PictureLandscapeIcon from 'icons/custom/picture-landscape.svg'
import RadioIcon from 'icons/custom/radio-button.svg'
import TextInputIcon from 'icons/custom/text-input.svg'
import FontSizeIcon from 'icons/custom/text-size.svg'

import './TypeIcon.scss'

type Config = {
  Icon: React.ComponentType<{ className?: string }>
  style: CSSProperties
}

const partIconsByType: { [key: string]: React.ComponentType } = {
  [PartType.Image]: PictureLandscapeIcon,
  [PartType.Logo]: StarIcon,
  [PartType.Text]: TextIcon,
}

const questionConfigByType: { [key: string]: Config } = {
  [QuestionInputType.None]: {
    Icon: NoneIcon,
    style: { backgroundColor: '#EAEAEA', fill: 'black' },
  },
  [QuestionInputType.Thumbnail]: {
    Icon: ThumbnailsIcon,
    style: { backgroundColor: '#1368ad', fill: 'white' },
  },
  [QuestionInputType.LargeThumbnail]: {
    Icon: ThumbnailsIcon,
    style: { backgroundColor: '#1368ad', fill: 'white' },
  },
  [QuestionInputType.Text]: {
    Icon: TextInputIcon,
    style: { backgroundColor: '#26b83d', fill: 'white' },
  },
  [QuestionInputType.File]: {
    Icon: FileUploadIcon,
    style: { backgroundColor: '#ff5352', fill: 'white' },
  },
  [QuestionInputType.Dropdown]: {
    Icon: DropDownIcon,
    style: { backgroundColor: '#65a2d3', fill: 'white' },
  },
  [QuestionInputType.SmallRadio]: {
    Icon: RadioIcon,
    style: { backgroundColor: '#1426d1', color: 'white' },
  },
  [QuestionInputType.Label]: {
    Icon: LabelIcon,
    style: { backgroundColor: '#2da1bb', fill: 'white' },
  },
  [QuestionInputType.Checkbox]: {
    Icon: CheckIcon,
    style: { backgroundColor: '#2da1bb', fill: 'white' },
  },
  [QuestionInputType.ColorPicker]: {
    Icon: ColorPickerIcon,
    style: { backgroundColor: '#825BB4', fill: 'white' },
  },
}

const displayConfigByType: { [key: string]: Config } = {
  [QuestionType.Image]: {
    Icon: PictureLandscapeIcon,
    style: { backgroundColor: '#7edaff', fill: 'black' },
  },
  [QuestionType.Logo]: { Icon: StarIcon, style: { backgroundColor: '#ff7c7c', fill: 'black' } },
  [QuestionType.Font]: { Icon: FontIcon, style: { backgroundColor: '#ffdd9d', fill: 'black' } },
  [QuestionType.FontSize]: { Icon: FontSizeIcon, style: { backgroundColor: '#ffd510', fill: 'black' } },
  [QuestionType.Color]: { Icon: TextColorIcon, style: { backgroundColor: '#febf7b', fill: 'black' } },
  [QuestionType.Material]: { Icon: MaterialIcon, style: { backgroundColor: '#7ec5ff', fill: 'black' } },
  [QuestionType.Text]: { Icon: TextIcon, style: { backgroundColor: '#ffdf73', fill: 'black' } },
  [QuestionType.Value]: { Icon: NoneIcon, style: { backgroundColor: '#EAEAEA', fill: 'black' } },
  [QuestionType.Outline]: { Icon: OutlineIcon, style: { backgroundColor: '#fea546', fill: 'black' } },
  [GroupType.Folder]: { Icon: FolderIcon, style: { backgroundColor: '#dcd9d9', fill: 'black' } },
  [GroupType.BulkOrder]: { Icon: BulkOrderIcon, style: { backgroundColor: '#EAEAEA', fill: 'black' } },
}

export type IconEntityType =
  | EntityType.Group
  | EntityType.Part
  | EntityType.Question
  | EntityType.PrintArea
  | 'DISPLAY_TYPE'

export type SpecificType = QuestionType | QuestionInputType | PartType | GroupType

const getIconConfig = (entityType: IconEntityType, specificType?: SpecificType): Config => {
  switch (entityType) {
    case EntityType.PrintArea:
      return { Icon: PrinterIcon, style: { backgroundColor: '#dcd9d9', color: 'black' } }
    case EntityType.Part:
      return { Icon: partIconsByType[specificType!], style: {} }
    case EntityType.Question:
      return questionConfigByType[specificType!]
    case 'DISPLAY_TYPE':
      return displayConfigByType[specificType!]
    case EntityType.Group:
      return displayConfigByType[specificType ?? GroupType.Folder]
    default:
      return { Icon: () => null, style: {} }
  }
}

export interface TypeIconProps {
  entityType: IconEntityType
  specificType?: SpecificType
  className?: string
  iconClassName?: string
  preventStyling?: boolean
}

const TypeIcon = ({
  entityType,
  specificType,
  className = '',
  iconClassName = '',
  preventStyling = false,
}: TypeIconProps) => {
  if (['DISPLAY_TYPE', EntityType.Part, EntityType.Question].includes(entityType) && !specificType) return null
  const { Icon, style } = getIconConfig(entityType, specificType)

  return (
    <div className={classNames('type-icon', className)} style={preventStyling ? {} : style}>
      <Icon aria-label={`${specificType || entityType} icon`} className={iconClassName} />
    </div>
  )
}

export default TypeIcon
