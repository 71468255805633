import { DemoComplexity, DemoProduct } from '@packages/types'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Card from 'common/components/card/Card'
import Tag from 'common/components/Tag'
import IconButton from 'common/components/IconButton'
import Tooltip from 'common/components/Tooltip'
import EyeIcon from 'icons/core-solid/interface-essential/interface-edit-view.svg'
import classMerge from 'utils/classMerge'
import imageLoader, { dimensionStrings } from 'utils/loaders/ImageLoader'

export interface DemoProductCardProps {
  product: DemoProduct
}

const demoTagStyle = {
  [DemoComplexity.Basic]: 'bg-success-light text-success-dark',
  [DemoComplexity.Medium]: 'bg-primary-75 text-primary-800',
  [DemoComplexity.Complex]: 'bg-warning-light text-warning-dark',
}

const DemoProductCard = ({ product }: DemoProductCardProps) => {
  const match = useRouteMatch<{ brandName: string }>()
  const history = useHistory()
  const previewBaseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : '/admin'
  const src = imageLoader.buildSrc(product.thumbnail, { dimensionString: dimensionStrings.regular })

  const openBuilder = () => {
    const baseUrl = match.url.split('/')
    baseUrl.pop()
    history.push(`${baseUrl.join('/')}/demos/${product.id}/builder`)
  }

  return (
    <Card
      className="group flex-wrap w-full md:w-[240px] h-[234px] hover:shadow-[0_0_15px_0px_rgba(0,0,0,0.12)] transition-shadow m-[10px] mr-[20px] ml-0 shadow-[0_0_32px_0_rgba(0,0,0,0.08)] cursor-pointer"
      aria-label={product.name}
      shadow={false}
      role="gridcell"
      onClick={openBuilder}
    >
      <img
        src={src}
        className="aspect-[4/3] object-contain p-1 w-full h-[163px]"
        role="img"
        alt={`${product.name} image`}
      />
      <Card.Separator className="w-full" />
      <div className="flex w-full h-[69px] justify-between p-4 relative pb-2">
        <div className="flex flex-col items-start whitespace-nowrap overflow-hidden space-y-1 group-hover:pr-3">
          <span
            className="text-sm leading-4 text-ellipsis overflow-hidden text-neutral-800"
            aria-label="Demo product name"
          >
            {product.name}
          </span>
          <Tag
            aria-label="demo complexity"
            className={classMerge('text-xs', demoTagStyle[product.demoAttributes.complexity])}
          >
            {product.demoAttributes.complexity}
          </Tag>
        </div>
        <Tooltip
          content="Preview"
          containerClassName="flex absolute right-4"
          onClick={e => {
            e.stopPropagation()
            window.open(`${previewBaseUrl}/preview?productId=${product.id}`, '_blank')
          }}
        >
          <IconButton
            Icon={EyeIcon}
            role="button"
            aria-label="Open demo preview"
            className="fill-black p-[9px] opacity-0 group-hover:opacity-100"
          />
        </Tooltip>
      </div>
    </Card>
  )
}

export default DemoProductCard
