import { useSortable } from '@dnd-kit/sortable'
import { useContext } from 'react'

import { ProjectedContext } from './SortableTreesContext'

const useTreeSortable = ({ id, ...rest }: Parameters<typeof useSortable>[0]) => {
  const { transform, over, active, isDragging, ...sortableData } = useSortable({ id, ...rest })

  const { projected, flattenedItems, currentCollision } = useContext(ProjectedContext)

  const realOverItem =
    !!currentCollision.current?.data && currentCollision.current?.data.value < 0.6
      ? flattenedItems.find(item => item.id === currentCollision.current?.id)
      : undefined

  const activeIsSelf = id === active?.id

  const isOverCollapsedFolder = active != null && active.id !== realOverItem?.id && Boolean(realOverItem?.collapsed)

  const willReceiveItem =
    !activeIsSelf &&
    ((projected?.parent && projected?.parent.id === id) ||
      (isOverCollapsedFolder && realOverItem?.canHaveChildren === true && realOverItem.id === id))

  return {
    ...sortableData,
    transform,
    active,
    over,
    isDragging,
    projected,
    willReceiveItem,
  }
}

export default useTreeSortable
