import { CustomizerIntegration, LegacyCustomizerIntegrationType } from '@packages/types'
import React from 'react'

import Button from 'common/components/Button'

export const CUSTOMIZER_INTEGRATION_CHOICES = {
  [LegacyCustomizerIntegrationType.CustomizeButton]: {
    value: LegacyCustomizerIntegrationType.CustomizeButton,
    label: 'Customize button',
    description: 'Only display a Customize button on your product page to open a modal containing your customizer.',
  },
  [LegacyCustomizerIntegrationType.CustomizerOnly]: {
    value: LegacyCustomizerIntegrationType.CustomizerOnly,
    label: 'Customizer only',
    description: 'Integrate your customizer directly on your product page.',
  },
}

interface ShopifyIntegrationDetailsProps {
  currentIntegration?: CustomizerIntegration
  onClick: () => void
}

const ShopifyIntegrationDetails = ({ currentIntegration, onClick }: ShopifyIntegrationDetailsProps) => {
  const integrationValues = currentIntegration
    ? CUSTOMIZER_INTEGRATION_CHOICES[currentIntegration.integrationType]
    : undefined

  return (
    <div className="flex flex-col flex-1 space-y-3 h-full mt-1">
      <span className="font-p1">{integrationValues?.label || ''}</span>
      <span className="text-xs text-neutral-600">{integrationValues?.description || ''}</span>
      <Button className="w-fit" onClick={onClick}>
        Change integration
      </Button>
    </div>
  )
}

export default ShopifyIntegrationDetails
