import classNames from 'classnames'
import React from 'react'

import BlankStateAction from './BlankStateAction'
import BlankStateDetails from './BlankStateDetails'
import BlankStateIcon from './BlankStateIcon'
import BlankStateTitle from './BlankStateTitle'

export interface BlankStateProps {
  children: React.ReactNode
  className?: string
}

const BlankState = ({ children, className }: BlankStateProps) => (
  <div className={classNames('inline-flex flex-col items-start', className)}>{children}</div>
)

BlankState.Action = BlankStateAction
BlankState.Details = BlankStateDetails
BlankState.Icon = BlankStateIcon
BlankState.Title = BlankStateTitle

export default BlankState
