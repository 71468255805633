import { ECommerce, OnlineStore, StartingPoint } from '@packages/types'
import React from 'react'

import CustomListItem from './CustomListItem'
import PrestaShopListItem from './PrestaShopListItem'
import ShopifyListItem from './ShopifyListItem'
import WixListItem from './WixListItem'
import WooCommerceListItem from './WooCommerceListItem'

interface ListItemProps {
  onlineStore: OnlineStore
  startingPoint: StartingPoint
  productId: string
}

const ListItem = ({ onlineStore, ...rest }: ListItemProps) => {
  switch (onlineStore.eCommerce) {
    case ECommerce.Shopify:
      return <ShopifyListItem onlineStore={onlineStore} {...rest} />
    case ECommerce.Wix:
      return <WixListItem onlineStore={onlineStore} {...rest} />
    case ECommerce.Prestashop:
      return <PrestaShopListItem onlineStore={onlineStore} {...rest} />
    case ECommerce.Woocommerce:
      return <WooCommerceListItem onlineStore={onlineStore} {...rest} />
    case ECommerce.CustomStore:
    default:
      return <CustomListItem onlineStore={onlineStore} {...rest} />
  }
}

export default ListItem
