import { Rule, RuleAssertion, RuleThenType } from '@packages/types'
import { generateId } from '@packages/unique-string'
import { cloneDeep } from 'lodash'

export const generateNewRule = (): Rule => ({
  id: generateId('RULE'),
  when: [{ path: [], assertion: RuleAssertion.Is, value: '' }],
  then: [{ type: RuleThenType.AddRestriction, questionId: '', answerId: '' }],
})

export const duplicateRule = (rule: Rule) => ({ ...cloneDeep(rule), id: generateId('RULE') })
