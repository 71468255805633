import { PrintArea } from '@packages/types'
import React from 'react'

import './PrintAreaPreviewSection.scss'

interface PrintAreaPreviewSectionProps {
  printArea: PrintArea
}

const maxWidthAndHeight = 198

export const PrintAreaPreviewSection = ({ printArea }: PrintAreaPreviewSectionProps) => {
  const totalWidth = 2 * printArea.bleed + printArea.width
  const totalHeight = 2 * printArea.bleed + printArea.height
  const bleedWidth = totalWidth > totalHeight ? maxWidthAndHeight : (totalWidth / totalHeight) * maxWidthAndHeight
  const bleedHeight = totalWidth > totalHeight ? (totalHeight / totalWidth) * maxWidthAndHeight : maxWidthAndHeight
  const areaWidth = (printArea.width * bleedWidth) / totalWidth
  const areaHeight = (printArea.height * bleedHeight) / totalHeight
  const marginWidth = areaWidth - 2 * ((printArea.margins.horizontal * bleedWidth) / totalWidth)
  const marginHeight = areaHeight - 2 * ((printArea.margins.vertical * bleedHeight) / totalHeight)

  const printAreaStyling = {
    width: `${areaWidth}px`,
    height: `${areaHeight}px`,
  }
  const bleedAreaStyling = {
    width: `${bleedWidth}px`,
    height: `${bleedHeight}px`,
  }
  const marginsAreaStyling = {
    width: `${marginWidth}px`,
    height: `${marginHeight}px`,
    maxWidth: `${areaWidth}px`,
    maxHeight: `${areaHeight}px`,
  }

  return (
    <div className="print-area-preview">
      <div className="print-area" style={printAreaStyling} />
      {printArea.bleed > 0 && <div className="print-area-bleed" style={bleedAreaStyling} />}
      {(printArea.margins.horizontal > 0 || printArea.margins.vertical > 0) && (
        <div className="print-area-safety-margin" style={marginsAreaStyling} />
      )}
    </div>
  )
}

export default PrintAreaPreviewSection
