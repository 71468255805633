import { Asset, ImageAnswer, supportedImageExtensions } from '@packages/types'
import React from 'react'

import * as coreSelectors from 'builder/build/core/selectors'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as navigationActions from 'builder/build/navigation/actions'
import { LeftPanel } from 'builder/build/navigation/types/leftPanel'
import * as settingsActions from 'builder/build/settings/actions'
import { useDispatch, useSelector } from 'cms/hooks'
import FileUploader from 'common/assets/components/FileUploader'
import DimensionsUpdatedToast from 'common/components/toast/DimensionsUpdatedToast'
import useToast from 'common/components/toast/useToast'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'
import isNullOrEmpty from 'utils/isNullOrEmpty'
import imageLoader from 'utils/loaders/ImageLoader'

import { patchAnswer } from '../actions'
import AnswersDimensionsWarning from './AnswersDimensionsWarning'
import GeneralSettingsSection from './sections/GeneralSettingsSection'
import TitleSection from './sections/TitleSection'

interface ImageAnswerPanelProps {
  answer: ImageAnswer
}

export const ImageAnswerPanel = ({ answer }: ImageAnswerPanelProps) => {
  const dispatch = useDispatch()
  const { openCustomToast } = useToast()
  const customizerDispatch = customizerHooks.useCustomizerDispatch()
  const dimensions = useSelector(coreSelectors.dimensionsSelector)

  const updateAnswer: GeneralSettingsSection['onUpdate'] = (field, value) => {
    dispatch(patchAnswer(answer, { [field]: value }))
  }

  const updateView = (index: number, value: { image: Asset | null }) => {
    if (isNullOrEmpty(dimensions) && value.image) {
      imageLoader.getSize(value.image.url).then(newDimensions => {
        dispatch(settingsActions.updateDimensions(newDimensions))
        dispatch(patchAnswer(answer, { views: { [index]: value } }))
        customizerDispatch(twoDDisplayerActions.switchView(index))

        openCustomToast(props => (
          <DimensionsUpdatedToast
            onView={() => dispatch(navigationActions.setLeftPanel(LeftPanel.settings))}
            {...props}
          />
        ))
      })
    } else {
      dispatch(patchAnswer(answer, { views: { [index]: value } }))
      customizerDispatch(twoDDisplayerActions.switchView(index))
    }
  }

  return (
    <div className="panel__content" aria-label="Image answer panel">
      <TitleSection answer={answer} />
      <hr className="panel__divider" />
      <div className="panel__section image-answer-panel__section">
        {answer.views.map((view, i) => (
          <div className="panel__group [&:not(:first-child)]:mt-6" key={i}>
            <div className="panel__row">
              <span className="panel__field-label" data-userpilot-step={`upload-image-answer-${i + 1}`}>
                View {i + 1}
              </span>
            </div>
            <div className="panel__row panel__row--no-margin-bottom relative">
              <FileUploader
                file={view.image}
                dropText="Drop image, or"
                onChange={image => updateView(i, { image })}
                extensions={supportedImageExtensions}
              />
              <AnswersDimensionsWarning
                className="!absolute top-2 right-2"
                entityId={answer.id}
                entityType={answer.entityType}
                placement="top-start"
                view={i}
              />
            </div>
          </div>
        ))}
      </div>
      <hr className="panel__divider" />
      <GeneralSettingsSection answer={answer} onUpdate={updateAnswer} />
    </div>
  )
}

export default ImageAnswerPanel
