import classNames from 'classnames'
import React from 'react'

import Radio, { RadioProps } from './Radio'

export interface RadioGroupProps {
  className?: string
  disabled?: boolean
  name: string
  options: Array<RadioProps & { label: string; description?: string }>
  horizontal?: boolean
  rtl?: boolean
}

const RadioGroup = ({ className, name, disabled, horizontal, options, rtl }: RadioGroupProps) => (
  <div
    className={classNames(
      'flex',
      { 'flex-col': !horizontal, 'space-y-3': !horizontal, 'space-x-6': horizontal, 'text-neutral-200': disabled },
      className
    )}
    role="radiogroup"
  >
    {options.map(option => {
      const isOptionDisabled = option.disabled || disabled

      return (
        <div
          key={option.id}
          className={classNames('flex items-center', option.className, { 'flex-row-reverse justify-between': rtl })}
        >
          <Radio {...option} name={name} disabled={isOptionDisabled} />
          <label
            className={classNames('font-input', {
              'text-neutral-200': isOptionDisabled,
              'cursor-pointer': !isOptionDisabled,
              'ml-2': !rtl,
              'flex flex-col': option.description,
            })}
            htmlFor={option.id}
          >
            {option.description ? (
              <>
                <span className={option.className}>{option.label}</span>
                <span className="text-xs text-neutral-600">{option.description}</span>
              </>
            ) : (
              <span className={option.className}>{option.label}</span>
            )}
          </label>
        </div>
      )
    })}
  </div>
)

export default RadioGroup
