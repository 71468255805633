import { DenormalizedTheme, ThemeSettings } from '@packages/types'
import { useIsMutating } from '@tanstack/react-query'
import { getQueryKey } from '@trpc/react-query'
import fastJSONPatch from 'fast-json-patch'
import { FormikProps } from 'formik'
import { debounce, isEqual } from 'lodash'
import { useEffect, useCallback, useState, useMemo } from 'react'

import useToast from 'common/components/toast/useToast'
import { trpc } from 'common/hooks/trpc'

const useAutoSave = ({
  themeId,
  baseTheme,
  formik: formik,
  isDraft,
  onCreateDraft,
}: {
  themeId: string
  baseTheme: DenormalizedTheme & { isDraft?: boolean }
  formik: FormikProps<ThemeSettings>
  isDraft: boolean
  onCreateDraft?: () => void
}) => {
  const disable = !themeId
  const { openGenericErrorToast } = useToast()
  const isPublishing = useIsMutating(getQueryKey(trpc.theme.publishById), { exact: true })
  const isDiscarding = useIsMutating(getQueryKey(trpc.theme.discardDraftById), { exact: true })

  const [baseThemeSettings, setBaseThemeSettings] = useState(baseTheme?.settings || undefined)

  const isDirty = useMemo(() => {
    if (!themeId || !baseThemeSettings || !formik.values) return false
    return !isEqual(baseThemeSettings, formik.values) && formik.dirty
  }, [baseThemeSettings, formik.values])

  const [isSaving, setIsSaving] = useState(false)

  const { mutateAsync: patchDraft, isLoading: isPatching } = trpc.theme.patchDraft.useMutation()
  const { mutateAsync: updateDraft, isLoading: isUpdating } = trpc.theme.updateDraft.useMutation()
  const { mutateAsync: createDraft, isLoading: isCreating } = trpc.theme.createDraft.useMutation()

  const isPending = isPublishing || isDiscarding || isPatching || isUpdating || isCreating || isSaving

  const handleSave = useCallback(async () => {
    try {
      if (!isDirty) return
      setIsSaving(true)

      if (isDraft) {
        try {
          const patch = fastJSONPatch.compare({ settings: baseTheme.settings || {} }, { settings: formik.values })
          if (patch.length > 0) await patchDraft({ themeId, patch })
        } catch (error) {
          await updateDraft({
            themeId,
            themeRevision: {
              ...baseTheme,
              settings: formik.values,
            },
          })
        }
      } else {
        await createDraft({
          themeId,
          themeRevision: {
            ...baseTheme,
            settings: formik.values,
          },
        })
        onCreateDraft?.()
      }

      setBaseThemeSettings(formik.values)
    } catch (error) {
      openGenericErrorToast('The theme was not saved.')
    } finally {
      setIsSaving(false)
    }
  }, [baseTheme, formik.values, isDraft])

  const debouncedSave = useCallback(debounce(handleSave, 1000), [baseTheme, formik.values, isDraft])

  useEffect(() => {
    if (!themeId || !baseTheme?.settings) return
    setBaseThemeSettings(baseTheme?.settings)
  }, [baseTheme.settings])

  useEffect(() => {
    if (isDirty && !disable && !isPending) debouncedSave()
  }, [isDirty, disable, isPending])

  useEffect(() => {
    return () => {
      debouncedSave.flush()
    }
  }, [])
}

export default useAutoSave
