import { CancellationStrategy } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useContext } from 'react'
import * as yup from 'yup'

import TrackerContext from 'cms/tracking/components/TrackerContext'
import Button from 'common/components/Button'
import InputField from 'common/components/inputs/InputField'
import Modal, { ModalProps } from 'common/components/modal/Modal'
import RadioGroup from 'common/components/inputs/RadioGroup'
import useToast from 'common/components/toast/useToast'
import useSubscriptionService from './../../hooks/useSubscriptionService'

export interface CancelSubscriptionModalProps extends Omit<ModalProps, 'children'> {
  onClose: (withFeedback?: boolean) => void
}

interface CancelSubscriptionFormValues {
  cancellationStrategy: CancellationStrategy
}

const formValidation = yup
  .object()
  .shape({ cancellationStrategy: yup.string().required('Please select a cancellation moment') })

const cancellationStrategyOptions = [
  {
    label: 'Cancel now',
    description: 'Your customizers will be unavailable immediately',
    value: CancellationStrategy.Cancel,
  },
  {
    label: 'Cancel at the end of this cycle',
    description: 'Your subscription will stay active until the end of the current billing cycle',
    value: CancellationStrategy.RequestCancel,
  },
]

const CancelSubscriptionModal = ({ onClose, ...modalProps }: CancelSubscriptionModalProps) => {
  const subscriptionService = useSubscriptionService()
  const queryClient = useQueryClient()
  const { openGenericErrorToast } = useToast()
  const tracker = useContext(TrackerContext)

  const { mutate: updateSubscription } = useMutation(subscriptionService.cancel, {
    onSuccess: () => {
      queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
      tracker.send('account_cancelled')
      handleClose(true)
    },
    onError: () => {
      openGenericErrorToast('Your subscripion was not cancelled.')
    },
  })

  const formik = useFormik<CancelSubscriptionFormValues>({
    initialValues: {
      cancellationStrategy: CancellationStrategy.Cancel,
    },
    onSubmit: (values, { setSubmitting }) => updateSubscription(values, { onSettled: () => setSubmitting(false) }),
    validationSchema: formValidation,
  })

  const handleClose = (withFeedback?: boolean) => {
    formik.resetForm()
    onClose(withFeedback)
  }

  return (
    <Modal {...modalProps}>
      <form onSubmit={formik.handleSubmit}>
        <Modal.CloseButton onClick={() => handleClose()} />
        <Modal.Title>Cancelling you subscription?</Modal.Title>

        <Modal.Details className="space-y-4">
          <div>
            Are you sure you want to cancel your account? Your customizers will become unavailable. Following that,
            you'll need to manually remove all of the products you've created with Kickflip from your online store.
          </div>
          <InputField>
            <RadioGroup
              name="cancellationStrategy"
              options={cancellationStrategyOptions.map(option => ({
                ...option,
                id: option.value,
                checked: option.value === formik.values.cancellationStrategy,
                onChange: e => {
                  formik.handleChange(e)
                  formik.setFieldTouched('cancellationStrategy', true, false)
                },
              }))}
            />
          </InputField>
          <div>
            Not sure yet? You can{' '}
            <a
              aria-label="open intercom"
              className="text-primary-600 cursor-pointer"
              onClick={e => {
                e.preventDefault()
                window.Intercom('show')
              }}
            >
              reach our support team
            </a>{' '}
            at any time.
          </div>
        </Modal.Details>
        <Modal.Actions>
          <Button type="button" variant="default" onClick={() => handleClose()} disabled={formik.isSubmitting}>
            Keep active
          </Button>
          <Button
            variant="error"
            type="submit"
            aria-label="Confirm cancellation"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid || formik.isSubmitting}
          >
            Confirm cancellation
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  )
}

export default CancelSubscriptionModal
