import * as answersTypes from 'builder/build/answers/actionTypes'

import { syncConfiguration } from './actions'
import {
  ADD_ANSWERS,
  REMOVE_ANSWER,
  REMOVE_ANSWERS,
  CHANGE_INPUT_TYPE,
  CHANGE_MULTIPLE_SELECTION,
  SET_MIN_SELECTIONS,
  SET_MAX_SELECTIONS,
} from './actionTypes'

const ACTION_TYPES = [
  answersTypes.ADD_ANSWER,
  answersTypes.RESTORE_ANSWER,
  answersTypes.ARCHIVE_ANSWER,
  answersTypes.COPY_ANSWERS,
  answersTypes.UNLINK_ANSWER,
  ADD_ANSWERS,
  REMOVE_ANSWER,
  REMOVE_ANSWERS,
  CHANGE_INPUT_TYPE,
  CHANGE_MULTIPLE_SELECTION,
  SET_MIN_SELECTIONS,
  SET_MAX_SELECTIONS,
]

export default store => {
  return next => {
    return action => {
      const result = next(action)

      if (ACTION_TYPES.includes(action.type)) store.dispatch(syncConfiguration())

      return result
    }
  }
}
