import classNames from 'classnames'
import React from 'react'

const SideMenuGroupIconContainer = ({ children }: { children: JSX.Element }) => {
  return (
    <div
      className={classNames(
        'group-[[aria-selected="true"]]:text-primary-600 group-[[aria-selected="true"]]:fill-primary-600'
      )}
    >
      {children}
    </div>
  )
}

export default SideMenuGroupIconContainer
