import { mergeAll } from 'lodash/fp'

export type DataTableHook<TPersistence, Return> = (p: TPersistence) => Return

export type DataTableHookArray<TPersistence, Return> = DataTableHook<TPersistence, Return>[] | any[]

export type Spread<TPersistence, T extends DataTableHookArray<TPersistence, object>> = T extends [
  infer L extends DataTableHook<TPersistence, ReturnType<L>>,
  ...infer R extends DataTableHookArray<TPersistence, object>,
]
  ? ReturnType<L> & Spread<TPersistence, R>
  : unknown

const useDataTable = <TPersistence, T extends DataTableHookArray<TPersistence, object>>(
  persistence: TPersistence,
  hooks: [...T]
): Spread<TPersistence, T> => {
  return mergeAll(hooks.map(hook => hook(persistence)))
}

export default useDataTable
