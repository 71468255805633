import { PrintArea, PrintAreaView } from '@packages/types'
import { mapValues, omit, without } from 'lodash'
import { AnyAction } from 'redux'

import * as viewsTypes from 'builder/build/settings/actionTypes'
import * as printAreaUtils from 'common/printAreas/utils'

import * as types from './actionTypes'
import { updateDPI, updateMeasurementUnit } from './utils'

export interface State {
  [key: string]: PrintArea
}

const updatePrintAreaPreview = (state: State, action: AnyAction): State => {
  const printArea = state[action.payload.printAreaId]

  const printAreaInPixels = printAreaUtils.convertToPixels(printArea)

  const newScale = action.payload.update.width / printAreaInPixels.width

  return {
    ...state,
    [action.payload.printAreaId]: {
      ...printArea,
      productPreview: {
        ...printArea.productPreview,
        views: printArea.productPreview.views.reduce((views, view, i) => {
          if (i !== action.payload.view) return [...views, view]

          return [
            ...views,
            {
              ...view,
              x: action.payload.update.x,
              y: action.payload.update.y,
              rotation: action.payload.update.rotation,
              scale: newScale,
            },
          ]
        }, [] as PrintAreaView[]),
      },
    },
  }
}

const reorderPrintAreaViews = (printArea: PrintArea, originalIndex: number, newIndex: number): PrintArea => {
  const views = [...printArea.productPreview.views]
  const movedView = views[originalIndex]
  const reorderedViews = without(views, movedView)
  reorderedViews.splice(newIndex, 0, movedView)

  return {
    ...printArea,
    productPreview: {
      ...printArea.productPreview,
      designView:
        printArea.productPreview.designView === originalIndex
          ? newIndex
          : printArea.productPreview.designView === newIndex
            ? originalIndex
            : printArea.productPreview.designView,
      views: reorderedViews,
    },
  }
}

export default (state: State = {}, action: AnyAction): State => {
  switch (action.type) {
    case types.CREATE_PRINT_AREA:
      return {
        ...state,
        [action.payload.printArea.id]: action.payload.printArea,
      }
    case types.DELETE_PRINT_AREA:
      return omit(state, action.payload.printAreaId)
    case types.UPDATE_PRINT_AREA:
      return updatePrintAreaPreview(state, action)
    case types.SET_MASK:
      const printArea = state[action.payload.printAreaId]

      return {
        ...state,
        [action.payload.printAreaId]: {
          ...printArea,
          productPreview: {
            ...printArea.productPreview,
            masks: action.payload.masks,
          },
        },
      }
    case viewsTypes.ADD_VIEW:
      return mapValues(state, printArea => {
        return {
          ...printArea,
          productPreview: {
            ...printArea.productPreview,
            views: [
              ...printArea.productPreview.views,
              {
                ...printArea.productPreview.views[printArea.productPreview.designView],
                hidden: true,
              },
            ],
          },
        }
      })
    case types.UPDATE_DPI:
      return {
        ...state,
        [action.payload.printAreaId]: updateDPI(action.payload.dpi, state[action.payload.printAreaId]),
      }
    case types.UPDATE_MEASUREMENT_UNIT:
      return {
        ...state,
        [action.payload.printAreaId]: updateMeasurementUnit(
          action.payload.measurementUnit,
          state[action.payload.printAreaId]
        ),
      }
    case viewsTypes.DELETE_VIEW:
      return mapValues(state, printArea => {
        const views = [...printArea.productPreview.views]
        views.splice(action.payload.viewIndex, 1)

        return {
          ...printArea,
          productPreview: {
            ...printArea.productPreview,
            designView:
              action.payload.viewIndex <= printArea.productPreview.designView
                ? printArea.productPreview.designView - 1
                : printArea.productPreview.designView,
            views,
          },
        }
      })
    case viewsTypes.REORDER_VIEWS:
      return mapValues(state, printArea => {
        return reorderPrintAreaViews(printArea, action.payload.originalIndex, action.payload.newIndex)
      })
    default:
      return state
  }
}
