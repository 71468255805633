import { deburr } from 'lodash'
import React, { useState } from 'react'

import AnswerThumbnail from 'builder/build/answers/components/thumbnails/AnswerThumbnail'
import Card from 'builder/build/common/components/cards/Card'
import { useSelector, useDispatch } from 'cms/hooks'
import Input from 'common/components/inputs/Input'
import Button from 'common/components/Button'
import Checkbox from 'common/components/inputs/Checkbox'
import IconButton from 'common/components/IconButton'
import XIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/close.svg'

import { copyAnswers, importAnswers } from '../actions'
import { importableAnswersSelector } from '../selectors'

interface ImportAnswersPanelProps {
  questionId: string
  onImport: () => void
}

const ImportAnswersPanel = ({ questionId, onImport }: ImportAnswersPanelProps) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState('')
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([])

  const answers = useSelector(state => importableAnswersSelector(state, questionId))

  const toggleAnswer = (answerId: string) => {
    if (selectedAnswers.includes(answerId)) return setSelectedAnswers(selectedAnswers.filter(id => id !== answerId))
    setSelectedAnswers([...selectedAnswers, answerId])
  }

  const handleCopy = () => {
    dispatch(copyAnswers(questionId, selectedAnswers))
  }

  const handleImport = () => {
    dispatch(importAnswers(questionId, selectedAnswers))
    onImport()
  }

  const filteredAnswers = answers.filter(answer =>
    deburr(answer.name.toLowerCase()).includes(deburr(filter.toLowerCase()))
  )

  return (
    <>
      <div className="popper-menu-header-section">
        <div className="popper-menu-header-section__title">Import answers</div>
      </div>
      <div className="px-4 my-2">
        <Input
          placeholder="Search answers..."
          value={filter}
          onChange={e => setFilter(e.target.value)}
          rightAddon={
            filter !== '' && (
              <IconButton
                small
                variant="subtle"
                className="-mr-2"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setFilter('')
                }}
                Icon={XIcon}
              />
            )
          }
        />
      </div>
      {answers.length === 0 && (
        <div className="px-8 pt-4 pb-6 text-neutral-600 text-xs text-center">
          You don't have any answers you can import
        </div>
      )}
      <div className="max-h-96 overflow-auto" style={{ height: `${answers.length * 46}px` }}>
        {filteredAnswers.length === 0 && answers.length !== 0 && (
          <div className="px-8 pt-4 pb-6 text-neutral-600 text-xs text-center">No answers matches your search</div>
        )}
        {filteredAnswers.map(answer => (
          <Card
            className="group mx-4 relative shadow-xs border border-solid border-neutral-100 overflow-hidden mb-2"
            onClick={() => toggleAnswer(answer.id)}
          >
            <AnswerThumbnail answer={answer} />
            <span className="mr-auto">{answer.name}</span>
            <Checkbox
              id={answer.id}
              onChange={() => toggleAnswer(answer.id)}
              checked={selectedAnswers.includes(answer.id)}
            />
          </Card>
        ))}
      </div>
      <div className="popper-menu-footer-section">
        <Button onClick={handleCopy} disabled={selectedAnswers.length === 0}>
          Copy
        </Button>
        <Button onClick={handleImport} disabled={selectedAnswers.length === 0}>
          Import
        </Button>
      </div>
    </>
  )
}

export default ImportAnswersPanel
