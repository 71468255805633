import { money } from '@packages/money'
import { Text } from '@react-pdf/renderer'
import React from 'react'

interface PriceProps {
  amount: number
  currency: string
}

const Price = ({ amount, currency }: PriceProps) => {
  return <Text>{money(amount).toFormattedString({ currencyCode: currency })}</Text>
}

export default Price
