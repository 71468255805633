import { Product } from '@packages/types'
import { useQueryClient } from '@tanstack/react-query'
import React, { useContext } from 'react'

import * as coreSelectors from 'builder/build/core/selectors'
import GlobalRouterContext from 'builder/common/GlobalRouterContext'
import { useDispatch, useSelector } from 'cms/hooks'
import Button from 'common/components/Button'
import ProductLimitReachedToast from 'common/components/toast/ProductLimitReachedToast'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import { trpc } from 'common/hooks/trpc'
import useProductService from 'common/products/hooks/useProductService'

const DemoUseAsTemplateDiscard = () => {
  const dispatch = useDispatch()
  const { history, match } = useContext(GlobalRouterContext)
  const { openToast, openCustomToast, openGenericErrorToast } = useToast()
  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''
  const isDirty = useSelector(coreSelectors.isDirtySelector)
  const queryClient = useQueryClient()
  const productService = useProductService()
  const trpcUtils = trpc.useContext()

  const { mutate: instantiate, isLoading: isInstatiating } = trpc.demoProduct.instantiate.useMutation({
    onSuccess: (duplicatedProduct: Product) => {
      openToast('The product successfully created', ToastType.success)
      queryClient.invalidateQueries([...productService.fetch.queryKeys, match.params.productId])
      history.push(`${baseUrl}/products/${duplicatedProduct.id}/builder`)
    },
    onError: error => {
      if (error.data && 'cause' in error.data && error.data.cause === 'MaximumActiveProductsException') {
        return openCustomToast(props => (
          <ProductLimitReachedToast onUpgradePlan={() => history.push(`${baseUrl}/subscription`)} {...props} />
        ))
      }

      return openGenericErrorToast('Your product was not created.')
    },
  })

  const handleDiscard = () => {
    try {
      trpcUtils.demoProduct.get.invalidate()
      openToast('The demo was successfully reset', ToastType.success)
    } catch (error) {
      openGenericErrorToast('The demo was not reset.')
    }
  }

  const handleUseAsTemplate = () =>
    dispatch(async (_dispatch, getState) => {
      const state = getState()
      const customizerProduct = coreSelectors.customizerProductSelector(state)

      instantiate({ demoId: match.params.productId, live: customizerProduct })
    })

  return (
    <div className="flex items-center">
      <Button className="mr-2" disabled={!isDirty || isInstatiating} onClick={() => handleDiscard()}>
        Reset demo
      </Button>
      <Button variant="green" disabled={isInstatiating} onClick={() => handleUseAsTemplate()}>
        Use as template
      </Button>
    </div>
  )
}

export default DemoUseAsTemplateDiscard
