import { DenormalizedCustomizablePart, DenormalizedCustomizableQuestion, PartType } from '@packages/types'
import { createSelector } from 'reselect'

import * as constants from 'common/customizerProducts/constants'
import { PreviewMode } from 'common/preview/types/previewMode'
import * as customizationSelectors from 'customizer/customization/selectors'
import { RootState } from 'customizer/store'
import { getScreenSizeBreakPoint } from 'utils/loaders/ImageLoader'

import type { ClippingGroup, DrawingNode, MultiAnswerGroup, Node } from './types/node'
import type { Dimensions } from './types/dimensions'
import { partToLayer, printAreaToClippingGroup, multiAnswerImageToGroup } from './utils'

type DimensionsIncludingScreen = Dimensions & { screenWidth: number; screenHeight: number }
type DimensionsIncludingScale = Dimensions & { scale: number }

const DEFAULT_DIMENSIONS = constants.defaultDimensions
const MAX_DIMENSION_RATIO_TO_ENABLE_RESIZE = 0.9

const displayerSelector = ({ displayer }: RootState) => displayer

const renderedPrintAreaSelector = createSelector(displayerSelector, ({ renderedPrintArea }) => renderedPrintArea)
export const isEditingSelector = createSelector(displayerSelector, ({ editedPart }) => !!editedPart)
export const editedPrintAreaSelector = createSelector(displayerSelector, ({ editedPrintArea }) => editedPrintArea)
export const currentViewSelector = createSelector(displayerSelector, ({ currentView }) => currentView)
export const hiddenPartsSelector = createSelector(displayerSelector, ({ hiddenParts }) => hiddenParts ?? [])
export const isZoomDisabledSelector = createSelector(displayerSelector, ({ isZoomDisabled }) => isZoomDisabled)
export const forceHighlightSelector = createSelector(displayerSelector, ({ forceHighlight }) => forceHighlight)
export const isRenderCompletedSelector = createSelector(displayerSelector, ({ isRenderCompleted }) => isRenderCompleted)
export const preventTransformSelector = createSelector(displayerSelector, ({ preventTransform }) => preventTransform)

export const editedPartSelector = createSelector(displayerSelector, displayer => displayer.editedPart)

const getCanvasSizeAccordingToScreenSizeAndBrowserLimits = (dimensions: DimensionsIncludingScreen) => {
  // Optimize image loading by loading smaller images for smaller screens
  // second getScreenSizeBreakPoint ensures that the canvas size will not exceed the
  // maximum canvas size of the browser.
  // TODO: canvas size and image resize is tied together in a weird way, we should split the image resize behavior and the max canvas size eventually
  return (
    getScreenSizeBreakPoint(dimensions.screenWidth, dimensions.screenHeight) ??
    getScreenSizeBreakPoint(dimensions.width, dimensions.height)
  )
}

export const createDimensionsSelector = (dimensionsState: (state: RootState) => DimensionsIncludingScreen) =>
  createSelector(dimensionsState, dimensions => {
    const breakPoint = getCanvasSizeAccordingToScreenSizeAndBrowserLimits(dimensions)

    if (!breakPoint)
      return {
        width: dimensions.width,
        height: dimensions.height,
        scale: 1,
      }

    const minWidth = Math.min(dimensions.width, breakPoint.width)
    const minHeight = Math.min(dimensions.height, breakPoint.height)
    const ratio = Math.min(minWidth / dimensions.width, minHeight / dimensions.height)

    return {
      width: dimensions.width * ratio,
      height: dimensions.height * ratio,
      scale: ratio,
    }
  })

export const dimensionsSelector = createDimensionsSelector((state: RootState) => ({
  ...DEFAULT_DIMENSIONS,
  ...state.customization.customizerProduct.dimensions,
  screenWidth: window.screen.width,
  screenHeight: window.screen.height,
}))

export const isZoomableSelector = createSelector(
  dimensionsSelector,
  (state: RootState) => state.displayer.size,
  (dimensions, displayerSize) => {
    if (!dimensions || !displayerSize) return true
    const widthGap = dimensions.width - displayerSize.width
    const heightGap = dimensions.height - displayerSize.height

    if (widthGap < 0 && heightGap < 0) return false

    const isWidthZoomable = displayerSize.width / dimensions.width < MAX_DIMENSION_RATIO_TO_ENABLE_RESIZE
    const isHeightZoomable = displayerSize.height / dimensions.height < MAX_DIMENSION_RATIO_TO_ENABLE_RESIZE

    return isWidthZoomable || isHeightZoomable
  }
)

const transformToDrawingNodes = (
  parts: DenormalizedCustomizablePart[],
  dimensions: DimensionsIncludingScale,
  currentView: number,
  renderedPrintAreaId: string | null
) => {
  const clippingGroupNodes: Record<string, ClippingGroup> = {}
  const multiAnswerGroupNodes: Record<string, MultiAnswerGroup> = {}
  return parts.reduce<Node[]>((nodes, part) => {
    const partNode = partToLayer(part, dimensions, currentView) as DrawingNode | DrawingNode[]
    if (part.printArea?.id) {
      const clippingGroupNode =
        clippingGroupNodes[part.printArea.id] ??
        printAreaToClippingGroup(
          part.printArea,
          dimensions.scale ?? 1,
          currentView,
          renderedPrintAreaId === part.printArea.id
        )

      if (!clippingGroupNode) return nodes

      if (partNode) clippingGroupNode.objects.push(partNode as DrawingNode)

      if (!clippingGroupNodes[part.printArea.id]) {
        clippingGroupNodes[part.printArea.id] = clippingGroupNode
        return [...nodes, clippingGroupNode]
      }

      return nodes
    }

    if (
      part.type === PartType.Image &&
      part.image?.type === 'image' &&
      (part.image as DenormalizedCustomizableQuestion | undefined)?.isMultiAnswer
    ) {
      if (multiAnswerGroupNodes[part.id]) return nodes

      const multiAnswerGroupNode = multiAnswerImageToGroup(part.id) as MultiAnswerGroup

      if (!multiAnswerGroupNode) return nodes

      if (partNode) multiAnswerGroupNode.objects = partNode as DrawingNode[]

      multiAnswerGroupNodes[part.id] = multiAnswerGroupNode
      return [...nodes, multiAnswerGroupNode]
    }

    return partNode ? [...nodes, partNode as DrawingNode] : nodes
  }, [])
}

export const currentViewDrawingNodesSelector = createSelector(
  customizationSelectors.orderedPartsSelector,
  dimensionsSelector,
  currentViewSelector,
  renderedPrintAreaSelector,
  transformToDrawingNodes
)

export const drawingNodesSelector = createSelector(
  customizationSelectors.orderedPartsSelector,
  dimensionsSelector,
  (_state: RootState, view: number) => view,
  renderedPrintAreaSelector,
  transformToDrawingNodes
)

export const currentViewDrawingNodeSelector = createSelector(
  (state: RootState, partId: string) => {
    return customizationSelectors.orderedPartsSelector(state).filter(part => part.id === partId)
  },
  dimensionsSelector,
  currentViewSelector,
  renderedPrintAreaSelector,
  transformToDrawingNodes
)

export const currentViewClippingGroupNodeSelector = createSelector(
  (state: RootState, printAreaId: string) => {
    return customizationSelectors.orderedPartsSelector(state).filter(part => part.printArea?.id === printAreaId)
  },
  dimensionsSelector,
  currentViewSelector,
  renderedPrintAreaSelector,
  transformToDrawingNodes
)

export const isTouchSelector = (state: RootState) => {
  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    state.displayer.viewMode === PreviewMode.MOBILE
  )
}

export const isMobileSelector = (state: RootState) => state.displayer.isMobile
