import { DataTablePersistence } from './persistence/types/persistence'

type TablePaginationPersistence = { count: number; lastIndex: number; resultSize: number; collectionSize: number }

const useTablePagination =
  () =>
  <TModel extends TablePaginationPersistence = any>({ state, setState }: DataTablePersistence<TModel>) => {
    const nextPage = () => setState(state => ({ ...state, lastIndex: state.lastIndex + state.count }))
    const previousPage = () => setState(state => ({ ...state, lastIndex: state.lastIndex - state.count }))
    const firstPage = () => setState(state => ({ ...state, lastIndex: 0 }))
    const setCount = (value: number) => setState(state => ({ ...state, count: value, lastIndex: 0 }))
    const setResultSize = (value: number) => setState(state => ({ ...state, resultSize: value }))
    const setCollectionSize = (value: number) => setState(state => ({ ...state, collectionSize: value }))

    return {
      count: state.count,
      lastIndex: state.lastIndex,
      resultSize: state.resultSize,
      collectionSize: state.collectionSize,
      firstPage,
      nextPage,
      previousPage,
      setCount,
      setResultSize,
      setCollectionSize,
    }
  }

export default useTablePagination
