import { PrintArea } from '@packages/types'
import { merge } from 'lodash'
import React from 'react'

import * as builderConstants from 'builder/build/common/constants'
import PatchSketchPicker from 'builder/build/common/components/PatchSketchPicker'
import HorizontalField from 'builder/build/common/components/HorizontalField'
import VerticalFields from 'builder/build/common/components/VerticalFields'
import { useDispatch } from 'cms/hooks'
import Switch from 'common/components/Switch'
import Popover from 'common/components/popover/Popover'
import usePopover from 'common/components/popover/usePopover'

import { patchPrintArea } from '../../actions'

interface ShowPrintAreaSectionProps {
  printArea: PrintArea
}

const ShowPrintAreaSection = ({ printArea }: ShowPrintAreaSectionProps) => {
  const dispatch = useDispatch()
  const dropdownButton = usePopover()

  const handleChange = (field: string, value: string | boolean) =>
    dispatch(patchPrintArea(printArea, { productPreview: { [field]: value } }))

  return (
    <VerticalFields>
      <HorizontalField>
        <label>Outline color</label>
        <div
          aria-label="Outline color preview"
          {...dropdownButton.referenceProps}
          className="w-[18px] h-[18px] rounded-full hover:cursor-pointer"
          style={{ background: printArea.productPreview.outlineColor }}
        />
      </HorizontalField>
      <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen} className="w-48">
        <div className="px-4">
          <PatchSketchPicker
            styles={
              merge(builderConstants.colorPickerStyle, {
                saturation: {
                  marginLeft: '-1rem',
                  marginRight: '-1rem',
                  width: 'calc(100% + 2rem)',
                  paddingBottom: '75%',
                  position: 'relative',
                  overflow: 'hidden',
                },
              }) as any
            }
            disableAlpha
            color={printArea.productPreview.outlineColor}
            onChange={(color: { hex: string }) => handleChange('outlineColor', color.hex)}
          />
        </div>
      </Popover>
      <HorizontalField>
        <label htmlFor="print-area-is-outline-shown">Show outline</label>
        <Switch
          id="print-area-is-outline-shown"
          checked={printArea.productPreview.isShown}
          onChange={e => handleChange('isShown', e.target.checked)}
        />
      </HorizontalField>
    </VerticalFields>
  )
}

export default ShowPrintAreaSection
