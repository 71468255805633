import { EntityType } from '@packages/types'
import { useEffect, useRef } from 'react'
import { useLocation, useHistory, matchPath, useParams } from 'react-router-dom'

import * as coreSelectors from 'builder/build/core/selectors'
import * as coreUtils from 'builder/build/core/utils'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import { useDispatch } from 'cms/hooks'
import { AppDispatch, RootState } from 'cms/store'
import * as customizationUtils from 'customizer/customization/utils'
import * as questionPanelActions from 'customizer/questionPanel/actions'

const useCustomizerNavigation = () => {
  const dispatch = useDispatch()
  const customizerDispatch = customizerHooks.useCustomizerDispatch()
  const history = useHistory()
  const location = useLocation()
  const params = useParams<{ questionId?: string; id?: string }>()
  const selectedStep = useRef<string | null>(null)
  const locationRef = useRef(location)
  const currentStep = customizerHooks.useCustomizerSelector(customizerSelectors.currentStepSelector)
  const id = (params?.questionId || params?.id)!

  useEffect(() => {
    const currentStepMatch = matchPath(locationRef.current.pathname, {
      path: `/(groups|questions)/${currentStep}`,
      exact: false,
    })

    if (!currentStepMatch && selectedStep.current !== currentStep) {
      dispatch((_dispatch: AppDispatch, getState: () => RootState) => {
        if (!currentStep) return

        const state = getState()
        const rootId = coreSelectors.rootIdSelector(state)
        const isRoot = rootId === currentStep

        if (isRoot) return

        const entity = coreUtils.getEntityById(state.productBuilder, currentStep)

        if (!entity) return

        if (entity.entityType === EntityType.Group) {
          const matchGroup = matchPath<{ id: string }>(locationRef.current.pathname, {
            path: '/groups/:id',
          })

          if (matchGroup) {
            const groupId = customizationUtils.getFirstLevelAncestorId(
              Object.values(state.productBuilder.groups),
              matchGroup.params?.id,
              rootId
            )
            if (groupId && groupId === entity.id) return
          }
        }

        if (entity.entityType === EntityType.Question) return history.push(`/questions/${currentStep}`)
        if (entity.entityType === EntityType.Group) return history.push(`/groups/${currentStep}`)
      })
    }
    selectedStep.current = currentStep
  }, [dispatch, history, currentStep])

  useEffect(() => {
    locationRef.current = location
  }, [location])

  useEffect(() => {
    if (
      (location.pathname.includes('questions') || location.pathname.includes('groups')) &&
      id !== selectedStep.current
    )
      customizerDispatch(questionPanelActions.selectStep(id))
  }, [customizerDispatch, history, id, location.pathname])
}

export default useCustomizerNavigation
