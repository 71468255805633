import { Addon, CustomPlan, Plan } from '@packages/types'
import React from 'react'

import Card from 'common/components/card/Card'
import CheckList from 'common/components/CheckList'

import PlanPrice from '../PlanPrice'
import Reviews from './Reviews'

interface PlanCardProps {
  plan: Plan | CustomPlan
  addons: React.ReactNode
  addonsSubscribed: Addon[]
}

const PlanCard = ({ plan, addons, addonsSubscribed }: PlanCardProps) => {
  return (
    <Card className="w-[410px] mb-3 relative flex flex-col justify-between shrink-0">
      <Card.Section className="md:flex-col">
        <h1 className="mb-2">{plan.name}</h1>
        <PlanPrice plan={plan} addonsSubscribed={addonsSubscribed} />
        {!plan.custom && <span className="font-normal pt-4 w-full pb-6 text-neutral-600">{plan.description}</span>}
        {plan.attributesText && <CheckList items={plan.attributesText} className="fill-primary-500" />}
      </Card.Section>
      {addons}
      <Card.Separator />
      <Card.Section className="pt-2">
        <Reviews />
      </Card.Section>
    </Card>
  )
}

export default PlanCard
