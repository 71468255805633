import { QuestionType, EntityType, QuestionInputType } from '@packages/types'
import { SpringValue, animated } from '@react-spring/web'
import classNames from 'classnames'
import React from 'react'

import TypeIcon from 'builder/build/common/components/TypeIcon'

export interface QuestionMenuCardProps<T extends QuestionType | QuestionInputType> {
  item: {
    type: T
    label: string
    preview: string | null
    SecondaryIcon?: React.ComponentType
  }
  style?: {
    position?: 'absolute'
    opacity: SpringValue<number>
  }
  entityType: 'DISPLAY_TYPE' | EntityType.Question | EntityType.Group
  isSelected: boolean
  onSelect: (type: T) => void
  setHoveredItemPreview: (preview: string | null) => void
}

const QuestionMenuCard = <T extends QuestionType | QuestionInputType>({
  item,
  style,
  entityType,
  isSelected,
  onSelect,
  setHoveredItemPreview,
}: QuestionMenuCardProps<T>) => {
  return (
    <animated.div
      style={style}
      className={classNames(
        'relative flex pl-2 py-2 items-center rounded-md border w-[164.5px] h-[44px] bg-white hover:cursor-pointer',
        {
          'border-neutral-100': !isSelected,
          'ring-2 ring-primary-300 border-primary-600': isSelected,
        }
      )}
      onClick={() => onSelect(item.type)}
      onMouseOver={() => setHoveredItemPreview(item.preview)}
      onMouseLeave={() => setHoveredItemPreview(null)}
    >
      {item.SecondaryIcon && <item.SecondaryIcon />}
      <TypeIcon className="w-[26px] h-[26px] mr-2 p-1.5 rounded" entityType={entityType} specificType={item.type} />
      <span className="font-medium text-[12px]">
        {item.label}
        {item.type === QuestionType.Value && (
          <div className="font-normal text-[10px] text-neutral-600">Not shown on product</div>
        )}
      </span>
    </animated.div>
  )
}

export default QuestionMenuCard
