import { LegacyCustomizerIntegrationType } from '@packages/types'
import React from 'react'

import CustomizeButtonPreview from 'icons/customizerIntegration/customize-button-preview.svg'
import CustomizerOnlyPreview from 'icons/customizerIntegration/customizer-only-preview.svg'

interface ShopifyIntegrationPreviewProps {
  integrationType?: LegacyCustomizerIntegrationType
  className?: string
}

const ShopifyIntegrationPreview = ({ integrationType, className }: ShopifyIntegrationPreviewProps) => {
  return integrationType === LegacyCustomizerIntegrationType.CustomizeButton ? (
    <CustomizeButtonPreview className={className} />
  ) : (
    <CustomizerOnlyPreview className={className} />
  )
}

export default ShopifyIntegrationPreview
