import { BulkOrder, GroupQuantity } from '@packages/types'
import React, { useEffect, useRef, useState } from 'react'

import Card from 'builder/build/common/components/cards/Card'
import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import { useDispatch } from 'cms/hooks'
import IconButton from 'common/components/IconButton'
import Input from 'common/components/inputs/Input'
import Popover from 'common/components/popover/Popover'
import usePopover from 'common/components/popover/usePopover'
import Tooltip from 'common/components/Tooltip'
import useMouseOver from 'common/hooks/useMouseOver'
import * as bulkOrderActions from 'customizer/bulkOrder/actions'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'

import { patchGroup } from '../../actions'
import BulkOrderElementCardConfig from './BulkOrderElementCardConfig'

export interface BulkOrderQuantityCardProps {
  group: BulkOrder
}

const BulkOrderQuantityCard = ({ group }: BulkOrderQuantityCardProps) => {
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const { isMouseOver, onMouseEnter, onMouseLeave } = useMouseOver()
  const [isEditingName, setIsEditingName] = useState(false)
  const moreDropdownButton = usePopover({ placement: 'bottom-end' })
  const isEditingInputRef = useRef<HTMLInputElement>(null)

  const handleEditQuantityNameClick = () => {
    if (!!group?.children.length) customizerDispatch(bulkOrderActions.openModal())
    moreDropdownButton.close()
    setIsEditingName(true)
  }

  const handleUpdateQuantity = <TKey extends keyof GroupQuantity>(key: TKey, value: GroupQuantity[TKey]) => {
    if (!!group?.children.length) customizerDispatch(bulkOrderActions.openModal())
    return dispatch(patchGroup(group.id, { quantity: { ...group.quantity, [key]: value } }))
  }

  const quantityName = group.quantity?.name != null ? group.quantity?.name : 'Quantity'

  useEffect(() => {
    if (isEditingName && isEditingInputRef.current) {
      isEditingInputRef.current.focus()
    }
  }, [isEditingName])

  return (
    <Card
      className="mx-2 border-neutral-100 border flex-col items-start p-2 space-y-2 bg-white"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="flex items-center w-full space-x-2">
        <div className="flex flex-1 items-center h-6">
          {!isEditingName && <span className="builder-card__text">{quantityName || 'Quantity'}</span>}

          {isEditingName && (
            <Input
              small
              ref={isEditingInputRef}
              placeholder="Name"
              aria-label="Product name"
              autoComplete="off"
              value={quantityName}
              onChange={e => handleUpdateQuantity('name', e.target.value)}
              onBlur={() => setIsEditingName(false)}
              className="border-opacity-0 hover:border-opacity-100 focus:border-opacity-100 pl-1 pr-1"
            />
          )}
        </div>

        {(isMouseOver || moreDropdownButton.isOpen) && !isEditingName && (
          <>
            <IconButton
              aria-label="More actions"
              variant="subtle"
              small
              Icon={MoreIcon}
              {...moreDropdownButton.referenceProps}
            />
            <Popover isOpen={moreDropdownButton.isOpen} {...moreDropdownButton.floatingProps}>
              <Popover.Action key="remove" onClick={handleEditQuantityNameClick}>
                Edit quantity name
              </Popover.Action>
            </Popover>
          </>
        )}
      </div>
      <Tooltip
        content="These settings are only possible when there are more than one question."
        disabled={!!group?.children.length}
      >
        <BulkOrderElementCardConfig
          disabled={!group?.children.length}
          placeholder="Quantity"
          onDimensionSelect={value => handleUpdateQuantity('dimension', value)}
          onLabelChange={value => handleUpdateQuantity('label', value)}
          bulkConfig={group.quantity}
        />
      </Tooltip>
    </Card>
  )
}

export default BulkOrderQuantityCard
