import { $isLinkNode, $createLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { LinkPlugin as LexicalLinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { mergeRegister } from '@lexical/utils'
import { FormikConfig, useFormik } from 'formik'
import {
  $isTextNode,
  $getSelection,
  $isRangeSelection,
  $createTextNode,
  $createParagraphNode,
  $getRoot,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_LOW,
} from 'lexical'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import Input from 'common/components/inputs/Input'
import Button from 'common/components/Button'
import InputField from 'common/components/inputs/InputField'
import HelperText from 'common/components/inputs/HelperText'
import Popover from 'common/components/popover/Popover'
import usePopover from 'common/components/popover/usePopover'
import LinkIcon from 'icons/bold/01-Interface Essential/27-Link-Unlink/hyperlink.svg'

import ToolbarButton from '../ToolbarButton'
import { getSelectedNode, selectNode } from '../utils'

const authorizedProtocols = ['http:', 'https:', 'mailto:', 'tel:']

const formValidation = yup.object().shape({
  url: yup.lazy(value => {
    if (!value) return yup.string().required('Required')
    if (value.startsWith('mailto:'))
      return yup.string().matches(/^mailto:[^\s@]+@[^\s@]+\.[^\s@]+$/, { message: 'Invalid email' })
    if (value.startsWith('tel:')) return yup.string().matches(/^tel:\+?\d+$/, { message: 'Invalid phone number' })
    return yup.string().url('Invalid URL')
  }),
  text: yup.string(),
})

const LinkPlugin = () => {
  const [editor] = useLexicalComposerContext()
  const linkPopover = usePopover({ placement: 'bottom' })
  const isOpenRef = useRef(false)
  const [isLink, setIsLink] = useState(false)
  const [lastSelection, setLastSelection] = useState<ReturnType<typeof $getSelection>>(null)
  const [initialValues, setInitialValues] = useState({ url: '', text: '' })

  const handleSubmit: FormikConfig<{ url: string; text: string }>['onSubmit'] = ({ url, text }) => {
    editor.update(() => {
      const linkText = text || url
      const attributes = { target: '_blank', rel: 'noopener noreferrer' }

      if ($isRangeSelection(lastSelection)) {
        const selectedNode = getSelectedNode(lastSelection)
        const parentNode = selectedNode.getParent()

        if ($isLinkNode(parentNode)) {
          if ($isTextNode(selectedNode)) selectedNode.setTextContent(linkText)
          parentNode.setURL(url)
          selectNode(selectedNode)
        } else if (lastSelection.isCollapsed()) {
          const selectableNode = $createTextNode(linkText)
          const linkNode = $createLinkNode(url, attributes).append(selectableNode)
          if ($isTextNode(selectedNode)) {
            const splittedNodes = selectedNode.splitText(lastSelection.anchor.offset)
            splittedNodes[0].insertAfter(linkNode)
          } else {
            selectedNode.append(linkNode)
          }
          selectNode(selectableNode)
        } else {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url, ...attributes })
          const currentSelection = $getSelection()
          if ($isRangeSelection(currentSelection)) {
            const node = getSelectedNode(currentSelection)
            if ($isTextNode(node)) node.setTextContent(linkText)
            selectNode(node)
          }
        }
      } else {
        const selectableNode = $createTextNode(linkText)
        $getRoot().append($createParagraphNode().append($createLinkNode(url, attributes).append(selectableNode)))
        selectNode(selectableNode)
      }
    })

    linkPopover.close()
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: formValidation,
    onSubmit: handleSubmit,
  })

  const handleLinkInputBlur = () => {
    const hasProtocol = !!authorizedProtocols.find(protocol => formik.values.url.startsWith(protocol))
    if (formik.values.url !== '' && !hasProtocol) formik.setFieldValue('url', `https://${formik.values.url}`)
  }

  const updatePlugin = useCallback(() => {
    const selection = $getSelection()
    if (!isOpenRef.current) setLastSelection(selection)
    const isLink = $isRangeSelection(selection) ? $isLinkNode(getSelectedNode(selection).getParent()) : false
    setIsLink(isLink)
  }, [])

  useEffect(() => {
    isOpenRef.current = linkPopover.isOpen

    if (linkPopover.isOpen) {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          const node = getSelectedNode(selection)
          const parentNode = node.getParent()

          if ($isLinkNode(parentNode)) {
            setInitialValues({ url: parentNode.getURL(), text: node.getTextContent() })
            setIsLink(true)
          } else {
            setInitialValues({ url: '', text: selection.getTextContent() })
            setIsLink(false)
          }
        }
      })
    } else {
      formik.resetForm()
    }
  }, [linkPopover.isOpen])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => updatePlugin())
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updatePlugin()
          return false
        },
        COMMAND_PRIORITY_LOW
      )
    )
  }, [])

  return (
    <>
      <LexicalLinkPlugin />
      <ToolbarButton
        ref={linkPopover.referenceProps.ref}
        onMouseDown={linkPopover.referenceProps.onClick}
        isActive={isLink}
        aria-label="Format Link"
      >
        <LinkIcon width={16} height={16} />
      </ToolbarButton>
      <Popover isOpen={linkPopover.isOpen} {...linkPopover.floatingProps} className="w-[252px] z-40">
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className="flex flex-col p-2">
            <InputField className="mb-3">
              <Input
                id="url"
                name="url"
                placeholder="Link"
                onChange={formik.handleChange}
                onBlur={handleLinkInputBlur}
                value={formik.values.url}
                hasError={!!formik.touched.url && !!formik.errors.url}
              />
              {formik.touched.url && formik.errors.url && <HelperText hasError>{formik.errors.url}</HelperText>}
            </InputField>
            <InputField className="mb-3">
              <Input
                id="text"
                name="text"
                placeholder="Text"
                onChange={formik.handleChange}
                value={formik.values.text}
              />
            </InputField>
            <div className="flex justify-end">
              <Button className="mr-2" onClick={linkPopover.close} type="button">
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={!formik.dirty}>
                Apply
              </Button>
            </div>
          </div>
        </form>
      </Popover>
    </>
  )
}

export default LinkPlugin
