import React, { useContext } from 'react'

import GlobalRouterContext from 'builder/common/GlobalRouterContext'
import CommonTopBarBackButton from 'common/components/topBar/TopBarBackButton'

const TopBarBackButton = () => {
  const { match, history } = useContext(GlobalRouterContext)
  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''

  const handleClick = () => history.push(`${baseUrl}/products`)

  return <CommonTopBarBackButton onBackClick={handleClick} buttonClassNames="!mr-0" />
}

export default TopBarBackButton
