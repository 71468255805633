import React, { useEffect, useMemo, useState } from 'react'

const useScrollRight = (): [
  boolean,
  boolean,
  { onScroll: (e: React.UIEvent<HTMLDivElement>) => void; ref: (el: HTMLDivElement | null) => void },
] => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  const [notScrolled, setNotScrolled] = useState(true)
  const [fullyScrolled, setFullyScrolled] = useState(false)

  useEffect(() => {
    if (!!ref) {
      setTimeout(() => {
        setNotScrolled(true)
        setFullyScrolled(ref.getBoundingClientRect().width === ref.scrollWidth)
      }, 50)
    }
  }, [ref])

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setFullyScrolled(
      event.currentTarget.scrollLeft + event.currentTarget.getBoundingClientRect().width ===
        event.currentTarget.scrollWidth
    )
    setNotScrolled(event.currentTarget.scrollLeft === 0)
  }

  const data = useMemo<
    [
      boolean,
      boolean,
      { onScroll: (e: React.UIEvent<HTMLDivElement>) => void; ref: (el: HTMLDivElement | null) => void },
    ]
  >(() => [notScrolled, fullyScrolled, { onScroll, ref: setRef }], [notScrolled, fullyScrolled])

  return data
}

export default useScrollRight
