import {
  CustomOnlineStore,
  ECommerce,
  OnlineStore,
  PrestaShopOnlineStore,
  ShopifyOnlineStore,
  ShopifyTheme,
  WixOnlineStore,
  WooCommerceOnlineStore,
} from '@packages/types'

export const isShopify = (onlineStore: OnlineStore): onlineStore is ShopifyOnlineStore =>
  onlineStore.eCommerce === ECommerce.Shopify

export const isPrestaShop = (onlineStore: OnlineStore): onlineStore is PrestaShopOnlineStore =>
  onlineStore.eCommerce === ECommerce.Prestashop

export const isWooCommerce = (onlineStore: OnlineStore): onlineStore is WooCommerceOnlineStore =>
  onlineStore.eCommerce === ECommerce.Woocommerce

export const isWix = (onlineStore: OnlineStore): onlineStore is WixOnlineStore =>
  onlineStore.eCommerce === ECommerce.Wix

export const isCustomStore = (onlineStore: OnlineStore): onlineStore is CustomOnlineStore =>
  onlineStore.eCommerce === ECommerce.CustomStore

export const onlineStoresHasOneOf = (onlineStores: OnlineStore[], eCommerce: ECommerce): boolean => {
  return onlineStores.find(onlineStore => onlineStore.eCommerce === eCommerce) != null
}

export const redirectToShopifyIntegration = ({
  onlineStore,
  theme,
  template = 'product',
  productHandle,
}: {
  onlineStore: ShopifyOnlineStore
  theme?: ShopifyTheme
  template?: string
  productHandle?: string
}) => {
  if (!theme) return window.open(new URL(`https://${onlineStore.domain}/admin/themes`).toString(), '_blank')

  if (!theme?.appEmbedDeeplink) throw new Error('No appEmbedDeeplink found in theme')

  const deeplinkURL = new URL(theme.appEmbedDeeplink)
  if (!productHandle || template !== 'product') deeplinkURL.searchParams.set('template', template)
  if (productHandle) deeplinkURL.searchParams.set('previewPath', `/products/${productHandle}`)

  return window.open(deeplinkURL.toString(), '_blank')
}

export const isProductConnectedToAStore = (onlineStores: OnlineStore[], productId: string) => {
  const storeWithConnection = onlineStores?.find(onlineStore => {
    if (onlineStore.productsData[productId]?.startingPointsBindings?.length > 0) return true
    return false
  })

  return storeWithConnection != null
}

export const productHasPricing = (onlineStores: OnlineStore[], productId: string) => {
  const storeWithPricing = onlineStores?.find(onlineStore => {
    const basePrice = onlineStore.productsData[productId]?.pricing?.basePrice

    if (basePrice != null && basePrice !== '0') return true

    return false
  })

  return storeWithPricing != null
}
