import { Question, Rule, RuleThenType, RuleThen, RuleWhen, RuleAssertion, GroupType, Group } from '@packages/types'

import isNullOrEmpty from 'utils/isNullOrEmpty'

const doAnswerExistsInQuestion = (answerId: string, question: Question) => {
  return question.answers.includes(answerId)
}

const doAllAnswersExistInQuestion = (answerIds: string[], question: Question) => {
  return answerIds.every(id => doAnswerExistsInQuestion(id, question))
}

const isValidThen = (then: RuleThen[], questions: Record<string, Question>) => {
  return then?.every(({ payload, type, questionId, answerId }) => {
    const question = questions[questionId]

    if (isNullOrEmpty(type) || !question) return false

    switch (type) {
      case RuleThenType.DisableQuestion: {
        return true
      }
      case RuleThenType.RestrictAllBut:
      case RuleThenType.AddRestriction: {
        return question.isMultiAnswer
          ? Array.isArray(payload) && doAllAnswersExistInQuestion(payload, question)
          : !!answerId && doAnswerExistsInQuestion(answerId, question)
      }
      case RuleThenType.SetAuthorizedAnswers:
      case RuleThenType.SetRestrictedAnswers: {
        return Array.isArray(payload) && payload.length > 0 && doAllAnswersExistInQuestion(payload, question)
      }
    }
  })
}

const isValidWhen = (when: RuleWhen[], questions: Record<string, Question>) => {
  return when?.every(({ value, path, assertion }) => {
    const isPathValid = path.length === 3
    const question = questions[path[1]]

    if (isNullOrEmpty(value) || !isPathValid || !question) return false

    switch (assertion) {
      case RuleAssertion.MatchesInState:
      case RuleAssertion.DoesNotMatchInState: {
        if (!Array.isArray(value) || value.length !== 3) return false

        const matchingQuestion = questions[value[1]]
        return matchingQuestion && !!question.isMultiAnswer === !!matchingQuestion.isMultiAnswer
      }
      case RuleAssertion.Includes:
      case RuleAssertion.DoesNotInclude: {
        if (!question.isMultiAnswer) return false
        return Array.isArray(value) && doAllAnswersExistInQuestion(value, question)
      }
      case RuleAssertion.IsOneOf: {
        return Array.isArray(value) && doAllAnswersExistInQuestion(value, question)
      }
      case RuleAssertion.Is:
      case RuleAssertion.IsNot: {
        return question.isMultiAnswer
          ? Array.isArray(value) && doAllAnswersExistInQuestion(value, question)
          : !Array.isArray(value) && doAnswerExistsInQuestion(value, question)
      }
    }
  })
}

export const isBulkOrderRuleValid = (rule: Rule, groups: Record<string, Group>) => {
  const bulkOrderGroup = Object.values(groups).find(group => group.type === GroupType.BulkOrder)
  if (!bulkOrderGroup) return true

  const areSomeWhenInBulkOrder = rule.when?.some(({ path }) => bulkOrderGroup?.children.includes(path[1]))
  const areAllThenInBulkOrder = rule.then?.every(({ questionId }) => bulkOrderGroup?.children.includes(questionId))

  return !areSomeWhenInBulkOrder || (areSomeWhenInBulkOrder && areAllThenInBulkOrder)
}

interface isValidRuleParams {
  rule: Rule
  questions: Record<string, Question>
  groups: Record<string, Group>
}

const isValidRule = ({ rule, questions, groups }: isValidRuleParams) => {
  return (
    !rule.archived &&
    isValidWhen(rule.when, questions) &&
    isValidThen(rule.then, questions) &&
    isBulkOrderRuleValid(rule, groups)
  )
}

export default isValidRule
