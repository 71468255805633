import React from 'react'

import LeftHand, { type LeftHandProps } from './LeftHand'
import RightHand, { type RightHandProps } from './RightHand'

interface LeftOrRightProps extends LeftHandProps, RightHandProps {
  value: 'and' | 'then'
}

const LeftOrRight = (props: LeftOrRightProps) => {
  switch (props.value) {
    case 'and':
      return <LeftHand {...props} />
    case 'then':
      return <RightHand {...props} />
  }
}

export default LeftOrRight
