import classNames from 'classnames'
import React from 'react'

export interface BlankStateTitleProps {
  className?: string
  children?: React.ReactNode
}

const BlankStateTitle = ({ children, className }: BlankStateTitleProps) => (
  <h2 className={classNames('mt-6', className)}>{children}</h2>
)

export default BlankStateTitle
