import { PartType } from '@packages/types'
import { generateId } from '@packages/unique-string'

import * as constants from 'common/customizerProducts/constants'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import * as types from './actionTypes'
import {
  isQuestionEditableSelector,
  partFromQuestionIdSelector,
  partsSelector,
  firstLevelAncestorSelector,
} from './selectors'

export const editQuestion = questionId => (dispatch, getState) => {
  const state = getState()
  const isEditable = isQuestionEditableSelector(state, questionId)

  if (isEditable) {
    const part = partFromQuestionIdSelector(state, questionId)
    dispatch(editPart(part.id))
  } else {
    dispatch(stopEditPart())
  }
}

export const forceView = questionId => (dispatch, getState) => {
  const state = getState()
  const firstLevelAncestor = firstLevelAncestorSelector(state, questionId)

  const questionForceView = state.customization.questions[questionId]?.forceView
  const forceView = questionForceView ?? firstLevelAncestor?.forceView

  if (forceView !== null && forceView !== undefined) dispatch(twoDDisplayerActions.switchView(forceView))
}

export const createTextAnswer =
  (questionId, text, productViewText, createOnly = false) =>
  dispatch => {
    dispatch(forceView(questionId))
    const result = dispatch({
      type: types.CREATE_TEXT_ANSWER,
      payload: { questionId, answerId: generateId('ANSWER'), text, productViewText, createOnly },
    })

    dispatch(editQuestion(questionId))

    return result
  }

export const updateText = (questionId, answerId, text, productViewText) => dispatch => {
  if (answerId != null) {
    const result = dispatch({
      type: types.UPDATE_TEXT_ANSWER,
      payload: { questionId, answerId, text, productViewText },
    })
    dispatch(editQuestion(questionId))
    return result
  }

  dispatch(forceView(questionId))
  return dispatch(createTextAnswer(questionId, text, productViewText))
}

export const createLogoAnswer = (questionId, asset) => {
  return async dispatch => {
    const answerId = generateId('ANSWER')

    dispatch(forceView(questionId))
    dispatch({ type: types.CREATE_LOGO_ANSWER, payload: { questionId, answerId, asset } })
    dispatch(editQuestion(questionId))

    return answerId
  }
}

export const createAssetAnswer = (questionId, value) => {
  return dispatch => {
    const answerId = generateId('ANSWER')

    dispatch(forceView(questionId))
    dispatch({ type: types.CREATE_ASSET_ANSWER, payload: { questionId, answerId, value } })
    dispatch(editQuestion(questionId))

    return answerId
  }
}

export const createColorAnswer = (questionId, color) => {
  return dispatch => {
    dispatch(forceView(questionId))
    const result = dispatch({
      type: types.CREATE_COLOR_ANSWER,
      payload: {
        questionId,
        answerId: generateId('ANSWER'),
        color,
      },
    })
    dispatch(editQuestion(questionId))
    return result
  }
}

export const updateColorAnswer = (questionId, answerId, color) => {
  return dispatch => {
    dispatch(editQuestion(questionId))
    dispatch(forceView(questionId))
    if (answerId != null) {
      return dispatch({
        type: types.UPDATE_COLOR_ANSWER,
        payload: { answerId, color },
      })
    }
  }
}

export const updatePrintAreaLogoPositionAnswer = (answerId, position) => {
  return {
    type: types.UPDATE_PRINT_AREA_LOGO_POSITION_ANSWER,
    payload: { answerId, position },
  }
}

export const updatePrintAreaTextPositionAnswer = (answerId, position) => {
  return {
    type: types.UPDATE_PRINT_AREA_TEXT_POSITION_ANSWER,
    payload: { answerId, position },
  }
}

export const createFontSizeAnswer = (questionId, data) => {
  return {
    type: types.CREATE_FONT_SIZE_ANSWER,
    payload: { answerId: generateId('ANSWER'), questionId, ...data },
  }
}

export const updateFontSizeAnswer = (answerId, data) => {
  return {
    type: types.UPDATE_FONT_SIZE_ANSWER,
    payload: { answerId, ...data },
  }
}

export const startCustomization = payload => ({ type: types.START_CUSTOMIZATION, payload })

export const startDesignCustomization = payload => ({ type: types.START_DESIGN_CUSTOMIZATION, payload })

export const selectAnswerWhereAvailable = answerId => {
  return {
    type: types.SELECT_ANSWER_WHERE_AVAILABLE,
    payload: { answerId },
  }
}

const isLogoPartWithoutLogo = (state, partId) => {
  const { currentView } = state.displayer

  if (partId) {
    const parts = partsSelector(state)
    const part = parts.find(part => part.id === partId)

    if (part && part.type === PartType.Logo) {
      return part.logo?.selectedAnswer == null || !part.logo.selectedAnswer.views[currentView].logo
    }
  }

  return false
}

export const selectAnswer = (questionId, answerId) => {
  return (dispatch, getState) => {
    dispatch({ type: types.SELECT_ANSWER, payload: { questionId, answerId } })
    dispatch(forceView(questionId))
    const state = getState()
    const { editedPart: previouslyEditedPartId } = state.displayer

    if (isLogoPartWithoutLogo(state, previouslyEditedPartId)) dispatch(stopEditPart())

    const part = partFromQuestionIdSelector(state, questionId)

    isLogoPartWithoutLogo(state, part?.id) ? dispatch(stopEditPart()) : dispatch(editQuestion(questionId))
  }
}

export const selectAnswers = (questionId, answerIds) => {
  return dispatch => {
    dispatch(forceView(questionId))
    dispatch({ type: types.SELECT_ANSWERS, payload: { questionId, answerIds } })
    dispatch(stopEditPart())
  }
}

export const removeAnswer = (questionId, answerId) => {
  return (dispatch, getState) => {
    dispatch(forceView(questionId))
    dispatch({ type: types.REMOVE_ANSWER, payload: { questionId, answerId } })
    const state = getState()
    const { editedPart: previouslyEditedPartId } = state.displayer

    if (isLogoPartWithoutLogo(state, previouslyEditedPartId)) dispatch(stopEditPart())
  }
}

export const editPart = partId => (dispatch, getState) => {
  const state = getState()
  const part = state.customization.parts[partId]
  const printArea = state.customization.printAreas[part.printArea]

  dispatch({
    type: types.EDIT_PART,
    payload: { part, view: printArea?.productPreview.designView },
  })
}

export const stopEditPart = () => (dispatch, getState) => {
  const state = getState()
  const editedPartId = state.displayer.editedPart

  if (editedPartId) {
    dispatch({ type: types.STOP_EDIT_PART, payload: { part: state.customization.parts[editedPartId] } })
  }
}

export const setQuestionDefaultConfiguration = questionId => {
  return { type: types.SET_QUESTION_DEFAULT_CONFIGURATION, payload: questionId }
}

export const setQuestionHasFilteredWord = (questionId, hasFilteredWord) => ({
  type: types.SET_QUESTION_HAS_FILTERED_WORD,
  payload: { questionId, hasFilteredWord },
})

export const setQuestionHasRequiredError = (questionId, hasRequiredError) => ({
  type: types.SET_QUESTION_HAS_REQUIRED_ERROR,
  payload: { questionId, hasRequiredError },
})

export const disableSubmitAction = () => {
  return { type: types.DISABLE_SUBMIT }
}
