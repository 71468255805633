import { DataTablePersistence } from './persistence/types/persistence'

type TableSearchPersistence = { filter: string }

type TableSearchConfig = { onChange?: () => void }

const useTableSearch =
  <TModel extends TableSearchPersistence = any>({ onChange }: TableSearchConfig) =>
  ({ state, setState }: DataTablePersistence<TModel>) => {
    const handleSetValue = (filter?: string) => {
      setState(state => ({ ...state, filter }))
      onChange?.()
    }

    return { search: state.filter, setSearch: handleSetValue }
  }

export default useTableSearch
