import { NormalizedCustomizerProduct, QuestionInputType } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import Modal from 'common/components/modal/Modal'
import Button from 'common/components/Button'
import IconButton from 'common/components/IconButton'
import Alert from 'common/components/alert/Alert'
import ProductQuestionsDropdown from 'common/components/ProductQuestionsDropdown'
import XIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/close.svg'
import AddIcon from 'icons/custom/streamlinehq-interface-add-1-interface-essential-250.svg'

export interface SelectQuestionsProps {
  setQuestionIds: React.Dispatch<React.SetStateAction<(string | undefined)[]>>
  questionIds: (string | undefined)[]
  customizerProduct: NormalizedCustomizerProduct
  excludedQuestionIds?: string[]
  totalVariantCount: number
  isOverMaxVariantCount: boolean
}

const SelectQuestions = ({
  customizerProduct,
  questionIds,
  setQuestionIds,
  excludedQuestionIds = [],
  totalVariantCount,
  isOverMaxVariantCount,
}: SelectQuestionsProps) => {
  const setQuestionId = (questionId: string | undefined, index: number) => {
    setQuestionIds(questionIds => {
      const newQuestionIds = [...questionIds]
      newQuestionIds[index] = questionId
      return newQuestionIds
    })
  }

  return (
    <Modal.Details className="border-t border-neutral-100 pt-6">
      <div className="flex flex-col space-y-4">
        <Alert variant="info">
          <Alert.Details>Text field questions cannot be used to create variants</Alert.Details>
        </Alert>
        {questionIds.map((questionId, i) => (
          <div key={`select-question-${i}`} className="flex justify-between">
            <div className="flex w-56 space-x-2">
              <div className="grow">
                <ProductQuestionsDropdown
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  customizerProduct={customizerProduct}
                  onSelect={({ value }) => setQuestionId(value, i)}
                  excludeEmptyQuestions
                  exclude={[
                    ...excludedQuestionIds,
                    ...(questionIds.filter(
                      selectedQuestionId => selectedQuestionId != null && selectedQuestionId !== questionId
                    ) as string[]),
                    ...Object.values(customizerProduct.questions)
                      .filter(question => question.inputType === QuestionInputType.Text)
                      .map(question => question.id),
                  ]}
                  value={questionId}
                  aria-label={`Select question ${i + 1}`}
                />
              </div>
              {questionIds.length > 1 && (
                <IconButton
                  smallIcon
                  Icon={XIcon}
                  iconClassName="w-3 h-3"
                  onClick={() =>
                    setQuestionIds(questionIds => questionIds.filter((_, questionIndex) => questionIndex !== i))
                  }
                />
              )}
            </div>
            {questionId && (
              <div className="flex items-center">
                {i > 0 && <XIcon className="w-1.5 h-1.5 mr-2" />}
                {customizerProduct.questions[questionId].answers.length} answer
                {customizerProduct.questions[questionId].answers.length > 1 ? 's' : ''}
              </div>
            )}
          </div>
        ))}
        <div className="w-full flex justify-between">
          <Button
            icon={<AddIcon className="w-2.5 h-2.5" />}
            onClick={() => setQuestionIds(questionIds => [...questionIds, undefined])}
            disabled={questionIds.length === Object.keys(customizerProduct.questions).length || isOverMaxVariantCount}
          >
            Combine another question
          </Button>
          {totalVariantCount > 0 && (
            <div
              className={classNames('flex justify-end items-center border-t pt-1.5 border-neutral-200', {
                'text-tertiary-red-500': isOverMaxVariantCount,
              })}
            >
              {totalVariantCount} variant{totalVariantCount > 1 ? 's' : ''}
            </div>
          )}
        </div>
      </div>
    </Modal.Details>
  )
}

export default SelectQuestions
