import React from 'react'

export interface PanelHeaderSectionProps {
  children: React.ReactNode
}

const PanelHeaderSection = ({ children }: PanelHeaderSectionProps) => (
  <div className="flex h-[52px] basis-[52px] border-b border-neutral-75 px-4 items-center">{children}</div>
)

export default PanelHeaderSection
