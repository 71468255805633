import { PaymentStrategy } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useHistory, useParams } from 'react-router'

import useBrandService from 'cms/brands/hooks/useBrandService'
import useSubscriptionService from 'cms/subscription/hooks/useSubscriptionService'
import Button from 'common/components/Button'
import Modal, { ModalProps } from 'common/components/modal/Modal'
import { FeatureFlag } from 'common/users/components/withFlag'
import ShopifyIcon from 'icons/custom/shopify-icon.svg'
import WixIcon from 'icons/custom/wix-icon.svg'
import WooCommerceIcon from 'icons/custom/woocommerce-icon.svg'

export interface AddOnlineStoreModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
}

const AddOnlineStoreModal = ({ onClose, ...modalProps }: AddOnlineStoreModalProps) => {
  const history = useHistory()
  const params = useParams<{ brandName: string }>()
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''
  const subscriptionService = useSubscriptionService()
  const brandService = useBrandService()

  const { data: subscription } = useQuery(subscriptionService.fetchOne.queryKeys, subscriptionService.fetchOne)
  const { data: brand } = useQuery(brandService.fetchAccount.queryKeys, brandService.fetchAccount)

  if (!subscription || !brand) return null

  const ExternalPlatforms = () => (
    <div className="px-4 pt-6">
      <Button
        variant="custom-color"
        className="text-shopify mb-3 w-full mx-auto"
        onClick={() => window.open(ADD_SHOPIFY_STORE_URL)}
      >
        <div className="w-full flex items-center justify-center space-x-2">
          <ShopifyIcon className="w-4 h-4 fill-white" />
          <span>Shopify</span>
        </div>
      </Button>
      <FeatureFlag feature="wix">
        <Button
          variant="custom-color"
          className="text-wix mb-3 w-full mx-auto"
          onClick={() => window.open(ADD_WIX_STORE_URL)}
        >
          <div className="w-full flex items-center justify-center space-x-2">
            <WixIcon className="w-4 h-4" />
            <span>Wix</span>
          </div>
        </Button>
      </FeatureFlag>
      <Button variant="custom-color" className="mb-3 w-full" onClick={() => window.open(ADD_WOOCOMMERCE_STORE_URL)}>
        <div className="w-full flex items-center justify-center space-x-2">
          <WooCommerceIcon className="w-4 h-4 fill-white" />
          <span>WooCommerce</span>
        </div>
      </Button>
    </div>
  )

  const areCustomStoresAllowed: boolean =
    subscription.paymentStrategy == PaymentStrategy.Manual && brand.areCustomStoresAllowed

  return (
    <Modal size="small" onBackdropClick={onClose} {...modalProps}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Select your online store platform</Modal.Title>

      <div className="flex flex-col">
        {areCustomStoresAllowed ? (
          <div className="px-4 py-6">
            <Button
              className="w-full text-center"
              variant="custom-color"
              onClick={() => history.push(`${baseUrl}/onlinestores/create`)}
            >
              <div className="w-full">Add custom store</div>
            </Button>
          </div>
        ) : (
          <ExternalPlatforms />
        )}
        <Modal.Divider />
        {areCustomStoresAllowed ? (
          <ExternalPlatforms />
        ) : (
          <span className="m-3 text-center text-neutral-600 text-sm">
            For custom store integrations{' '}
            <a href="https://gokickflip.com/get-started/" target="_blank" className="text-sm text-primary-600">
              contact us
            </a>
            .
          </span>
        )}
      </div>
    </Modal>
  )
}

export default AddOnlineStoreModal
