import React from 'react'

import Checkbox from 'common/components/inputs/Checkbox'
import Tooltip from 'common/components/Tooltip'
import withFlag from 'common/users/components/withFlag'

export interface PreviewOutOfStockSettingProps {
  setOutOfStockState: (outOfStock: boolean) => void
  outOfStockState: boolean
}

const PreviewOutOfStockSetting = ({ setOutOfStockState, outOfStockState }: PreviewOutOfStockSettingProps) => (
  <Tooltip content="Out of stock indicators are displayed to help you preview what it will look like.">
    <div className="flex space-x-2 items-center mr-6">
      <Checkbox id="show-out-of-stock" onChange={e => setOutOfStockState(e.target.checked)} checked={outOfStockState} />
      <label htmlFor="show-out-of-stock" className="hover:cursor-pointer leading-4">
        Out of stock
      </label>
    </div>
  </Tooltip>
)

export default withFlag({
  Component: PreviewOutOfStockSetting,
  feature: 'inventory_management',
})
