import classNames from 'classnames'
import React, { useContext } from 'react'

export const QuestionPanelScrimContext = React.createContext<{
  showScrim: boolean
  setShowScrim: (newValue: boolean) => void
}>({ showScrim: false, setShowScrim: () => {} })

const QuestionPanelScrim = () => {
  const { showScrim } = useContext(QuestionPanelScrimContext)

  return (
    <div
      className={classNames(
        'absolute w-[calc(100%_+_1px)] h-0 z-[4] opacity-0 transition-opacity duration-[0.3s] ease-[ease-in-out] bg-[rgba(0,0,0,0.1)]',
        { 'h-full opacity-100': showScrim }
      )}
    />
  )
}

export default QuestionPanelScrim
