import {
  DndContext,
  DragOverlay,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { useState, RefAttributes } from 'react'
import { createPortal } from 'react-dom'

export type CardComponentProps<T extends Record<string, any>> = T & {
  isDragged: boolean
  isDragOverlay: boolean
  style?: {
    transform: string | undefined
    transition: string | undefined
  }
}

export type CardComponentType<T extends Record<string, any>, TElement extends HTMLElement> = React.FC<
  React.PropsWithChildren<CardComponentProps<T> & RefAttributes<TElement>>
>

export interface SortableItemProps<T extends Record<string, any>, TElement extends HTMLElement> {
  id: string
  item: T
  CardComponent: CardComponentType<T, TElement>
}

const SortableItem = <T extends Record<string, any>, TElement extends HTMLElement>({
  CardComponent,
  ...props
}: SortableItemProps<T, TElement>) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <CardComponent
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      {...props.item}
      isDragged={(active?.id as string) === props.id}
      isDragOverlay={false}
    />
  )
}

type SortableItem<T> = {
  id: string
  props: T
}

export type ReorderDetails = { sourceIndex: number; destinationIndex: number; sourceId: string; destinationId: string }

export interface CardListProps<T extends Record<string, any>, TElement extends HTMLElement> {
  onReorder: (reorderDetails: ReorderDetails) => void
  items: SortableItem<T>[]
  CardComponent: CardComponentType<T, TElement>
  className?: string
}

const CardList = <T extends Record<string, any>, TElement extends HTMLElement>({
  onReorder,
  items,
  CardComponent,
  className,
}: CardListProps<T, TElement>) => {
  const [activeId, setActiveId] = useState<string | null>(null)

  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 3 } }))

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    setActiveId(active.id as string)
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event

    if (over && active.id !== over.id) {
      const sourceIndex = items.findIndex(item => item.id === active.id)
      const destinationIndex = items.findIndex(item => item.id === over.id)

      onReorder({ sourceIndex, destinationIndex, sourceId: active.id as string, destinationId: over.id as string })
    }

    setActiveId(null)
  }

  const activeItem = items.find(item => item.id === activeId)

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items.map(item => item.id)} strategy={verticalListSortingStrategy}>
        <div className={className}>
          {items.map(item => (
            <SortableItem<T, TElement> key={item.id} id={item.id} item={item.props} CardComponent={CardComponent} />
          ))}
        </div>
      </SortableContext>
      {createPortal(
        <DragOverlay>
          {activeItem ? <CardComponent {...activeItem.props} isDragged={false} isDragOverlay /> : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  )
}

export default CardList
