import React from 'react'

import BlankState from 'common/components/blankState/BlankState'
import SkateboardIcon from 'icons/custom/bold-skateboard.svg'

export type ArchivedBlankStateProps = {
  onClick: () => void
}

const ArchivedBlankState = ({ onClick }: ArchivedBlankStateProps) => {
  return (
    <>
      <BlankState.Icon Icon={SkateboardIcon} className="text-neutral-300" />
      <BlankState.Title>There are no archived products, yet</BlankState.Title>
      <BlankState.Details>
        Once you archive products they will be shown here so you can restore them.
      </BlankState.Details>
      <BlankState.Action onClick={onClick}>Go to active products</BlankState.Action>
    </>
  )
}

export default ArchivedBlankState
