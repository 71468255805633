import { BrandUser } from '@packages/types'
import { useContext } from 'react'

import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import TenantContext from 'common/tenant/TenantContext'
import type { CreateInvitedUserFormValues, UserInviteFormValues } from './../types/form'

const useUserService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetchInvitedUsers: createQuery([tenant, 'userinvites'], async () => {
      const { data } = await apiClient.get<BrandUser[]>(`/brands/${tenant}/users/invite`)
      return data
    }),
    invite: async (formValues: UserInviteFormValues) => {
      const { data } = await apiClient.post<BrandUser>(`/brands/${tenant}/users/invite`, formValues)
      return data
    },
    updateToken: async (email: string) => {
      const { data } = await apiClient.post<BrandUser>(`/brands/${tenant}/users/resend-invite`, { email })
      return data
    },
    deleteInvitedUser: (id: string) => {
      return apiClient.delete<void, void>(`/brands/${tenant}/users/invite/${id}`)
    },
    validateInviteToken: createQuery([tenant, 'validate-invite-token'], async (token: string) => {
      return apiClient.get(`${ACCEPT_INVITE_URL}?token=${token}`)
    }),
    acceptInvite: async (token: string, formValues: CreateInvitedUserFormValues) => {
      const { data } = await apiClient.post<string>(`/brands/${tenant}/users/accept-invite/?token=${token}`, formValues)
      return data
    },
  }
}

export default useUserService
