import { ThemeName } from '@packages/types'
import React, { useEffect, useState } from 'react'

import { trpc } from 'common/hooks/trpc'

const sections = {
  barebones: {
    input: React.lazy(() => import('themes/common/builder/components/InputSection')),
    settings: React.lazy(() => import('themes/barebones/builder/components/StepThumbnailSection')),
  },
  booklike: {
    input: React.lazy(() => import('themes/booklike/builder/components/InputSection')),
    settings: null,
  },
}

type SectionKeys = 'settings' | 'input'

export type InputType = typeof sections.barebones.input
export type SettingsType = typeof sections.barebones.settings

const useThemeSection = <S extends SectionKeys>(sectionName: S) => {
  type ReturnType = S extends 'input' ? InputType : SettingsType
  const [section, setSection] = useState<ReturnType | null>(null)

  const { data: theme } = trpc.theme.getCurrent.useQuery()

  useEffect(() => {
    const themeName = theme?.name || ThemeName.Booklike
    const section = sections[themeName]?.[sectionName] as ReturnType
    setSection(section || null)
  }, [theme])

  return section
}

export default useThemeSection
