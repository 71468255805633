import React from 'react'

export interface PrintAreaProps {
  color: string
  width: number
  height: number
  bleed: number
  margins: {
    horizontal: number
    vertical: number
  }
}

const PrintArea = (props: PrintAreaProps) => {
  const hasBleed = props.bleed > 0 || props.bleed > 0

  const hasHorizontalMargins = props.margins.horizontal > 0
  const hasVerticalMargins = props.margins.vertical > 0

  const totalWidth = props.width + props.bleed * 2
  const totalHeight = props.height + props.bleed * 2

  const bleedXPercent = (props.bleed / totalWidth) * 100
  const bleedYPercent = (props.bleed / totalHeight) * 100

  const marginXPercent = (props.margins.horizontal / props.width) * 100
  const marginYPercent = (props.margins.vertical / props.width) * 100

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {hasBleed && (
        <>
          <div
            style={{
              width: `${100 - bleedXPercent * 2}%`,
              left: `${bleedXPercent}%`,
              top: `${bleedYPercent}%`,
              position: 'absolute',
              height: '1px',
              background: `${props.color}`,
            }}
          />
          <div
            style={{
              width: `${100 - bleedXPercent * 2}%`,
              left: `${bleedXPercent}%`,
              top: `${100 - bleedYPercent}%`,
              position: 'absolute',
              height: '1px',
              background: `${props.color}`,
            }}
          />
          <div
            style={{
              height: `${100 - bleedYPercent * 2}%`,
              left: `${bleedXPercent}%`,
              top: `${bleedYPercent}%`,
              position: 'absolute',
              width: '1px',
              background: `${props.color}`,
            }}
          />
          <div
            style={{
              height: `${100 - bleedYPercent * 2}%`,
              left: `${100 - bleedXPercent}%`,
              top: `${bleedYPercent}%`,
              position: 'absolute',
              width: '1px',
              background: `${props.color}`,
            }}
          />
        </>
      )}
      <div
        style={{
          position: 'absolute',
          width: `${100 - bleedXPercent * 2}%`,
          height: `${100 - bleedYPercent * 2}%`,
          left: `${bleedXPercent}%`,
          top: `${bleedYPercent}%`,
        }}
      >
        {hasHorizontalMargins && (
          <>
            <div
              style={{
                height: `${100 - marginYPercent * 2}%`,
                left: `${marginXPercent}%`,
                top: `${marginYPercent}%`,
                position: 'absolute',
                width: '1px',
                backgroundImage: `linear-gradient(to bottom, black 66%, rgba(255,255,255,0) 0%)`,
                backgroundPosition: 'center top',
                backgroundSize: '1px 15px',
                backgroundRepeat: 'repeat-y',
              }}
            />
            <div
              style={{
                height: `${100 - marginYPercent * 2}%`,
                left: `${100 - marginXPercent}%`,
                top: `${marginYPercent}%`,
                position: 'absolute',
                width: '1px',
                backgroundImage: `linear-gradient(to bottom, black 66%, rgba(255,255,255,0) 0%)`,
                backgroundPosition: 'center top',
                backgroundSize: '1px 15px',
                backgroundRepeat: 'repeat-y',
              }}
            />
          </>
        )}
        {hasVerticalMargins && (
          <>
            <div
              style={{
                width: `${100 - marginXPercent * 2}%`,
                left: `${marginXPercent}%`,
                top: `${marginYPercent}%`,
                position: 'absolute',
                height: '1px',
                backgroundImage: `linear-gradient(to right, black 66%, rgba(255,255,255,0) 0%)`,
                backgroundPosition: 'left center',
                backgroundSize: '15px 1px',
                backgroundRepeat: 'repeat-x',
              }}
            />
            <div
              style={{
                width: `${100 - marginXPercent * 2}%`,
                left: `${marginXPercent}%`,
                top: `${100 - marginYPercent}%`,
                position: 'absolute',
                height: '1px',
                backgroundImage: `linear-gradient(to right, black 66%, rgba(255,255,255,0) 0%)`,
                backgroundPosition: 'left center',
                backgroundSize: '15px 1px',
                backgroundRepeat: 'repeat-x',
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default PrintArea
