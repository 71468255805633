import { ShopifyOnlineStore } from '@packages/types'
import React from 'react'

import Page from 'cms/layout/page/Page'
import Card from 'common/components/card/Card'

interface ShopifyOnlineStoreInfoProps {
  onlineStore: ShopifyOnlineStore
}

const ShopifyOnlineStoreInfo = ({ onlineStore }: ShopifyOnlineStoreInfoProps) => (
  <Page.Section>
    <Page.Aside
      title="Store"
      description="This information comes from your ecommerce. Please change it from there if the infos is inaccurate."
    />
    <Page.Content>
      <Card className="flex flex-col flex-1">
        <Card.Section>
          <div className="flex flex-col flex-[2] border-r border-neutral-100">
            <span className="text-xs font-medium text-neutral-400 mb-4">Online store name</span>
            <span>{onlineStore.name}</span>
          </div>
          <div className="flex flex-col flex-1 text-right">
            <span className="text-xs font-medium text-neutral-400 mb-4">Default currency</span>
            <span>{onlineStore.currency}</span>
          </div>
        </Card.Section>

        <Card.Separator />

        <Card.Section className="md:flex-col">
          <span className="text-xs font-medium text-neutral-400 mb-4">Domain</span>
          <a className="text-primary-400" href={`https://${onlineStore.domain}`} target="_blank">
            {onlineStore.domain}
          </a>
        </Card.Section>
      </Card>
    </Page.Content>
  </Page.Section>
)

export default ShopifyOnlineStoreInfo
