import { BrandUserRole } from '@packages/types'
import React from 'react'

import SettingsHeader from 'cms/layout/SettingsHeader'
import Page from 'cms/layout/page/Page'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import { NetworkError } from 'common/api/types/error'
import useToast from 'common/components/toast/useToast'
import { ToastType } from 'common/components/toast/types/toastType'
import useGoBack from 'common/hooks/useGoBack'
import { trpc } from 'common/hooks/trpc'

import CreateUserForm from './CreateUserForm'

const roleOptions = [{ value: BrandUserRole.Admin, label: 'Admin' }]

const CreateUser = () => {
  const goBack = useGoBack()
  const { openToast, openGenericErrorToast } = useToast()

  const { mutate: createUser } = trpc.user.create.useMutation({
    onSuccess: () => {
      openToast('User was successfully created!', ToastType.success)
      goBack()
    },
    onError: (error: NetworkError, { email }) => {
      if (error.data?.code === 'CONFLICT') {
        openToast(`The email ${email} is already in use by another user`, ToastType.warning)
      } else {
        openGenericErrorToast('User has not been created.')
      }
    },
  })

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page>
        <CreateUserForm
          roleOptions={roleOptions}
          onSubmit={(values, helpers) =>
            createUser(
              { ...values, role: values.role as BrandUserRole },
              { onSettled: () => helpers.setSubmitting(false) }
            )
          }
          onCancel={goBack}
        />
      </Page>
    </main>
  )
}

export default CreateUser
