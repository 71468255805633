import { ShopifyOnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import Page from 'cms/layout/page/Page'
import useShopifyProxyService from 'cms/onlineStores/hooks/useShopifyProxyService'

import AppEmbedSection from './AppEmbedSection'
import LegacySections from './LegacySections'
import ShopifyMultiCurrency from './ShopifyMultiCurrency'
import ShopifyOnlineStoreInfo from './ShopifyOnlineStoreInfo'

interface ShopifyOnlineStoreProps {
  onlineStore: ShopifyOnlineStore
}

const ShopifyOnlineStoreContent = ({ onlineStore }: ShopifyOnlineStoreProps) => {
  const shopifyProxyService = useShopifyProxyService()

  const { data: themes } = useQuery([...shopifyProxyService.fetchInstalledThemes.queryKeys, onlineStore.id], () =>
    shopifyProxyService.fetchInstalledThemes(onlineStore.id)
  )

  const { data: app } = useQuery([...shopifyProxyService.fetchApp.queryKeys, onlineStore.id], () =>
    shopifyProxyService.fetchApp(onlineStore.id)
  )

  return (
    <>
      <ShopifyOnlineStoreInfo onlineStore={onlineStore} />
      {!!onlineStore.currencies?.length && (
        <>
          <Page.Separator />
          <ShopifyMultiCurrency />
        </>
      )}
      <Page.Separator />
      {app?.isLegacy && <LegacySections onlineStore={onlineStore} themes={themes} />}
      {app && !app.isLegacy && <AppEmbedSection onlineStore={onlineStore} themes={themes} />}
    </>
  )
}

export default ShopifyOnlineStoreContent
