import { PartType, QuestionType, QuestionInputType, AnswerType, View } from '@packages/types'

export const defaultDimensions = { width: 1000, height: 1000 }

export const customizerProduct = {
  readOnlyProperties: ['jsVersion', 'id', '_id', '__v', 'createdAt', 'updatedAt', 'isDraft'] as const,
}

export const answers = {
  default: {
    [AnswerType.Material]: {
      type: AnswerType.Material,
      color: '#FFFFFF',
      disableColor: false,
      lighting: true,
      lightIntensity: 0.6,
      lightThreshold: 225,
    },
    [AnswerType.Image]: {
      type: AnswerType.Image,
      image: null,
    },
    [AnswerType.Color]: { type: AnswerType.Color, color: '#000000' },
    [AnswerType.Text]: { type: AnswerType.Text, text: '' },
    [AnswerType.Logo]: { type: AnswerType.Logo, logo: null },
    [AnswerType.LogoPosition]: {
      type: AnswerType.LogoPosition,
      hidden: false,
      maxWidth: 200,
      maxHeight: 200,
      rotation: 0,
      x: 250,
      y: 250,
    },
    [AnswerType.Position]: {
      type: AnswerType.Position,
      bezier: [203, 250, 203, 250, 300, 250, 300, 250],
      hidden: false,
      maxWidth: 200,
      maxHeight: 200,
      rotation: 0,
      textAlign: 'left',
      x: 250,
      y: 250,
    },
    [AnswerType.Font]: {
      type: AnswerType.Font,
      assets: {},
    },
    [AnswerType.FontSize]: { type: AnswerType.FontSize, size: '32px' },
    [AnswerType.Value]: {},
    [AnswerType.PrintAreaLogoPosition]: {},
    [AnswerType.PrintAreaTextPosition]: {},
    [AnswerType.Outline]: { type: AnswerType.Outline, color: '#B5B5B5', width: 0 },
  } as { [key in AnswerType]: View },
}

export const questions = {
  questionTypeNames: {
    [QuestionType.Image]: 'Image',
    [QuestionType.Material]: 'Color',
    [QuestionType.Logo]: 'Logo',
    [QuestionType.Text]: 'Text',
    [QuestionType.Color]: 'Color',
    [QuestionType.Font]: 'Font',
    [QuestionType.FontSize]: 'Font size',
    [QuestionType.Outline]: 'Outline',
  } as { [key in QuestionType]: string },
  partFields: {
    [QuestionType.Image]: 'image',
    [QuestionType.Material]: 'material',
    [QuestionType.Logo]: 'logo',
    [QuestionType.Text]: 'text',
    [QuestionType.Color]: 'color',
    [QuestionType.Font]: 'font',
    [QuestionType.FontSize]: 'fontSize',
    [QuestionType.Outline]: 'outline',
  } as { [key in QuestionType]: string },
  restrictedTypesByInputType: {
    [QuestionInputType.Text]: [
      QuestionType.Image,
      QuestionType.Logo,
      QuestionType.Color,
      QuestionType.Material,
      QuestionType.Font,
      QuestionType.FontSize,
      QuestionType.Outline,
    ],
    [QuestionInputType.File]: [
      QuestionType.Image,
      QuestionType.Text,
      QuestionType.Color,
      QuestionType.Material,
      QuestionType.Font,
      QuestionType.FontSize,
      QuestionType.Outline,
    ],
    [QuestionInputType.None]: [QuestionType.Value],
    [QuestionInputType.ColorPicker]: [
      QuestionType.Image,
      QuestionType.Logo,
      QuestionType.Text,
      QuestionType.Font,
      QuestionType.FontSize,
      QuestionType.Outline,
    ],
  } as { [key in QuestionInputType]: QuestionType[] },
  partNeedingAssignmentByType: {
    [QuestionType.Font]: PartType.Text,
    [QuestionType.FontSize]: PartType.Text,
    [QuestionType.Color]: PartType.Text,
    [QuestionType.Outline]: PartType.Text,
    [QuestionType.Material]: PartType.Image,
  } as { [key in QuestionType]: PartType },
  editableTypes: [
    QuestionType.Material,
    QuestionType.Font,
    QuestionType.Color,
    QuestionType.Logo,
    QuestionType.Image,
    QuestionType.Text,
    QuestionType.FontSize,
    QuestionType.Value,
    QuestionType.Outline,
  ],
  inputTypesWithInteractionRequired: [QuestionInputType.Dropdown, QuestionInputType.ColorPicker],
}

export const parts = {
  questionFields: {
    [PartType.Image]: [
      { field: 'image', type: QuestionType.Image },
      { field: 'material', type: QuestionType.Material },
    ],
    [PartType.Logo]: [
      { field: 'logo', type: QuestionType.Logo },
      { field: 'position', type: AnswerType.LogoPosition },
    ],
    [PartType.Text]: [
      { field: 'text', type: QuestionType.Text },
      { field: 'font', type: QuestionType.Font },
      { field: 'color', type: QuestionType.Color },
      { field: 'outline', type: QuestionType.Outline },
      { field: 'fontSize', type: QuestionType.FontSize },
      { field: 'position', type: AnswerType.Position },
    ],
  } as const,
  mainQuestionFields: {
    [PartType.Image]: 'image',
    [PartType.Logo]: 'logo',
    [PartType.Text]: 'text',
  } as const,
}
