import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'

interface PortalProps {
  children?: React.ReactNode
  target: string
  componentKey: string
  prepend?: boolean
  className: string
}

const Portal = ({ children, target, componentKey, prepend = false, className = '' }: PortalProps) => {
  const element = useMemo(() => {
    const spanElement = document.createElement('span')
    spanElement.setAttribute('id', componentKey)
    if (className) spanElement.setAttribute('class', className)

    return spanElement
  }, [])

  const node = document.getElementById(componentKey)
  if (node) ReactDOM.createPortal(children, node)

  const targetNode = document.querySelector(target)
  if (!targetNode) return null

  const newNode = prepend ? targetNode.insertBefore(element, targetNode.firstChild) : targetNode.appendChild(element)
  return ReactDOM.createPortal(children, newNode)
}

export default Portal
