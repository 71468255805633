import { PrintArea } from '@packages/types'
import React from 'react'

import VerticalFields from 'builder/build/common/components/VerticalFields'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import ViewButtons from 'builder/build/customizer/components/ViewButtons'

import VisibilitySetting from './VisibilitySetting'

interface ViewsSectionProps {
  printArea: PrintArea
}

const { currentViewSelector } = customizerSelectors
const { useCustomizerSelector } = customizerHooks

const ViewsSection = ({ printArea }: ViewsSectionProps) => {
  const currentView = useCustomizerSelector(currentViewSelector) ?? 0

  return (
    <VerticalFields>
      <ViewButtons currentView={currentView} />
      <VisibilitySetting currentView={currentView} printArea={printArea} />
    </VerticalFields>
  )
}

export default ViewsSection
