import * as normalize from '@packages/normalizer'
import { CustomizerProduct, NormalizedCustomizerProduct } from '@packages/types'
import { isEqual } from 'lodash'
import { createSelector } from 'reselect'

import { RootState } from 'cms/store'

const { Denormalizer, Normalizer } = normalize.customizerProduct

export const productBuilderSelector = ({ productBuilder }: RootState) => productBuilder

export const idSelector = (_state: RootState, id: string) => id

export const viewsSelector = createSelector(productBuilderSelector, ({ customizerProduct: { views } }) => views)

export const dimensionsSelector = createSelector(
  productBuilderSelector,
  ({ customizerProduct: { dimensions } }) => dimensions
)

export const customizerProductSelector = createSelector(productBuilderSelector, productBuilder => {
  return Denormalizer.run(productBuilder, productBuilder.customizerProduct, 'customizerProducts', {
    virtuals: false,
  }) as CustomizerProduct
})

export const normalizedCustomizerProductSelector = createSelector(customizerProductSelector, customizerProduct => {
  return {
    rules: {},
    questions: {},
    parts: {},
    groups: {},
    answers: {},
    printAreas: {},
    ...Normalizer.run(customizerProduct, 'customizerProducts'),
  } as NormalizedCustomizerProduct
})

export const pristineCustomizerProductSelector = createSelector(productBuilderSelector, ({ pristine }) => pristine)

export const partSelector = createSelector(customizerProductSelector, idSelector, ({ parts }, id) => {
  return parts.find(part => part.id === id)
})

export const partsSelector = createSelector(productBuilderSelector, productBuilder => {
  return Denormalizer.run({ ...productBuilder }, Object.keys(productBuilder.parts), 'parts')
})

export const isDraftSelector = createSelector(customizerProductSelector, ({ isDraft }) => isDraft)

export const isDirtySelector = createSelector(
  [normalizedCustomizerProductSelector, pristineCustomizerProductSelector],
  (a, b) => !isEqual(a, b)
)

export const productNameSelector = createSelector(productBuilderSelector, ({ productName }) => productName)

export const rulesSelector = createSelector(productBuilderSelector, ({ rules }) => rules)

export const rootIdSelector = ({ productBuilder }: RootState) => {
  return productBuilder.customizerProduct.tree
}

export const rulesAsArraySelector = createSelector(productBuilderSelector, ({ customizerProduct, rules }) =>
  customizerProduct.rules.map(ruleId => rules[ruleId])
)
