import { CustomizerIntegrationType } from '@packages/types'
import React from 'react'

import CustomizeButtonPreview from 'icons/customizerIntegration/customize-button-preview.svg'
import CustomizerOnlyPreview from 'icons/customizerIntegration/customizer-only-preview.svg'

interface IntegrationCardSectionProps {
  integrationType?: CustomizerIntegrationType
}

const IntegrationCardSection = ({ integrationType }: IntegrationCardSectionProps) => {
  return (
    <>
      <div className="flex align-top flex-[2] border-neutral-100">
        {integrationType === CustomizerIntegrationType.CustomizeButton ? (
          <CustomizeButtonPreview className="w-48" />
        ) : (
          <CustomizerOnlyPreview className="w-48" />
        )}
        <div className="flex flex-col ml-4">
          <span className="text-xs font-medium text-neutral-400 mb-4">Integration type</span>

          <span>
            {integrationType === CustomizerIntegrationType.CustomizeButton ? 'Customize button' : 'Customizer only'}
          </span>
        </div>
      </div>
    </>
  )
}

export default IntegrationCardSection
