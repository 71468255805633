import { FontType, FontAssets, OverflowBehavior, TextAlign, TextVerticalAlign } from '@packages/types'
import { useEffect, useMemo, useState } from 'react'

import MultilineTextSVG from 'common/drawing/MultilineTextSVG'

export interface useSVGTextProps {
  text: string
  fontFamily: string
  fontType?: FontType
  fontSize: number
  fontAssets: FontAssets
  textAlign: TextAlign
  verticalAlign: TextVerticalAlign
  color: string
  outlineColor: string
  outlineWidth: string
  boundingBox?: { maxWidth?: number; maxHeight?: number }
  overflowBehavior?: OverflowBehavior
}

const useMultilineSVGText = ({
  text,
  fontFamily,
  fontType,
  fontSize,
  fontAssets,
  textAlign,
  verticalAlign,
  color,
  outlineColor,
  outlineWidth,
  boundingBox,
  overflowBehavior,
}: useSVGTextProps) => {
  const svg = useMemo(() => new MultilineTextSVG(), [])
  const [html, setHTML] = useState('')

  useEffect(() => {
    svg
      .build({
        color: { hex: color },
        position: { textAlign, verticalAlign, boundingBox, overflowBehavior },
        text: { value: text },
        font: { size: `${fontSize}px`, family: fontFamily, assets: fontAssets, fontType },
        outline: { hex: outlineColor, width: outlineWidth },
      })
      .then(() => {
        const svgElement = svg.toSVG()
        const textElements: HTMLDivElement[] = []

        svgElement.childNodes.forEach(node => {
          if (node instanceof SVGForeignObjectElement) {
            const textContainerElement = node.children[0] as HTMLDivElement

            textContainerElement.style.width = '100%'
            textContainerElement.style.height = '100%'

            if (node.getAttributeNS(null, 'id') === 'text-outline') {
              textContainerElement.style.position = 'absolute'
              textContainerElement.style.zIndex = '-1'
            }

            textElements.push(textContainerElement)
          }
        })

        setHTML(textElements.map(textElement => textElement.outerHTML).join(''))
      })
  }, [text, fontFamily, fontSize, fontAssets, textAlign, color, outlineColor, outlineWidth, boundingBox])

  return html
}

export default useMultilineSVGText
