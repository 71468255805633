import React, { useEffect } from 'react'
import { useParams } from 'react-router'

import { partSelector } from 'builder/build/core/selectors'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import * as partsSelectors from 'builder/build/parts/selectors'
import { useSelector } from 'cms/hooks'
import * as constants from 'common/customizerProducts/constants'
import * as twoDDisplayerSelectors from 'customizer/2dDisplayer/selectors'
import * as customizationActions from 'customizer/customization/actions'

import { currentViewDrawingNodeSelectorWithoutRestrictions } from '../selectors'
import Canvas from './containers/Canvas'
import ClippingGroup from './containers/ClippingGroup'
import Info from './editors/Info'
import { getTransformedNode, getEditor } from './utils'

export interface PartEditorProps {
  partId: string
}

const PartEditor = ({ partId }: PartEditorProps) => {
  const customizerDispatch = customizerHooks.useCustomizerDispatch()
  const { id: routeId } = useParams<{ id: string }>()
  const routePart = useSelector(state => partsSelectors.partFromQuestionIdSelector(state, routeId))
  const isEditing = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.isEditingSelector)
  const currentView = customizerHooks.useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0

  const stopEditing = () => {
    customizerDispatch(customizationActions.stopEditPart())
  }

  useEffect(() => {
    if (routePart?.id !== partId && partId != null) {
      stopEditing()
    }
  }, [routeId])

  const part = useSelector(state => partSelector(state, partId))

  const drawingNodes = customizerHooks.useCustomizerSelector(state =>
    currentViewDrawingNodeSelectorWithoutRestrictions(state, partId)
  )

  if (routePart?.id !== partId) return null

  const transformedNode = getTransformedNode(partId, drawingNodes)

  if (!isEditing || !part) return null

  if (!transformedNode)
    return (
      <Canvas>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2">
          <Info
            message={`${part[constants.parts.mainQuestionFields[part.type]]?.name} is not shown on view ${currentView + 1}`}
            tooltip="You can toggle the visibility in the toolbar."
            onDoneClick={stopEditing}
          />
        </div>
      </Canvas>
    )

  const Editor = getEditor(transformedNode)

  const editor = <Editor node={transformedNode} entity={part} currentView={currentView} />

  return (
    <Canvas>
      {drawingNodes.map(node => {
        return node.type === 'clippingGroup' ? <ClippingGroup clippingGroup={node}>{editor}</ClippingGroup> : editor
      })}
    </Canvas>
  )
}

export default PartEditor
