import { Answer, Group, Question } from '@packages/types'
import React from 'react'
import { useHistory } from 'react-router'

import BlankState from 'common/components/blankState/BlankState'
import UniversalSearchList from 'common/components/universalSearch/UniversalSearchList'
import QuickActionIcon from 'icons/bold/01-Interface Essential/47-Connect/flash-1.svg'

import AnswerSearchResult from './AnswerSearchResult'
import EntitySearchResult from './EntitySearchResult'
import PrintAreaSearchResult from './PrintAreaSearchResult'
import useBuilderUniversalSearch from './useBuilderUniversalSearch'

interface SearchResultsContentProps {
  query: string
  groupedResults: ReturnType<typeof useBuilderUniversalSearch>
  onResultClick: () => void
}

const SearchResults = ({ query, groupedResults, onResultClick }: SearchResultsContentProps) => {
  const history = useHistory()

  const handleQuestionClick = (question: Question) => {
    onResultClick()
    history.push(`/questions/${question.id}`)
  }

  const handleAnswerClick = (question: Question, answer: Answer) => {
    onResultClick()
    history.push(`/questions/${question.id}?answer=${answer.id}`)
  }

  const handleGroupClick = (group: Group) => {
    onResultClick()
    history.push(`/groups/${group.id}`)
  }

  const handlePrintAreaClick = (question: Question) => {
    onResultClick()
    history.push(`/questions/${question.id}/print-area`)
  }

  if (groupedResults.length === 0) {
    return (
      <UniversalSearchList className="w-[500px] px-3">
        <BlankState>
          <BlankState.Title className="!mt-1">Your search yield no results</BlankState.Title>
          <BlankState.Details>Try adjusting your search query and make sure there is no typo</BlankState.Details>
        </BlankState>
      </UniversalSearchList>
    )
  }

  return (
    <UniversalSearchList className="w-[500px] max-h-[80vh]">
      {groupedResults.map(({ type, results }) => {
        switch (type) {
          case 'quickAction':
            return (
              <UniversalSearchList.Section key={type}>
                <UniversalSearchList.Title>
                  <QuickActionIcon className="h-3 w-3 fill-primary-400" />
                  <div>Quick actions</div>
                </UniversalSearchList.Title>
                {results.map(quickAction => (
                  <quickAction.Component key={quickAction.key} onClick={onResultClick} query={query} />
                ))}
              </UniversalSearchList.Section>
            )
          case 'question':
            return (
              <UniversalSearchList.Section key={type}>
                <UniversalSearchList.Title>Questions</UniversalSearchList.Title>
                {results.map(question => (
                  <EntitySearchResult
                    key={question.id}
                    query={query}
                    entity={question}
                    onClick={() => handleQuestionClick(question)}
                  />
                ))}
              </UniversalSearchList.Section>
            )
          case 'answer':
            return (
              <UniversalSearchList.Section key={type}>
                <UniversalSearchList.Title>Answers</UniversalSearchList.Title>
                {results.map(({ question, answer }) => (
                  <AnswerSearchResult
                    key={`${question.id}-${answer.id}`}
                    query={query}
                    question={question}
                    answer={answer}
                    onClick={() => handleAnswerClick(question, answer)}
                  />
                ))}
              </UniversalSearchList.Section>
            )
          case 'group':
            return (
              <UniversalSearchList.Section key={type}>
                <UniversalSearchList.Title>Groups</UniversalSearchList.Title>
                {results.map(group => (
                  <EntitySearchResult
                    key={group.id}
                    query={query}
                    entity={group}
                    onClick={() => handleGroupClick(group)}
                  />
                ))}
              </UniversalSearchList.Section>
            )
          case 'printArea':
            return (
              <UniversalSearchList.Section key={type}>
                <UniversalSearchList.Title>Print areas</UniversalSearchList.Title>
                {results.map(({ question, printArea }) => (
                  <PrintAreaSearchResult
                    key={`${question.id}-${printArea.id}`}
                    query={query}
                    question={question}
                    printArea={printArea}
                    onClick={() => handlePrintAreaClick(question)}
                  />
                ))}
              </UniversalSearchList.Section>
            )
          default:
            return null
        }
      })}
    </UniversalSearchList>
  )
}

export default SearchResults
